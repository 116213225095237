<template>
    <table class="table_style">
        <tr>
            <td rowspan="5">
                <p class="text-h6 font-weight-bold text-center mb-0">{{title}}</p>
                <p class="text-caption font-weight-bold text-center">({{width}}x{{height==null? "이미지 높이" : height}})</p>
                <v-checkbox
                    v-if="$route.path == '/admin/banner/ads'"
                    class="mx-auto"
                    style="width:64px;"
                    v-model="is_show"
                    dense
                    label="노출"
                    @change="banner_show_change"
                ></v-checkbox>
            </td>
        </tr>        
        <tr>
            <td class="ma-0 pa-0" style="background:white; text-align:center;">
                <v-sheet class="d-flex justify-center pa-3">
                    <v-img
                        :src="'/upload/banner_image/'+encodeURI(banner_image)" 
                        :width="width" 
                        :height="height"
                    ></v-img>
                </v-sheet>
            </td>
        </tr>           
        <tr>
            <td class="py-2 px-3" style="background:white;">
                <v-file-input
                    placeholder="업로드할 이미지를 선택해주세요"
                    outlined
                    dense
                    show-size
                    hide-details
                    prepend-icon
                    color="blue"
                    v-model="uploadImage"
                >
                    <template v-slot:prepend-inner>
                        <v-icon class="mr-1" color="blue">mdi-image-filter-hdr</v-icon>
                    </template>
                </v-file-input>
            </td>
        </tr>           
        <tr>
            <td class="py-2 px-3" style="background:white;">
                <v-text-field
                    solo
                    flat
                    dense
                    outlined
                    hide-details
                    placeholder="링크할 주소를 입력해주세요"
                    v-model="link"
                ></v-text-field>
            </td>
        </tr>           
        <tr>
            <td class="py-2 px-3">
                <v-btn
                    @click="banner_submit()"
                    width="100%"
                    depressed
                    dark
                    color="blue"
                >
                    배너 설정하기
                </v-btn>
            </td>
        </tr>
    </table>
</template>
<script>
export default {
    props: ["title", "type", "width", "height"],

    data: () => ({
        banner_image: "",
        link:"",
        uploadImage: null,
        is_show: false
    }),

    mounted(){
        this.$http.post('/api/admin/main/banner/import', {
            params: {
                type: this.type,
            }
        }).then((res) => {
            this.banner_image = res.data[0].banner_image
            this.link = res.data[0].link
            this.is_show = res.data[0].is_show
        })
    },

    methods: {
        image_and_link: _.debounce(function() {
            var file = this.uploadImage
            var formData = new FormData()
            formData.append("image", file)
            this.$http.post('/api/admin/main/banner/image', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                this.banner_image = res.data
                this.$http.post('/api/admin/main/banner/update', {
                    params: {
                        image: res.data,
                        link: this.link,
                        type: this.type,
                    }
                }).then(() => {
                    alert("배너 설정이 수정되었습니다.")
                })
            })
        }, 500),

        only_link(){
            this.$http.post('/api/admin/main/banner/update/link', {
                params: {
                    link: this.link,
                    type: this.type
                }
            }).then(() => {
                alert("배너 링크가 수정되었습니다.")
            })
        },

        banner_show_change(){
            this.$http.post('/api/admin/main/banner/update/show', {
                params: {
                    is_show: this.is_show,
                    type: this.type
                }
            }).then(() => {
                alert("배너 표시 여부가 수정되었습니다.")
            })
        },

        banner_submit(){
            if(this.uploadImage == null){
                this.only_link()
            }else{
                this.image_and_link()
            }
        }
    }
}
</script>
<style scoped>
.table_style{
    border:1px solid #ccc;
    border-collapse: collapse;
}

.table_style tr td{
    border:1px solid #ccc;
    padding:10px;
}

/* v-checkbox > 체크란, 라벨 사이 간격 */
::v-deep .v-input--checkbox .v-input--selection-controls__input {
    margin-right: 2px !important;
}
</style>