<template>
    <v-sheet class="transparent">
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">밴 IP 관리</font>
        </v-sheet>
        <v-sheet outlined class="pa-2 ma-2">
            <v-radio-group v-model="selected">
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="list"
                    :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                    no-data-text="현재 데이터가 없습니다."
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:45px;">
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <td style="width:100%"><font class="d-block text-truncate" style="width:100%">{{item.ip}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    disabled
                    large
                    class="px-6 mr-4 text-lowercase"
                >
                    선택항목: {{selectedItem.ip}}
                </v-btn>
                <v-btn
                    large dark color="blue"
                    class="px-8 ml-3"
                    @click="dialog.create = true"
                >
                    <v-icon small left>mdi-database-plus-outline</v-icon>
                    추가하기
                </v-btn>
                <v-btn
                    large dark color="orange"
                    class="px-8 ml-3"
                    @click="delete_submit()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    삭제하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- 밴 IP 추가하기 Dialog -->
        <v-dialog v-model="dialog.create" width="auto">
            <v-sheet width="420">
                <p class="pt-3 mb-3 font-weight-bold text-center text-subtitle-1 grey--text text--darken-2">
                    밴 IP 추가하기
                </p>
                <v-divider></v-divider>
                <v-sheet class="pa-3">
                    <table class="disposeTable">
                        <tr>
                            <td>밴 IP</td>
                            <td>
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    v-model="create.ip"
                                ></v-text-field>
                            </td>
                        </tr>
                    </table>
                    <v-sheet class="mt-2 pa-2 d-flex justify-center">
                        <v-btn large dark color="blue" class="px-6 mr-4" @click="create_submit()">
                            <v-icon small left>mdi-database-edit-outline</v-icon>추가하기
                        </v-btn>
                        <v-btn large dark color="grey" class="px-6" @click="dialog.create = false">
                            <v-icon small left>mdi-cancel</v-icon>취소하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '', value: ''},
            {text: '밴 IP', value: 'ip'},
        ],
        list: [],

        selected: false,
        selectedItem: {},

        dialog: {
            create: false
        },

        create: {
            ip: ""
        }
    }),

    mounted(){
        // 목록
        this.load()
    },

    methods: {
        // 목록
        load(){
            this.$http.post('/api/admin/user/ip_ban/list').then((res) => {
                this.list = res.data.map(e => {
                    return {
                        ...e, ip: e.ip.replace("::ffff:", "")
                    }
                })
            })
        },

        // 선택한 값 저장
        selectUpdate(item){
            this.selectedItem = item
        },

        // 추가하기 제출
        create_submit(){
            if(this.create.ip==""){
                alert("추가하실 레귤레이션를 입력해주세요.")
            }else{
                this.$http.post('/api/admin/user/ip_ban/insert', {
                    params: {
                        ip: "::ffff:"+this.create.ip
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        alert("레귤레이션이 추가되었습니다.")
                        this.dialog.create = false
                        this.load()
                    }
                })
            }
        },

        delete_submit(){
            if(!Object.keys(this.selectedItem).length){
                alert("아직 선택하신 레귤레이션이 없습니다.")
            }else{
                this.$http.post('/api/admin/user/ip_ban/delete', {
                    params: {
                        id: this.selectedItem.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        alert("레귤레이션이 삭제되었습니다.")
                        this.load()
                    }
                })
            }
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    width:85px;
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}

#selected_user{
    width:100%;
    border:1px solid #ccc;
    border-collapse: collapse;
}

#selected_user tr th{
    height:20px;
    padding:12px 20px;
    border:1px solid #ccc;
    background: #f7f7f7;
}

#selected_user tr td{
    height:20px;
    padding:12px 20px;
    border:1px solid #ccc;
}
</style>
<style scoped>
.disposeTable{
    width: 100%;
    border: 1px solid #e0e0e0;
    border-collapse: collapse;
}

.disposeTable tr td{
    padding: 12px;
    border: 1px solid #e0e0e0;
}

.disposeTable tr td:first-child{
    width:160px;
    text-align:center;
    background:#f8f8f8;
    font-weight:bold;
}
</style>