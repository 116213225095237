<template>
    <v-sheet>
        <v-sheet outlined class="d-flex align-center ma-2">
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">메인배너 관리</font>
        </v-sheet>
        <v-sheet outlined class="pa-4 ma-2 pt-6">
            <!-- 메인배너 설정 -->
            <p class="text-h5 font-weight-bold mb-2">메인배너 설정</p>
            <BannerSetting title="메인 배너" type="main" width="830" height="150" />

            <!-- 사이드 배너(1) 설정 -->
            <p class="text-h5 font-weight-bold mt-6 mb-2">사이드 배너(1) 설정</p>
            <BannerSetting title="사이드 배너" type="side1" width="200" :height="null" />

            <!-- 사이드 배너(2) 설정 -->
            <p class="text-h5 font-weight-bold mt-6 mb-2">사이드 배너(2) 설정</p>
            <BannerSetting title="사이드 배너" type="side2" width="200" :height="null" />

            <!-- 사이드 배너(3) 설정 -->
            <p class="text-h5 font-weight-bold mt-6 mb-2">사이드 배너(3) 설정</p>
            <BannerSetting title="사이드 배너" type="side3" width="200" :height="null" />
        </v-sheet>
    </v-sheet>
</template>
<script>
import BannerSetting from '@/components/BannerSetting'

export default {
    components: {
        BannerSetting
    }
}
</script>