<template>
    <v-sheet color="transparent">
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <!-- 페이지 제목 -->
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">시즌 관리</font>
            <v-spacer></v-spacer>

            <!-- 한글, 일본 시즌 선택 -->
            <v-sheet width="200">
                <v-select
                    class="mr-4"
                    outlined dense hide-details
                    v-model="selected_nation"
                    :items="select_list.set_nation"
                ></v-select>
            </v-sheet>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2">
                <v-sheet class="d-flex" width="660">
                    <v-sheet width="160" class="mr-3">
                        <v-select
                            outlined
                            hide-details
                            dense
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>
                    <v-text-field
                        v-model="keyword"
                        outlined
                        hide-details
                        dense
                        placeholder="검색어를 입력해주세요"
                        @keyup.enter="search()"
                    ></v-text-field>
                    <v-btn
                        height="40"
                        dark color="blue"
                        class="px-6 ml-3"
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-radio-group v-model="selected">
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="list"
                    :footer-props="{'items-per-page-options': [6, 20, 100, -1]}"
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:45px;">
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <td style="width:100%"><font class="d-block text-truncate" style="width:600px;">{{item.name}}</font></td>
                            <td style="width:300px"><font class="d-block text-truncate" style="width:300px;">{{new Date(item.term).toLocaleDateString()}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 선택항목 & 버튼 -->
            <v-card
                class="mt-2 pa-2 d-flex justify-end"
                outlined
            >
                <v-btn
                    disabled
                    large
                    class="px-6 mr-4"
                >
                    선택항목: {{selectedItem.name}}
                </v-btn>
                <v-btn
                    class="px-6"
                    color="orange"
                    large
                    dark
                    @click="updateOpen()"
                >
                    <v-icon small left>mdi-database-edit-outline</v-icon>
                    수정하기
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="blue"
                    large
                    dark
                    @click="deleteItem()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    삭제하기
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="blue"
                    large
                    dark
                    @click="dialog.create = true"
                >
                    <v-icon small left>mdi-database-plus-outline</v-icon>
                    추가하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- 수정하기 -->
        <v-dialog v-model="dialog.update" width="auto">
            <v-sheet class="pa-4">
                <v-sheet>
                    <table v-if="selectedAfter" class="customTable">
                        <tr>
                            <td>시즌명</td>
                            <td>{{selectedItem.name}}</td>
                            <td style="width:100px;">
                                <v-text-field
                                    outlined dense hide-details
                                    v-model="selectedAfter.name"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>기간</td>
                            <td>{{new Date(selectedItem.term).toLocaleDateString()}}</td>
                            <td>
                                <v-text-field
                                    outlined dense hide-details
                                    v-model="selectedAfter.term"
                                    type="date"
                                ></v-text-field>
                            </td>
                        </tr>
                    </table>
                </v-sheet>
                <v-sheet class="mt-2 pt-2 d-flex justify-center">
                    <v-btn
                        large dark color="blue"
                        class="px-6 mr-4"
                        @click="updateSubmit()"
                    >
                        <v-icon small left>mdi-database-edit-outline</v-icon>수정하기
                    </v-btn>
                    <v-btn
                        large dark color="grey"
                        class="px-6"
                        @click="dialog.update = false"
                    >
                        <v-icon small left>mdi-cancel</v-icon>취소하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>

        <!-- 추가하기 -->
        <v-dialog v-model="dialog.create" width="auto">
            <v-sheet class="pa-4">
                <v-sheet>
                    <table class="customTable">
                        <tr>
                            <td>시즌명</td>
                            <td style="width:100px;">
                                <v-text-field
                                    outlined dense hide-details
                                    v-model="create.name"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>기간</td>
                            <td>
                                <v-text-field
                                    outlined dense hide-details
                                    v-model="create.term"
                                    type="date"
                                ></v-text-field>
                            </td>
                        </tr>
                    </table>
                </v-sheet>
                <v-sheet class="mt-2 pt-2 d-flex justify-center">
                    <v-btn
                        large dark color="blue"
                        class="px-6 mr-4"
                        @click="createSubmit()"
                    >
                        <v-icon small left>mdi-database-edit-outline</v-icon>추가하기
                    </v-btn>
                    <v-btn
                        large dark color="grey"
                        class="px-6"
                        @click="dialog.create = false"
                    >
                        <v-icon small left>mdi-cancel</v-icon>취소하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '', value: ''},
            {text: '시즌명', value: 'name'},
            {text: '기간', value: 'term'}
        ],
        
        list: [],

        dialog: {
            update: false,
            create: false
        },

        selected: false,
        selectedItem: {},
        selectedAfter: {},

        url: "",

        create: {
            name: "",
            term: ""
        },

        select_list: {
            search_type: [
                "시즌",
            ],

            set_nation: [
                {text: "한글 시즌", value: "kr"},
                {text: "영어 시즌", value: "en"},
                {text: "일본 시즌", value: "jp"}
            ]
        },

        selected_nation: "kr",

        search_type: "시즌",
        keyword: ""
    }),

    mounted(){
        this.search()
    },

    watch: {
        selected_nation(){
            this.list = []
            this.search()
        }
    },

    methods: {
        // 검색
        search(){
            this.$http.post('/api/admin/deck/season/select/search', {
                params: {
                    keyword: this.keyword,
                    nation: this.selected_nation
                }
            }).then((res) => {
                console.log(res.data)
                this.list = res.data
            })

            // 값 초기화
            this.selected = false
            this.selectedItem = {}
            this.selectedAfter = {}
            this.create = {}
            this.uploadImage = {}

            // Dialog 초기화
            this.dialog.update = false
            this.dialog.create = false
        },

        // 선택한 항목 수정하기에 담기
        selectUpdate(item){
            this.selectedItem.id = item.id
            this.selectedItem.name = item.name
            this.selectedItem.term = item.term

            this.selectedAfter.id = item.id
            this.selectedAfter.name = item.name
            this.selectedAfter.term = item.term
        },

        // 수정하기 열기
        updateOpen(){
            if(!Object.keys(this.selectedItem).length){
                alert("선택한 시즌이 없습니다.")
            }else{
                this.dialog.update = true
            }
        },

        // 수정하기
        updateSubmit() {
            var timezoneOffset = new Date().getTimezoneOffset() * 60000
            var timezoneDate = new Date(new Date(this.selectedAfter.term) - timezoneOffset) 

            this.$http.post('/api/admin/deck/season/update', {
                params: {
                    name: this.selectedAfter.name,
                    term: timezoneDate.toISOString().slice(0, 10),
                    id: this.selectedItem.id,
                    nation: this.selected_nation
                }
            }).then((res) => {
                alert("시즌이 수정되었습니다.")
                this.search()
                this.dialog.update = false
            })
        },

        // 삭제하기
        deleteItem(){
            if(!Object.keys(this.selectedItem).length){
                alert("선택한 시즌이 없습니다.")
            }else{
                if(confirm('정말 해당 시즌을 삭제하시겠습니까?')){
                    this.$http.post('/api/admin/deck/season/delete', {
                        params: {
                            id: this.selectedItem.id,
                            nation: this.selected_nation
                        }
                    }).then((res) => {
                        if(!res.data.affectedRows){
                            alert("시즌 삭제 도중 오류가 발생하였습니다.")
                        }else{
                            alert("시즌이 삭제되었습니다.")
                            this.search()
                        }
                    })
                }
            }
        },

        // 추가하기
        createSubmit: _.debounce(function() {
            this.$http.post('/api/admin/deck/season/insert', {
                params: {
                    name: this.create.name,
                    term: this.create.term,
                    nation: this.selected_nation
                }
            }).then((res) => {
                console.log(res)
                alert("시즌이 추가되었습니다.")
                this.search()
            })
        }, 500),
    }
}
</script>
<style scoped>
/* 목록 */
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}

/* Table (수정하기, 추가하기) */
.customTable{
    border:1px solid #efefef;
    border-collapse: collapse;
}

.customTable tr td:first-child{
    text-align: center;
    font-weight: bold;
    background:#f2f2f2;
    height:50px;
    width:140px;
    padding:10px;
    border:1px solid #dedede;
}

.customTable tr td{
    height:50px;
    width:400px;
    padding:10px;
    border:1px solid #dedede;
}
</style>
<style scoped>
.my-clipper {
    width:100%;
}

.placeholder {
    width:300px;
    height:100px;
}
</style>