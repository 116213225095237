<template>
    <v-sheet class="transparent">
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">포인트 지급 기준 관리</font>
        </v-sheet>
        <v-sheet outlined class="pa-2 ma-2">
            <v-radio-group v-model="selected">
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="list"
                    :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                    no-data-text="현재 데이터가 없습니다."
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:45px">
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <td style="width:100%"><font class="d-block text-truncate">{{item.menu}}</font></td>
                            <td style="width:500px"><font class="d-block text-truncate" style="width:500px;">{{item.activity}}</font></td>
                            <td style="width:500px"><font class="d-block text-truncate" style="width:500px;">{{item.amount}}P</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    class="px-6 mr-4"
                    large
                    disabled
                >
                    선택항목: {{selectedItem.menu}}
                    <v-icon small class="mx-1">mdi-chevron-right</v-icon>
                    {{selectedItem.activity}}
                </v-btn>
                <v-btn
                    class="px-6"
                    color="orange"
                    dark
                    large
                    depressed
                    @click="updateOpen()"
                >
                    <v-icon small left>mdi-database-edit-outline</v-icon>
                    수정하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- 수정하기 -->
        <v-dialog v-model="dialog.update" width="400">
            <v-sheet class="pa-2">
                <!-- 수정 전 -->
                <v-sheet class="mt-2 d-flex">
                    <v-sheet outlined class="pa-1 mr-2 d-flex justify-center align-center grey lighten-3" style="width:100%;">
                        <font class="font-weight-bold">수정 전</font>
                    </v-sheet>
                    <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                        <v-text-field
                            class="title_field"
                            readonly
                            dense
                            solo
                            flat
                            hide-details
                            v-model="selectedItem.amount"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>

                <!-- 수정 후 -->
                <v-sheet class="mt-2 d-flex">
                    <v-sheet outlined class="pa-1 mr-2 d-flex justify-center align-center grey lighten-3" style="width:100%;">
                        <font class="font-weight-bold">수정 후</font>
                    </v-sheet>
                    <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                        <v-text-field
                            class="title_field"
                            hide-details
                            dense
                            solo
                            flat
                            v-model="selectedAfter.amount"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>

                <!-- 버튼 -->
                <v-sheet class="mt-2 pa-2 d-flex justify-center">
                    <v-btn
                        class="px-6 mr-4"
                        color="blue"
                        dark
                        large
                        depressed
                        @click="updateSubmit()"
                    >
                        <v-icon small left>mdi-database-edit-outline</v-icon>수정하기
                    </v-btn>
                    <v-btn
                        class="px-6"
                        color="grey"
                        large
                        depressed
                        dark
                        @click="dialog.update = false"
                    >
                        <v-icon small left>mdi-cancel</v-icon>취소하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '', value: ''},
            {text: '메뉴', value: 'menu'},
            {text: '활동', value: 'activity'},
            {text: '포인트 지급', value: 'amount'}
        ],
        list: [],

        // 선택
        selected: false,
        selectedItem: {},
        selectedAfter: {},

        // 다이얼로그
        dialog: {
            update: false
        },
    }),

    mounted(){
        this.$http.get('/api/admin/point/setting/list')
        .then((res) => {
            this.list = res.data
        })
    },

    methods: {
        // 선택
        selectUpdate(item){
            this.selectedItem = item
            this.selectedAfter.amount = item.amount
        },

        // 수정하기 열기
        updateOpen(){
            if(Object.keys(this.selectedItem).length){
                this.dialog.update = true
            }else{
                alert("선택한 항목이 없습니다.")
            }
        },

        // 수정하기 실행
        updateSubmit(){
            this.$http.post('/api/admin/point/setting/update', {
                params: {
                    menu: this.selectedItem.menu,
                    activity: this.selectedItem.activity,
                    amount: this.selectedAfter.amount,
                }
            }).then(() => {
                alert("해당 포인트 지급량이 변경되었습니다.")
                this.dialog.update = false
                this.$http.get('/api/admin/point/setting/list')
                .then((res) => {
                    this.list = res.data
                })
            })
        }
    }
}
</script>
<style>
#list{
    width:100%;
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    width:85px;
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>