<template>
    <v-card class="pa-4" width="1200">
        <!-- 제목 -->
        <p class="py-1 font-weight-bold text-center text-h5">
            <span v-if="card_nation=='kr'">한글카드</span>
            <span v-if="card_nation=='en'">영어카드</span>
            <span v-if="card_nation=='jp'">일어카드</span>
            선입력하기
        </p>
        
        <!-- 이미지 & 기본 정보 -->
        <v-sheet class="d-flex justify-space-between">
            <v-sheet
                width="30%"
                class="d-flex align-center justify-center grey lighten-4"
                style="border:1px solid #CCCCCC !important;"
            >
                <!-- 이미지 -->
                <CardImage
                    :card_id="card_id"
                    :card_nation="card_nation"
                    @imageUpdated="imageImport"
                    :key="key+'CardImage'"
                />
            </v-sheet>
            <v-sheet class="ml-2" width="69.5%">
                <!-- 기본 정보 -->
                <Basic
                    :card_id="card_id"
                    :card_nation="card_nation"
                    :type="type"
                    :insert_type="insert_type"
                    @basicUpdated="basicImport"
                    :key="key+'Basic'"
                />
            </v-sheet>
        </v-sheet>

        <!-- 능력 정보 -->
        <Abilities
            :card_id="card_id"
            :card_nation="card_nation"
            :type="type"
            @abilitiesUpdated="abilitiesImport"
            :key="key+'Abilities'"
        />
        
        <!-- 기술 정보 -->
        <Attacks 
            :card_id="card_id"
            :card_nation="card_nation"
            :type="type"
            @attacksUpdated="attacksImport"
            :key="key+'Attacks'"
        />

        <!-- Submit -->
        <v-btn
            width="100%"
            large
            class="blue mt-4"
            dark
            depressed
            @click="submit()"
        >
            카드 입력하기
        </v-btn>
    </v-card>
</template>
<script>
import CardImage from './Modules/CardImage'
import Basic from './Modules/basic'
import Abilities from './Modules/Abilities'
import Attacks from './Modules/Attacks'

export default {
    components: {
        CardImage,
        Basic,
        Abilities,
        Attacks
    },

    props: [
        "card_id",
        "card_nation",
        "insert_type",
        "type",
        "isPreCard"
    ],

    data: () => ({
        card: {
            ancientTrait_name: "",
            ancientTrait_text: "",
            set_printedTotal: "",
            set_name: "",
            image: {}
        },

        key: 0,
        id: ""
    }),

    mounted(){
        // 단축키 설정
        window.addEventListener('keydown', (e) => {
            if(e.ctrlKey && e.code=="Enter")
            {
                this.submit()
                e.preventDefault()
            }
        })
    },

    methods: {
        // 카드 입력값 초기화
        reloadComponents() {
            this.key += 1
        },

        // 카드 이미지 $Emit
        imageImport(CardImage){
            console.log(CardImage)
            this.card.image = CardImage
        },

        // 기본 정보 $Emit
        basicImport(basic){
            console.log("basic", basic)
            Object.assign(this.card, basic)
        },

        // 능력 정보 $Emit
        abilitiesImport(abilities){
            console.log("abilities", abilities)
            this.card.abilities = abilities
        },

        // 기술 정보 $Emit
        attacksImport(attacks){
            console.log("attacks", attacks)
            this.card.attacks = attacks
        },
        
        // ID 설정
        setId(){
            if(this.card_id == '' || this.card_id == undefined){
                this.id = this.card.id
            }else{
                this.id = this.card_id
            }
        },

        // 값 검증
        isInputEmpty(card){
            // 발매중일 경우
            if(card.isAvailable){
                if(typeof(card.image)=="string"){
                    alert("카드 이미지는 필수입력값입니다.")
                    return false
                }
                else if(!card.id){
                    alert("고유아이디는 필수입력값입니다.")
                    return false
                }
                else if(!card.name){
                    alert("영어이름은 필수입력값입니다.")
                    return false
                }
                else if(!card.supertype){
                    alert("슈퍼속성은 필수입력값입니다.")
                    return false
                }
                else if(!card.number){
                    alert("블록넘버는 필수입력값입니다.")
                    return false
                }
                else if(!card.set.printedTotal){
                    alert("토탈넘버는 필수입력값입니다.")
                    return false
                }
                else if(!card.set.name){
                    alert("제품명은 필수입력값입니다.")
                    return false
                }
            }
        },

        // 카드 이미지 업로드
        async cardImageUpload(card){
            if(typeof(this.card.image)=="string" && this.card.image == ""){
                this.card.image = 'default.png'
            }else{
                let formData = new FormData()
                formData.append("image", this.card.image)

                await this.$http.post('/api/admin/card/pre_card/upload/image/'+this.card_nation, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res) => {
                    card.image = res.data
                })
            }
        },

        // dump 입력
        async InsertDump(card, id){
            // DUMP 입력값 테스트
            console.log("InsertDump", card)
            
            this.$http.post('/api/admin/card/pre_card/insert/card_dump', {
                params: {
                    id: id,
                    image: 'default.png',
                    dump: JSON.stringify(card),
                    isAvailable: card.isAvailable
                }
            })
        },

        // card 입력
        async InsertCard(card, id){
            // basic 입력
            await this.$http.post('/api/admin/card/pre_card/insert/card_'+this.card_nation+'', {
                params: {
                    id: id,
                    name: card.name_local,
                    ancientTrait_name: card.ancientTrait_name,
                    ancientTrait_text: card.ancientTrait_text,
                    effects: card.effects,
                    rules: card.rules,
                    image: card.image,
                    number: card.number,
                    rarity: card.rarity,
                    set_printedTotal: card.set_printedTotal,
                    set_name: card.set_name,
                    writer: this.$store.state.user_id,
                    isAvailable: card.isAvailable
                }
            }).then(() => {
                this.$emit("reload")
            })

            // abilities 입력
            if(card.abilities && card.abilities.length){
                for(let ability of card.abilities){
                    await this.$http.post('/api/admin/card/pre_card/insert/card_'+this.card_nation+'/abilities', {
                        params: {
                            card_id: id,
                            type: ability.type,
                            name: ability.name,
                            text: ability.text
                        }
                    })
                }
            }

            // attacks 입력
            if(card.attacks && card.attacks.length){
                for(let attack of card.attacks){
                    await this.$http.post('/api/admin/card/pre_card/insert/card_'+this.card_nation+'/attacks', {
                        params: {
                            card_id: id,
                            name: attack.name,
                            cost: attack.cost.toString(),
                            convertedEnergyCost: attack.cost.length,
                            damage: attack.damage,
                            text: attack.text
                        }
                    })
                }
            }
        },

        // Submit
        async submit(){
            // ID 설정
            this.setId()

            // 값 검증
            if(this.isInputEmpty(this.card) == false) return

            // 카드 이미지 업로드
            await this.cardImageUpload(this.card)

            // dump 입력
            await this.InsertDump(this.card, this.id)

            // card 입력
            await this.InsertCard(this.card, this.id)

            // 카드 입력값 초기화
            await this.reloadComponents()

            // DB 알림
            let alarm_type = ""
            if(this.card_nation == "kr"){
                alarm_type = "한글카드"
            }else if(this.card_nation == "jp"){
                alarm_type = "일본카드"
            }else if(this.card_nation == "en"){
                alarm_type = "영어카드"
            }

            // DB 알림
            this.$dbAlarm(
                alarm_type,
                this.$store.state.nickname,
                this.$store.state.user_id
            )

            // dialog 종료
            await this.$emit("close")
        }
    }
}
</script>