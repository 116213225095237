<template>
    <div>
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">수정요청 관리</font>
            <v-spacer></v-spacer>

            <!-- 상태 설정 -->
            <v-sheet width="160">
                <v-select
                    class="mr-4"
                    outlined
                    dense
                    hide-details
                    v-model="selected_status"
                    :items="select_list.status"
                ></v-select>
            </v-sheet>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex" width="660">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="blue"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-radio-group v-model="selected">
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="list"
                    :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                    no-data-text="현재 데이터가 없습니다."
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:45px;">
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <td @click="link(item)" style="width:160px; cursor:pointer;"><font class="d-block text-truncate" style="width:240px;">{{item.user_id}}({{item.nickname}})</font></td>
                            <td @click="link(item)" style="width:160px; cursor:pointer;"><font class="d-block text-truncate" style="width:160px;">{{item.category}}</font></td>
                            <td @click="link(item)" style="width:100%; cursor:pointer;"><font class="d-block text-truncate" style="width:420px;">{{item.content}}</font></td>
                            <td @click="link(item)" style="width:220px; cursor:pointer;"><font class="d-block text-truncate" style="width:220px;">{{new Date(item.created).toLocaleString()}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 선택항목 & 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    class="px-6 mr-4 text-lowercase"
                    disabled
                    large
                    depressed
                >
                    선택항목: '{{selectedItem.reporter_user_id}}'의 '{{selectedItem.reported_user_id}}' 수정요청
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="blue"
                    large
                    dark
                    depressed
                    @click="dispose_open()"
                >
                    <v-icon small left>mdi-database-plus-outline</v-icon>
                    요청 처리하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- 요청 처리하기 (dialog) -->
        <v-dialog
            v-model="dialog.dispose"
            width="auto"
            content-class="rounded-10"
        >
            <v-sheet width="800" class="pa-4">
                <p class="font-weight-bold text-center text-h6 grey--text text--darken-2">
                    요청 처리하기
                </p>
                <v-sheet class="px-3">
                    <table class="disposeTable">
                        <tr>
                            <td>요청자</td>
                            <td>{{selectedItem.user_id}}</td>
                        </tr>
                        <tr>
                            <td>요청 항목</td>
                            <td>{{selectedItem.category}}</td>
                        </tr>
                        <tr>
                            <td>요청 내용</td>
                            <td style="height:120px;">
                                <p class="text-break" style="max-width:600px;">
                                    {{selectedItem.content}}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>처리</td>
                            <td>
                                <v-select
                                    dense
                                    outlined
                                    hide-details
                                    :items="select_list.status"
                                    v-model="selectedAfter.status"
                                ></v-select>
                            </td>
                        </tr>
                        <tr v-if="selectedAfter.status == '반려'">
                            <td>반려사유</td>
                            <td>
                                <v-text-field
                                    dense
                                    outlined
                                    hide-details
                                    v-model="memo"
                                    placeholder="20자 이내로 입력해주세요"
                                    :maxlength="20"
                                ></v-text-field>
                            </td>
                        </tr>
                    </table>
                    <v-sheet class="mt-4 d-flex justify-center">
                        <v-btn
                            class="px-6 mr-4"
                            color="blue"
                            large
                            dark
                            depressed
                            @click="report_dispose_submit()"
                        >
                            <v-icon small left>mdi-database-edit-outline</v-icon>
                            요청 처리하기
                        </v-btn>
                        <v-btn
                            class="px-6"
                            color="grey"
                            large
                            dark
                            depressed
                            @click="dialog.dispose = false"
                        >
                            <v-icon small left>mdi-cancel</v-icon>
                            취소하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '', value: ''},
            {text: '요청자 아이디 (닉네임)', value: 'user_id'},
            {text: '항목', value: 'category'},
            {text: '내용', value: 'content'},
            {text: '요청일자', value: 'created'}
        ],

        list: [],

        select_list: {
            search_type: [
                "요청자 아이디", 
                "항목",
                "내용",
            ],

            status: [
                "접수",
                "수정완료",
                "반려"
            ],
        },

        selected_status: "접수",

        selected: false,
        selectedItem: {},
        selectedAfter: {
            status: ""
        },

        search_type: "요청자 아이디",
        keyword: "",
        memo: "",

        dialog: {
            dispose: false
        }
    }),

    mounted(){
        this.load()
    },

    watch: {
        selected_status(){
            this.load()
        }
    },

    methods: {
        // 목록
        load(){
            this.$http.post('/api/admin/trade/request/list', {
                params: {
                    status: this.selected_status
                }
            }).then((res) => {
                this.list = res.data.reverse()
            })
        },

        // 검색
        search(){
            this.$http.post('/api/admin/trade/request/search', {
                params: {
                    status: this.selected_status,
                    keyword: this.keyword,
                    type: this.search_type
                }
            }).then((res) => {
                this.list = res.data
            })
        },

        // 거래글 링크
        link(item){
            this.$router.push("/trade/post/read?id=" + item.post_id)
        },

        // 선택하기
        selectUpdate(item){
            this.selectedItem = item
            this.selectedAfter.status = item.status
        },

        // 요청 처리하기 열기
        dispose_open(){
            if(!Object.keys(this.selectedItem).length){
                alert("선택하신 요청이 없습니다.")
            }else{
                this.dialog.dispose = true
            }
        },

        // 요청 처리하기 제출
        report_dispose_submit(){
            this.$http.post('/api/admin/trade/request/update_status', {
                params: {
                    status: this.selectedAfter.status,
                    id: this.selectedItem.id,
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    alert("해당 요청의 상태가 변경되었습니다.")
                    this.load()
                    this.dialog.dispose = false

                    // 알림 보내기
                    this.$http.post('/api/admin/trade/request/alarm/send', {
                        params: {
                            user_id: this.selectedItem.user_id,
                            sender: this.$store.state.user_id,
                            type: "거래글 관리자 수정요청 ( " + this.memo + " )",
                            content: this.selectedAfter.status,
                            link: "/trade/post/read?id=" + this.selectedItem.post_id
                        }
                    })
                }
            })
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    width:85px;
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>
<style scoped>
.disposeTable{
    width: 100%;
    border: 1px solid #e0e0e0;
    border-collapse: collapse;
}

.disposeTable tr td{
    padding: 12px;
    border: 1px solid #e0e0e0;
}

.disposeTable tr td:first-child{
    width:160px;
    text-align:center;
    background:#f8f8f8;
    font-weight:bold;
}
</style>