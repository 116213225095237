<template>
    <table id="basic" width="100%">
        <tr>
            <th colspan="4" class="blue lighten-5">
                기본 정보
            </th>
        </tr>

        <!-- 고유아이디 & 이름 -->
        <tr>
            <!-- 고유아이디 -->
            <td v-if="insert_type=='whole'">고유 ID</td>
            <td v-if="insert_type=='whole'">
                <v-text-field
                    :readonly="type=='add' || type=='update'"
                    :class="type=='add' || type=='update'? 'grey lighten-3':''"
                    hide-details outlined dense
                    v-model="basic.id"
                    @change="basicUpdated()"
                ></v-text-field>
            </td>

            <!-- 이름 -->
            <td v-if="type!='provide'">
                <span v-if="card_nation=='kr'">한글</span>
                <span v-if="card_nation=='en'">영어</span>
                <span v-if="card_nation=='jp'">일어</span>
                이름
            </td>
            <td v-if="type=='provide'">한글/일어 이름</td>
            <td 
                :colspan="insert_type=='simple'? 3:0" 
                :class="insert_type=='simple'? 'px-4':''"
            >
                <v-text-field
                    hide-details outlined dense
                    v-model="basic.name_local"
                    @change="basicUpdated(); test()"
                ></v-text-field>
            </td>
        </tr>

        <!-- 영어 이름 & 도감 번호 -->
        <tr v-if="insert_type=='whole'">
            <!-- 영어 이름 -->
            <td>영어 이름</td>
            <td>
                <v-text-field
                    :readonly="type=='add' || card_nation=='en'"
                    :class="type=='add' || card_nation=='en'? 'grey lighten-3':''"
                    hide-details outlined dense
                    v-model="basic.name"
                    @change="basicUpdated()"
                ></v-text-field>
            </td>

            <!-- 도감 번호 -->
            <td>도감 번호</td>
            <td>
                <v-text-field
                    :readonly="type=='add'"
                    :class="type=='add'? 'grey lighten-3':''"
                    hide-details outlined dense
                    v-model="basic.nationalPokedexNumbers"
                    @change="basicUpdated()"
                ></v-text-field>
            </td>
        </tr>

        <!-- 슈퍼속성 & 서브속성 -->
        <tr v-if="insert_type=='whole'">
            <!-- 슈퍼속성 -->
            <td>슈퍼속성</td>
            <td>
                <v-autocomplete
                    :readonly="type=='add'"
                    :class="type=='add'? 'grey lighten-3':''"
                    dense
                    outlined
                    hide-details
                    :items="select_list.supertype"
                    item-text="name"
                    item-value="value"
                    v-model="basic.supertype"
                    @input="supertypeUpdated()"
                    @change="basicUpdated()"
                ></v-autocomplete>
            </td>

            <!-- 서브속성 -->
            <td>서브속성</td>
            <td>
                <v-autocomplete
                    :readonly="type=='add'"
                    :class="type=='add'? 'grey lighten-3':''"
                    attach
                    chips
                    dense
                    hide-details
                    outlined
                    no-data-text="슈퍼속성을 선택해주세요"
                    multiple
                    item-text="kr"
                    item-value="en"
                    :items="select_list.subtypes"
                    v-model="basic.subtypes"
                    @change="basicUpdated()"
                >
                    <template v-slot:selection="{ item }">
                        <v-chip
                            small
                            class="my-1 ml-1 mr-0"
                            dark
                            color="blue"
                        >
                            <span style="color:white">{{item.kr}}</span>
                        </v-chip>
                    </template>
                </v-autocomplete>
            </td>
        </tr>

        <!-- HP & 속성 -->
        <tr v-if="insert_type=='whole'">
            <!-- HP -->
            <td>HP</td>
            <td>
                <v-text-field
                    :readonly="type=='add'"
                    :class="type=='add'? 'grey lighten-3':''"
                    hide-details outlined dense 
                    type="number"
                    :min="0"
                    :max="340"
                    v-model="basic.hp"
                    @change="basicUpdated()"
                ></v-text-field>
            </td>

            <!-- 속성 -->
            <td>속성</td>
            <td>
                <v-autocomplete
                    :readonly="type=='add'"
                    :class="type=='add'? 'grey lighten-3':''"
                    :menu-props="{ closeOnContentClick: true}"
                    attach
                    chips
                    dense
                    hide-details
                    outlined
                    multiple
                    :items="select_list.type"
                    v-model="basic.types"
                    item-text="name"
                    item-value="value"
                    @change="basicUpdated()"
                >
                    <template v-slot:selection="{ item }">
                        <v-chip
                            small
                            class="my-1 ml-1 mr-0"
                            dark
                            color="blue"
                        >
                            <span style="color:white">{{item.name}}</span>
                        </v-chip>
                    </template>
                </v-autocomplete>
            </td>
        </tr>

        <!-- 진화전 & 진화후 -->
        <tr v-if="insert_type=='whole'">
            <!-- 진화전 -->
            <td>진화전</td>
            <td>
                <v-text-field
                    :readonly="type=='add'"
                    :class="type=='add'? 'grey lighten-3':''"
                    hide-details outlined dense
                    v-model="basic.evolvesFrom"
                    @change="basicUpdated()"
                ></v-text-field>
            </td>

            <!-- 진화후 -->
            <td>진화후</td>
            <td>
                <v-combobox
                    :readonly="type=='add'"
                    :class="type=='add'? 'grey lighten-3':''"
                    append-icon=""
                    placeholder="여러값 입력 시 엔터"
                    single-line
                    outlined
                    dense
                    hide-details
                    chips
                    multiple
                    v-model="basic.evolvesTo"
                    @change="basicUpdated()"
                    class="px-0"
                >
                    <template v-slot:selection="{ item }">
                        <v-chip
                            small
                            class="my-1 ml-1 mr-0"
                            dark
                            color="blue"
                        >
                            <span style="color:white">{{item}}</span>
                        </v-chip>
                    </template>
                </v-combobox>
            </td>
        </tr>

        <!-- 약점 & 저항력 -->
        <tr v-if="insert_type=='whole'">
            <!-- 약점 -->
            <td>약점</td>
            <td>
                <v-autocomplete
                    :readonly="type=='add'"
                    :class="type=='add'? 'grey lighten-3':''"
                    :menu-props="{ closeOnContentClick: true}"
                    attach
                    chips
                    dense
                    hide-details
                    outlined
                    multiple
                    :items="select_list.weaknesses"
                    item-text="name"
                    item-value="input"
                    v-model="basic.weaknesses"
                    @change="basicUpdated()"
                >
                    <template v-slot:selection="{ item }">
                        <v-chip
                            small
                            class="my-1 ml-1 mr-0"
                            dark
                            color="blue"
                        >
                            <span style="color:white">{{item.name}}</span>
                        </v-chip>
                    </template>
                </v-autocomplete>
            </td>

            <!-- 저항력 -->
            <td>저항력</td>
            <td>
                <v-autocomplete
                    :readonly="type=='add'"
                    :class="type=='add'? 'grey lighten-3':''"
                    :menu-props="{ closeOnContentClick: true}"
                    attach
                    chips
                    dense
                    hide-details
                    outlined
                    multiple
                    :items="select_list.resistances"
                    item-text="name"
                    item-value="input"
                    v-model="basic.resistances"
                    @change="basicUpdated()"
                >
                    <template v-slot:selection="{ item }">
                        <v-chip
                            small
                            class="my-1 ml-1 mr-0"
                            dark
                            color="blue"
                            
                        >
                            <span style="color:white">{{item.name}}</span>
                        </v-chip>
                    </template>
                </v-autocomplete>
            </td>
        </tr>

        <!-- 아티스트 & 후퇴비용 -->
        <tr v-if="insert_type=='whole'">
            <!-- 아티스트 -->
            <td>아티스트</td>
            <td>
                <v-text-field
                    :readonly="type=='provide'"
                    :class="type=='provide' && !type=='provide_read'? 'grey lighten-3':''"
                    hide-details outlined dense
                    v-model="basic.artist"
                    @change="basicUpdated()"
                ></v-text-field>
            </td>

            <!-- 후퇴비용 -->
            <td>후퇴비용</td>
            <td>
                <v-sheet class="d-flex justify-space-between">
                    <v-sheet
                        width="100%"
                        min-height="40"
                        class="rounded pt-1"
                        style="border:1px solid #9e9e9e;"
                        :class="type=='add'? 'grey lighten-3':''"
                    >
                        <v-chip
                            small
                            class="my-1 ml-1 mr-0"
                            dark
                            color="blue"
                            v-for='(item, index) in basic.retreatCost' :key="index"

                        >
                            <span v-if="item=='Colorless'" style="color:white">무색</span>
                            <span v-if="item=='Darkness'" style="color:white">악</span>
                            <span v-if="item=='Dragon'" style="color:white">드래곤</span>
                            <span v-if="item=='Fairy'" style="color:white">페어리</span>
                            <span v-if="item=='Fighting'" style="color:white">격투</span>
                            <span v-if="item=='Fire'" style="color:white">불꽃</span>
                            <span v-if="item=='Grass'" style="color:white">풀</span>
                            <span v-if="item=='Lightning'" style="color:white">번개</span>
                            <span v-if="item=='Metal'" style="color:white">강철</span>
                            <span v-if="item=='Psychic'" style="color:white">초</span>
                            <span v-if="item=='Water'" style="color:white">물</span>
                            <v-icon
                                class='ml-2'
                                color="white"
                                small
                                :disabled="type=='add'"
                                @click='basic.retreatCost.splice(index, 1)'
                            >
                                mdi-close
                            </v-icon>
                        </v-chip>
                    </v-sheet>
                    <v-sheet width="48" class="ml-1">
                        <v-menu :close-on-content-click="false">
                            <template v-slot:activator='{ on }'>
                                <v-btn :disabled="type=='add'" x-small width="40" height="40" depressed class="blue white--text" v-on='on'>
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    v-for='(item, index) in select_list.retreatCost' :key="index"
                                    @click='basic.retreatCost.push(item.value)'
                                >
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-sheet>
                </v-sheet>
            </td>
        </tr>

        <!-- 레귤레이션 & 희귀도 -->
        <tr>
            <!-- 레귤레이션 -->
            <td
                v-if="type!='provide' && type!='read'"
            >
                레귤레이션
            </td>
            <td
                v-if="type!='provide' && type!='read'"
                :class="insert_type=='simple'? 'px-4':''"
            >
                <v-select
                    v-if="insert_type=='whole'"
                    dense
                    hide-details
                    outlined
                    :items="select_list.regulationMark"
                    v-model="basic.regulationMark"
                    @change="basicUpdated()"
                ></v-select>
                <span
                    v-if="insert_type=='simple'"
                >
                    {{basic.regulationMark}}
                </span>
            </td>

            <!-- 희귀도 -->
            <td>희귀도</td>
            <td
                :colspan="type=='provide' || type=='read'? 3:0"
                :class="insert_type=='simple'? 'px-4':''"
            >
                <v-autocomplete
                    dense
                    outlined
                    hide-details
                    :items="select_list.rarity"
                    item-text="name"
                    item-value="value"
                    v-model="basic.rarity"
                    @change="basicUpdated()"
                ></v-autocomplete>
            </td>
        </tr>

        <!-- 블록넘버 & 토탈넘버 -->
        <tr>
            <!-- 블록넘버 -->
            <td>블록넘버</td>
            <td :class="insert_type=='simple'? 'px-4':''">
                <v-text-field
                    hide-details outlined dense
                    v-model="basic.number"
                    @change="basicUpdated()"
                ></v-text-field>
            </td>

            <!-- 토탈넘버 -->
            <td>토탈넘버</td>
            <td :class="insert_type=='simple'? 'px-4':''">
                <v-text-field
                    hide-details outlined dense
                    v-model="basic.set.printedTotal"
                    type="number"
                    @change="basicUpdated()"
                    placeholder="제품명 선택시 자동입력"
                ></v-text-field>
            </td>
        </tr>

        <!-- 제품명 -->
        <tr>
            <td>제품명</td>
            <td colspan="3" :class="insert_type=='simple'? 'px-4':''">
                <v-autocomplete
                    dense
                    outlined
                    hide-details
                    placeholder="제품명 이름"
                    :items="select_list.set"
                    v-model="basic.set.name"
                    @change="autoTotalNumber()"
                ></v-autocomplete>
            </td>
        </tr>

        <!-- 고대 능력명 -->
        <tr v-if="insert_type=='whole'">
            <td>고대 능력명</td>
            <td colspan="3">
                <v-text-field
                    hide-details outlined dense
                    v-model="basic.ancientTrait.name"
                    @change="basicUpdated()"
                ></v-text-field>
            </td>
        </tr>

        <!-- 고대 능력 효과 -->
        <tr v-if="insert_type=='whole'">
            <td>고대 능력 효과</td>
            <td colspan="3">
                <v-text-field
                    hide-details outlined dense
                    v-model="basic.ancientTrait.text"
                    @change="basicUpdated()"
                ></v-text-field>
            </td>
        </tr>

        <!-- 효과 -->
        <tr>
            <td>효과</td>
            <td colspan="3" :class="insert_type=='simple'? 'px-4':''">
                <v-text-field
                    hide-details outlined dense
                    v-model="basic.effects"
                    @change="basicUpdated()"
                ></v-text-field>
            </td>
        </tr>

        <!-- 룰 -->
        <tr>
            <td>룰</td>
            <td colspan="3" :class="insert_type=='simple'? 'px-4':''">
                <v-text-field
                    hide-details outlined dense
                    v-model="basic.rules"
                    @change="basicUpdated()"
                ></v-text-field>
            </td>
        </tr>

        <!-- 발매여부 -->
        <tr v-if="insert_type!='provide'">
            <td>발매여부</td>
            <td colspan="3" :class="insert_type=='simple'? 'px-4':''">
                <v-sheet class="d-flex justify-center mr-10" color="transparent">
                    <v-checkbox
                        class="my-0"
                        hide-details dense
                        v-model="basic.isAvailable"
                        :label="basic.isAvailable? '발매중':'발매안됨'"
                        @change="basicUpdated()"
                    ></v-checkbox>
                </v-sheet>
            </td>
        </tr>

        <!-- 값 업데이트용 -->
        <tr v-show="false">
            <td>{{dummy}}</td>
        </tr>
    </table>
</template>
<script>
export default {
    props: [
        "cardImport",
        "card_id",
        "card_nation",
        "insert_type",
        "type"
    ],
    
    data: () => ({
        basic: {
            name: "",

            ancientTrait: {
                name: "",
                text: ""
            },
            ancientTrait_name: "",
            ancientTrait_text: "",

            set: {
                printedTotal: "",
                name: ""
            },
            set_printedTotal: "",
            set_name: "",

            retreatCost: [],
            weaknesses: [],
            resistances: [],
            isAvailable: 1
        },

        select_list: {
            rarity: [],
            supertype: ['Energy', 'Pokémon', 'Trainer'],
            subtypes: [],
            type: [
                { name:"무색", value:"Colorless" },
                { name:"악", value:"Darkness" },
                { name:"드래곤", value:"Dragon" },
                { name:"페어리", value:"Fairy" },
                { name:"격투", value:"Fighting" },
                { name:"불꽃", value:"Fire" },
                { name:"풀", value:"Grass" },
                { name:"번개", value:"Lightning" },
                { name:"강철", value:"Metal" },
                { name:"초", value:"Psychic" },
                { name:"물", value:"Water" }
            ],

            weaknesses: [
                { name: "무색 ×2", input: {type: "Colorless", value: "×2"} },
                { name: "악 ×2", input: {type: "Darkness", value: "×2"} },
                { name: "드래곤 ×2", input: {type: "Dragon", value: "×2"} },
                { name: "페어리 ×2", input: {type: "Fairy", value: "×2"} },
                { name: "격투 ×2", input: {type: "Fighting", value: "×2"} },
                { name: "불꽃 ×2", input: {type: "Fire", value: "×2"} },
                { name: "풀 ×2", input: {type: "Grass", value: "×2"} },
                { name: "번개 ×2", input: {type: "Lightning", value: "×2"} },
                { name: "강철 ×2", input: {type: "Metal", value: "×2"} },
                { name: "초 ×2", input: {type: "Psychic", value: "×2"} },
                { name: "물 ×2", input: {type: "Water", value: "×2"} },
            ],

            resistances: [
                { name: "무색 -20", input: {type: "Colorless", value: "-20"} },
                { name: "악 -20", input: {type: "Darkness", value: "-20"} },
                { name: "드래곤 -20", input: {type: "Dragon", value: "-20"} },
                { name: "페어리 -20", input: {type: "Fairy", value: "-20"} },
                { name: "격투 -20", input: {type: "Fighting", value: "-20"} },
                { name: "불꽃 -20", input: {type: "Fire", value: "-20"} },
                { name: "풀 -20", input: {type: "Grass", value: "-20"} },
                { name: "번개 -20", input: {type: "Lightning", value: "-20"} },
                { name: "강철 -20", input: {type: "Metal", value: "-20"} },
                { name: "초 -20", input: {type: "Psychic", value: "-20"} },
                { name: "물 -20", input: {type: "Water", value: "-20"} },
                { name: "무색 -30", input: {type: "Colorless", value: "-30"} },
                { name: "악 -30", input: {type: "Darkness", value: "-30"} },
                { name: "드래곤 -30", input: {type: "Dragon", value: "-30"} },
                { name: "페어리 -30", input: {type: "Fairy", value: "-30"} },
                { name: "격투 -30", input: {type: "Fighting", value: "-30"} },
                { name: "불꽃 -30", input: {type: "Fire", value: "-30"} },
                { name: "풀 -30", input: {type: "Grass", value: "-30"} },
                { name: "번개 -30", input: {type: "Lightning", value: "-30"} },
                { name: "강철 -30", input: {type: "Metal", value: "-30"} },
                { name: "초 -30", input: {type: "Psychic", value: "-30"} },
                { name: "물 -30", input: {type: "Water", value: "-30"} },
            ],

            retreatCost: [
                { name:"무색", value:"Colorless" },
                { name:"악", value:"Darkness" },
                { name:"드래곤", value:"Dragon" },
                { name:"페어리", value:"Fairy" },
                { name:"격투", value:"Fighting" },
                { name:"불꽃", value:"Fire" },
                { name:"풀", value:"Grass" },
                { name:"번개", value:"Lightning" },
                { name:"강철", value:"Metal" },
                { name:"초", value:"Psychic" },
                { name:"물", value:"Water" }
            ],

            regulationMark: [],

            set: []
        },

        supertype: {
            All : [],
            Energy: [],
            Pokémon: [],
            Trainer: []
        },

        dummy: "기본"
    }),

    mounted() {
        this.load()
    },

    watch: {
        card_nation(){
            this.load()
        }
    },

    methods: {
        test(){
            this.$http.post('/api/admin/card/translate/search', {
                params: {
                    keyword: this.basic.name_local
                }
            }).then((res) => {
                if((this.basic.name == undefined || this.basic.name == "") && res.data.length){
                    this.basic.name = res.data[0].en

                    // 값 업데이트용 Key
                    this.dummy = "update"
                }
            })
        },

        // 입력값 초기화
        BasicInit(){
            this.basic = {
                ancientTrait: {
                    name: "",
                    text: ""
                },
                ancientTrait_name: "",
                ancientTrait_text: "",

                set: {
                    printedTotal: "",
                    name: ""
                },
                set_printedTotal: "",
                set_name: "",

                retreatCost: [],
                weaknesses: [],
                resistances: [],
                isAvailable: 1
            }
        },

        // 카드 ID에서 카드 정보 가져오기
        async importCardDataFromCardId(card_id){
            // card_dump
            await this.$http.post('/api/admin/provide/info/card/import/dump', {
                params: {
                    card_id: card_id
                }
            }).then((res) => {
                this.basic.id = card_id
                this.basic.name = JSON.parse(res.data[0].dump).name
                this.basic.supertype = JSON.parse(res.data[0].dump).supertype
                this.basic.subtypes = JSON.parse(res.data[0].dump).subtypes
                this.basic.hp = JSON.parse(res.data[0].dump).hp
                this.basic.types = JSON.parse(res.data[0].dump).types
                this.basic.evolvesFrom = JSON.parse(res.data[0].dump).evolvesFrom
                this.basic.evolvesTo = JSON.parse(res.data[0].dump).evolvesTo
                this.basic.artist = JSON.parse(res.data[0].dump).artist
                this.basic.number = JSON.parse(res.data[0].dump).number
                this.basic.nationalPokedexNumbers = JSON.parse(res.data[0].dump).nationalPokedexNumbers
                this.basic.regulationMark = JSON.parse(res.data[0].dump).regulationMark
                this.basic.retreatCost = JSON.parse(res.data[0].dump).retreatCost
                // this.basic.isAvailable = res.data[0].isAvailable

                if(JSON.parse(res.data[0].dump).images = null)
                {
                    this.basic.weaknesses = JSON.parse(res.data[0].dump).weaknesses
                    this.basic.resistances = JSON.parse(res.data[0].dump).resistances
                }
                else
                {
                    if(JSON.parse(res.data[0].dump).weaknesses!=undefined && JSON.parse(res.data[0].dump).weaknesses.length){
                        for(let weakness of JSON.parse(res.data[0].dump).weaknesses){
                            this.basic.weaknesses.push(
                                {
                                    name: weakness.type+" "+weakness.value, 
                                    input: {
                                        type:weakness.type, 
                                        value:weakness.value
                                    }
                                }
                            )
                        }
                    }

                    if(JSON.parse(res.data[0].dump).resistances!=undefined && JSON.parse(res.data[0].dump).resistances.length){
                        for(let resistance of JSON.parse(res.data[0].dump).resistances){
                            this.basic.resistances.push(
                                {
                                    name: resistance.type+" "+resistance.value,
                                    input: {
                                        type:resistance.type,
                                        value:resistance.value
                                    }
                                }
                            )
                        }
                    }
                }
            })

            // card_kr
            if(this.card_nation=='kr')
            {
                await this.$http.post('/api/card/detail/card_kr', {
                    params: {
                        id: card_id
                    }
                }).then(res => {
                    if(res.data.length){
                        this.basic.name_local = res.data[0].name
                        this.basic.ancientTrait.name = res.data[0].ancientTrait_name
                        this.basic.ancientTrait.text = res.data[0].ancientTrait_text
                        this.basic.number = res.data[0].number
                        this.basic.effects = res.data[0].effects
                        this.basic.rules = res.data[0].rules
                        this.basic.rarity = res.data[0].rarity
                        this.basic.set.printedTotal = res.data[0].set_printedTotal
                        this.basic.set.name = res.data[0].set_name
                        this.basic.isAvailable = res.data[0].isAvailable
                    }
                })
            }

            // card_jp
            else if(this.card_nation=='jp')
            {
                await this.$http.post('/api/card/detail/card_jp', {
                    params: {
                        id: card_id
                    }
                }).then(res => {
                    if(res.data.length){
                        this.basic.name_local = res.data[0].name
                        this.basic.ancientTrait.name = res.data[0].ancientTrait_name
                        this.basic.ancientTrait.text = res.data[0].ancientTrait_text
                        this.basic.number = res.data[0].number
                        this.basic.effects = res.data[0].effects
                        this.basic.rules = res.data[0].rules
                        this.basic.rarity = res.data[0].rarity
                        this.basic.set.printedTotal = res.data[0].set_printedTotal
                        this.basic.set.name = res.data[0].set_name
                        this.basic.isAvailable = res.data[0].isAvailable
                    }
                })
            }

            // card_en
            else if(this.card_nation=='en')
            {
                await this.$http.post('/api/card/detail/card_en', {
                    params: {
                        id: card_id
                    }
                }).then(res => {
                    if(res.data.length){
                        this.basic.name_local = JSON.parse(res.data[0].dump).name
                        if(JSON.parse(res.data[0].dump).hasOwnProperty('ancientTrait')){
                            this.basic.ancientTrait.name = JSON.parse(res.data[0].dump).ancientTrait.name
                            this.basic.ancientTrait.text = JSON.parse(res.data[0].dump).ancientTrait.text
                        }else{
                            this.basic.ancientTrait.name = JSON.parse(res.data[0].dump).ancientTrait_name
                            this.basic.ancientTrait.text = JSON.parse(res.data[0].dump).ancientTrait_text
                        }
                        this.basic.number = JSON.parse(res.data[0].dump).number
                        this.basic.effects = JSON.parse(res.data[0].dump).effects
                        this.basic.rules = JSON.parse(res.data[0].dump).rules
                        this.basic.rarity = JSON.parse(res.data[0].dump).rarity
                        this.basic.set.printedTotal = JSON.parse(res.data[0].dump).set.printedTotal
                        this.basic.set.name = JSON.parse(res.data[0].dump).set.name
                        this.basic.isAvailable = res.data[0].isAvailable
                    }
                })
            }
        },

        // 제보카드 정보 불러오기
        async importProvideCard(provide_id){
            await this.$http.post('/api/admin/provide/info/import/card', {
                params: {
                    provide_id: provide_id
                }
            }).then(res => {
                console.log(res)
                if(res.data.length){
                    this.basic.name = res.data[0].name
                    this.basic.name_local = res.data[0].name_local
                    this.basic.ancientTrait.name = res.data[0].ancientTrait_name
                    this.basic.ancientTrait.text = res.data[0].ancientTrait_text
                    this.basic.effects = res.data[0].effects
                    this.basic.rules = res.data[0].rules
                    this.basic.number = res.data[0].number
                    this.basic.rarity = res.data[0].rarity
                    this.basic.set.printedTotal = res.data[0].set_printedTotal
                    this.basic.set.name = res.data[0].set_name
                }
            })
        },

        // 희귀도 없을 경우 자동 매칭
        async rarityMatch(card_id){
            // 해당 ID의 영어 희귀도 가져오기
            await this.$http.post('/api/admin/provide/info/card/import/dump', {
                params: {
                    card_id: card_id
                }
            }).then( async (res) => {
                // 해당 ID의 card_dump가 있을 경우
                if(res.data.length){
                    let rarity_en = JSON.parse(res.data[0].dump).rarity

                    // 해당 영어 희귀도에 매칭되는 한글, 일어 희귀도 찾기
                    await this.$http.post('/api/admin/card/pre_card/rarity_match', {
                        params: {
                            rarity_en: rarity_en
                        }
                    }).then((res) => {
                        // 값이 있을 경우 적용
                        if(res.data.length){
                            if(this.card_nation == "kr"){
                                this.basic.rarity = res.data[0].kr
                            }else if(this.card_nation == "jp"){
                                this.basic.rarity = res.data[0].jp
                            }
                        }
                    })
                }
            })
        },

        // 희귀도 select_list 가져오기
        async loadRaritySelectList(){
            this.$http.post('/api/admin/card/pre_card/rarity/list')
            .then((res) => {
                if(this.card_nation=='kr'){
                    this.select_list.rarity = res.data.map(e => e.kr).filter(e => e!='-' && e!='')
                }else if(this.card_nation=='jp'){
                    this.select_list.rarity = res.data.map(e => e.jp).filter(e => e!='-' && e!='')
                }else if(this.card_nation=='en'){
                    this.select_list.rarity = res.data.map(e => e.en).filter(e => e!='-' && e!='')
                }
            })
        },

        // 제품명 select_list 가져오기
        loadSetSelectList(){
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: this.card_nation
                }
            }).then(res => { 
                this.select_list.set = res.data.map(a => a.name)
            })
        },

        // 레귤레이션 select_list 불러오기
        loadRegulationSelectList(){
            this.$http.post('/api/admin/card/regulation_mark/list').then((res) => {
                this.select_list.regulationMark = res.data.map(e => e.regulation_mark).reverse()
            })
        },

        // 카드종류(subtypes) list 불러오기
        loadSubtypesSelectList(){
            this.$http.post('/api/admin/card/supertype/list/show')
            .then((res) => {
                this.supertype.All = res.data
                this.supertype.Energy = res.data.filter(e => e.category == "Energy")
                this.supertype.Pokémon = res.data.filter(e => e.category == "Pokémon")
                this.supertype.Trainer = res.data.filter(e => e.category == "Trainer")
            })
        },

        // supertype 선택시 subtypes select_list 가져오기
        supertypeUpdated() {
            if(this.basic.supertype == 'Energy')
            {
                this.select_list.subtypes = this.supertype.Energy
            }
            else if(this.basic.supertype == 'Pokémon')
            {
                this.select_list.subtypes = this.supertype.Pokémon
            }
            else if(this.basic.supertype == 'Trainer')
            {
                this.select_list.subtypes = this.supertype.Trainer
            }
        },

        // 기본 정보 업데이트
        basicUpdated(){
            // 방식 변경
            if(this.basic.ancientTrait.name != null){
                this.basic.ancientTrait_name = this.basic.ancientTrait.name
            }

            if(this.basic.ancientTrait.text != null){
                this.basic.ancientTrait_text = this.basic.ancientTrait.text
            }

            if(this.basic.set.printedTotal != null){
                this.basic.set_printedTotal = this.basic.set.printedTotal
            }

            if(this.basic.set.name != null){
                this.basic.set_name = this.basic.set.name
            }

            if(this.basic.weaknesses.length){

            }

            this.$emit("basicUpdated", this.basic)
        },

        // 불러오기
        async load(){
            // 입력값 초기화
            await this.BasicInit()
            
            // 카드상세 페이지 > 한글카드(선), 일어카드(선) 수정하기
            // 관리자 > 선입력카드 관리 > 수정하기
            if(this.type == "update" || this.type=="provide")
            {
                await this.importCardDataFromCardId(this.card_id)
            }
            else if(this.type == "read")
            {
                await this.importProvideCard(this.card_id)
            }

            // 희귀도 없을 경우 자동매칭
            if(this.basic.rarity == "" || this.basic.rariy == null){
                await this.rarityMatch(this.card_id)
            }

            // 희귀도 목록 가져오기
            await this.loadRaritySelectList()

            // 제품명 목록 가져오기
            await this.loadSetSelectList()

            // 레귤레이션 list 불러오기
            await this.loadRegulationSelectList()

            // 카드종류(supertype) list 불러오기
            await this.loadSubtypesSelectList()

            // supertype에 따른 subtypes 업데이트
            await this.supertypeUpdated()

            // basic 업데이트
            await this.basicUpdated()
        },

        // 제품명 선택시 토탈넘버 자동입력
        autoTotalNumber(){
            this.$http.post('/api/card/select/sets/specific', {
                params: {
                    nation: this.card_nation,
                    set_name: this.basic.set.name
                }
            }).then((res) => { 
                if(res.data.length){
                    this.basic.set.printedTotal = res.data[0].totalNumber
                    this.basicUpdated()
                }
            })
        }
    }
}
</script>
<style scoped>
/* 테이블 */
#basic{
    border:1px solid #ccc;
    border-collapse:collapse;
    text-align:center;
    height:100%;
}

/* 헤더 (기본 정보) */
#basic tr th{
    width:100%;
    height:44px;
    height:44px;
    font-size:13pt;
    font-weight:bold;
}

/* 값 TD */
#basic tr td{
    width:260px;
    height:38px;
    font-size:11pt;
    border:1px solid #ccc;
    padding:6px;
}

/* 이름 TD */
#basic tr td:nth-child(odd){ 
    width:140px;
    background:#f2f3f4;
}
</style>