<template>
    <div>
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">카드가격 관리자 입력</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex" width="660">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="blue"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-radio-group v-model="selected">
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="list"
                    :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:45px;">
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <td @click="linkToPost(item)" style="cursor:pointer; width:100%"><font class="d-block text-truncate" :class="item.post_title=='관리자 입력'? 'orange--text':''" style="width:220px;">{{item.post_title}}</font></td>
                            <td @click="linkToPost(item)" style="cursor:pointer; width:150px;"><font class="d-block text-truncate" style="width:150px;">{{item.card_id}}</font></td>
                            <td @click="linkToPost(item)" style="cursor:pointer; width:80px;"><font class="d-block text-truncate" style="width:80px;">{{item.card_nation}}</font></td>
                            <td @click="linkToPost(item)" style="cursor:pointer; width:140px;">
                                <font v-if="item.card_nation=='kr'" class="d-block text-truncate" style="width:140px;">{{item.rarity_kr}}</font>
                                <font v-if="item.card_nation=='jp'" class="d-block text-truncate" style="width:140px;">{{item.rarity_jp}}</font>
                                <font v-if="item.card_nation=='en'" class="d-block text-truncate" style="width:140px;">{{item.rarity_en}}</font>
                            </td>
                            <td @click="linkToPost(item)" style="cursor:pointer; width:160px;"><font class="d-block text-truncate" style="width:160px;">{{item.card_name}}</font></td>
                            <td @click="linkToPost(item)" style="cursor:pointer; width:120px;"><font class="d-block text-truncate" style="width:120px;">{{item.card_state}}</font></td>
                            <td @click="linkToPost(item)" style="cursor:pointer; width:120px;"><font class="d-block text-truncate" style="width:120px;">{{item.card_price}}</font></td>
                            <td @click="linkToPost(item)" style="cursor:pointer; width:140px;"><font class="d-block text-truncate" style="width:140px;">{{item.card_created==null? null : new Date(item.card_created).toLocaleDateString()}}</font></td>
                            <td @click="linkToPost(item)" style="cursor:pointer; width:140px;"><font class="d-block text-truncate" style="width:140px;">{{item.card_sold==null? null : new Date(item.card_sold).toLocaleDateString()}}</font></td>
                            <td @click="linkToPost(item)" style="cursor:pointer; width:100px;"><font class="d-block text-truncate" style="width:100px;">{{item.state}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 선택항목 & 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    class="px-6 mr-4"
                    large
                    disabled
                >
                    선택항목: {{selectedItem.post_title}}
                </v-btn>
                <v-btn
                    class="px-6"
                    color="orange"
                    large
                    dark
                    depressed
                    @click="updateOpen()"
                >
                    <v-icon small left>mdi-database-edit-outline</v-icon>
                    수정하기
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="blue"
                    large
                    dark
                    depressed
                    @click="deleteItem()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    삭제하기
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="blue"
                    large
                    dark
                    depressed
                    @click="createItem()"
                >
                    <v-icon small left>mdi-database-plus-outline</v-icon>
                    추가하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- dialog -->
        <v-sheet>
            <!-- 추가하기 -->
            <v-dialog
                v-model="dialog.create"
                width="1360"
                content-class="rounded-10"
            >
                <v-sheet class="pa-4">
                    <!-- 제목 -->
                    <p class="font-weight-bold text-center text-h6 grey--text text--darken-2">
                        추가하기
                    </p>

                    <!-- 입력 -->
                    <v-sheet class="mt-2 d-flex" v-for="(item, index) in card_list" :key="index">
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-text-field
                                v-model="item.card_view_name"
                                class="title_field"
                                hide-details
                                dense
                                placeholder="카드 선택"
                                solo
                                flat
                                readonly
                                @click="searchCard(index)"
                            ></v-text-field>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-select
                                v-model="item.card_state"
                                hide-details
                                dense
                                solo
                                flat
                                placeholder="카드상태"
                                :items="select_list.card_state"
                            ></v-select>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-text-field
                                v-model="item.card_price"
                                class="title_field"
                                hide-details
                                dense
                                placeholder="가격"
                                solo
                                flat
                                type="number"
                                reverse
                                prefix="원"
                            ></v-text-field>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-text-field
                                v-model="item.card_created"
                                hide-details dense solo flat
                                placeholder="등록날짜"
                                @click="open_created_select(item)"
                            ></v-text-field>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-text-field
                                v-model="item.card_sold"
                                hide-details dense solo flat
                                placeholder="판매날짜"
                                @click="open_sold_select(item)"
                            ></v-text-field>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-select
                                v-model="item.state"
                                hide-details dense solo flat
                                placeholder="등록됨 or 판매완료"
                                :items="select_list.state"
                                @change="state_changed(item)"
                            ></v-select>
                        </v-sheet>
                        <v-sheet class="mr-2">
                            <v-btn 
                                height="48"
                                block
                                color="blue"
                                dark
                                depressed
                                @click="searchCard(index)"
                            >
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </v-sheet>
                        <v-sheet>
                            <v-btn
                                v-if="!index"
                                height="48"
                                block
                                color="blue"
                                dark
                                depressed
                                @click="addField()"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-btn
                                v-if="index"
                                height="48"
                                block
                                color="blue"
                                dark
                                depressed
                                @click="subtractField(index)"
                            >
                                <v-icon>mdi-minus</v-icon>
                            </v-btn>
                        </v-sheet>
                    </v-sheet>
                    <v-sheet class="mt-4 d-flex justify-center">
                        <v-btn
                            class="px-6 mr-4"
                            color="blue"
                            large
                            dark
                            depressed
                            @click="createSubmit()"
                        >
                            <v-icon small left>mdi-database-edit-outline</v-icon>
                            추가하기
                        </v-btn>
                        <v-btn
                            class="px-6"
                            color="grey"
                            large
                            dark
                            depressed
                            @click="dialog.create = false"
                        >
                            <v-icon small left>mdi-cancel</v-icon>
                            취소하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-dialog>

            <!-- 등록날짜 선택 -->
            <v-dialog v-model="dialog.createdSelect" width="auto">
                <v-date-picker
                    locale="kr"
                    v-model="created_select"
                    @input="update_created()"
                ></v-date-picker>
            </v-dialog>

            <!-- 판매날짜 선택 -->
            <v-dialog v-model="dialog.soldSelect" width="auto">
                <v-date-picker
                    locale="kr"
                    v-model="sold_select"
                    @input="update_sold()"
                ></v-date-picker>
            </v-dialog>

            <!-- 추가하기 > 카드선택 -->
            <v-dialog
                v-model="dialog.cardSelect"
                width="1040"
            >
                <v-card class="pa-1">
                    <v-tabs
                        v-model="cardTab"
                        grow
                        text
                        color="blue"
                    >
                        <v-tab href="#CardList">
                            카드 선택
                        </v-tab>
                        <v-tab href="#CardDetail">
                            카드 상세페이지
                        </v-tab>
                    </v-tabs>
                    <v-divider></v-divider>
                    <v-tabs-items v-model="cardTab">
                        <v-tab-item value="CardList">
                            <CardList
                                :isWrite="true"
                                @goToDetail="goToDetail"
                            />
                        </v-tab-item>
                        <v-tab-item value="CardDetail" class="pt-4">
                            <CardDetail
                                :id="cardDetail_id"
                                :isBoardContent="false"
                                @CardSelected="CardSelected"
                                :key="componentKey"
                            />
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-dialog>

            <!-- 수정하기 -->
            <v-dialog
                v-model="dialog.update"
                width="1360"
                content-class="rounded-10"
            >
                <v-sheet class="pa-4">
                    <!-- 제목 -->
                    <p class="font-weight-bold text-center text-h6 grey--text text--darken-2">
                        수정하기
                    </p>

                    <!-- 수정 전 -->
                    <v-sheet class="mt-2 d-flex">
                        <v-sheet outlined class="pa-1 mr-2 d-flex justify-center align-center grey lighten-3" style="width:100%;">
                            <font class="font-weight-bold">수정 전</font>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-text-field
                                v-model="selectedItem.card_id"
                                class="title_field"
                                hide-details
                                dense
                                solo
                                flat
                                readonly
                            ></v-text-field>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-text-field
                                v-model="selectedItem.card_nation"
                                class="title_field"
                                hide-details dense
                                solo
                                flat
                                readonly
                            ></v-text-field>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-text-field
                                v-model="selectedItem.card_state"
                                class="title_field"
                                hide-details dense
                                solo
                                flat
                                readonly
                            ></v-text-field>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-text-field
                                v-model="selectedItem.card_price"
                                class="title_field"
                                hide-details
                                dense
                                solo
                                flat
                                type="number"
                                reverse
                                prefix="원"
                                readonly
                            ></v-text-field>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2 d-flex justify-center align-center" style="width:100%;">
                            {{selectedItem.card_created==null? null : new Date(selectedItem.card_created).toLocaleDateString()}}
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2 d-flex justify-center align-center" style="width:100%;">
                            {{selectedItem.card_sold==null? null : new Date(selectedItem.card_sold).toLocaleDateString()}}
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-text-field
                                v-model="selectedItem.state"
                                class="title_field"
                                hide-details dense
                                solo
                                flat
                                readonly
                            ></v-text-field>
                        </v-sheet>
                    </v-sheet>

                    <!-- 수정 후 -->
                    <v-sheet class="mt-2 d-flex">
                        <v-sheet outlined class="pa-1 mr-2 d-flex justify-center align-center grey lighten-3" style="width:100%;">
                            <font class="font-weight-bold">수정 후</font>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-text-field
                                v-model="selectedAfter.card_id"
                                class="title_field"
                                hide-details
                                dense
                                solo
                                flat
                                readonly
                            ></v-text-field>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-select
                                v-model="selectedAfter.card_nation"
                                class="title_field"
                                hide-details
                                dense
                                solo
                                flat
                                :items="select_list.card_nation"
                            ></v-select>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-select
                                v-model="selectedAfter.card_state"
                                hide-details
                                dense
                                solo
                                flat
                                placeholder="카드상태"
                                :items="select_list.card_state"
                            ></v-select>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-text-field
                                v-model="selectedAfter.card_price"
                                class="title_field"
                                hide-details
                                dense
                                placeholder="가격"
                                solo
                                flat
                                type="number"
                                reverse
                                prefix="원"
                            ></v-text-field>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-text-field
                                v-model="selectedAfter.card_created"
                                hide-details dense solo flat
                                placeholder="등록날짜"
                                @click="update_open_created_select(selectedAfter)"
                            ></v-text-field>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-text-field
                                v-model="selectedAfter.card_sold"
                                hide-details dense solo flat
                                placeholder="판매날짜"
                                @click="update_open_sold_select(selectedAfter)"
                            ></v-text-field>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2" style="width:100%;">
                            <v-select
                                v-model="selectedAfter.state"
                                hide-details dense solo flat
                                placeholder="등록됨 or 판매완료"
                                :items="select_list.state"
                                @change="state_changed(selectedAfter)"
                            ></v-select>
                        </v-sheet>
                    </v-sheet>
                    <v-sheet class="mt-4 d-flex justify-center">
                        <v-btn
                            class="px-6 mr-4"
                            color="blue"
                            dark
                            large
                            depressed
                            @click="updateSubmit()"
                        >
                            <v-icon small left>mdi-database-edit-outline</v-icon>수정하기
                        </v-btn>
                        <v-btn
                            class="px-6"
                            color="grey"
                            dark
                            large
                            depressed
                            @click="dialog.update = false"
                        >
                            <v-icon small left>mdi-cancel</v-icon>취소하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-dialog>

            <!-- 수정하기 > 등록날짜 선택 -->
            <v-dialog v-model="dialog.update_createdSelect" width="auto">
                <v-date-picker
                    locale="kr"
                    v-model="created_select"
                    @input="update_update_created()"
                ></v-date-picker>
            </v-dialog>

            <!-- 수정하기 > 판매날짜 선택 -->
            <v-dialog v-model="dialog.update_soldSelect" width="auto">
                <v-date-picker
                    locale="kr"
                    v-model="sold_select"
                    @input="update_update_sold()"
                ></v-date-picker>
            </v-dialog>
        </v-sheet>
    </div>
</template>
<script>
import CardList from '@/components/card/List'
import CardDetail from '@/components/card/detailForTrade/Detail'

export default {
    components: {
        CardList,
        CardDetail
    },

    data: () => ({
        // 목록
        headers: [
            {text: '', value: ''},
            {text: '거래글 제목', value: 'post_title'},
            {text: '카드 ID', value: 'card_id'},
            {text: '국가', value: 'card_nation'},
            {text: '희귀도', value: 'rarity'},
            {text: '카드 이름', value: 'card_name'},
            {text: '카드 상태', value: 'card_state'},
            {text: '카드 가격', value: 'card_price'},
            {text: '등록 날짜', value: 'card_created'},
            {text: '판매 날짜', value: 'card_sold'},
            {text: '상태', value: 'state'}
        ],
        list: [],

        // 선택목록
        select_list: {
            search_type: [
                "거래글 제목", 
                "카드 ID", 
                "국가",
                "희귀도",
                "카드 이름",
                "카드 상태",
                "카드 가격",
                "등록 날짜",
                "판매 날짜",
                "상태"
            ],
            card_state: ['기타', 'S급', 'A급', 'B급', 'C급 이하', '등급'],
            state: ['등록됨', '판매완료'],
            card_nation: ['kr', 'en', 'jp']
        },

        // 추가하기 카드목록
        card_list: [
            {
                card_view_name: "",
                card_id: "",
                card_nation: "",
                card_name: "",
                card_state: "",
                card_price: 0,
                card_created: "",
                card_sold: "",
                state: ""
            }
        ],
        cardTab: null,
        cardDetail_id:"",
        cardImage: "",
        selectedIndex: 0,

        // 다이얼로그
        dialog: {
            create:false,
            cardSelect:false,
            createdSelect:false,
            soldSelect:false,
            update: false,
            update_createdSelect: false,
            update_soldSelect: false
        },

        // 선택
        selected: false,
        selectedItem: {},
        selectedAfter: {},

        // 날짜선택
        created_select: "",
        sold_select: "",

        updateSelected: [],

        componentKey: 0,

        search_type: "거래글 제목",
        keyword: "",
    }),

    mounted(){
        // 목록 불러오기
        this.load()
    },

    methods: {
        // 목록 불러오기
        load(){
            this.$http.post('/api/admin/trade/price/select/all')
            .then((res) => {
                this.list = res.data
            })
        },

        // 추가하기 Dialog 열기
        createItem(){
            this.dialog.create = true
        },

        // 추가하기 > 등록날짜 Dialog 열기
        open_created_select(item){
            this.selectedItem = item
            this.dialog.createdSelect = true
        },

        // 추가하기 > 판매날짜 Dialog 열기
        open_sold_select(item){
            this.selectedItem = item
            this.dialog.soldSelect = true
        },

        // 추가하기 > 등록날짜 설정 (판매날짜 초기화)
        update_created(){
            this.selectedItem.card_created = this.created_select
            this.selectedItem.card_sold = null
            this.selectedItem.state = "등록됨"
            this.dialog.createdSelect = false
        },

        // 추가하기 > 판매날짜 설정 (등록날짜 초기화)
        update_sold(){
            this.selectedItem.card_sold = this.sold_select
            this.selectedItem.card_created = null
            this.selectedItem.state = "판매완료"
            this.dialog.soldSelect = false
        },

        // 추가하기 > 상태변경시 등록날짜 & 판매날짜 초기화
        state_changed(item){
            if(item.state=='등록됨'){
                item.card_sold = null
            }else if(item.state=='판매완료'){
                item.card_created = null
            }
        },

        // 추가하기 > 카드선택창 열기
        searchCard(index){
            this.selectedIndex = index
            this.dialog.cardSelect = true
        },

        // 추가하기 > 카드선택창 > 카드목록에서 카드 선택
        goToDetail(id){
            this.cardDetail_id = id
            this.cardTab="CardDetail"
            this.componentKey++
        },

        // 추가하기 > 카드선택창 > 카드목록 Computed
        CardSelected(card, nation){
            if(nation=='kr'){
                this.card_list[this.selectedIndex].card_view_name = card.kr.name + " (" + card.kr.id+")"
                this.card_list[this.selectedIndex].card_name = card.kr.name
            }else if(nation=='en'){
                this.card_list[this.selectedIndex].card_view_name = card.en.name + " (" + card.en.id+")"
                this.card_list[this.selectedIndex].card_name = card.en.name
            }else if(nation=='jp'){
                this.card_list[this.selectedIndex].card_view_name = card.jp.name + " (" + card.jp.id+")"
                this.card_list[this.selectedIndex].card_name = card.jp.name
            }

            this.card_list[this.selectedIndex].card_id = card.en.id
            this.card_list[this.selectedIndex].card_nation = nation
            this.dialog.cardSelect = false
        },

        // 추가하기 > 카드선택창 > 카드 추가
        addField(){
            this.card_list.push(
                {
                    card_view_name: "",
                    card_id: "",
                    card_nation: "",
                    card_name: "",
                    card_state: "",
                    card_price: 0,
                    state: ""
                }
            )
        },

        // 추가하기 > 카드선택창 > 카드 빼기
        subtractField(index){
            this.card_list.splice(index,1)
        },

        // 추가하기 > Submit
        async createSubmit(){
            for(let i=0; i<this.card_list.length; i++){
                await this.$http.post('/api/admin/trade/price/insert/card', {
                    params: {
                        post_id : 0,
                        card_id: this.card_list[i].card_id,
                        card_nation : this.card_list[i].card_nation,
                        card_name : this.card_list[i].card_name,
                        card_state : this.card_list[i].card_state,
                        card_price : this.card_list[i].card_price,
                        card_created : this.card_list[i].card_created,
                        card_sold : this.card_list[i].card_sold,
                        state : this.card_list[i].state
                    }
                })
            }
            await this.load()
            this.dialog.create = false
        },

        // 카드 삭제하기
        deleteItem(){
            if(Object.keys(this.selectedItem).length){
                if(confirm('정말 해당 카드거래를 삭제하시겠습니까?')){
                    this.$http.post('/api/admin/trade/price/delete', {
                        params: {
                            id: this.selectedItem.id
                        }
                    }).then((res) => {
                        alert("해당 카드거래가 삭제되었습니다.")
                        this.load()
                    })
                }
            }else{
                alert("선택한 카드가 없습니다.")
            }
        },

        // 수정하기 > 등록날짜 Dialog 열기
        update_open_created_select(){
            this.dialog.update_createdSelect = true
        },

        // 수정하기 > 판매날짜 Dialog 열기
        update_open_sold_select(){
            this.dialog.update_soldSelect = true
        },

        // 수정하기 > 등록날짜 설정 (판매날짜 초기화)
        update_update_created(){
            this.selectedAfter.card_created = this.created_select
            this.selectedAfter.card_sold = null
            this.selectedAfter.state = "등록됨"
            this.dialog.createdSelect = false
        },

        // 수정하기 > 판매날짜 설정 (등록날짜 초기화)
        update_update_sold(){
            this.selectedAfter.card_sold = this.sold_select
            this.selectedAfter.card_created = null
            this.selectedAfter.state = "판매완료"
            this.dialog.soldSelect = false
        },

        // 수정하기 열기
        updateOpen(){
            if(Object.keys(this.selectedItem).length){
                this.dialog.update = true
            }else{
                alert("선택한 카드가 없습니다.")
            }
        },

        selectUpdate(item){
            // 수정 전
            this.selectedItem = item
            this.selectedItem.card_created = item.card_created
            this.selectedItem.card_sold = item.card_sold

            // 수정 후
            this.selectedAfter.id = item.id
            this.selectedAfter.card_id = item.card_id
            this.selectedAfter.card_nation = item.card_nation
            this.selectedAfter.card_name = item.card_name
            this.selectedAfter.card_state = item.card_state
            this.selectedAfter.card_price = item.card_price
            this.selectedAfter.card_created = item.card_created == null? null : new Date(item.card_created).toLocaleDateString()
            this.selectedAfter.card_sold = item.card_sold == null? null : new Date(item.card_sold).toLocaleDateString()
            this.selectedAfter.state = item.state
        },

        // 수정하기 제출
        updateSubmit(){
            this.$http.post('/api/admin/trade/price/update/submit', {
                params: {
                    id : this.selectedAfter.id,
                    card_nation : this.selectedAfter.card_nation,
                    card_state : this.selectedAfter.card_state,
                    card_price : this.selectedAfter.card_price,
                    card_created : this.selectedAfter.card_created == null? null : this.$moment(new Date(this.selectedAfter.card_created)).format('YYYY-MM-DD HH:mm:ss'),
                    card_sold : this.selectedAfter.card_sold == null? null : this.$moment(new Date(this.selectedAfter.card_sold)).format('YYYY-MM-DD HH:mm:ss'),
                    state : this.selectedAfter.state
                }
            }).then(res => {
                this.dialog.update = false
                this.load()
            })
        },

        // 검색
        search(){
            this.$http.post('/api/admin/trade/price/search', {
                params: {
                    keyword: this.keyword,
                    type: this.search_type
                }
            }).then((res) => {
                this.list = res.data
            })
        },

        // 거래글로 이동
        linkToPost(item){
            window.open('/trade/post/read?id='+item.post_id, '_blank')
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}

.updatetable{
    border:1px solid #efefef;
    border-collapse: collapse;
}

.updatetable tr td{
    height:50px;
    padding:10px;
    border:1px solid #dedede;
}

.updatetable tr th{
    background:#f2f2f2;
    text-align: left;
    padding:10px;
    border:1px solid #dedede;
}
</style>