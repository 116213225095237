<template>
    <v-sheet class="transparent">
        <!-- 제목 -->
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">신고 목록</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex" width="660">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="blue"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-radio-group v-model="selected">
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="list"
                    :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                    no-data-text="현재 데이터가 없습니다."
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:45px;">
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <td @click="$router.push(item.link)" style="width:160px; cursor:pointer;"><font class="d-block text-truncate" style="width:160px;">{{item.reporter_user_id}}</font></td>
                            <td @click="$router.push(item.link)" style="width:160px; cursor:pointer;"><font class="d-block text-truncate" style="width:160px;">{{item.reported_user_id}}</font></td>
                            <td @click="$router.push(item.link)" style="width:140px">
                                <font class="d-block text-truncate" style="width:140px; cursor:pointer;">
                                    <span v-if="item.type=='board_post'">게시글</span>
                                    <span v-if="item.type=='board_comment'">댓글</span>
                                    <span v-if="item.type=='board_subcomment'">대댓글</span>
                                    <span v-if="item.type=='trade_post'">거래글</span>
                                    <span v-if="item.type=='trade_comment'">거래글 댓글</span>
                                    <span v-if="item.type=='message'">메세지</span>
                                    <span v-else>{{item.type}}</span>
                                </font>
                            </td>
                            <td @click="$router.push(item.link)" style="width:160px; cursor:pointer;"><font class="d-block text-truncate" style="width:160px;">{{item.category}}</font></td>
                            <td @click="$router.push(item.link)" style="width:100%; cursor:pointer;"><font class="d-block text-truncate" style="width:500px;">{{item.content}}</font></td>
                            <td @click="$router.push(item.link)" style="width:180px; cursor:pointer;"><font class="d-block text-truncate" style="width:180px;">{{new Date(item.created).toLocaleString()}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 선택항목 & 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    disabled
                    large
                    depressed
                    class="px-6 mr-4 text-lowercase"
                >
                    선택항목: '{{selectedItem.reporter_user_id}}'의 '{{selectedItem.reported_user_id}}' 신고
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="blue"
                    large
                    dark
                    depressed
                    @click="dispose_open()"
                >
                    <v-icon small left>mdi-database-plus-outline</v-icon>
                    신고 처리하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- 신고 처리하기 Dialog -->
        <v-dialog v-model="dialog.dispose" width="auto" content-class="rounded-10">
            <v-sheet width="800" class="pa-4">
                <p class="font-weight-bold text-center text-h6 grey--text text--darken-2">
                    신고 처리하기
                </p>
                <v-sheet class="px-3">
                    <table class="disposeTable">
                        <tr>
                            <td>신고자</td>
                            <td>{{selectedItem.reporter_user_id}}</td>
                        </tr>
                        <tr>
                            <td>신고 대상자</td>
                            <td>{{selectedItem.reported_user_id}}</td>
                        </tr>
                        <tr>
                            <td>유형</td>
                            <td>
                                <span v-if="selectedItem.type=='board_post'">게시글</span>
                                <span v-if="selectedItem.type=='board_comment'">댓글</span>
                                <span v-if="selectedItem.type=='board_subcomment'">대댓글</span>
                                <span v-if="selectedItem.type=='trade_post'">거래글</span>
                                <span v-if="selectedItem.type=='trade_comment'">거래글 댓글</span>
                                <span v-if="selectedItem.type=='message'">메세지</span>
                            </td>
                        </tr>
                        <tr>
                            <td>신고 항목</td>
                            <td>{{selectedItem.category}}</td>
                        </tr>
                        <tr>
                            <td>신고 내용</td>
                            <td style="height:120px;">
                                <p class="text-break" style="max-width:600px;">
                                    {{selectedItem.content}}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>처분</td>
                            <td>
                                <v-select
                                    dense
                                    outlined
                                    hide-details
                                    :items="select_list.dispose"
                                    placeholder="처분 일수를 선택해주세요"
                                    v-model="dispose_date"
                                    item-text="text"
                                    item-value="value"
                                ></v-select>
                            </td>
                        </tr>
                        <tr>
                            <td>비고</td>
                            <td>
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    v-model="memo"
                                    placeholder="비고란"
                                ></v-text-field>
                            </td>
                        </tr>
                    </table>
                    <v-sheet class="mt-4 d-flex justify-center">
                        <v-btn
                            large
                            dark
                            depressed
                            color="blue"
                            class="px-6 mr-4"
                            @click="report_dispose_submit()"
                        >
                            <v-icon small left>mdi-database-edit-outline</v-icon>
                            신고 처리하기
                        </v-btn>
                        <v-btn
                            large
                            dark
                            depressed
                            color="grey"
                            class="px-6"
                            @click="dialog.dispose = false"
                        >
                            <v-icon small left>mdi-cancel</v-icon>
                            취소하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '', value: ''},
            {text: '신고자 아이디', value: 'reporter_user_id'},
            {text: '신고받은 아이디', value: 'reported_user_id'},
            {text: '유형', value: 'type'},
            {text: '항목', value: 'category'},
            {text: '내용', value: 'content'},
            {text: '신고일자', value: 'created'}
        ],

        list: [],

        selected: false,
        selectedItem: {},

        dialog: {
            dispose: false
        },

        select_list: {
            dispose: [
                {text: "1일", value: 1},
                {text: "7일", value: 7},
                {text: "30일", value: 30},
                {text: "100일", value: 100},
                {text: "영구", value: 9999}
            ],

            search_type: [
                "신고자 아이디", 
                "신고받은 아이디", 
                "항목",
                "내용",
                "신고일자"
            ],
        },

        search_type: "신고자 아이디",
        keyword: "",

        dispose_date: 0,
        memo: ""
    }),

    mounted(){
        this.load()
    },

    methods: {
        // 목록
        load(){
            this.$http.post('/api/admin/user/reported/list')
            .then((res) => {
                this.list = res.data
            })
        },

        // 선택하기
        selectUpdate(item){
            this.selectedItem = item
        },

        dispose_open(){
            if(!Object.keys(this.selectedItem).length){
                alert("선택하신 신고가 없습니다.")
            }else{
                this.dialog.dispose = true
            }
        },

        report_dispose_submit(){
            this.$http.post('/api/admin/user/reported/dispose', {
                params: {
                    user_id: this.selectedItem.reported_user_id,
                    report_id: this.selectedItem.id,
                    dispose_date: this.dispose_date,
                    memo: this.memo,
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    alert("해당 아이디가 정상적으로 밴되었습니다.")
                    this.load()
                    this.dialog.dispose = false
                }
            })
        },

        // 검색
        search(){
            this.$http.post('/api/admin/user/reported/search', {
                params: {
                    keyword: this.keyword,
                    type: this.search_type
                }
            }).then((res) => {
                this.list = res.data
            })
        },
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    width:85px;
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>
<style scoped>
.disposeTable{
    width: 100%;
    border: 1px solid #e0e0e0;
    border-collapse: collapse;
}

.disposeTable tr td{
    padding: 12px;
    border: 1px solid #e0e0e0;
}

.disposeTable tr td:first-child{
    width:160px;
    text-align:center;
    background:#f8f8f8;
    font-weight:bold;
}
</style>