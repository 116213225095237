<template>
    <v-sheet>
        <v-sheet outlined class="d-flex align-center ma-2">
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">광고배너 관리</font>
        </v-sheet>
        <v-sheet outlined class="pa-4 ma-2">
            <BannerSetting class="mt-2" title="커뮤니티" type="community" width="360" height="46" />
            <BannerSetting class="mt-2" title="출석체크" type="day_check" width="360" height="46" />
            <BannerSetting class="mt-2" title="공식대회" type="official_league" width="360" height="46" />
            <BannerSetting class="mt-2" title="카드검색&상세" type="card_search_detail" width="360" height="46" />
            <BannerSetting class="mt-2" title="콜렉션북" type="collection" width="360" height="46" />
            <BannerSetting class="mt-2" title="카드랭킹" type="card_ranking" width="360" height="46" />
            <BannerSetting class="mt-2" title="카드거래" type="card_trade" width="360" height="46" />
            <BannerSetting class="mt-2" title="덱레시피" type="deck_recipe" width="360" height="46" />
            <BannerSetting class="mt-2" title="마이페이지" type="mypage" width="360" height="46" />
        </v-sheet>
    </v-sheet>
</template>
<script>
import BannerSetting from '@/components/BannerSetting'

export default {
    components: {
        BannerSetting
    }
}
</script>