<template>
    <v-sheet color="transparent">
        <!-- 제목 -->
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">태그 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 목록 -->
            <v-radio-group v-model="selected">
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="list"
                    :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:45px;">
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <td style="width:100%"><font class="d-block text-truncate" style="min-width:200px;">{{item.tag}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 선택항목 & 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    disabled
                    large
                    class="px-6 mr-4"
                >
                    선택항목: {{selectedItem.tag}}
                </v-btn>
                <v-btn
                    large dark color="orange"
                    class="px-6"
                    @click="updateOpen()"
                >
                    <v-icon small left>mdi-database-edit-outline</v-icon>
                    수정하기
                </v-btn>
                <v-btn
                    large dark color="blue"
                    class="px-6 ml-3"
                    @click="deleteItem()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    삭제하기
                </v-btn>
                <v-btn
                    large dark color="blue"
                    class="px-6 ml-3"
                    @click="dialog.create = true"
                >
                    <v-icon small left>mdi-database-plus-outline</v-icon>
                    추가하기
                </v-btn>
            </v-card>

            <!-- dialog -->
            <v-sheet>
                <!-- 수정하기 -->
                <v-dialog v-model="dialog.update" width="auto">
                    <v-sheet class="pa-2">
                        <v-sheet>
                            <table class="updatetable">
                                <tr>
                                    <th style="width:54px;"></th>
                                    <th style="width:300px;">태그</th>
                                </tr>
                                <tr>
                                    <td style="width:54px;">기존</td>
                                    <td style="width:500px;">{{selectedItem.tag}}</td>
                                </tr>
                                <tr v-if="selectedAfter">
                                    <td style="width:54px;">변경</td>
                                    <td style="width:300px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:200px;"
                                            v-model="selectedAfter.tag"
                                        ></v-text-field>
                                    </td>
                                </tr>
                            </table>
                        </v-sheet>
                        <v-sheet class="mt-2 pa-2 d-flex justify-center">
                            <v-btn
                                large dark color="blue"
                                class="px-6 mr-4"
                                @click="updateSubmit()"
                            >
                                <v-icon small left>mdi-database-edit-outline</v-icon>
                                수정하기
                            </v-btn>
                            <v-btn
                                large dark color="grey"
                                class="px-6"
                                @click="dialog.update=false"
                            >
                                <v-icon small left>mdi-cancel</v-icon>
                                취소하기
                            </v-btn>
                        </v-sheet>
                    </v-sheet>
                </v-dialog>

                <!-- 추가하기 -->
                <v-dialog v-model="dialog.create" width="auto">
                    <v-sheet class="pa-2">
                        <v-sheet>
                            <table class="updatetable">
                                <tr>
                                    <th style="width:54px;"></th>
                                    <th style="width:300px;">태그</th>
                                </tr>
                                <tr v-if="create">
                                    <td style="width:54px;">추가</td>
                                    <td style="width:300px;">
                                        <v-text-field
                                            outlined dense hide-details
                                            style="min-width:200px;"
                                            v-model="create.tag"
                                        ></v-text-field>
                                    </td>
                                </tr>
                            </table>
                        </v-sheet>
                        <v-sheet class="mt-2 pa-2 d-flex justify-center">
                            <v-btn
                                large dark color="blue"
                                class="px-6 mr-4"
                                @click="createSubmit()"
                            >
                                <v-icon small left>mdi-database-edit-outline</v-icon>
                                추가하기
                            </v-btn>
                            <v-btn
                                large dark color="grey"
                                class="px-6"
                                @click="dialog.create=false"
                            >
                                <v-icon small left>mdi-cancel</v-icon>
                                취소하기
                            </v-btn>
                        </v-sheet>
                    </v-sheet>
                </v-dialog>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '', value: ''},
            {text: '태그', value: 'tag'},
        ],
        list: [],

        selected: false,
        selectedItem: {},
        selectedAfter: {},

        dialog: {
            update:false,
            create:false
        },

        create: {
            tag: "",
        }
    }),

    mounted(){
        // 목록 불러오기
        this.load()
    },

    methods: {
        // 목록 불러오기
        load(){
            this.$http.get('/api/admin/deck/tag/list')
            .then((res) => {
                this.list = res.data.reverse()
            })
        },

        // 선택시 값 업데이트
        selectUpdate(item){
            this.selectedItem.id = item.id
            this.selectedItem.tag = item.tag

            this.selectedAfter.id = item.id
            this.selectedAfter.tag = item.tag
        },

        // 태그 수정 open
        updateOpen(){
            if(Object.keys(this.selectedItem).length){
                this.dialog.update = true
            }else{
                alert("선택한 태그가 없습니다.")
            }
        },


        // 태그 수정 Submit
        updateSubmit(){
            this.$http.post('/api/admin/deck/tag/update', {
                params: {
                    before: this.selectedItem,
                    after: this.selectedAfter
                }
            }).then((res) => {
                alert("해당 태그 정보가 수정되었습니다.")
                this.dialog.update = false
                this.selected = false
                this.selectedItem = {}
                this.selectedAfter = {}
                this.load()
            })
        },

        // 태그 추가 Submit
        createSubmit(){
            this.$http.post('/api/admin/deck/tag/create', {
                params: {
                    tag: this.create.tag,
                }
            }).then((res) => {
                alert("태그가 추가되었습니다.")
                this.dialog.create = false
                this.create = {}
                this.load()
            })
        },

        // 태그 삭제하기
        deleteItem(){
            if(!Object.keys(this.selectedItem).length){
                alert("선택한 태그가 없습니다.")
            }else{
                if(confirm('정말 해당 태그를 삭제하시겠습니까?')){
                    this.$http.post('/api/admin/deck/tag/delete', {
                        params: {
                            id: this.selectedItem.id,
                        }
                    }).then((res) => {
                        alert("해당 태그가 삭제되었습니다.")
                        this.load()
                    })
                }
            }
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}

.updatetable{
    border:1px solid #efefef;
    border-collapse: collapse;
}

.updatetable tr td{
    height:50px;
    padding:10px;
    border:1px solid #dedede;
}

.updatetable tr th{
    background:#f2f2f2;
    text-align: left;
    padding:10px;
    border:1px solid #dedede;
}
</style>