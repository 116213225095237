import { render, staticRenderFns } from "./ban_ip.vue?vue&type=template&id=50db0a92&scoped=true"
import script from "./ban_ip.vue?vue&type=script&lang=js"
export * from "./ban_ip.vue?vue&type=script&lang=js"
import style0 from "./ban_ip.vue?vue&type=style&index=0&id=50db0a92&prod&lang=css"
import style1 from "./ban_ip.vue?vue&type=style&index=1&id=50db0a92&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50db0a92",
  null
  
)

export default component.exports