<template>
    <v-sheet color="transparent">
        <!-- 상단 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">정보 입력/수정 관리</font>
            <v-spacer></v-spacer>
            <v-sheet width="210">
                <v-select
                    class="mr-4"
                    outlined dense hide-details
                    v-model="selected_status"
                    :items="select_list.status"
                    @change="status_changed()"
                ></v-select>
            </v-sheet>
        </v-sheet>

        <!-- 목록 & 버튼 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex" width="660">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="computed_search_type_list"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="blue"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-radio-group v-model="selected">
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="list"
                    :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:45px;">
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <td @click="linkToDetail(item)" style="cursor:pointer; width:140px;"><font class="d-block text-truncate" style="width:140px;">{{item.user_id}}</font></td>
                            <td @click="linkToDetail(item)" style="cursor:pointer; width:140px;"><font class="d-block text-truncate" style="width:140px;">{{item.nickname}}</font></td>
                            <td @click="linkToDetail(item)" style="cursor:pointer; width:140px;"><font class="d-block text-truncate" style="width:140px;">{{item.card_id}}</font></td>
                            <td @click="linkToDetail(item)" style="cursor:pointer; width:140px;"><font class="d-block text-truncate" style="width:140px;">{{item.card_nation}}</font></td>
                            <td @click="linkToDetail(item)" style="cursor:pointer; width:100%;">
                                <font v-if="selected_status == '제보등록'" class="d-block text-truncate" style="width:420px;">{{item.content}}</font>
                                <font v-if="selected_status == '지급완료'" class="d-block text-truncate" style="width:300px;">{{item.reason}}</font>
                                <font v-if="selected_status == '제보반려'" class="d-block text-truncate" style="width:420px;">{{item.reason}}</font>
                            </td>
                            <td @click="linkToDetail(item)" style="cursor:pointer; width:120px;" v-if="selected_status == '지급완료'">
                                <font class="d-block text-truncate" style="width:120px;">{{item.point}}</font>
                            </td>
                            <td @click="linkToDetail(item)" style="cursor:pointer; width:140px;">
                                <font v-if="selected_status == '제보등록'" class="d-block text-truncate" style="width:140px;">{{new Date(item.created).toLocaleDateString()}}</font>
                                <font v-if="selected_status != '제보등록'" class="d-block text-truncate" style="width:140px;">{{item.answered==null? null : new Date(item.answered).toLocaleDateString()}}</font>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 선택항목 & 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    class="px-6 mr-4 text-lowercase"
                    disabled
                    large
                >
                    선택항목: {{selectedItem.card_id}}
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="blue"
                    large
                    dark
                    depressed
                    @click="detail_open()"
                >
                    <v-icon small left>mdi-database-edit-outline</v-icon>
                    카드정보 상세보기
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="orange"
                    large
                    dark
                    depressed
                    @click="reward_open()"
                >
                    <v-icon small left>mdi-database-edit-outline</v-icon>
                    포인트 지급하기
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="red"
                    large
                    dark
                    depressed
                    @click="reject_open()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    제보 반려하기
                </v-btn>
                <v-btn
                    v-if="selected_status == '제보등록'"
                    class="px-6 ml-3"
                    color="red"
                    large
                    dark
                    depressed
                    @click="delete_submit()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    제보 삭제하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- 카드정보 상세보기 (dialog) -->
        <v-dialog
            v-model="dialog.detail"
            width="auto"
            content-class="rounded-10"
        >
            <ReadCard 
                :provide_id = selectedItem.id
                :card_provide_id = selectedItem.card_provide_id 
                :card_nation= selectedItem.card_nation
                :key="componentKey"
            />
        </v-dialog>

        <!-- 포인트 지급하기 (dialog) -->
        <v-dialog
            v-model="dialog.reward"
            width="auto"
            content-class="rounded-10"
        >
            <v-sheet
                width="720"
                class="pa-4"
            >
                <p class="font-weight-bold text-center text-h6 grey--text text--darken-2">
                    포인트 지급하기
                </p>
                <v-sheet class="px-3">
                    <table class="disposeTable">
                        <tr>
                            <td>제보자</td>
                            <td>{{selectedItem.user_id}} ({{selectedItem.nickname}})</td>
                        </tr>
                        <tr>
                            <td>카드 ID</td>
                            <td>{{selectedItem.card_id}}</td>
                        </tr>
                        <tr>
                            <td>제보 내용</td>
                            <td style="height:120px;">
                                <p class="text-break" style="max-width:600px;">
                                    {{selectedItem.content}}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>지급 사유</td>
                            <td class="px-3">
                                <v-text-field
                                    v-model="reward.reason"
                                    class="title_field"
                                    hide-details
                                    dense
                                    outlined
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>지급 포인트</td>
                            <td>
                                <v-sheet outlined class="pa-1" style="width:100%;">
                                    <v-text-field
                                        v-model="reward.point"
                                        class="title_field"
                                        hide-details
                                        dense
                                        solo
                                        flat
                                        type="number"
                                        reverse
                                        prefix="P"
                                    ></v-text-field>
                                </v-sheet>
                            </td>
                        </tr>
                        <tr>
                            <td>수정내역 메모</td>
                            <td>
                                <v-sheet outlined class="pa-1" style="width:100%;">
                                    <v-text-field
                                        v-model="update_log_memo"
                                        class="title_field"
                                        hide-details
                                        dense
                                        solo
                                        flat
                                    ></v-text-field>
                                </v-sheet>
                            </td>
                        </tr>
                    </table>
                    <v-sheet class="mt-4 d-flex justify-center">
                        <v-btn
                            class="px-6 mr-4"
                            color="blue"
                            large
                            dark
                            depressed
                            @click="reward_submit()"
                        >
                            <v-icon small left>mdi-database-edit-outline</v-icon>
                            포인트 지급하기
                        </v-btn>
                        <v-btn
                            class="px-6"
                            color="grey"
                            large
                            dark
                            depressed
                            @click="dialog.reward = false"
                        >
                            <v-icon small left>mdi-cancel</v-icon>
                            취소하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-dialog>

        <!-- 제보 반려하기 (dialog) -->
        <v-dialog
            v-model="dialog.reject"
            width="auto"
            content-class="rounded-10"
        >
            <v-sheet
                width="720"
                class="pa-4"
            >
                <p class="font-weight-bold text-center text-h6 grey--text text--darken-2">
                    제보 반려하기
                </p>
                <v-sheet class="px-3">
                    <table class="disposeTable">
                        <tr>
                            <td>제보자</td>
                            <td>{{selectedItem.user_id}} ({{selectedItem.nickname}})</td>
                        </tr>
                        <tr>
                            <td>카드 ID</td>
                            <td>{{selectedItem.card_id}}</td>
                        </tr>
                        <tr>
                            <td>제보 내용</td>
                            <td style="height:120px;">
                                <p class="text-break" style="max-width:600px;">
                                    {{selectedItem.content}}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>반려사유</td>
                            <td>
                                <v-sheet outlined class="pa-1" style="width:100%;">
                                    <v-text-field
                                        v-model="reject.reason"
                                        class="title_field"
                                        hide-details
                                        dense
                                        solo
                                        flat
                                    ></v-text-field>
                                </v-sheet>
                            </td>
                        </tr>
                    </table>
                    <v-sheet class="mt-4 d-flex justify-center">
                        <v-btn
                            class="px-6 mr-4"
                            color="blue"
                            large
                            dark
                            depressed
                            @click="reject_submit()"
                        >
                            <v-icon small left>mdi-database-edit-outline</v-icon>
                            제보 반려하기
                        </v-btn>
                        <v-btn
                            class="px-6"
                            color="grey"
                            large
                            dark
                            depressed
                            @click="dialog.reject = false"
                        >
                            <v-icon small left>mdi-cancel</v-icon>
                            취소하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
import ReadCard from '@/components/cardmodule/ReadCard'

export default {
    components: {
        ReadCard
    },

    data: () => ({
        // 목록
        list: [],

        // 선택
        selected: false,
        selectedItem: {},

        dialog: {
            detail: false,
            reward: false,
            reject: false
        },

        reward: {
            point: 0
        },

        reject: {
            reason: ""
        },

        update_log_memo: "",

        select_list: {
            status: [
                "제보등록",
                "지급완료",
                "제보반려"
            ]
        },

        selected_status: "제보등록",

        search_type: "제보자 ID",
        keyword: "",
        componentKey: 0,
    }),

    mounted(){
        // 목록
        this.load()
    },

    computed: {
        headers(){
            if(this.selected_status == "제보등록"){
                return [
                    {text: '', value: ''},
                    {text: '제보자 ID', value: 'user_id'},
                    {text: '제보자 닉네임', value: 'nickname'},
                    {text: '카드 ID', value: 'card_id'},
                    {text: '국가', value: 'card_nation'},
                    {text: '제보 내용', value: 'content'},
                    {text: '제보 일자', value: 'created'}
                ]
            }else if(this.selected_status == "지급완료"){
                return [
                    {text: '', value: ''},
                    {text: '제보자 ID', value: 'user_id'},
                    {text: '제보자 닉네임', value: 'nickname'},
                    {text: '카드 ID', value: 'card_id'},
                    {text: '국가', value: 'card_nation'},
                    {text: '지급 사유', value: 'reason'},
                    {text: '지급 포인트', value: 'point'},
                    {text: '지급 일자', value: 'answered'}
                ]
            }else if(this.selected_status == "제보반려"){
                return [
                    {text: '', value: ''},
                    {text: '제보자 ID', value: 'user_id'},
                    {text: '제보자 닉네임', value: 'nickname'},
                    {text: '카드 ID', value: 'card_id'},
                    {text: '국가', value: 'card_nation'},
                    {text: '반려 사유', value: 'reason'},
                    {text: '반려 일자', value: 'answered'}
                ]
            }
        },

        computed_search_type_list(){
            if(this.selected_status == "제보등록"){
                return [
                    '제보자 ID',
                    '제보자 닉네임',
                    '카드 ID',
                    '국가',
                    '제보 내용',
                    '제보 일자'
                ]
            }else if(this.selected_status == "지급완료"){
                return [
                    '제보자 ID',
                    '제보자 닉네임',
                    '카드 ID',
                    '국가',
                    '지급 사유',
                    '지급 포인트',
                    '지급 일자'
                ]
            }else if(this.selected_status == "제보반려"){
                return [
                    '제보자 ID',
                    '제보자 닉네임',
                    '카드 ID',
                    '국가',
                    '반려 사유',
                    '반려 일자'
                ]
            }
        }
    },

    methods: {
        forceRerenderer(){
            this.componentKey += 1
        },

        // 선택한 값 저장
        selectUpdate(item){
            this.selectedItem = item
            this.forceRerenderer()
        },

        // 목록
        load(){
            if(this.selected_status == "제보등록"){
                this.$http.post('/api/admin/provide/info/list/registered')
                .then((res) => {
                    this.list = res.data
                    this.selectedItem = {}
                    this.selected = false
                })
            }else if(this.selected_status == "지급완료"){
                this.$http.post('/api/admin/provide/info/list/payed')
                .then((res) => {
                    this.list = res.data
                    this.selectedItem = {}
                    this.selected = false
                })
            }else if(this.selected_status == "제보반려"){
                this.$http.post('/api/admin/provide/info/list/rejected')
                .then((res) => {
                    this.list = res.data
                    this.selectedItem = {}
                    this.selected = false
                })
            }
        },

        // 카드상세 페이지로 이동
        linkToDetail(item){
            window.open('/card/detail?id='+item.card_id, '_blank')
        },

        // 검색 상태 변경
        status_changed(){
            this.load()
        },

        // 카드정보 상세보기 열기
        detail_open(){
            if(Object.keys(this.selectedItem).length==0){
                alert("선택한 제보가 없습니다.")
            }else{
                this.dialog.detail = true
            }
        },

        // 포인트 지급하기 열기
        reward_open(){
            if(Object.keys(this.selectedItem).length == 0){
                alert("선택한 제보가 없습니다.")
            }else{
                this.dialog.reward = true
            }
        },

        // 포인트 지급하기
        async reward_submit(){
            if(Object.keys(this.selectedItem).length == 0){
                alert("선택한 제보가 없습니다.")
            }else if(this.reward.point == 0){
                alert("지급할 포인트는 0이 될 수 없습니다.")
            }else if(this.reward.point < 0){
                alert("지급할 포인트는 0이하가 될 수 없습니다.")
            }else if(this.update_log_memo == ""){
                alert("수정내역에 표시할 메모를 입력해주세요.")
            }else{
                let selectedItemCard = {}
                await this.$http.post('/api/admin/provide/info/import/card', {
                    params: {
                        provide_id: this.selectedItem.id
                    }
                }).then((res) => {
                    selectedItemCard = res.data[0]
                })

                // 기존 카드 정보 변경
                if(this.selectedItem.card_nation == "kr"){
                    await this.$http.post('/api/admin/provide/info/update/card/kr', {
                        params: {
                            name: selectedItemCard.name_local,
                            ancientTrait_name: selectedItemCard.ancientTrait_name,
                            ancientTrait_text: selectedItemCard.ancientTrait_text,
                            effects: selectedItemCard.effects,
                            rules: selectedItemCard.rules,
                            number: selectedItemCard.number,
                            rarity: selectedItemCard.rarity,
                            set_printedTotal: selectedItemCard.set_printedTotal,
                            set_name: selectedItemCard.set_name,
                            writer: this.selectedItem.user_id,
                            card_id: selectedItemCard.card_id,
                        }
                    }).then((res) => {
                        console.log(res)
                    })

                    await this.$http.post('/api/admin/provide/info/delete/abilities/kr', {
                        params: {
                            card_id: selectedItemCard.card_id
                        }
                    }).then((res) => {
                        console.log(res)
                    })

                    await this.$http.post('/api/admin/provide/info/delete/attacks/kr', {
                        params: {
                            card_id: selectedItemCard.card_id
                        }
                    }).then((res) => {
                        console.log(res)
                    })

                    this.$http.post('/api/admin/provide/info/import/card/attacks', {
                        params: {
                            card_provide_id : this.selectedItem.card_provide_id
                        }
                    }).then((res) => {
                        let attacks = {}
                        if(res.data.length){
                            attacks = res.data
                            attacks.forEach(e => {
                                if(e.cost.length){
                                    e.cost = e.cost.split(',')
                                }
                            })
                            for(let attack of attacks){
                                this.$http.post('/api/admin/provide/info/insert/attacks/kr', {
                                    params: {
                                        card_id: selectedItemCard.card_id,
                                        name: attack.name,
                                        cost: attack.cost.toString(),
                                        convertedEnergyCost: attack.cost.length,
                                        damage: attack.damage,
                                        text: attack.text
                                    }
                                }).then((res) => {
                                    console.log(res)
                                }) 
                            }
                        }
                    })

                    this.$http.post('/api/admin/provide/info/import/card/abilities', {
                        params: {
                            card_provide_id : this.selectedItem.card_provide_id
                        }
                    }).then((res) => {
                        let abilities = {}
                        if(res.data.length){
                            abilities = res.data

                            // 능력 입력
                            for(let ability of abilities){
                                this.$http.post('/api/admin/provide/info/insert/abilities/kr', {
                                    params: {
                                        card_id: selectedItemCard.card_id,
                                        type: ability.type,
                                        name: ability.name,
                                        text: ability.text
                                    }
                                }).then((res) => {
                                    console.log(res)
                                })
                            }
                        }
                    })

                }else if(this.selectedItem.card_nation=='jp'){
                    await this.$http.post('/api/admin/provide/info/update/card/jp', {
                        params: {
                            name: selectedItemCard.name_local,
                            ancientTrait_name: selectedItemCard.ancientTrait_name,
                            ancientTrait_text: selectedItemCard.ancientTrait_text,
                            effects: selectedItemCard.effects,
                            rules: selectedItemCard.rules,
                            number: selectedItemCard.number,
                            rarity: selectedItemCard.rarity,
                            set_printedTotal: selectedItemCard.set_printedTotal,
                            set_name: selectedItemCard.set_name,
                            writer: this.selectedItem.user_id,
                            card_id: selectedItemCard.card_id,
                        }
                    }).then((res) => {
                        console.log(res)
                    })

                    await this.$http.post('/api/admin/provide/info/delete/abilities/jp', {
                        params: {
                            card_id: selectedItemCard.card_id
                        }
                    }).then((res) => {
                        console.log(res)
                    })

                    await this.$http.post('/api/admin/provide/info/delete/attacks/jp', {
                        params: {
                            card_id: selectedItemCard.card_id
                        }
                    }).then((res) => {
                        console.log(res)
                    })

                    this.$http.post('/api/admin/provide/info/import/card/attacks', {
                        params: {
                            card_provide_id : this.selectedItem.card_provide_id
                        }
                    }).then((res) => {
                        let attacks = {}
                        if(res.data.length){
                            attacks = res.data
                            attacks.forEach(e => {
                                if(e.cost.length){
                                    e.cost = e.cost.split(',')
                                }
                            })
                            for(let attack of attacks){
                                this.$http.post('/api/admin/provide/info/insert/attacks/jp', {
                                    params: {
                                        card_id: selectedItemCard.card_id,
                                        name: attack.name,
                                        cost: attack.cost.toString(),
                                        convertedEnergyCost: attack.cost.length,
                                        damage: attack.damage,
                                        text: attack.text
                                    }
                                }).then((res) => {
                                    console.log(res)
                                }) 
                            }
                        }
                    })

                    this.$http.post('/api/admin/provide/info/import/card/abilities', {
                        params: {
                            card_provide_id : this.selectedItem.card_provide_id
                        }
                    }).then((res) => {
                        let abilities = {}
                        if(res.data.length){
                            abilities = res.data

                            // 능력 입력
                            for(let ability of abilities){
                                this.$http.post('/api/admin/provide/info/insert/abilities/jp', {
                                    params: {
                                        card_id: selectedItemCard.card_id,
                                        type: ability.type,
                                        name: ability.name,
                                        text: ability.text
                                    }
                                }).then((res) => {
                                    console.log(res)
                                })
                            }
                        }
                    })
                }

                // point_list에 내역 추가
                await this.$http.post('/api/point/record', {
                    params: {
                        user_id: this.selectedItem.user_id,
                        menu: this.selectedItem.card_id,
                        activity: "정보 입력/수정",
                        title: "정보 입력/수정 감사드립니다.",
                        amount: this.reward.point,
                    }
                })

                // 지급사유, 지급 포인트 입력
                await this.$http.post('/api/admin/provide/info/reward/update', {
                    params: {
                        id: this.selectedItem.id,
                        reason: this.reward.reason,
                        point: this.reward.point
                    }
                })

                // 수정내역 입력하기
                await this.$http.post('/api/admin/provide/insert/update_log', {
                    params: {
                        provide_type: "card",
                        provide_id: this.selectedItem.id,
                        card_id: selectedItemCard.card_id,
                        card_nation: this.selectedItem.card_nation,
                        provider: this.selectedItem.user_id,
                        updater: this.$store.state.user_id,
                        memo: this.update_log_memo
                    }
                })

                // 오류제보 status 변경
                await this.$http.post('/api/admin/provide/info/status/update', {
                    params: {
                        id: this.selectedItem.id
                    }
                }).then(() => {
                    this.load()
                })

                // user_info 포인트 추가
                await this.$http.post('/api/admin/user/normal/reward/point', {
                    params: {
                        point: this.reward.point,
                        user_id: this.selectedItem.user_id
                    }
                }).then((res) => {
                    alert("해당 회원에게 포인트가 지급되었습니다.")
                    this.dialog.reward = false
                })
            }
        },

        // 제보 반려하기 열기
        reject_open(){
            if(Object.keys(this.selectedItem).length==0){
                alert("선택한 제보가 없습니다.")
            }else{
                this.dialog.reject = true
            }
        },

        // 제보 반려하기 제출
        reject_submit(){
            if(this.reject.reason == ""){
                alert("반려사유를 입력해주세요")
            }else{
                // 오류제보 반려 알림 보내기
                this.$http.post('/api/alarm/insert', {
                    params: {
                        user_id: this.selectedItem.user_id,
                        sender: this.$store.state.user_id,
                        type: "provide_info_rejected"
                    }
                })

                // 오류제보 status 변경 & 반려일자 입력
                this.$http.post('/api/admin/provide/info/reject/status/update', {
                    params: {
                        id: this.selectedItem.id,
                        reason: this.reject.reason
                    }
                }).then((res) => {
                    if(!res.data.affectedRows){
                        alert("제보 반려 도중 오류가 발생하였습니다.")
                    }else{
                        alert("해당 제보가 반려되었습니다.")
                        // 입력값 초기화
                        this.reject.reason = ""

                        // 닫기 & load
                        this.dialog.reject = false
                        this.load()
                    }
                })
            }
        },

        // 제보 삭제하기
        delete_submit(){
            if(confirm("제보를 정말 삭제하시겠습니까?")){
                this.$http.post("/api/admin/provide/info/delete", {
                    params: {
                        id: this.selectedItem.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        alert("해당 제보가 삭제되었습니다.")
                        this.load()
                    }
                })
            }
        },

        // 검색
        search(){
            if(this.selected_status == "제보등록")
            {
                this.$http.post('/api/admin/provide/info/search/registered', {
                    params: {
                        keyword: this.keyword,
                        type: this.search_type
                    }
                }).then((res) => {
                    this.list = res.data
                    this.selectedItem = {}
                    this.selected = false
                })
            }
            else if(this.selected_status == "지급완료")
            {
                this.$http.post('/api/admin/provide/info/search/payed', {
                    params: {
                        keyword: this.keyword,
                        type: this.search_type
                    }
                }).then((res) => {
                    this.list = res.data
                    this.selectedItem = {}
                    this.selected = false
                })
            }
            else if(this.selected_status == "제보반려")
            {
                this.$http.post('/api/admin/provide/info/search/rejected', {
                    params: {
                        keyword: this.keyword,
                        type: this.search_type
                    }
                }).then((res) => {
                    this.list = res.data
                    this.selectedItem = {}
                    this.selected = false
                })
            }
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>
<style scoped>
.disposeTable{
    width: 100%;
    border: 1px solid #e0e0e0;
    border-collapse: collapse;
}

.disposeTable tr td{
    padding: 12px;
    border: 1px solid #e0e0e0;
}

.disposeTable tr td:first-child{
    width:160px;
    text-align:center;
    background:#f8f8f8;
    font-weight:bold;
}
</style>