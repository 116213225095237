<template>
    <v-sheet color="transparent">
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <!-- 페이지 제목 -->
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">기타상품 관리 (준비중)</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex" width="660">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="blue"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-radio-group v-model="selected">
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="list"
                    :footer-props="{'items-per-page-options': [6, 20, 100, -1]}"
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:45px;">
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <td style="width:120px" class="pa-2">
                                <v-img 
                                    v-if="selected_nation == '한글 제품명'"
                                    height="60"
                                    width="120"
                                    class="mx-auto"
                                    :src="'/upload/set_kr_image/'+encodeURI(item.image)"
                                ></v-img>
                                <v-img 
                                    v-if="selected_nation == '일본 제품명'"
                                    height="60"
                                    width="120"
                                    class="mx-auto"
                                    :src="'/upload/set_jp_image/'+encodeURI(item.image)"
                                ></v-img>
                            </td>
                            <td style="width:140px"><font class="d-block text-truncate" style="width:140px;">{{item.series}}</font></td>
                            <td style="width:100%"><font class="d-block text-truncate" style="width:300px;">{{item.name}}</font></td>
                            <td style="width:120px"><font class="d-block text-truncate" style="width:120px;">{{item.pack_price}}</font></td>
                            <td style="width:120px"><font class="d-block text-truncate" style="width:120px;">{{item.box_price}}</font></td>
                            <td style="width:200px">
                                <div
                                    class="py-2"
                                    style="white-space:pre-line; font-size:13px; width:200px;"
                                    v-text="item.content"
                                ></div>
                            </td>
                            <td style="width:90px"><font class="d-block text-truncate" style="width:90px;">{{item.totalNumber}}</font></td>
                            <td style="width:90px"><font class="d-block text-truncate" style="width:90px;">{{item.amount}}</font></td>
                            <td style="width:180px"><font class="d-block text-truncate" style="width:180px;">{{new Date(item.release_datetime).toLocaleDateString()}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 선택항목 & 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    class="px-6 mr-4"
                    large
                    disabled
                >
                    선택항목: {{selectedItem.name}}
                </v-btn>
                <v-btn
                    class="px-6"
                    color="orange"
                    dark
                    large
                    depressed
                    @click="updateOpen()"
                >
                    <v-icon small left>mdi-database-edit-outline</v-icon>
                    수정하기
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="blue"
                    dark
                    large
                    depressed
                    @click="deleteItem()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    삭제하기
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="blue"
                    dark
                    large
                    depressed
                    @click="dialog.create = true"
                >
                    <v-icon small left>mdi-database-plus-outline</v-icon>
                    추가하기
                </v-btn>
            </v-card>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '', value: ''},
            {text: '상품명', value: 'title'},
            {text: '상품 설명', value: 'content'}
        ],
        list: [],

        selected: false,
        selectedItem: {},

        select_list: {
            search_type: [
                "상품명",
            ]   
        },

        search_type: "상품명",
        keyword: ""
    })
}
</script>
<style scoped>
/* 목록 */
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>