<template>
    <v-sheet color="transparent">
        <!-- 헤더 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">DUMP 데이터 추출</font>
        </v-sheet>
        
        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex" width="660">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="blue"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-data-table
                id="list"
                style="overflow-x:auto"
                :headers="headers"
                :items="list"
                :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                no-data-text="아직 선입력된 카드가 없습니다."
            >
                <template v-slot:item="{ item }">
                    <tr>
                        <td>{{item.id}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.supertype}}</td>
                        <td>{{JSON.parse(item.subtypes)?.toString()}}</td>
                        <td>
                            {{typeof item.prices == 'string'? displayPrice(item.prices): null}}
                        </td>
                        <td>{{JSON.parse(item.types)?.toString()}}</td>
                        <td>{{item.regulationMark}}</td>
                        <td>{{item.set_name}}</td>
                        <td>{{item.number}}</td>
                        <td>{{item.rarity}}</td>
                    </tr>
                </template>
            </v-data-table>

            <!-- 버튼 -->
            <v-sheet class="mt-2 pa-2 d-flex justify-end" outlined>
                <!-- Excel 저장하기 -->
                <vue-excel-xlsx
                    class="ml-3"
                    :data="list"
                    :columns="columns"
                    :file-name="'DUMP카드 목록'"
                    :file-type="'xlsx'"
                    :sheet-name="'sheetname'"
                >
                    <v-btn
                        class="px-6"
                        color="green"
                        large
                        dark
                        depressed
                    >
                        <v-icon left>mdi-microsoft-excel</v-icon>
                        Excel 저장하기
                    </v-btn>
                </vue-excel-xlsx>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text:"ID",},
            {text:"이름",},
            {text:"슈퍼타입"},
            {text:"서브타입"},
            {text:"가격"},
            {text:"타입"},
            {text:"레귤레이션"},
            {text:"제품명"},
            {text:"콜렉션넘버"},
            {text:"희귀도"},
        ],

        list: [],

        select_list: {
            search_type: [
                "ID", 
                "이름", 
                "슈퍼타입",
                "서브타입",
                "가격",
                "타입",
                "레귤레이션",
                "제품명",
                "콜렉션넘버",
                "희귀도"
            ]
        },

        search_type: "이름",
        keyword: "",

        // Excel 저장
        columns: [
            {label: 'ID', field:'id'},
            {label: '이름', field:'name'},
            {label: '슈퍼타입', field:'supertype'},
            {label: '서브타입', field:'subtypes'},
            {label: '가격', field:'displayPrice'},
            {label: '타입', field:'type'},
            {label: '레귤레이션', field:'regulation'},
            {label: '제품명', field:'set.name'},
            {label: '콜렉션넘버', field:'number'},
            {label: '희귀도', field:'rarity'}
        ]
    }),

    mounted(){
        // 목록
        this.search()
    },

    methods: {
        // 검색
        search(){
            this.$http.post('/api/admin/card/dump/search', {
                params: {
                    keyword: this.keyword,
                    type: this.search_type
                }
            }).then((res) => {
                console.log(res.data)
                this.list = res.data
            })
        },

        displayPrice(prices) {
            let prices_parsed = JSON.parse(prices)

            if (prices_parsed.low !== undefined || prices_parsed.low !== null) {
                return prices_parsed.low
            } else if (prices_parsed.mid !== undefined || prices_parsed.mid !== null) {
                return prices_parsed.mid
            } else if (prices_parsed.high !== undefined || prices_parsed.high !== null) {
                return prices_parsed.high
            } else if (prices_parsed.market !== undefined || prices_parsed.market !== null) {
                return prices_parsed.market
            } else if (prices_parsed.directLow !== undefined || prices_parsed.directLow !== null) {
                return prices_parsed.directLow
            } else {
                return null
            }
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>