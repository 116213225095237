<template>
    <v-sheet class="transparent">
        <!-- 제목 -->
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">블라인드 게시글 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex" width="660">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="blue"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-data-table
                id="list"
                :headers="headers"
                :items="list"
                :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                no-data-text="현재 데이터가 없습니다."
            >
                <template v-slot:item="{ item }">
                    <tr @click="read(item)" style="cursor:pointer">
                        <td><font class="d-block text-truncate" style="width:180px;">{{item.user_id}}</font></td>
                        <td><font class="d-block text-truncate" style="width:180px;">{{item.nickname}}</font></td>
                        <td><font class="d-block text-truncate" style="width:140px;">{{item.group_name}}</font></td>
                        <td><font class="d-block text-truncate" style="width:140px;">{{item.board_name}}</font></td>
                        <td><font class="d-block text-truncate" style="width:520px;">{{item.title}}</font></td>
                        <td><font class="d-block text-truncate" style="width:180px;">{{new Date(item.created).toLocaleString()}}</font></td>
                    </tr>
                </template>
            </v-data-table>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '작성자 아이디', value: 'nickname'},
            {text: '작성자 닉네임', value: 'nickname'},
            {text: '게시판 그룹명', value: 'group_name'},
            {text: '게시판 명', value: 'board_name'},
            {text: '글 제목', value: 'title'},
            {text: '글 작성일자', value: 'created'}
        ],

        list: [],

        select_list: {
            search_type: [
                "작성자 아이디", 
                "작성자 닉네임", 
                "게시판 그룹명",
                "게시판 명",
                "글 제목",
                "글 작성일자"
            ]
        },

        search_type: "작성자 아이디",
        keyword: ""
    }),

    mounted(){
        this.$http.post('/api/admin/board/blinded_post/list')
        .then((res) => {
            this.list = res.data
        })
    },

    methods: {
        read(item){
            this.$router.push("/board/read?board_group="+item.group_name+"&board_name="+item.board_name+"&id="+item.id)
        },

        // 검색
        search(){
            this.$http.post('/api/admin/board/blinded_post/search', {
                params: {
                    keyword: this.keyword,
                    type: this.search_type
                }
            }).then((res) => {
                this.list = res.data
            })
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    width:85px;
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>