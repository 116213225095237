<template>
    <div>
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <!-- 페이지 제목 -->
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">공식대회 오류제보 관리</font>
            <v-spacer></v-spacer>

            <!-- 선택: 제보등록, 지급완료 -->
            <v-sheet width="210">
                <v-select
                    class="mr-4"
                    outlined dense hide-details
                    v-model="selected_status"
                    :items="select_list.status"
                    @change="status_changed()"
                ></v-select>
            </v-sheet>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex" width="660">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="computed_search_type_list"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="blue"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
            
            <!-- 목록 -->
            <v-radio-group v-model="selected">
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="list"
                    :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:45px;">
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <!-- 제보자 ID -->
                            <td @click="linkToDetail(item)" style="cursor:pointer; width:140px;"><font class="d-block text-truncate" style="width:140px;">{{item.user_id}}</font></td>

                            <!-- 제보자 닉네임 -->
                            <td @click="linkToDetail(item)" style="cursor:pointer; width:140px;"><font class="d-block text-truncate" style="width:140px;">{{item.nickname}}</font></td>

                            <!-- 공식대회 제목 -->
                            <td @click="linkToDetail(item)" style="cursor:pointer; width:400px;"><font class="d-block text-truncate" style="width:400px;">{{item.title}}</font></td>

                            <!-- 제보 내용 or 지급 사유 -->
                            <td @click="linkToDetail(item)" style="cursor:pointer; width:100%;">
                                <font v-if="selected_status == '제보등록'" class="d-block text-truncate" style="width:420px;">{{item.content}}</font>
                                <font v-if="selected_status == '지급완료'" class="d-block text-truncate" style="width:300px;">{{item.reason}}</font>
                                <font v-if="selected_status == '제보반려'" class="d-block text-truncate" style="width:420px;">{{item.content}}</font>
                            </td>

                            <!-- 지급 포인트 (지급 완료) -->
                            <td v-if="selected_status == '지급완료'" @click="linkToDetail(item)" style="cursor:pointer; width:120px;">
                                <font class="d-block text-truncate" style="width:120px;">{{item.point}}</font>
                            </td>

                            <!-- 제보, 지급, 반려일자 -->
                            <td @click="linkToDetail(item)" style="cursor:pointer; width:180px;">
                                <font v-if="selected_status == '제보등록'" class="d-block text-truncate" style="width:180px;">{{new Date(item.created).toLocaleDateString()}}</font>
                                <font v-if="selected_status != '제보등록'" class="d-block text-truncate" style="width:180px;">{{new Date(item.answered).toLocaleDateString()}}</font>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 선택항목 & 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    class="px-6 mr-4 text-lowercase"
                    large
                    disabled
                >
                    선택항목: {{selectedItem.user_id}}
                </v-btn>
                <v-btn
                    v-if="selected_status == '제보등록'"
                    class="px-6 ml-3"
                    color="orange"
                    large
                    dark
                    depressed
                    @click="reward_open()"
                >
                    <v-icon small left>mdi-database-edit-outline</v-icon>
                    포인트 지급하기
                </v-btn>
                <v-btn
                    v-if="selected_status == '제보등록'"
                    class="px-6 ml-3"
                    color="red"
                    large
                    dark
                    depressed
                    @click="reject_open()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    제보 반려하기
                </v-btn>
                <v-btn
                    v-if="selected_status == '제보등록'"
                    class="px-6 ml-3"
                    color="red"
                    large
                    dark
                    depressed
                    @click="delete_submit()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    제보 삭제하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- 포인트 지급하기 (dialog) -->
        <v-dialog
            v-model="dialog.reward"
            width="auto"
            content-class="rounded-10"
        >
            <v-sheet
                width="720"
                class="pa-4"
            >
                <p class="font-weight-bold text-center text-h6 grey--text text--darken-2">
                    포인트 지급하기
                </p>
                <v-sheet class="px-3">
                    <table class="disposeTable">
                        <tr>
                            <td>제보자</td>
                            <td>{{selectedItem.user_id}} ({{selectedItem.nickname}})</td>
                        </tr>
                        <tr>
                            <td>공식대회 제목</td>
                            <td>{{selectedItem.title}}</td>
                        </tr>
                        <tr>
                            <td>제보 내용</td>
                            <td style="height:120px;">
                                <p class="text-break" style="max-width:600px;">
                                    {{selectedItem.content}}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>지급 사유</td>
                            <td class="px-3">
                                <v-text-field
                                    class="title_field"
                                    hide-details
                                    dense
                                    outlined
                                    v-model="reward.reason"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>지급 포인트</td>
                            <td>
                                <v-sheet outlined class="pa-1">
                                    <v-text-field
                                        class="title_field"
                                        hide-details
                                        dense
                                        solo
                                        flat
                                        reverse
                                        prefix="P"
                                        type="number"
                                        v-model="reward.point"
                                    ></v-text-field>
                                </v-sheet>
                            </td>
                        </tr>
                    </table>
                    <v-sheet class="mt-4 d-flex justify-center">
                        <v-btn
                            class="px-6 mr-4"
                            color="blue"
                            large
                            dark
                            depressed
                            @click="reward_submit()"
                        >
                            <v-icon small left>mdi-database-edit-outline</v-icon>
                            포인트 지급하기
                        </v-btn>
                        <v-btn
                            class="px-6"
                            color="grey"
                            large
                            dark
                            depressed
                            @click="dialog.reward = false"
                        >
                            <v-icon small left>mdi-cancel</v-icon>
                            취소하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-dialog>

        <!-- 제보 반려하기 (dialog) -->
        <v-dialog
            v-model="dialog.reject"
            width="auto"
            content-class="rounded-10"
        >
            <v-sheet
                width="720"
                class="pa-4"
            >
                <p class="font-weight-bold text-center text-h6 grey--text text--darken-2">
                    제보 반려하기
                </p>
                <v-sheet class="px-3">
                    <table class="disposeTable">
                        <tr>
                            <td>제보자</td>
                            <td>{{selectedItem.user_id}} ({{selectedItem.nickname}})</td>
                        </tr>
                        <tr>
                            <td>공식대회 제목</td>
                            <td>{{selectedItem.title}}</td>
                        </tr>
                        <tr>
                            <td>제보 내용</td>
                            <td style="height:120px;">
                                <p class="text-break" style="max-width:600px;">
                                    {{selectedItem.content}}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>반려사유</td>
                            <td>
                                <v-sheet outlined class="pa-1">
                                    <v-text-field
                                        class="title_field"
                                        dense
                                        solo
                                        flat
                                        hide-details
                                        v-model="reject.reason"
                                    ></v-text-field>
                                </v-sheet>
                            </td>
                        </tr>
                    </table>
                    <v-sheet class="mt-4 d-flex justify-center">
                        <v-btn
                            class="px-6 mr-4"
                            color="blue"
                            large
                            dark
                            depressed
                            @click="reject_submit()"
                        >
                            <v-icon small left>mdi-database-edit-outline</v-icon>
                            제보 반려하기
                        </v-btn>
                        <v-btn
                            class="px-6"
                            color="grey"
                            large
                            dark
                            depressed
                            @click="dialog.reject = false"
                        >
                            <v-icon small left>mdi-cancel</v-icon>
                            취소하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
export default {
    data: () => ({
        // 목록
        list: [],

        // 선택
        selected: false,
        selectedItem: {},

        dialog: {
            reward: false,
            reject: false
        },

        reward: {
            point: 0
        },

        reject: {
            reason: ""
        },

        select_list: {
            status: [
                "제보등록",
                "지급완료",
                "제보반려"
            ]
        },

        selected_status: "제보등록",

        search_type: "제보자 ID",
        keyword: "",
    }),

    mounted(){
        this.load()
    },

    computed: {
        headers(){
            if(this.selected_status == "제보등록"){
                return [
                    {text: '', value: ''},
                    {text: '제보자 ID', value: 'user_id'},
                    {text: '제보자 닉네임', value: 'nickname'},
                    {text: '공식대회 제목', value: 'title'},
                    {text: '제보 내용', value: 'content'},
                    {text: '제보 일자', value: 'created'}
                ]
            }else if(this.selected_status == "지급완료"){
                return [
                    {text: '', value: ''},
                    {text: '제보자 ID', value: 'user_id'},
                    {text: '제보자 닉네임', value: 'nickname'},
                    {text: '공식대회 제목', value: 'title'},
                    {text: '지급 사유', value: 'reason'},
                    {text: '지급 포인트', value: 'point'},
                    {text: '지급 일자', value: 'answered'}
                ]
            }else if(this.selected_status == "제보반려"){
                return [
                    {text: '', value: ''},
                    {text: '제보자 ID', value: 'user_id'},
                    {text: '제보자 닉네임', value: 'nickname'},
                    {text: '공식대회 제목', value: 'title'},
                    {text: '반려 사유', value: 'reason'},
                    {text: '반려 일자', value: 'answered'}
                ]
            }
        },

        computed_search_type_list(){
            if(this.selected_status == "제보등록"){
                return [
                    '제보자 ID',
                    '제보자 닉네임',
                    '공식대회 제목',
                    '제보 내용',
                    '제보 일자'
                ]
            }else if(this.selected_status == "지급완료"){
                return [
                    '제보자 ID',
                    '제보자 닉네임',
                    '공식대회 제목',
                    '지급 사유',
                    '지급 포인트',
                    '지급 일자',
                ]
            }else if(this.selected_status == "제보반려"){
                return [
                    '제보자 ID',
                    '제보자 닉네임',
                    '공식대회 제목',
                    '반려 사유',
                    '반려 일자',
                ]
            }
        }
    },

    methods: {
        // 검색
        search(){
            if(this.selected_status == "제보등록")
            {
                this.$http.post('/api/admin/provide/official_league/search/registered', {
                    params: {
                        keyword: this.keyword,
                        type: this.search_type
                    }
                }).then((res) => {
                    this.list = res.data
                })
            }
            else if(this.selected_status == "지급완료")
            {
                this.$http.post('/api/admin/provide/official_league/search/payed', {
                    params: {
                        keyword: this.keyword,
                        type: this.search_type
                    }
                }).then((res) => {
                    this.list = res.data
                })
            }
            else if(this.selected_status == "제보반려")
            {
                this.$http.post('/api/admin/provide/official_league/search/rejected', {
                    params: {
                        keyword: this.keyword,
                        type: this.search_type
                    }
                }).then((res) => {
                    this.list = res.data
                })
            }
        },

        // 목록
        load(){
            if(this.selected_status == "제보등록"){
                this.$http.post('/api/admin/provide/official_league/list/registered')
                .then((res) => {
                    this.list = res.data
                })
            }else if(this.selected_status == "지급완료"){
                this.$http.post('/api/admin/provide/official_league/list/payed')
                .then((res) => {
                    console.log(res)
                    this.list = res.data
                })
            }else if(this.selected_status == "제보반려"){
                this.$http.post('/api/admin/provide/official_league/list/rejected')
                .then((res) => {
                    this.list = res.data
                })
            }
        },

        // 선택한 값 저장
        selectUpdate(item){
            this.selectedItem = item
        },

        // 카드상세 페이지로 이동
        linkToDetail(item){
            window.open('/official_league/read?id='+item.league_id, '_blank')
        },

        // 검색 상태 변경
        status_changed(){
            this.load()
        },

        // 포인트 지급하기 열기
        reward_open(){
            if(Object.keys(this.selectedItem).length==0){
                alert("선택한 제보가 없습니다.")
            }else{
                this.dialog.reward = true
            }
        },

        // 포인트 지급하기
        reward_submit(){
            if(this.reward.point == 0){
                alert("지급할 포인트는 0이 될 수 없습니다.")
            }else if(this.reward.point < 0){
                alert("지급할 포인트는 0이하가 될 수 없습니다.")
            }else{
                // point_list에 내역 추가
                this.$http.post('/api/point/record', {
                    params: {
                        user_id: this.selectedItem.user_id,
                        menu: "공식대회",
                        activity: "오류제보",
                        title: "오류제보 감사드립니다.",
                        amount: this.reward.point
                    }
                })

                // 오류제보 status 변경
                this.$http.post('/api/admin/provide/official_league/reward/status/update', {
                    params: {
                        id: this.selectedItem.id
                    }
                })

                // 지급사유, 지급 포인트 입력
                this.$http.post('/api/admin/provide/official_league/reward/update', {
                    params: {
                        id: this.selectedItem.id,
                        reason: this.reward.reason,
                        point: this.reward.point
                    }
                })

                // user_info 포인트 추가
                this.$http.post('/api/admin/user/normal/reward/point', {
                    params: {
                        point: this.reward.point,
                        user_id: this.selectedItem.user_id
                    }
                }).then((res) => {
                    alert("해당 회원에게 포인트가 지급되었습니다.")
                    this.dialog.reward = false
                    this.load()
                })
            }
        },

        // 제보 반려하기 열기
        reject_open(){
            if(Object.keys(this.selectedItem).length==0){
                alert("선택한 제보가 없습니다.")
            }else{
                this.dialog.reject = true
            }
        },

        // 제보 반려하기 제출
        reject_submit(){
            if(this.reject.reason == ""){
                alert("반려사유를 입력해주세요")
            }else{
                // 오류제보 반려 알림 보내기
                this.$http.post('/api/alarm/insert', {
                    params: {
                        user_id: this.selectedItem.user_id,
                        sender: this.$store.state.user_id,
                        type: "provide_official_league_rejected"
                    }
                })

                // 오류제보 status 변경 & 반려일자 입력
                this.$http.post('/api/admin/provide/official_league/reject/status/update', {
                    params: {
                        id: this.selectedItem.id,
                        reason: this.reject.reason
                    }
                }).then((res) => {
                    if(!res.data.affectedRows){
                        alert("제보 반려 도중 오류가 발생하였습니다.")
                    }else{
                        alert("해당 제보가 반려되었습니다.")
                        // 입력값 초기화
                        this.reject.reason = ""

                        // 닫기 & load
                        this.dialog.reject = false
                        this.load()
                    }
                })
            }
        },

        // 제보 삭제하기
        delete_submit(){
            if(confirm("제보를 정말 삭제하시겠습니까?")){
                this.$http.post("/api/admin/provide/official_league/delete", {
                    params: {
                        id: this.selectedItem.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        alert("해당 제보가 삭제되었습니다.")
                        this.load()
                    }
                })
            }
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>
<style scoped>
.disposeTable{
    width: 100%;
    border: 1px solid #e0e0e0;
    border-collapse: collapse;
}

.disposeTable tr td{
    padding: 12px;
    border: 1px solid #e0e0e0;
}

.disposeTable tr td:first-child{
    width:160px;
    text-align:center;
    background:#f8f8f8;
    font-weight:bold;
}
</style>