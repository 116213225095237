<template>
    <div>
        <!-- 제목 -->
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">공지 관리</font>
        </v-sheet>
        
        <!-- 공지 작성 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- TipTapWriter -->
            <TipTapWriter 
                v-if="writer_options.content"
                class="tiptap_style mb-2"
                :options="writer_options"
            />

            <!-- 버튼 (Submit) -->
            <v-sheet class="d-flex justify-end">
                <v-btn
                    class="px-6"
                    color="blue"
                    large
                    dark
                    depressed
                    @click="update_notice()"
                >
                    <v-icon small left>mdi-database-edit-outline</v-icon>
                    공지 문구 적용하기
                </v-btn>
            </v-sheet>
        </v-sheet>

        <!-- 공지 미리보기 -->
        <v-sheet outlined class="py-4 px-2 ma-2">
            <font class="text-subtitle-1 font-weight-bold ml-2 grey--text text--darken-2">공지 화면 미리보기</font>
            <v-divider class="mx-2 mb-4 mt-2"></v-divider>

            <!-- TipTapReader -->
            <v-sheet outlined class="pa-2 mx-2" width="787">
                <TipTapReader 
                    v-if="reader_options.content"
                    class="tiptap_style"
                    :options="reader_options"
                    :key="componentKey"
                />
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
import TipTapWriter from "@/components/tiptap/Writer"
import TipTapReader from "@/components/tiptap/Reader"

export default {
    components: {
        TipTapWriter,
        TipTapReader
    },

    data: () => ({
        //TipTap
        writer_options: {
            content: '',
            readonly: true,
            editable: true,
            supportImage: true,
            supportVideo: true
        },

        reader_options: {
            content: '',
            readonly: true,
            editable: true,
            supportImage: true,
            supportVideo: true
        },

        componentKey: 0
    }),

    watch: {
        writer_options: {
            deep:true,
            handler(newData) {
                this.content_change()
            }
        }
    },

    mounted(){
        this.$http.post('/api/admin/deck/notice/import')
        .then((res) => {
            this.writer_options.content = res.data[0].text
            this.reader_options.content = res.data[0].text
        })
    },

    methods: {
        content_change(){
            this.reader_options.content = this.writer_options.content
            this.forceRerender()
        },

        forceRerender() {
            this.componentKey += 1
        },

        update_notice(){
            this.$http.post('/api/admin/deck/notice/update', {
                params: {
                    text: this.writer_options.content
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    alert('공지 문구가 정상적으로 적용되었습니다')
                }
            })
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    width:85px;
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>