<template>
    <v-sheet
        class="pa-4"
        width="1100"
    >
        <!-- 기본 정보 -->
        <Basic
            v-if="provide_id"
            type="read"
            insert_type="simple"
            :card_id="provide_id"
            :card_nation="card_nation"
        />

        <!-- 능력 정보 -->
        <Abilities
            type="read"
            :card_id="card_provide_id"
        />
        
        <!-- 기술 정보 -->
        <Attacks
            type="read"
            :card_id="card_provide_id"
        />
    </v-sheet>
</template>
<script>
import CardImage from './Modules/CardImage'
import Basic from './Modules/basic'
import Abilities from './Modules/Abilities'
import Attacks from './Modules/Attacks'

export default {
    components: {
        CardImage,
        Basic,
        Abilities,
        Attacks
    },

    props: [
        "provide_id",
        "card_provide_id",
        "card_nation"
    ],

    data: () => ({
        card_id: ""
    }),

    mounted(){
        this.$http.post('/api/admin/provide/info/import/card', {
            params: {
                provide_id: this.provide_id
            }
        }).then((res) => {
            this.card_id = res.data[0].card_id
        })
    }
}
</script>