<template>
    <v-sheet class="transparent">
        <!-- 상단 -->
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">안내문구 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-4 ma-2">
            <!-- TipTapWriter -->
            <TipTapWriter 
                v-if="writer.options.content"
                class="tiptap_style"
                style="min-height:700px; width:790px;"
                :options="writer.options"
            />

            <!-- 버튼 -->
            <v-sheet class="d-flex justify-end">
                <v-btn
                    class="px-6"
                    color="blue"
                    large
                    dark
                    depressed
                    @click="update()"
                >
                    <v-icon small left>mdi-database-edit-outline</v-icon>
                    안내문구 수정하기
                </v-btn>
            </v-sheet>
        </v-sheet>

        <!-- 미리보기 -->
        <v-sheet outlined class="py-4 px-2 ma-2">
            <!-- 제목 -->
            <font class="text-subtitle-1 font-weight-bold ml-2 grey--text text--darken-2">안내문구 미리보기</font>
            <v-divider class="mx-2 mb-4 mt-2"></v-divider>

            <!-- TipTapReader -->
            <TipTapReader 
                v-if="reader.options.content"
                class="tiptap_style ml-2"
                style="min-height:700px; width:790px; border:1px solid #eee;"
                :options="reader.options"
            />
        </v-sheet>
    </v-sheet>
</template>
<script>
import TipTapWriter from "@/components/tiptap/Writer"
import TipTapReader from "@/components/tiptap/Reader"

export default {
    components: {
        TipTapWriter,
        TipTapReader
    },

    data: () => ({
        //TipTap
        writer: {
            options: {
                content: '',
                editable: true,
                supportImage: true,
                supportVideo: true,
            }
        },

        reader: {
            options: {
                content: '',
                editable: false,
                supportImage: true,
                supportVideo: true,
            }
        }
    }),

    mounted() {
        this.import()
    },

    methods: {
        import(){
            this.$http.post('/api/admin/pointmall/guide/import')
            .then(res => {
                this.writer.options.content = res.data[0].content
                this.reader.options.content = res.data[0].content
            })
        },

        update(){
            this.$http.post('/api/admin/pointmall/guide/update', {
                params: {
                    content: this.writer.options.content
                }
            }).then((res) => {
                alert('안내문구가 정상적으로 수정되었습니다')
                this.$router.go(this.$router.currentRoute)
            })
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    width:85px;
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>