<template>
    <v-sheet class="transparent">
        <!-- 제목 -->
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">주문 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2" width="1620">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex" width="660">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="blue"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-data-table
                style="overflow: hidden"
                id="list"
                :headers="headers"
                :items="list"
                :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                no-data-text="현재 데이터가 없습니다."
            >
                <template v-slot:item="{ item }">
                    <tr>
                        <td style="width:240px; cursor:pointer;" @click="$router.push('/pointmall/read?id='+item.product_id)"><font class="d-block text-truncate" style="width:240px;">{{item.title}}</font></td>
                        <td style="width:110px; cursor:pointer;" @click="$router.push('/pointmall/read?id='+item.product_id)"><font class="d-block text-truncate" style="width:110px;">{{item.sell_type}}</font></td>
                        <td style="width:120px; cursor:pointer;" @click="$router.push('/pointmall/read?id='+item.product_id)"><font class="d-block text-truncate" style="width:120px;">{{item.delivery_price}}</font></td>
                        <td style="width:120px; cursor:pointer;" @click="$router.push('/pointmall/read?id='+item.product_id)"><font class="d-block text-truncate" style="width:120px;">{{item.user_id}}</font></td>
                        <td style="width:120px; cursor:pointer;" @click="$router.push('/pointmall/read?id='+item.product_id)"><font class="d-block text-truncate" style="width:120px;">{{item.nickname}}</font></td>
                        <td style="width:120px; cursor:pointer;" @click="$router.push('/pointmall/read?id='+item.product_id)"><font class="d-block text-truncate" style="width:120px;">{{item.receiver}}</font></td>
                        <td style="width:140px; cursor:pointer;" @click="$router.push('/pointmall/read?id='+item.product_id)"><font class="d-block text-truncate" style="width:140px;">{{item.phone}}</font></td>
                        <td style="width:100%; cursor:pointer; padding-right:20px;" @click="$router.push('/pointmall/read?id='+item.product_id)"><font class="d-block text-truncate">{{item.address}}</font></td>
                        <td style="width:140px; cursor:pointer;" @click="$router.push('/pointmall/read?id='+item.product_id)"><font class="d-block text-truncate" style="width:140px;">{{item.delivery_pay_type}}</font></td>
                        <td style="width:200px; cursor:pointer;" @click="$router.push('/pointmall/read?id='+item.product_id)"><font class="d-block text-truncate" style="width:200px;">{{item.request_memo}}</font></td>
                        <td style="width:190px; cursor:pointer;" @click="$router.push('/pointmall/read?id='+item.product_id)"><font class="d-block text-truncate" style="width:190px;">{{new Date(item.created).toLocaleString()}}</font></td>
                        <td style="width:140px">
                            <v-sheet width="140">
                                <v-select
                                    outlined
                                    dense
                                    hide-details
                                    v-model="item.order_status"
                                    :items="select_list.order_status"
                                    @change="status_update(item)"
                                ></v-select>
                            </v-sheet>
                        </td>
                        <td style="width:400px">
                            <v-sheet width="400">
                                <v-text-field
                                    outlined
                                    hide-details
                                    dense
                                    v-model="item.memo"
                                    @change="memo_update(item)"
                                ></v-text-field>
                            </v-sheet>
                        </td>
                    </tr>
                </template>
            </v-data-table>

            <!-- 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <!-- EXCEL 저장하기 -->
                <vue-excel-xlsx
                    :data="list"
                    :columns="columns"
                    :file-name="'포인트몰 주문 목록'"
                    :file-type="'xlsx'"
                    :sheet-name="'sheetname'"
                >
                    <v-btn
                        class="px-6"
                        color="green"
                        large
                        dark
                        depressed
                    >
                        <v-icon left>mdi-microsoft-excel</v-icon>
                        Excel 저장하기
                    </v-btn>
                </vue-excel-xlsx>
            </v-card>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '상품명', value: 'title'},
            {text: '판매 타입', value: 'sell_type'},
            {text: '배송가격', value: 'delivery_price'},
            {text: '구매자 아이디', value: 'user_id'},
            {text: '구매자 닉네임', value: 'nickname'},
            {text: '수령인', value: 'receiver'},
            {text: '전화번호', value: 'phone'},
            {text: '배송지', value: 'address'},
            {text: '배송비 결제방식', value: 'delivery_pay_type'},
            {text: '요청사항', value: 'request_memo'},
            {text: '신청일자', value: 'created'},
            {text: '상태', value: 'status'},
            {text: '메모', value: 'memo'}
        ],
        list: [],

        // Excel 저장
        columns : [
            {label: '상품명', field: 'title'},
            {label: '판매 타입', field: 'sell_type'},
            {label: '배송가격', field: 'delivery_price'},
            {label: '구매자 아이디', field: 'user_id'},
            {label: '구매자 닉네임', field: 'nickname'},
            {label: '수령인', field: 'receiver'},
            {label: '전화번호', field: 'phone'},
            {label: '배송지', field: 'address'},
            {label: '배송비 결제방식', field: 'delivery_pay_type'},
            {label: '요청사항', field: 'request_memo'},
            {label: '신청일자', field: 'created'},
            {label: '상태', field: 'status'},
            {label: '메모', field: 'memo'}
        ],

        // 검색
        select_list: {
            order_status: ['신청완료', '보류', '배송중', '배송완료', '당첨', '연락안됨', '정보없음'],

            search_type: [
                "상품명", 
                "판매 타입", 
                "배송가격",
                "구매자 아이디",
                "수령인",
                "전화번호",
                "배송지",
                "배송비 결제방식",
                "신청일자",
                "상태",
                "메모"
            ]
        },

        search_type: "상품명",
        keyword: "",
    }),

    mounted(){
        // 목록
        this.load()
    },

    methods: {
        // 목록
        load(){
            this.$http.post('/api/admin/pointmall/order/list')
            .then((res) => {
                this.list = res.data
            })
        },

        status_update(item){
            this.$http.post('/api/admin/pointmall/order/accept/status_update', {
                params: {
                    order_status: item.order_status,
                    id: item.id
                }
            }).then(() => {
                alert("해당 주문 상태가 변경되었습니다.")
                this.load()
            })
        },

        memo_update(item){
            this.$http.post('/api/admin/pointmall/order/accept/memo_update', {
                params: {
                    memo: item.memo,
                    id: item.id
                }
            }).then(() => {
                alert("해당 주문 메모가 변경되었습니다.")
                this.load()
            })
        },

        // 검색
        search(){
            this.$http.post('/api/admin/pointmall/order/search', {
                params: {
                    keyword: this.keyword,
                    type: this.search_type
                }
            }).then((res) => {
                this.list = res.data
            })
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    width:85px;
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>