<template>
    <v-sheet class="transparent">
        <!-- 제목 -->
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">DB알림 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-4 ma-2">
            <!-- 테이블 -->
            <table class="custom_table">
                <tr
                    v-for="item in list" :key="item.type"
                >
                    <td>
                        {{item.type}}
                    </td>
                    <td>
                        <v-btn
                            v-if="item.status"
                            height="40"
                            class="px-6"
                            color="orange"
                            dark
                            depressed
                            @click="updateStatus(item)"
                        >
                            <v-icon small>mdi-bell</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="!item.status"
                            height="40"
                            class="px-6 grey--text"
                            color="grey lighten-2"
                            depressed
                            @click="updateStatus(item)"
                        >
                            <v-icon small>mdi-bell-off-outline</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </table>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        list: []
    }),

    mounted () {
        // 목록
        this.load()
    },

    methods: {
        // 목록
        load(){
            this.$http.post("/api/admin/main/alarm/list")
            .then((res) => {
                this.list = res.data
            })
        },

        // 상태 변경
        updateStatus(item){
            this.$http.post("/api/admin/main/alarm/update/status", {
                params: {
                    status: !item.status,
                    id: item.id
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    alert("알림 설정이 변경되었습니다.")
                    this.load()
                }
            })
        }
    }
}
</script>
<style>
.custom_table{
    border:1px solid #efefef;
    border-collapse: collapse;
}

.custom_table tr td{
    width:240px;
    height:80px;
    padding:10px;
    text-align:center;
    border:1px solid #dedede;
}

.custom_table tr td:last-child{
    width:120px;
}
</style>