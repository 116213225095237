<template>
    <v-sheet class="transparent">
        <!-- 상단 -->
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">댓글 기본문구 설정</font>
        </v-sheet>

        <!-- 입력 -->
        <v-sheet outlined class="pa-2 ma-2">
            <v-sheet outlined class="pa-2 mb-2">
                <v-textarea
                    hide-details
                    flat
                    solo
                    v-model="ads.text"
                ></v-textarea>
            </v-sheet>
            <v-sheet class="d-flex justify-end">
                <v-btn
                    class="px-6"
                    color="blue"
                    large
                    dark
                    depressed
                    @click="update_ads()"
                >
                    <v-icon small left>mdi-database-edit-outline</v-icon>
                    광고 문구 적용하기
                </v-btn>
            </v-sheet>
        </v-sheet>

        <!-- 미리보기 -->
        <v-sheet
            outlined
            class="py-4 px-2 ma-2"
        >
            <font class="text-subtitle-1 font-weight-bold ml-2 grey--text text--darken-2">댓글 화면 미리보기</font>
            <v-divider class="mx-2 mb-4 mt-2"></v-divider>
            <v-sheet outlined class="pa-2 mx-2" width="787" style="background:#fafafa;">
                <v-sheet outlined class="d-flex justify-center py-1">
                    <v-textarea
                        hide-details
                        flat
                        solo
                        :placeholder="ads.text"
                    ></v-textarea>
                </v-sheet>
                <v-sheet class="d-flex mt-2" color="transparent">
                    <v-spacer></v-spacer>
                    <v-btn
                        small
                        dark
                        depressed
                        color="blue"
                    >
                        댓글 달기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        ads: {
            text: ""
        },

        example: ""
    }),

    mounted(){
        this.$http.post('/api/admin/board/ads/import').then((res) => {
            this.ads = res.data[0]
        })
    },

    methods: {
        update_ads(){
            this.$http.post('/api/admin/board/ads/update', {
                params: {
                    text: this.ads.text
                }
            }).then((res) => {
                alert('광고 문구가 정상적으로 적용되었습니다');
            })
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    width:85px;
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>