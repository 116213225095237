<template>
    <div>
        <!-- 상단 (한글카드, 일본카드 선택) -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">직원 활동로그</font>
            <v-spacer></v-spacer>
            <v-sheet width="200">
                <v-select
                    class="mr-4"
                    outlined
                    dense
                    hide-details
                    :items="select_list.nation"
                    v-model="selected_nation"
                ></v-select>
            </v-sheet>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet class="mt-2 mb-4 d-flex justify-space-between">
                <!-- 직원 검색 -->
                <v-sheet width="49%">
                    <v-autocomplete
                        placeholder="검색하실 직원을 선택해주세요"
                        outlined
                        hide-details
                        dense
                        item-text="nickname"
                        item-value="user_id"
                        :items="select_list.manager"
                        v-model="selected"
                    ></v-autocomplete>
                </v-sheet>

                <!-- 날짜 검색 -->
                <v-sheet width="49%" class="d-flex">
                    <!-- 시작 -->
                    <v-text-field
                        dense
                        outlined
                        hide-details
                        placeholder="검색 시작일"  
                        type="date"         
                        v-model="start_date"
                    ></v-text-field>

                    <!-- ~ -->
                    <v-icon small class="mx-5">mdi-tilde</v-icon>

                    <!-- 종료 -->
                    <v-text-field
                        dense
                        outlined
                        hide-details
                        placeholder="검색 종료일"  
                        type="date"
                        v-model="end_date"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        width="200"
                        class="ml-4"
                        color="blue"
                        dark
                        depressed
                        @click="search()"
                    >
                        날짜 검색
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-data-table
                id="log_list"
                :headers="headers"
                :items="list"
                :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                no-data-text="아직 선택한 직원이 없거나 활동로그 내역이 없습니다."
            >
                <template v-slot:item="{ item }">
                    <tr @click="goToCardDetail(item)" style="cursor:pointer">
                        <td class="pa-2">
                            <v-sheet width="40">
                                <v-img 
                                    contain
                                    :src="item.image != null? '/upload/'+ (selected_nation == '한글카드'? 'card_kr_image':'card_jp_image') +'/'+encodeURI(item.image):'/upload/'+folder+'/default.png'"
                                ></v-img>
                            </v-sheet>
                        </td>
                        <td><font class="d-block text-truncate" style="width:100px">{{item.nickname}}</font></td>
                        <td><font class="d-block text-truncate" style="width:100px">{{item.id}}</font></td>
                        <td><font class="d-block text-truncate" style="width:120px">{{item.name}}</font></td>
                        <td><font class="d-block text-truncate" style="width:140px">{{item.effects}}</font></td>
                        <td><font class="d-block text-truncate" style="width:140px">{{item.rules}}</font></td>
                        <td><font class="d-block text-truncate" style="width:120px">{{item.number}} / {{item.set_printedTotal}}</font></td>
                        <td><font class="d-block text-truncate" style="width:120px">{{item.rarity}}</font></td>
                        <td><font class="d-block text-truncate" style="width:220px">{{item.set_name}}</font></td>
                        <td><font class="d-block text-truncate" style="width:200px">{{new Date(item.last_modified_datetime).toLocaleString()}}</font></td>
                    </tr>
                </template>
            </v-data-table>
        </v-sheet>
    </div>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: 'IMG', value: 'image'},
            {text: '직원 닉네임', value: 'nickname'},
            {text: '카드 고유ID', value: 'id'},
            {text: '카드명', value: 'name'},
            {text: '효과', value: 'effects'},
            {text: '룰', value: 'rules'},
            {text: '블록넘버 / 토탈넘버', value: 'number'},
            {text: '희귀도', value: 'rarity'},
            {text: '제품명', value: 'set_name'},
            {text: '입력시간', value: 'last_modified_datetime'}
        ],

        select_list: {
            manager: ["전체"],
            nation: ["한글카드", "일본카드"]
        },
        
        list: [],
        selected: "전체",
        selected_nation: "한글카드",

        start_date: "",
        end_date: ""
    }),

    mounted(){
        // 직원 목록
        this.loadManagerList()

        // 검색
        this.search()
    },

    watch: {
        selected(){
            this.search()
        },

        selected_nation(){
            this.search()
        }
    },

    methods: {
        // 직원 목록
        loadManagerList(){
            this.$http.post('/api/admin/staff/manager/list')
            .then((res) => {
                this.select_list.manager = this.select_list.manager.concat(res.data)
            })
        },

        // 검색
        search(){
            this.$http.post('/api/admin/staff/log/search', {
                params: {
                    card_nation: this.selected_nation,
                    user_id: this.selected,
                    start_date: new Date(this.start_date),
                    end_date: new Date(this.end_date)
                }
            }).then((res) => {
                this.list = res.data
            })
        },

        // 카드상세로 이동
        goToCardDetail(item){
            window.open('/card/detail?id='+item.id, '_blank')
        }
    }
}
</script>
<style>
#log_list{
    border:1px solid #efefef;
}
#log_list tr th{
    background:#f7f7f7;
}

#log_list tr th, #log_list tr td{
    width:50px;
    padding:0 5px;
    padding-left:10px;
}

#log_list tr th:not(:last-child), #log_list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>