<template>
    <div>
        <!-- 제목 -->
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">UID 연결신청 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 목록 -->
            <v-radio-group v-model="selected">
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="list"
                    :footer-props="{
                        'items-per-page-options': [10, 20, 100, -1],
                        'items-per-page-text':'페이지당 UID 연결신청 수'
                    }"
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:45px;">
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <td style="width:120px; cursor:pointer;" @click="$router.push('/official_league/player/detail?id='+item.uid)"><font class="d-block text-truncate">{{item.uid}}</font></td>
                            <td style="width:160px; cursor:pointer;" @click="$router.push('/official_league/player/detail?id='+item.uid)"><font class="d-block text-truncate">{{item.player_kr}}</font></td>
                            <td style="width:160px; cursor:pointer;" @click="$router.push('/official_league/player/detail?id='+item.uid)"><font class="d-block text-truncate">{{item.player_en}}</font></td>
                            <td style="width:160px; cursor:pointer;" @click="$router.push('/official_league/player/detail?id='+item.uid)"><font class="d-block text-truncate">{{item.player_local}}</font></td>
                            <td style="width:140px; cursor:pointer;" @click="$router.push('/official_league/player/detail?id='+item.uid)"><font class="d-block text-truncate">{{item.country}}</font></td>
                            <td style="width:140px; cursor:pointer;" @click="$router.push('/official_league/player/detail?id='+item.uid)"><font class="d-block text-truncate">{{item.age_group}}</font></td>
                            <td style="width:20%"><font class="d-block text-truncate">{{item.user_id}}</font></td>
                            <td style="width:180px"><font class="d-block text-truncate">{{new Date(item.created).toLocaleString()}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 선택항목 & 버튼 -->
            <v-card outlined class="mt-2 pa-2 d-flex justify-end">
                <v-btn
                    class="px-6 mr-4"
                    disabled
                    large
                >
                    선택항목: {{selectedItem.uid}}
                </v-btn>
                <v-btn
                    class="px-6"
                    color="orange"
                    large
                    dark
                    depressed
                    @click="admit()"
                >
                    <v-icon small left>mdi-check-circle-outline</v-icon>
                    승인하기
                </v-btn>
            </v-card>
        </v-sheet>
    </div>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '', value: ''},
            {text: 'UID', value: 'uid'},
            {text: '한글이름', value: 'player_kr'},
            {text: '영어이름', value: 'player_en'},
            {text: '현지이름', value: 'player_local'},
            {text: '국가', value: 'country'},
            {text: '연령구분', value: 'age_group'},
            {text: '신청자 아이디', value: 'user_id'},
            {text: '신청일자', value: 'created'},
        ],

        list: [],

        selected: false,
        selectedItem: {},
    }),

    mounted(){
        // 목록 불러오기
        this.load()
    },

    methods: {
        // 목록 불러오기
        load(){
            this.$http.post('/api/uid_apply/select/admin_list')
            .then((res) => {
                console.log(res)
                this.list = res.data
            })
        },

        // 선택시 값 업데이트
        selectUpdate(item){
            this.selectedItem.uid = item.uid
            this.selectedItem.user_id = item.user_id
            this.selectedItem.player_kr = item.player_kr
            this.selectedItem.player_en = item.player_en
            this.selectedItem.player_country = item.country
            this.selectedItem.player_age_group = item.age_group
        },

        admit(){
            if(!this.selectedItem.uid){
                alert("선택된 값이 없습니다.")
            }else{
                this.$http.post("/api/user_league_info/update/uid", {
                    params: {
                        player_kr: this.selectedItem.player_kr,
                        player_en: this.selectedItem.player_en,
                        user_id: this.selectedItem.user_id,
                        uid: this.selectedItem.uid
                    }
                }).then((res) => {
                    console.log(res)
                    if(res.data.affectedRows){
                        this.$http.post("/api/official_league/player/update/mypage", {
                            params: {
                                player_country: this.selectedItem.player_country,
                                player_age_group: this.selectedItem.player_age_group,
                                uid: this.selectedItem.uid,
                            }
                        }).then((res) => {
                            console.log(res)
                        })

                        this.$http.post("/api/uid_apply/update/status", {
                            params: {
                                user_id: this.selectedItem.user_id,
                                uid: this.selectedItem.uid
                            }
                        }).then((res) => {
                            console.log(res)
                            if(res.data.affectedRows){
                                alert("해당 UID 연결신청이 승인되었습니다.")
                                this.load()
                            }
                        })
                    }
                })
            }
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}

.updatetable{
    border:1px solid #efefef;
    border-collapse: collapse;
}

.updatetable tr td{
    height:50px;
    padding:10px;
    border:1px solid #dedede;
}

.updatetable tr th{
    background:#f2f2f2;
    text-align: left;
    padding:10px;
    border:1px solid #dedede;
}
</style>