<template>
    <v-sheet class="transparent">
        <!-- 제목 -->
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">상품 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex" width="660">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="blue"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-data-table
                id="list"
                :headers="headers"
                :items="list"
                :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                no-data-text="현재 데이터가 없습니다."
            >
                <template v-slot:item="{ item }">
                    <tr @click="goToProduct(item)" style="cursor:pointer">
                        <td style="width:88" class="pa-2">
                            <v-sheet width="80">
                                <v-img
                                    contain
                                    :src="item.thumbnail!=null? '/upload/pointmall/thumbnail/'+encodeURI(item.thumbnail):'/upload/pointmall/thumbnail/default.png'"
                                ></v-img>
                            </v-sheet>
                        </td>
                        <td style="width:100%"><font class="d-block text-truncate" style="width:300px;">{{item.title}}</font></td>
                        <td style="width:80"><font class="d-block text-truncate" style="width:80px;">{{item.sell_type}}</font></td>
                        <td style="width:80"><font class="d-block text-truncate" style="width:80px;">{{item.price}}</font></td>
                        <td style="width:80"><font class="d-block text-truncate" style="width:80px;">{{item.delivery_price}}</font></td>
                        <td style="width:80"><font class="d-block text-truncate" style="width:80px;">{{item.category}}</font></td>
                        <td style="width:80"><font class="d-block text-truncate" style="width:100px;">{{item.event_type}}</font></td>
                        <td style="width:80"><font class="d-block text-truncate" style="width:80px;">{{item.amount}}</font></td>
                        <td style="width:100"><font class="d-block text-truncate" style="width:100px;">{{item.status}}</font></td>
                        <td style="width:180"><font class="d-block text-truncate" style="width:180px;">{{new Date(item.start_date).toLocaleString()}}</font></td>
                        <td style="width:180"><font class="d-block text-truncate" style="width:180px;">{{new Date(item.end_date).toLocaleString()}}</font></td>
                    </tr>
                </template>
            </v-data-table>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '썸네일', value: 'thumbnail'},
            {text: '제목', value: 'title'},
            {text: '판매 타입', value: 'sell_type'},
            {text: '가격', value: 'price'},
            {text: '배송가격', value: 'delivery_price'},
            {text: '카테고리', value: 'category'},
            {text: '이벤트 타입', value: 'event_type'},
            {text: '상품 수량', value: 'amount'},
            {text: '상태', value: 'status'},
            {text: '시작 일자', value: 'start_date'},
            {text: '종료 일자', value: 'end_date'}
        ],
        list: [],

        // 선택목록
        select_list: {
            search_type: [
                "제목", 
                "판매 타입", 
                "가격",
                "배송가격",
                "카테고리",
                "이벤트 타입",
                "상품 수량",
                "상태",
                "시작 일자",
                "종료 일자"
            ]
        },

        search_type: "제목",
        keyword: "",
    }),

    mounted(){
        // 목록
        this.load()
    },

    methods: {
        // 목록
        load(){
            this.$http.get('/api/admin/pointmall/product/list')
            .then((res) => {
                this.list = res.data
            })
        },

        // 링크
        goToProduct(item){
            this.$router.push('/pointmall/read?id='+item.id)
        },

        // 검색
        search(){
            this.$http.post('/api/admin/pointmall/product/search', {
                params: {
                    keyword: this.keyword,
                    type: this.search_type
                }
            }).then((res) => {
                this.list = res.data
            })
        },
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    width:85px;
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>