<template>
    <v-sheet color="transparent">
        <!-- 헤더 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">선입력카드 관리</font>
            <v-spacer></v-spacer>
            <v-sheet width="210">
                <v-select
                    class="mr-4"
                    outlined dense hide-details
                    v-model="selected_card_nation"
                    :items="select_list.card_nation"
                ></v-select>
            </v-sheet>
        </v-sheet>
        
        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex" width="660">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="blue"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-data-table
                id="list"
                :headers="headers"
                :items="list"
                show-select
                :footer-props="{'items-per-page-options': [5, 20, 100, -1]}"
                no-data-text="아직 선입력된 카드가 없습니다."
                v-model="selectedItem"
                @update:page="init_selectedItem()"
                @update:sort-desc="init_selectedItem()"
            >
                <template v-slot:item="{ item }">
                    <tr>
                        <td>
                            <v-checkbox
                                :value="item"
                                v-model="selectedItem"
                                style="margin:0px;padding:0px"
                                hide-details
                            />
                        </td>
                        <td style="width:80px" class="pa-2">
                            <v-img 
                                v-if="selected_card_nation=='한글 선입력 카드'"
                                width="80"
                                class="mx-auto"
                                :src="'/upload/card_kr_image/'+encodeURI(item.image)"
                            ></v-img>
                            <v-img 
                                v-if="selected_card_nation=='일본 선입력 카드'"
                                width="80"
                                class="mx-auto"
                                :src="'/upload/card_jp_image/'+encodeURI(item.image)"
                            ></v-img>
                        </td>
                        <td @click="linkToDetail(item)" style="width:160px;cursor:pointer"><font class="d-block text-truncate" style="width:160px;">{{item.id}}</font></td>
                        <td @click="linkToDetail(item)" style="width:180px;cursor:pointer"><font class="d-block text-truncate" style="width:180px;">{{item.name}}</font></td>
                        <td @click="linkToDetail(item)" style="width:180px;cursor:pointer"><font class="d-block text-truncate" style="width:180px;">{{item.name_en}}</font></td>
                        <td @click="linkToDetail(item)" style="width:180px;cursor:pointer"><font class="d-block text-truncate" style="width:180px;">{{item.rarity}}</font></td>
                        <td @click="linkToDetail(item)" style="width:100px;cursor:pointer"><font class="d-block text-truncate" style="width:100px;">{{item.number}}</font></td>
                        <td @click="linkToDetail(item)" style="width:100px;cursor:pointer"><font class="d-block text-truncate" style="width:100px;">{{item.set_printedTotal}}</font></td>
                        <td @click="linkToDetail(item)" style="width:100%;cursor:pointer"><font class="d-block text-truncate" style="width:360px;">{{item.set_name}}</font></td>
                    </tr>
                </template>
            </v-data-table>

            <!-- 선택항목 & 버튼 -->
            <v-sheet class="mt-2 pa-2 d-flex justify-end" outlined>
                <!-- 선택항목 -->
                <v-btn
                    class="px-6 mr-4 text-lowercase"
                    large
                    disabled
                >
                    선택항목: {{selectedItem.name}} ( {{selectedItem.id}} )
                </v-btn>      

                <!-- API 카드와 병합하기 -->
                <v-btn
                    class="px-6"
                    color="deep-orange"
                    large
                    dark
                    depressed
                    @click="mergeOpen()"
                >
                    <v-icon small left>mdi-call-merge</v-icon>
                    API 카드와 병합하기
                </v-btn>

                <!-- 수정하기 -->
                <v-btn
                    class="px-6 ml-3"
                    color="orange"
                    large
                    dark
                    depressed
                    @click="updateOpen()"
                >
                    <v-icon small left>mdi-database-edit-outline</v-icon>
                    수정하기
                </v-btn>

                <!-- 삭제하기 -->
                <v-btn
                    class="px-6 ml-3"
                    color="orange"
                    large
                    dark
                    depressed
                    @click="deleteSubmit()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    삭제하기
                </v-btn>

                <!-- 추가하기 -->
                <v-sheet v-if="selected_card_nation == '한글 선입력 카드'">
                    <v-btn
                        class="px-6 ml-3"
                        color="blue"
                        large
                        dark
                        depressed
                        @click="dialog.kr = true"
                    >
                        <v-icon small left>mdi-database-plus-outline</v-icon>
                        한글카드 입력하기
                    </v-btn>
                    <v-btn
                        class="px-6 ml-3"
                        color="orange"
                        large
                        dark
                        depressed
                        @click="addJapanOpen()"
                    >
                        <v-icon small left>mdi-database-plus-outline</v-icon>
                        일본카드 추가하기
                    </v-btn>
                </v-sheet>
                <v-sheet v-if="selected_card_nation == '일본 선입력 카드'">
                    <v-btn
                        class="px-6 ml-3"
                        color="blue"
                        large
                        dark
                        depressed
                        @click="dialog.jp = true"
                    >
                        <v-icon small left>mdi-database-plus-outline</v-icon>
                        일본카드 입력하기
                    </v-btn>
                    <v-btn
                        class="px-6 ml-3"
                        color="orange"
                        large
                        dark
                        depressed
                        @click="addKoreaOpen()"
                    >
                        <v-icon small left>mdi-database-plus-outline</v-icon>
                        한글카드 추가하기
                    </v-btn>
                </v-sheet>

                <!-- Excel 저장하기 -->
                <vue-excel-xlsx
                    class="ml-3"
                    :data="selectedItem"
                    :columns="columns"
                    :file-name="'선입력카드 목록'"
                    :file-type="'xlsx'"
                    :sheet-name="'sheetname'"
                >
                    <v-btn
                        class="px-6"
                        color="green"
                        large
                        dark
                        depressed
                    >
                        <v-icon left>mdi-microsoft-excel</v-icon>
                        Excel 저장하기
                    </v-btn>
                </vue-excel-xlsx>
            </v-sheet>
        </v-sheet>

        <!-- API 카드와 병합하기 -->
        <v-dialog
            v-model="dialog.merge"
            width="auto"
            content-class="rounded-15"
            style="position:relative;"
        >
            <v-sheet
                class="pa-4"
            >
                <p class="text-h6 text-center font-weight-bold">API 카드와 병합하기</p>

                <v-autocomplete
                    height="42"
                    dense outlined hide-details
                    style="width:440px;"
                    class="v15 rounded-10 shrink mx-auto mb-3"
                    placeholder="제품명"
                    :items="select_list.set.en"
                    v-model="selected_set"
                    :disabled="loading"
                    @change="find_api_id()"                    
                >
                    <template v-slot:append>
                        <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-autocomplete>

                <table class="merge_table">
                    <tr>
                        <th>이미지</th>
                        <th>ID</th>
                        <th>희귀도</th>
                        <th>이름</th>
                        <th>병합할 ID</th>
                    </tr>
                    <tr v-for="item in selectedItem" :key="item.id">
                        <td style="width:80px" class="pa-2">
                            <v-img 
                                v-if="selected_card_nation=='한글 선입력 카드'"
                                width="80"
                                class="mx-auto"
                                :src="'/upload/card_kr_image/'+encodeURI(item.image)"
                            ></v-img>
                            <v-img 
                                v-if="selected_card_nation=='일본 선입력 카드'"
                                width="80"
                                class="mx-auto"
                                :src="'/upload/card_jp_image/'+encodeURI(item.image)"
                            ></v-img>
                        </td>
                        <td style="width:160px;">{{item.id}}</td>
                        <td style="width:160px;">{{item.rarity}}</td>
                        <td style="width:200px;"><font class="d-block text-truncate" style="width:200px;">{{item.name}}</font></td>
                        <td style="width:200px;">
                            <v-text-field
                                outlined
                                dense
                                hide-details
                                v-model="item.api_card_id"
                            ></v-text-field>
                        </td>
                    </tr>
                </table>
                <v-sheet class="mt-4 d-flex justify-center">
                    <v-btn
                        class="rounded-10 px-6 mr-4"
                        color="blue"
                        dark
                        large
                        depressed
                        @click="mergeSubmit()"
                    >
                        <v-icon small left>mdi-call-merge</v-icon>
                        병합하기
                    </v-btn>
                    <v-btn
                        class="rounded-10 px-6"
                        color="grey"
                        dark
                        large
                        depressed
                        @click="dialog.merge = false"
                    >
                        <v-icon small left>mdi-cancel</v-icon>
                        취소하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 로딩 -->
            <v-progress-circular
                v-if="loading"
                style="position:absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index:999;"
                indeterminate
                color="primary"
                size="80"
            ></v-progress-circular>
        </v-dialog>

        <!-- 한글/일본 카드 입력하기 -->
        <v-dialog v-model="dialog.kr" width="auto">
            <InsertCard
                :key="componentKey"
                card_nation="kr"
                insert_type="whole"
                type="insert"
                :isPreCard="true"
                @reload="load"
                @close="close()"
            />
        </v-dialog>
        <v-dialog v-model="dialog.jp" width="auto">
            <InsertCard
                :key="componentKey"
                card_nation="jp"
                insert_type="whole"
                type="insert"
                :isPreCard="true"
                @reload="load"
                @close="close()"
            />
        </v-dialog>

        <!-- 한글/일본 카드 수정하기 -->
        <v-dialog v-model="dialog.update.kr" width="auto">
            <UpdateCard
                :key="componentKey"
                :card_id="card_id"
                text_nation="kr"
                insert_type="whole"
                type="update"
                @reload="load"
                @close="close()"
            />
        </v-dialog>
        <v-dialog v-model="dialog.update.jp" width="auto">
            <UpdateCard
                :key="componentKey"
                :card_id="card_id"
                text_nation="jp"
                insert_type="whole"
                type="update"
                @reload="load"
                @close="close()"
            />
        </v-dialog>

        <!-- 한글/일본 카드 추가하기 -->
        <v-dialog v-model="dialog.add.kr" width="auto">
            <InsertCard
                :key="componentKey"
                :card_id="card_id"
                card_nation="kr"
                insert_type="whole"
                type="insert"
                :isAdd="true"
                @reload="load"
                @close="close()"
            />
        </v-dialog>
        <v-dialog v-model="dialog.add.jp" width="auto">
            <InsertCard
                :key="componentKey"
                :card_id="card_id"
                card_nation="jp"
                insert_type="whole"
                type="insert"
                :isAdd="true"
                @reload="load"
                @close="close()"
            />
        </v-dialog>
    </v-sheet>
</template>
<script>
import InsertCard from '@/components/cardmodule/InsertCard'
import UpdateCard from '@/components/cardmodule/UpdateCard'

export default {
    components: {
        InsertCard,
        UpdateCard
    },

    data: () => ({
        headers: [
            {text: '이미지', value:'image'},
            {text: 'ID', value:'id'},
            {text: '이름', value:'name'},
            {text: '영어 이름', value:'name_en'},
            {text: '희귀도', value:'rarity'},
            {text: '블록넘버', value:'number'},
            {text: '토탈넘버', value:'set_printedTotal'},
            {text: '제품명', value:'set_name'}
        ],
        list: [],
        selected: false,

        selectedItem: [],

        select_list: {
            search_type: [
                "ID", 
                "이름", 
                "영어이름",
                "희귀도",
                "블록넘버",
                "토탈넘버",
                "제품명"
            ],

            card_nation: [
                "한글 선입력 카드", 
                "일본 선입력 카드"
            ],

            // 제품명
            set: {
                en: [],
            },
        },
        selected_card_nation: "한글 선입력 카드",
        search_type: "이름",
        keyword: "",

        selected_set: "",

        dialog: {
            kr: false,
            jp: false,
            merge: false,
            update: {
                kr: false,
                jp: false
            },
            add: {
                kr: false,
                jp: false
            }
        },

        loading:false,

        api_card_id: "",
        card_id: "",

        componentKey: 0,

        nameList: [],

        // Excel 저장
        columns: [
            {label: 'ID', field:'id'},
            {label: '이름', field:'name'},
            {label: '영어 이름', field:'name_en'},
            {label: '희귀도', field:'rarity'},
            {label: '블록넘버', field:'number'},
            {label: '토탈넘버', field:'set_printedTotal'},
            {label: '제품명', field:'set_name'}
        ],
    }),

    watch: {
        selected_card_nation(){
            this.load()
        }
    },

    mounted(){
        // 목록
        this.load()

        // 제품명 목록 불러오기
        this.loadSetsList()

        // 목록 불러오기
        this.loadNameList()
    },

    methods: {
        // 목록
        load(){
            if(this.selected_card_nation == "한글 선입력 카드"){
                this.$http.post('/api/admin/card/pre_card/list/kr')
                .then((res) => {
                    this.list = res.data
                    console.log(this.list)
                    this.init_selectedItem()
                })
            }else if(this.selected_card_nation == "일본 선입력 카드"){
                this.$http.post('/api/admin/card/pre_card/list/jp')
                .then((res) => {
                    this.list = res.data
                    this.init_selectedItem()
                })
            }
        },

        // 제품명 목록 불러오기
        loadSetsList(){
            // 한글
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "en"
                }
            }).then(res => { 
                this.select_list.set.en = res.data.map(a => a.name)
            })
        },

        // 목록 불러오기
        loadNameList(){
            this.$http.post('/api/admin/card/translate/list')
            .then((res) => {
                this.nameList = res.data
            })
        },

        // 선택한 값 초기화
        init_selectedItem(){
            this.selectedItem = []
        },

        // 컴포넌트 다시 불러오기
        reloadComponents() {
            this.componentKey += 1
        },

        // dialog 닫기
        close(){
            this.dialog.kr = false
            this.dialog.jp = false
            this.dialog.merge = false
            this.dialog.update.kr = false
            this.dialog.update.jp = false
            this.dialog.add.kr = false
            this.dialog.add.jp = false
        },

        // 카드상세 페이지 링크
        linkToDetail(item){
            window.open('/card/detail?id='+item.id, '_blank')
        },

        // 수정하기 열기
        updateOpen(){
            this.reloadComponents()
            if(this.selectedItem.length != 1){
                alert("수정하기 시 선택한 카드가 1개이어야 합니다.")
            }else{
                this.card_id = this.selectedItem[0].id

                if(this.selected_card_nation == "한글 선입력 카드"){
                    this.dialog.update.kr = true
                }else if(this.selected_card_nation == "일본 선입력 카드"){
                    this.dialog.update.jp = true
                }
            }
        },

        // 삭제하기 제출
        async deleteSubmit(){
            if(!Object.keys(this.selectedItem).length){
                alert("선택한 선입력카드가 없습니다.")
            }else{
                if(confirm("정말 선택하신 선입력카드들을 삭제하시겠습니까?")){
                    if(this.selected_card_nation == "한글 선입력 카드"){
                        for(let i=0; i < this.selectedItem.length; i++){
                            await this.$http.post('/api/admin/card/pre_card/delete/kr', {
                                params: {
                                    card_id: this.selectedItem[i].id,
                                }
                            }).then((res) => {
                                console.log(res)
                            })

                            await this.$http.post('/api/admin/card/pre_card/delete/dump', {
                                params: {
                                    card_id: this.selectedItem[i].id,
                                }
                            }).then((res) => {
                                console.log(res)
                            })
                        }
                        this.load()

                    }else if(this.selected_card_nation == "일본 선입력 카드"){
                        for(let i=0; i < this.selectedItem.length; i++){
                            await this.$http.post('/api/admin/card/pre_card/delete/jp', {
                                params: {
                                    card_id: this.selectedItem[i].id,
                                }
                            }).then((res) => {
                                console.log(res)
                            })

                            await this.$http.post('/api/admin/card/pre_card/delete/dump', {
                                params: {
                                    card_id: this.selectedItem[i].id,
                                }
                            }).then((res) => {
                                console.log(res)
                            })
                        }
                        this.load()
                    }
                }
            }
        },

        // API 병합하기 열기
        mergeOpen(){
            if(!Object.keys(this.selectedItem).length){
                alert("선택한 선입력카드가 없습니다.")
            }else{
                this.dialog.merge = true
            }
        },

        // 병합할 ID 찾기
        async find_api_id(){
            this.loading = true

            console.log(this.selected_set)
            console.log(this.selectedItem)

            // this.selectedItem = await this.selectedItem.map(item => {
            //     let match = ""
            //     if(this.selected_card_nation == "한글 선입력 카드"){
            //         match = this.nameList.find(nameItem => nameItem.kr === item.name)
            //     }else{
            //         match = this.nameList.find(nameItem => nameItem.jp === item.name)
            //     }

            //     item.translated_name = match ? match.en : null

            //     return item
            // })

            console.log(this.selectedItem)

            for(let item of this.selectedItem){
                await this.$http.post("/api/admin/card/pre_card/find_id", {
                    params: {
                        set_name: this.selected_set,
                        name_en: item.name_en
                    }
                }).then(async (res) => {
                    if(res.data.length){
                        item.api_card_id = res.data[0].id
                    }else{
                        item.api_card_id = ""
                    }
                })
            }

            this.loading = false
        },

        // API 병합하기 제출
        async mergeSubmit(){
            let filteredSelectedItem = this.selectedItem.filter(e => e.api_card_id != undefined && e.api_card_id != "")

            if(!filteredSelectedItem.length){
                alert("병할할 ID가 입력되지 않았습니다.")
                return
            }

            for(let item of filteredSelectedItem){
                console.log(item)
                if(!item.name_en || !item.supertype){
                    alert("선택한 카드중에 영어이름 또는 슈퍼속성이 누락된 카드가 있어 카드병합을 진행할 수 없습니다.")
                    return
                }
            }

            this.loading = true

            if(this.selected_card_nation == "한글 선입력 카드"){
                for(let i=0; i < filteredSelectedItem.length; i++){
                    await this.$http.post('/api/admin/card/pre_card/merge/kr', {
                        params: {
                            pre_card_id: filteredSelectedItem[i].id,
                            api_card_id: filteredSelectedItem[i].api_card_id
                        }
                    }).then((res) => {
                        // if(res.data.affectedRows)
                        // {
                        //     alert("'"+filteredSelectedItem[i].id+"'와 '"+filteredSelectedItem[i].api_card_id+"'이 정상적으로 병합이 완료되었습니다.")
                        // }
                        // else{
                        //     alert("'"+filteredSelectedItem[i].api_card_id+"'가 없는 고유아이디이거나 병합 도중 오류가 발생하였습니다.")
                        // }
                    })

                    // 만약 있다면 일본카드도 같이 병합
                    await this.$http.post('/api/admin/card/pre_card/merge/jp', {
                        params: {
                            pre_card_id: filteredSelectedItem[i].id,
                            api_card_id: filteredSelectedItem[i].api_card_id
                        }
                    })
                }
                alert("병합이 모두 완료되었습니다")
                this.load()
                this.dialog.merge = false

            }else if(this.selected_card_nation == "일본 선입력 카드"){
                for(let i=0; i < filteredSelectedItem.length; i++){
                    await this.$http.post('/api/admin/card/pre_card/merge/jp', {
                        params: {
                            pre_card_id: filteredSelectedItem[i].id,
                            api_card_id: filteredSelectedItem[i].api_card_id
                        }
                    }).then((res) => {
                        // if(res.data.affectedRows)
                        // {
                        //     alert("'"+filteredSelectedItem[i].id+"'와 '"+filteredSelectedItem[i].api_card_id+"'이 정상적으로 병합이 완료되었습니다.")
                        // }
                        // else
                        // {
                        //     alert("'"+filteredSelectedItem[i].api_card_id+"'가 없는 고유아이디이거나 병합 도중 오류가 발생하였습니다.")
                        // }
                    })

                    // 만약 있다면 한글카드도 같이 병합
                    await this.$http.post('/api/admin/card/pre_card/merge/kr', {
                        params: {
                            pre_card_id: filteredSelectedItem[i].id,
                            api_card_id: filteredSelectedItem[i].api_card_id
                        }
                    })
                }
                this.loading = false
                alert("병합이 모두 완료되었습니다")
                this.load()
                this.dialog.merge = false
            }
        },

        // 일본카드 추가하기
        addJapanOpen(){
            if(this.selectedItem.length != 1){
                alert("추가하기 시 선택한 카드가 1개이어야 합니다.")
            }else{
                this.card_id = this.selectedItem[0].id

                this.$http.post('/api/admin/card/pre_card/check/card_jp', {
                    params: {
                        id: this.selectedItem[0].id
                    }
                }).then((res) => {
                    if(res.data.length){
                        alert("선택한 카드는 이미 일본 선입력카드 정보가 있습니다.")
                    }else{
                        this.dialog.add.jp = true
                    }
                })
            }
        },

        // 한글카드 추가하기
        addKoreaOpen(){
            if(this.selectedItem.length != 1){
                alert("추가하기 시 선택한 카드가 1개이어야 합니다.")
            }else{
                this.card_id = this.selectedItem[0].id

                this.$http.post('/api/admin/card/pre_card/check/card_kr', {
                    params: {
                        id: this.selectedItem.id
                    }
                }).then((res) => {
                    if(res.data.length){
                        alert("선택한 카드는 이미 한글 선입력카드 정보가 있습니다.")
                    }else{
                        this.dialog.add.kr = true
                    }
                })
            }
        },

        // 검색
        search(){
            if(this.selected_card_nation == '한글 선입력 카드'){
                this.$http.post('/api/admin/card/pre_card/search/kr', {
                    params: {
                        keyword: this.keyword,
                        type: this.search_type
                    }
                }).then((res) => {
                    this.list = res.data
                    this.init_selectedItem()
                })
            }else if(this.selected_card_nation == "일본 선입력 카드"){
                this.$http.post('/api/admin/card/pre_card/search/jp', {
                    params: {
                        keyword: this.keyword,
                        type: this.search_type
                    }
                }).then((res) => {
                    this.list = res.data
                    this.init_selectedItem()
                })
            }
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>
<style scoped>
.merge_table{
    width:100%;
    border:1px solid #efefef;
    border-collapse: collapse;
}

.merge_table tr td{
    height:50px;
    padding:10px;
    border:1px solid #dedede;
}

.merge_table tr th{
    background:#f2f2f2;
    text-align: left;
    padding:10px;
    border:1px solid #dedede;
}
</style>