<template>
    <v-sheet class="transparent">
        <!-- 제목 -->
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">직원 목록</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex" width="660">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="blue"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-radio-group v-model="selected">
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="list"
                    :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                    no-data-text="아직 선택한 직원이 없거나 활동로그 내역이 없습니다."
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:45px;">
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <td style="width:100%;"><font class="d-block text-truncate" style="width:100%;">{{item.user_id}}</font></td>
                            <td><font class="d-block text-truncate" style="width:200px;">{{item.nickname}}</font></td>
                            <td><font class="d-block text-truncate" style="width:200px;">{{item.point}}</font></td>
                            <td><font class="d-block text-truncate" style="width:200px;">{{item.email}}</font></td>
                            <td><font class="d-block text-truncate" style="width:200px;">{{item.status}}</font></td>
                            <td><font class="d-block text-truncate" style="width:180px;">{{new Date(item.created).toLocaleString()}}</font></td>
                            <td>
                                <v-btn outlined color="blue" block @click="updateToNormalUser(item)">권한 해제</v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 선택항목 & 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    class="px-6 mr-4"
                    disabled
                    large
                >
                    선택항목: {{selectedItem.user_id}}
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="blue"
                    large
                    dark
                    depressed
                    @click="rewardOpen()"
                >
                    <v-icon small left>mdi-database-plus-outline</v-icon>
                    포인트 지급하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- 포인트 지급하기 -->
        <v-dialog v-model="dialog.reward" width="600" content-class="rounded-10">
            <v-sheet class="pa-4">
                <!-- 수정 전 -->
                <v-sheet class="mt-2">
                    <table id="selected_user">
                        <tr>
                            <th>회원 아이디</th>
                            <th>닉네임</th>
                            <th>회원등급</th>
                            <th>보유 포인트</th>
                        </tr>
                        <tr>
                            <td>{{selectedItem.user_id}}</td>
                            <td>{{selectedItem.nickname}}</td>
                            <td>{{selectedItem.grade}}</td>
                            <td>{{selectedItem.point}}</td>
                        </tr>
                    </table>
                    <v-sheet outlined class="pa-1 mt-2">
                        <v-text-field
                            v-model="reward.why"
                            class="title_field"
                            hide-details
                            dense
                            solo
                            flat
                            placeholder="지급 사유를 적어주세요"
                        ></v-text-field>
                    </v-sheet>
                    <v-sheet outlined class="pa-1 mt-2" style="width:100%;">
                        <v-text-field
                            v-model="reward.point"
                            class="title_field"
                            hide-details
                            dense
                            solo
                            flat
                            type="number"
                            reverse
                            prefix="P"
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>
                <v-sheet class="mt-2 pa-2 d-flex justify-center">
                    <v-btn
                        class="rounded-10 px-6 mr-4"
                        color="blue"
                        large
                        dark
                        depressed
                        @click="rewardSubmit()"
                    >
                        <v-icon small left>mdi-database-edit-outline</v-icon>
                        포인트 지급하기
                    </v-btn>
                    <v-btn
                        class="rounded-10 px-6"
                        color="grey"
                        large
                        dark
                        depressed
                        @click="dialog.reward=false"
                    >
                        <v-icon small left>mdi-cancel</v-icon>
                        취소하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '', value: ''},
            {text: '회원 아이디', value: 'user_id'},
            {text: '닉네임', value: 'nickname'},
            {text: '포인트', value: 'point'},
            {text: '이메일 주소', value: 'email'},
            {text: '이메일 인증', value: 'status'},
            {text: '가입일자', value: 'created'},
            {text: '권한 해제', value: ''}
        ],
        
        list: [],

        selected: false,
        selectedItem: {},

        select_list: {
            search_type: [
                "회원 아이디", 
                "회원 닉네임", 
                "포인트",
                "이메일 주소",
                "이메일 인증",
                "가입일자"
            ]
        },

        search_type: "회원 아이디",
        keyword: "",

        dialog: {
            reward: false
        },

        reward: {
            why: '',
            point: 0
        }
    }),

    mounted(){
        // 목록
        this.load()
    },

    methods: {
        // 목록
        load(){
            this.$http.post('/api/admin/staff/manager/list')
            .then((res) => {
                this.list = res.data
            })
        },

        // 권한 해제
        updateToNormalUser(item){
            if(confirm(item.user_id+"의 권한을 해제하시겠습니까?")){
                this.$http.post('/api/admin/user/normal/updateToNormalUser', {
                    params: {
                        user_id: item.user_id
                    }
                }).then((res) => {
                    alert("해당 매니저가 일반 회원로 변경되었습니다.")
                    this.$http.post('/api/admin/staff/manager/list')
                    .then((res) => {
                        this.list = res.data
                    })
                })
            }
        },

        // 검색
        search(){
            this.$http.post('/api/admin/staff/manager/search', {
                params: {
                    keyword: this.keyword,
                    type: this.search_type
                }
            }).then((res) => {
                this.list = res.data
            })
        },

        // 선택한 값 저장
        selectUpdate(item){
            this.selectedItem = item
        },

        // 포인트 지급하기 열기
        rewardOpen(){
            if(Object.keys(this.selectedItem).length==0){
                alert("선택한 회원가 없습니다.")
            }else{
                this.dialog.reward = true
            }
        },

        // 포인트 지급하기
        rewardSubmit(){
            if(this.reward.why==''){
                alert("포인트 지급사유를 입력해주세요")
            }else if(this.reward.point==0){
                alert("지급할 포인트는 0이 될 수 없습니다.")
            }else if(this.reward.point<0){
                alert("지급할 포인트는 0이하가 될 수 없습니다.")
            }else{
                // point_list에 내역 추가
                this.$http.post('/api/admin/user/normal/reward/point_list', {
                    params: {
                        why: this.reward.why,
                        point: this.reward.point,
                        user_id: this.selectedItem.user_id
                    }
                })

                // user_info 포인트 추가
                this.$http.post('/api/admin/user/normal/reward/point', {
                    params: {
                        point: this.reward.point,
                        user_id: this.selectedItem.user_id
                    }
                }).then((res) => {
                    alert("해당 회원에게 포인트가 지급되었습니다.")
                    this.dialog.reward = false
                    this.load()
                })
            }
        },
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    width:85px;
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>