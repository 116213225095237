<template>
    <v-sheet color="transparent">
        <!-- 헤더 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">카드 삭제</font>
        </v-sheet>
        
        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 입력창 -->
            <v-text-field
                class="d-block mx-auto py-10"
                style="width:400px;"
                v-model="keyword"
                outlined
                dense
                hide-details
                placeholder="삭제할 카드의 고유 아이디를 입력해주세요"
            ></v-text-field>

            <!--버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    large dark color="blue"
                    class="px-6 ml-3"
                    @click="deleteCard()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    삭제하기
                </v-btn>
            </v-card>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        keyword: ""
    }),

    methods: {
        deleteCard(){
            this.$http.post("/api/admin/card/delete/card", {
                params: {
                    card_id: this.keyword
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    alert("해당 카드가 삭제되었습니다.")
                    this.keyword = ""
                }
            })
        }
    }
}
</script>