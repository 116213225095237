<template>
    <v-sheet color="transparent">
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">접속로그 관리</font>
        </v-sheet>
        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 목록 -->
            <v-data-table
                id="list"
                :headers="headers"
                :items="list"
                :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
            >
                <template v-slot:item="{ item }">
                    <tr>
                        <td style="width:20%;">{{item.ip}}</td>
                        <!-- <td style="width:60%;"><p style="max-width:780px;" class="text-truncate mb-0">{{createQueryString(decodeUrlParams(item.path))}}</p></td> -->
                        <td style="width:60%;">
                            <p
                                style="max-width:780px; cursor:pointer; "
                                class="text-truncate mb-0"
                                onmouseover="this.style.color='#f39800'"
                                onmouseout="this.style.color='initial'"
                                @click="$router.push(item.path)"
                            >
                                {{item.path}}
                            </p>
                        </td>
                        <td style="width:20%;">{{new Date(item.created).toLocaleString()}}</td>
                    </tr>
                </template>
            </v-data-table>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        // 목록
        headers: [
            {text: '접속 IP', value: 'ip'},
            {text: '접속주소', value: 'path'},
            {text: '접속시간', value: 'created'}
        ],

        list: []
    }),

    mounted(){
        this.load()
    },

    methods: {
        decodeUrlParams(url) {
            const params = new URLSearchParams(url.split('?')[1])
            let decodedParams = {}

            for (let [key, value] of params) {
                try {
                    decodedParams[key] = decodeURIComponent(value);
                } catch (e) {
                    // 잘못된 형식의 URI 컴포넌트가 있을 경우 처리
                    console.error(`디코드 중 오류 발생: ${key} = ${value}`, e);
                    decodedParams[key] = value; // 오류가 발생한 원본 값을 그대로 사용
                }
            }

            return decodedParams
        },

        // createQueryString(params) {
        //     return Object.keys(params)
        //         .map(key => `${key}=${params[key]}`)
        //         .join('&')
        // },

        // 목록
        load(){
            this.$http.post('/api/visited_log/select')
            .then((res) => {
                console.log(res)
                this.list = res.data
            })
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>