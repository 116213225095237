<template>
    <v-sheet color="transparent">
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <!-- 페이지 제목 -->
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">제품명 관리</font>
            <v-spacer></v-spacer>

            <!-- 한글, 일본 제품명 선택 -->
            <v-sheet width="200">
                <v-select
                    class="mr-4"
                    outlined dense hide-details
                    v-model="selected_nation"
                    :items="select_list.set_nation"
                ></v-select>
            </v-sheet>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2" style="overflow-x:auto;">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2">
                <v-sheet class="d-flex" width="660">
                    <v-sheet width="160" class="mr-3">
                        <v-select
                            outlined
                            hide-details
                            dense
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>
                    <v-text-field
                        v-model="keyword"
                        outlined
                        hide-details
                        dense
                        placeholder="검색어를 입력해주세요"
                        @keyup.enter="search()"
                    ></v-text-field>
                    <v-btn
                        height="40"
                        dark color="blue"
                        class="px-6 ml-3"
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-radio-group v-model="selected">
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="list"
                    :footer-props="{'items-per-page-options': [6, 20, 100, -1]}"
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:45px;">
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <td style="width:160px" class="pa-2">
                                <v-img 
                                    height="50"
                                    width="150"
                                    class="mx-auto"
                                    :src="'/upload/set_'+selected_nation+'_image/'+encodeURI(item.image)"
                                ></v-img>
                            </td>
                            <td style="width:140px"><font class="d-block text-truncate" style="width:140px;">{{item.series}}</font></td>
                            <td style="width:100%"><font class="d-block text-truncate" style="width:200px;">{{item.name}}</font></td>
                            <td style="width:120px"><font class="d-block text-truncate" style="width:120px;">{{item.pack_price}}</font></td>
                            <td style="width:120px"><font class="d-block text-truncate" style="width:120px;">{{item.box_price}}</font></td>
                            <td style="width:200px">
                                <div
                                    class="py-2"
                                    style="white-space:pre-line; font-size:13px; width:200px;"
                                    v-text="item.content"
                                ></div>
                            </td>
                            <td style="width:90px"><font class="d-block text-truncate" style="width:90px;">{{item.totalNumber}}</font></td>
                            <td style="width:90px"><font class="d-block text-truncate" style="width:100px;">{{item.ptcgoCode}}</font></td>
                            <td style="width:90px"><font class="d-block text-truncate" style="width:80px;">{{item.amount}}</font></td>
                            <td style="width:180px"><font class="d-block text-truncate" style="width:160px;">{{new Date(item.release_datetime).toLocaleDateString()}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 선택항목 & 버튼 -->
            <v-card
                class="mt-2 pa-2 d-flex justify-end"
                outlined
            >
                <v-btn
                    disabled
                    large
                    class="px-6 mr-4"
                >
                    선택항목: {{selectedItem.name}}
                </v-btn>
                <v-btn
                    class="px-6"
                    color="orange"
                    large
                    dark
                    @click="updateOpen()"
                >
                    <v-icon small left>mdi-database-edit-outline</v-icon>
                    수정하기
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="blue"
                    large
                    dark
                    @click="deleteItem()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    삭제하기
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="blue"
                    large
                    dark
                    @click="dialog.create = true"
                >
                    <v-icon small left>mdi-database-plus-outline</v-icon>
                    추가하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- 수정하기 -->
        <v-dialog v-model="dialog.update" width="auto">
            <v-sheet class="pa-4">
                <v-sheet>
                    <table v-if="selectedAfter" class="customTable">
                        <tr>
                            <td rowspan="2">이미지</td>
                            <td rowspan="2" class="ma-0 pa-0" style="background:white; text-align:center;">
                                <!-- 이전 이미지 -->
                                <v-img 
                                    height="100"
                                    width="300"
                                    class="mx-auto"
                                    :src="'/upload/set_'+selected_nation+'_image/'+encodeURI(selectedItem.image)"
                                ></v-img>
                            </td>

                            <td class="ma-0 pa-0" style="background:white; text-align:center;">
                                <v-sheet class="d-flex justify-center py-2 px-3">
                                    <!-- 이미지 미리보기 -->
                                    <clipper-basic
                                        :src="url"
                                        ref="clipper"
                                        class="my-clipper"
                                        :ratio="3"
                                        :init-width="100"
                                        :init-height="100"
                                    >
                                        <div class="placeholder" slot="placeholder"></div>
                                    </clipper-basic>
                                </v-sheet>
                            </td>
                        </tr>   
                        <tr>
                            <td class="py-2 px-3" style="background:white;">
                                <v-sheet
                                    @drop.prevent="addDropFile"
                                    @dragover.prevent
                                >
                                    <v-file-input
                                        placeholder="제품명 이미지를 선택해주세요"
                                        outlined
                                        dense
                                        show-size
                                        hide-details
                                        prepend-icon
                                        color="primary"
                                        v-model="uploadImage"
                                        @change="onFileChange()"
                                    >
                                        <template v-slot:prepend-inner>
                                            <v-icon class="mr-1" color="primary">mdi-image-filter-hdr</v-icon>
                                        </template>
                                    </v-file-input>
                                </v-sheet>
                            </td>
                        </tr>
                        <tr>
                            <td>시리즈</td>
                            <td>{{selectedItem.series}}</td>
                            <td>
                                <v-text-field
                                    outlined dense hide-details
                                    v-model="selectedAfter.series"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>제품명</td>
                            <td>{{selectedItem.name}}</td>
                            <td>
                                <v-text-field
                                    outlined dense hide-details
                                    v-model="selectedAfter.name"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>팩 가격</td>
                            <td>{{selectedItem.pack_price}}</td>
                            <td>
                                <v-text-field
                                    outlined dense hide-details type="number"
                                    v-model="selectedAfter.pack_price"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>1BOX 가격</td>
                            <td>{{selectedItem.box_price}}</td>
                            <td>
                                <v-text-field
                                    outlined dense hide-details type="number"
                                    v-model="selectedAfter.box_price"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>구성물</td>
                            <td>
                                <div
                                    class="py-2"
                                    style="white-space:pre-line; width:180px;"
                                    v-text="selectedItem.content"
                                ></div>
                            </td>
                            <td>
                                <v-textarea
                                    outlined
                                    rows="4"
                                    hide-details
                                    v-model="selectedAfter.content"
                                    placeholder="구성물"
                                ></v-textarea>
                            </td>
                        </tr>
                        <tr>
                            <td>토탈넘버</td>
                            <td>{{selectedItem.totalNumber}}</td>
                            <td style="width:100px;">
                                <v-text-field
                                    outlined dense hide-details type="number"
                                    v-model="selectedAfter.totalNumber"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>ptcgoCode</td>
                            <td>{{selectedItem.ptcgoCode}}</td>
                            <td style="width:100px;">
                                <v-text-field
                                    outlined dense hide-details
                                    v-model="selectedAfter.ptcgoCode"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>제품명 출시일</td>
                            <td>{{new Date(selectedItem.release_datetime).toLocaleDateString()}}</td>
                            <td>
                                <v-text-field
                                    outlined dense hide-details
                                    v-model="selectedAfter.release_datetime"
                                    type="date"
                                ></v-text-field>
                            </td>
                        </tr>
                    </table>
                </v-sheet>
                <v-sheet class="mt-2 pt-2 d-flex justify-center">
                    <v-btn
                        large dark color="blue"
                        class="px-6 mr-4"
                        @click="updateSubmit()"
                    >
                        <v-icon small left>mdi-database-edit-outline</v-icon>수정하기
                    </v-btn>
                    <v-btn
                        large dark color="grey"
                        class="px-6"
                        @click="dialog.update = false"
                    >
                        <v-icon small left>mdi-cancel</v-icon>취소하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>

        <!-- 추가하기 -->
        <v-dialog v-model="dialog.create" width="auto">
            <v-sheet class="pa-4">
                <v-sheet>
                    <table class="customTable">
                        <tr>
                            <td rowspan="2">이미지</td>
                            <!-- 이미지 미리보기 -->
                            <clipper-basic
                                :src="url"
                                ref="clipper"
                                class="my-clipper"
                                :ratio="3"
                                :init-width="100"
                                :init-height="100"
                            >
                                <div class="placeholder" slot="placeholder"></div>
                            </clipper-basic>
                        </tr>   
                        <tr>
                            <td class="py-2 px-3" style="background:white;">
                                <v-sheet
                                    @drop.prevent="addDropFile"
                                    @dragover.prevent
                                >
                                    <v-file-input
                                        placeholder="제품명 이미지를 선택해주세요"
                                        outlined
                                        dense
                                        show-size
                                        hide-details
                                        prepend-icon
                                        color="primary"
                                        v-model="uploadImage"
                                        @change="onFileChange()"
                                    >
                                        <template v-slot:prepend-inner>
                                            <v-icon class="mr-1" color="primary">mdi-image-filter-hdr</v-icon>
                                        </template>
                                    </v-file-input>
                                </v-sheet>
                            </td>
                        </tr>
                        <tr>
                            <td>시리즈</td>
                            <td>
                                <v-text-field
                                    outlined dense hide-details
                                    v-model="create.series"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>제품명</td>
                            <td>
                                <v-text-field
                                    outlined dense hide-details
                                    v-model="create.name"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>팩 가격</td>
                            <td>
                                <v-text-field
                                    outlined dense hide-details type="number"
                                    v-model="create.pack_price"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>1BOX 가격</td>
                            <td>
                                <v-text-field
                                    outlined dense hide-details type="number"
                                    v-model="create.box_price"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>구성물</td>
                            <td>
                                <v-textarea
                                    outlined
                                    rows="4"
                                    hide-details
                                    v-model="create.content"
                                    placeholder="구성물"
                                ></v-textarea>
                            </td>
                        </tr>
                        <tr>
                            <td>토탈넘버</td>
                            <td style="width:100px;">
                                <v-text-field
                                    outlined dense hide-details type="number"
                                    v-model="create.totalNumber"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>ptcgoCode</td>
                            <td style="width:100px;">
                                <v-text-field
                                    outlined dense hide-details
                                    v-model="create.ptcgoCode"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>제품명 출시일</td>
                            <td>
                                <v-text-field
                                    outlined dense hide-details
                                    v-model="create.release_datetime"
                                    type="date"
                                ></v-text-field>
                            </td>
                        </tr>
                    </table>
                </v-sheet>
                <v-sheet class="mt-2 pt-2 d-flex justify-center">
                    <v-btn
                        large dark color="blue"
                        class="px-6 mr-4"
                        @click="createSubmit()"
                    >
                        <v-icon small left>mdi-database-edit-outline</v-icon>추가하기
                    </v-btn>
                    <v-btn
                        large dark color="grey"
                        class="px-6"
                        @click="dialog.create = false"
                    >
                        <v-icon small left>mdi-cancel</v-icon>취소하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '', value: ''},
            {text: '이미지', value: 'image'},
            {text: '시리즈', value: 'series'},
            {text: '제품명', value: 'name'},
            {text: '팩 가격', value: 'pack_price'},
            {text: '1BOX 가격', value: 'box_price'},
            {text: '구성물', value: 'content'},
            {text: '토탈넘버', value: 'totalNumber'},
            {text: 'ptcgoCode', value: 'ptcgoCode'},
            {text: '수량', value: 'amount'},
            {text: '제품명 출시일', value: 'release_datetime'}
        ],
        
        list: [],

        dialog: {
            update: false,
            create: false
        },

        selected: false,
        selectedItem: {},
        selectedAfter: {},

        uploadImage: {},
        url: "",

        create: {
            series: "",
            name: "",
            pack_price: 0,
            box_price: 0,
            content: "",
            totalNumber: 0,
            ptcgoCode: null,
            release_datetime: ""
        },

        select_list: {
            search_type: [
                "제품명",
            ],

            set_nation: [
                {text: "한글 제품명", value: "kr"},
                {text: "영어 제품명", value: "en"},
                {text: "일본 제품명", value: "jp"}
            ]
        },

        selected_nation: "kr",

        search_type: "제품명",
        keyword: ""
    }),

    mounted(){
        this.search()
    },

    watch: {
        selected_nation(){
            this.list = []
            this.search()
        }
    },

    methods: {
        // 검색
        search(){
            this.$http.post('/api/admin/card/set/select/search', {
                params: {
                    keyword: this.keyword,
                    nation: this.selected_nation
                }
            }).then((res) => {
                console.log(res.data)
                console.log(res)
                this.list = res.data
            })

            // 값 초기화
            this.selected = false
            this.selectedItem = {}
            this.selectedAfter = {}
            this.create = {}
            this.uploadImage = {}

            // Dialog 초기화
            this.dialog.update = false
            this.dialog.create = false
        },

        // 선택한 항목 수정하기에 담기
        selectUpdate(item){
            this.selectedItem.id = item.id
            this.selectedItem.image = item.image
            this.selectedItem.series = item.series
            this.selectedItem.name = item.name
            this.selectedItem.pack_price = item.pack_price
            this.selectedItem.box_price = item.box_price
            this.selectedItem.content = item.content
            this.selectedItem.totalNumber = item.totalNumber
            this.selectedItem.ptcgoCode = item.ptcgoCode
            this.selectedItem.release_datetime = item.release_datetime

            this.selectedAfter.id = item.id
            this.selectedAfter.image = item.image
            this.selectedAfter.series = item.series
            this.selectedAfter.name = item.name
            this.selectedAfter.pack_price = item.pack_price
            this.selectedAfter.box_price = item.box_price
            this.selectedAfter.content = item.content
            this.selectedAfter.totalNumber = item.totalNumber
            this.selectedAfter.ptcgoCode = item.ptcgoCode
            this.selectedAfter.release_datetime = item.release_datetime
        },

        // 파일 선택시 이미지 표시
        onFileChange() {
            const file = this.uploadImage
            this.$emit("imageUpdated", this.uploadImage)
            this.url = URL.createObjectURL(file)
        },

        // 파일 드롭
        addDropFile(e) { 
            const file = e.dataTransfer.files[0]
            this.uploadImage = e.dataTransfer.files[0]
            this.$emit("imageUpdated", this.uploadImage)
            this.url = URL.createObjectURL(file)
        },

        // 수정하기 열기
        updateOpen(){
            if(!Object.keys(this.selectedItem).length){
                alert("선택한 제품명이 없습니다.")
            }else{
                this.dialog.update = true
            }
        },

        // 수정하기
        updateSubmit: _.debounce(function() {
            var timezoneOffset = new Date().getTimezoneOffset() * 60000
            var timezoneDate = new Date(new Date(this.selectedAfter.release_datetime) - timezoneOffset) 

            // 이미지 첨부 없을때
            if(!this.uploadImage.name){
                this.$http.post('/api/admin/card/set/update', {
                    params: {
                        image: this.selectedItem.image,
                        set_series: this.selectedAfter.series,
                        set_name_before: this.selectedItem.name,
                        set_name_after: this.selectedAfter.name,
                        pack_price: this.selectedAfter.pack_price,
                        box_price: this.selectedAfter.box_price,
                        content: this.selectedAfter.content,
                        totalNumber: this.selectedAfter.totalNumber,
                        ptcgoCode: this.selectedAfter.ptcgoCode,
                        release_datetime: timezoneDate.toISOString().slice(0, 10),
                        id: this.selectedAfter.id,
                        nation: this.selected_nation
                    }
                }).then((res) => {
                    alert("제품명이 수정되었습니다.")
                    this.search()
                })

            // 이미지 첨부 있을때
            }else{
                // Crop 이미지 파일로 변환
                const canvas = this.$refs.clipper.clip() //call component's clip method
                var imgDataUrl = canvas.toDataURL("image/webp", 0.5) //canvas->image
                var binaryData = atob(imgDataUrl.split(',')[1])
                var array = []
                for (var i = 0; i < binaryData.length; i++) {
                    array.push(binaryData.charCodeAt(i));
                }
                var file = new File([new Uint8Array(array)], "filename.png", {type: 'image/png'})
                var formData = new FormData()
                formData.append("image", file)

                // 이미지 업로드
                this.$http.post('/api/image/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    params: {
                        type: "set_"+this.selected_nation+"_image"
                    }
                }).then((res) => {
                    this.$http.post('/api/admin/card/set/update', {
                        params: {
                            image : res.data,
                            set_series: this.selectedAfter.series,
                            set_name_before: this.selectedItem.name,
                            set_name_after: this.selectedAfter.name,
                            pack_price: this.selectedAfter.pack_price,
                            box_price: this.selectedAfter.box_price,
                            content: this.selectedAfter.content,
                            totalNumber: this.selectedAfter.totalNumber,
                            ptcgoCode: this.selectedAfter.ptcgoCode,
                            release_datetime: timezoneDate.toISOString().slice(0, 10),
                            id: this.selectedAfter.id,
                            nation: this.selected_nation
                        }
                    }).then((res) => {
                        alert("제품명이 수정되었습니다.")
                        this.search()
                    })
                })
            }
        }, 500),

        // 삭제하기
        deleteItem(){
            if(!Object.keys(this.selectedItem).length){
                alert("선택한 제품명이 없습니다.")
            }else{
                if(confirm('정말 해당 제품명을 삭제하시겠습니까?')){
                    this.$http.post('/api/admin/card/set/delete', {
                        params: {
                            id: this.selectedItem.id,
                            nation: this.selected_nation
                        }
                    }).then((res) => {
                        if(!res.data.affectedRows){
                            alert("제품명 삭제 도중 오류가 발생하였습니다.")
                        }else{
                            alert("제품명이 삭제되었습니다.")
                            this.search()
                        }
                    })
                }
            }
        },

        // 추가하기
        createSubmit: _.debounce(function() {
            if(!this.uploadImage.name){
                alert("등록하실 이미지를 선택해주세요")
            }else{
                // Crop 이미지 파일로 변환
                const canvas = this.$refs.clipper.clip() //call component's clip method
                var imgDataUrl = canvas.toDataURL("image/webp", 0.5) //canvas->image
                var binaryData = atob(imgDataUrl.split(',')[1])
                var array = []
                for (var i = 0; i < binaryData.length; i++) {
                    array.push(binaryData.charCodeAt(i));
                }
                var file = new File([new Uint8Array(array)], "filename.png", {type: 'image/png'})
                var formData = new FormData()
                formData.append("image", file)
                
                // 이미지 업로드
                this.$http.post('/api/image/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    params: {
                        type: "set_"+this.selected_nation+"_image"
                    }
                }).then((res) => {
                    this.$http.post('/api/admin/card/set/insert', {
                        params: {
                            image : res.data,
                            series: this.create.series,
                            name: this.create.name,
                            pack_price: this.create.pack_price,
                            box_price: this.create.box_price,
                            content: this.create.content,
                            totalNumber: this.create.totalNumber,
                            ptcgoCode: this.create.ptcgoCode,
                            release_datetime: this.create.release_datetime,
                            nation: this.selected_nation
                        }
                    }).then((res) => {
                        alert("제품명이 추가되었습니다.")
                        this.search()
                    })
                })
            }
        }, 500),
    }
}
</script>
<style scoped>
/* 목록 */
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}

/* Table (수정하기, 추가하기) */
.customTable{
    border:1px solid #efefef;
    border-collapse: collapse;
}

.customTable tr td:first-child{
    text-align: center;
    font-weight: bold;
    background:#f2f2f2;
    height:50px;
    width:140px;
    padding:10px;
    border:1px solid #dedede;
}

.customTable tr td{
    height:50px;
    width:400px;
    padding:10px;
    border:1px solid #dedede;
}
</style>
<style scoped>
.my-clipper {
    width:100%;
}

.placeholder {
    width:300px;
    height:100px;
}
</style>