<template>
    <v-sheet class="transparent">
        <!-- 제목 -->
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">처분 로그</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex" width="660">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="blue"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-data-table
                id="list"
                :headers="headers"
                :items="list"
                :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                no-data-text="현재 데이터가 없습니다."
            >
                <template v-slot:item="{ item }">
                    <tr>
                        <td style="width:160px; cursor:pointer;"><font class="d-block text-truncate" style="width:160px;">{{item.user_id}}</font></td>
                        <td style="width:160px; cursor:pointer;"><font class="d-block text-truncate" style="width:160px;">{{item.nickname}}</font></td>
                        <td style="width:140px">
                            <font class="d-block text-truncate" style="width:140px; cursor:pointer;">
                                <span v-if="item.report_type=='board_post'">게시글</span>
                                <span v-if="item.report_type=='board_comment'">댓글</span>
                                <span v-if="item.report_type=='trade_post'">거래글</span>
                                <span v-if="item.report_type=='trade_comment'">거래글 댓글</span>
                                <span v-else>{{item.report_type}}</span>
                            </font>
                        </td>
                        <td style="width:140px; cursor:pointer;"><font class="d-block text-truncate" style="width:140px;">{{item.report_category}}</font></td>
                        <td style="width:100%; cursor:pointer;"><font class="d-block text-truncate" style="width:280px;">{{item.report_content}}</font></td>
                        <td style="width:260px; cursor:pointer;"><font class="d-block text-truncate" style="width:260px;">{{item.memo}}</font></td>
                        <td style="width:140px; cursor:pointer;"><font class="d-block text-truncate" style="width:140px;">{{item.ban_amount}}</font></td>
                        <td style="width:180px; cursor:pointer;"><font class="d-block text-truncate" style="width:180px;">{{new Date(item.created).toLocaleString()}}</font></td>
                    </tr>
                </template>
            </v-data-table>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '회원 아이디', value: 'user_id'},
            {text: '닉네임', value: 'nickname'},
            {text: '유형', value: 'report_type'},
            {text: '항목', value: 'report_category'},
            {text: '신고 내용', value: 'report_content'},
            {text: '비고', value: 'memo'},
            {text: '밴 처분 일수', value: 'ban_amount'},
            {text: '처분 일자', value: 'created'}
        ],

        list: [],

        select_list: {
            search_type: [
                "회원 아이디", 
                "닉네임", 
                "항목",
                "신고 내용",
                "비고",
                "밴 처분 일수",
                "처분 일자"
            ]
        },

        search_type: "회원 아이디",
        keyword: ""
    }),

    mounted(){
        this.load()
    },

    methods: {
        // 목록
        load(){
            this.$http.post('/api/admin/user/ban_log/list')
            .then((res) => {
                this.list = res.data
            })
        },

        // 검색
        search(){
            this.$http.post('/api/admin/user/ban_log/search', {
                params: {
                    keyword: this.keyword,
                    type: this.search_type
                }
            }).then((res) => {
                this.list = res.data
            })
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    width:85px;
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>