<template>
    <v-sheet class="transparent">
        <!-- 제목 -->
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">카드종류 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex" width="660">
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            hide-details
                            dense
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>
                    <v-text-field
                        v-model="keyword"
                        outlined
                        hide-details
                        dense
                        placeholder="검색어를 입력해주세요"
                        @keyup.enter="search()"
                    ></v-text-field>
                    <v-btn
                        height="40"
                        dark color="blue"
                        class="px-6 ml-3"
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-radio-group v-model="selected">
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="list"
                    :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                    no-data-text="현재 데이터가 없습니다."
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:45px;">
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <td style="width:300px"><font class="d-block text-truncate" style="width:300px">{{item.category}}</font></td>
                            <td style="width:420px"><font class="d-block text-truncate" style="width:420px;">{{item.en}}</font></td>
                            <td style="width:100%"><font class="d-block text-truncate" style="width:300px;">{{item.kr}}</font></td>
                            <td style="width:140px">
                                <v-select
                                    style="width:140px;"
                                    hide-details
                                    outlined
                                    dense
                                    :items="select_list.status"
                                    v-model="item.status"
                                    @change="update_status(item)"
                                ></v-select>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>
            
            <!-- 선택항목 & 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    disabled
                    large
                    class="px-6 mr-4 text-lowercase"
                >
                    선택항목: {{selectedItem.en}} ({{selectedItem.kr}})
                </v-btn>
                <v-btn
                    large dark color="blue"
                    class="px-6 ml-3"
                    @click="update_open()"
                >
                    <v-icon small left style="margin-top:2px;" >mdi-database-plus-outline</v-icon>
                    수정하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- 카드종류 수정하기 Dialog -->
        <v-dialog v-model="dialog.update" width="auto">
            <v-sheet width="800">
                <p class="pt-3 mb-3 font-weight-bold text-center text-subtitle-1 grey--text text--darken-2">
                    카드종류 수정하기
                </p>
                <v-divider></v-divider>
                <v-sheet class="pa-3">
                    <table class="disposeTable">
                        <tr>
                            <td>대분류</td>
                            <td>{{selectedItem.category}}</td>
                        </tr>
                        <tr>
                            <td>API 값</td>
                            <td>{{selectedItem.en}}</td>
                        </tr>
                        <tr>
                            <td>표기값</td>
                            <td>
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    v-model="selectedAfter.kr"
                                ></v-text-field>
                            </td>
                        </tr>
                    </table>
                    <v-sheet class="mt-2 pa-2 d-flex justify-center">
                        <v-btn large dark color="blue" class="px-6 mr-4" @click="update_submit()">
                            <v-icon small left>mdi-database-edit-outline</v-icon>
                            수정하기
                        </v-btn>
                        <v-btn large dark color="grey" class="px-6" @click="dialog.update = false">
                            <v-icon small left>mdi-cancel</v-icon>
                            취소하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
import pokemon from 'pokemontcgsdk'
pokemon.configure({apiKey: 'bbcd32a1-e0f7-41ca-8712-77bace202b14'})

export default {
    data: () => ({
        headers: [
            {text: '', value: ''},
            {text: '대분류', value: 'category'},
            {text: 'API 값', value: 'en'},
            {text: '표기값', value: 'kr'},
            {text: '상태', value: 'status'}
        ],

        list: [],

        selected: false,
        selectedItem: {},
        selectedAfter: {},

        dialog: {
            update: false
        },

        newtype: [],

        select_list: {
            search_type: [
                "API 값", 
                "표기값", 
            ],
            status: ["보이기", "숨기기"]
        },

        search_type: "API 값",
        keyword: ""
    }),

    async mounted(){
        // 목록
        await this.load()

        // API 신규 카드종류 확인
        this.check_new_supertype()
    },

    methods: {
        // 목록
        load(){
            this.$http.post('/api/admin/card/supertype/list')
            .then((res) => {
                this.list = res.data
            })
        },

        // API 신규 카드종류 확인
        check_new_supertype(){
            pokemon.subtype.all().then(res => {
                this.newtype = (res.filter(e => !this.list.map(e => e.en).includes(e)))

                // 신규 카드종류 확인 후 추가하기
                if(this.newtype.length){
                    if(confirm("API에 신규로 추가된 카드종류가 있습니다.\nDB에 추가하시겠습니까?\n\n"+this.newtype)){
                        for(let i=0; i<this.newtype.length; i++){
                            this.$http.post('/api/admin/card/supertype/new', {
                                params: {
                                    category: "Pokémon",
                                    en: this.newtype[i],
                                    kr: this.newtype[i]
                                }
                            })
                        }
                    }
                }
                this.load()
            })
        },

        // 선택한 값 저장
        selectUpdate(item){
            this.selectedItem = item
            this.selectedAfter.kr = item.kr
        },

        // 수정하기 열기
        update_open(){
            if(Object.keys(this.selectedItem).length==0){
                alert("선택한 카드종류가 없습니다.")
            }else{
                this.dialog.update = true
            }
        },

        // 수정하기 제출
        update_submit(){
            if(this.selectedAfter.kr==""){
                alert("표기값을 입력해주세요.")
            }else{
                this.$http.post('/api/admin/card/supertype/update', {
                    params: {
                        kr: this.selectedAfter.kr,
                        id: this.selectedItem.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        alert("카드종류가 수정되었습니다.")
                        this.dialog.update = false
                        this.load()
                    }
                })
            }
        },

        // 상태 변경
        update_status(item){
            this.$http.post('/api/admin/card/supertype/update/status', {
                params: {
                    status: item.status,
                    id: item.id
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    alert("상태가 수정되었습니다.")
                }
            })
        },

        // 검색
        search(){
            this.$http.post('/api/admin/card/supertype/search', {
                params: {
                    keyword: this.keyword,
                    type: this.search_type
                }
            }).then((res) => {
                this.list = res.data
            })
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    width:85px;
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}

#selected_user{
    width:100%;
    border:1px solid #ccc;
    border-collapse: collapse;
}

#selected_user tr th{
    height:20px;
    padding:12px 20px;
    border:1px solid #ccc;
    background: #f7f7f7;
}

#selected_user tr td{
    height:20px;
    padding:12px 20px;
    border:1px solid #ccc;
}
</style>
<style scoped>
.disposeTable{
    width: 100%;
    border: 1px solid #e0e0e0;
    border-collapse: collapse;
}

.disposeTable tr td{
    padding: 12px;
    border: 1px solid #e0e0e0;
}

.disposeTable tr td:first-child{
    width:160px;
    text-align:center;
    background:#f8f8f8;
    font-weight:bold;
}
</style>