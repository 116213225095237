<template>
    <v-sheet color="transparent">
        <!-- 헤더 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <!-- 페이지 제목 -->
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">이미지매칭 관리</font>
            <v-spacer></v-spacer>
            
            <!-- 국가 선택 -->
            <v-sheet width="210">
                <v-select
                    class="mr-4"
                    outlined dense hide-details
                    v-model="selected_card_nation"
                    :items="select_list.card_nation"
                    @change="card_nation_changed()"
                ></v-select>
            </v-sheet>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex" width="660">
                    <v-sheet width="160" class="mr-3">
                        <v-select
                            outlined
                            hide-details
                            dense
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>
                    <v-text-field
                        v-model="keyword"
                        outlined
                        hide-details
                        dense
                        placeholder="검색어를 입력해주세요"
                        @keyup.enter="search()"
                    ></v-text-field>
                    <v-btn
                        height="40"
                        dark color="blue"
                        class="px-6 ml-3"
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-data-table
                id="list"
                :headers="headers"
                :items="list"
                :footer-props="{'items-per-page-options': [5, 20, 100, -1]}"
            >
                <template v-slot:item="{ item }">
                    <tr @click="linkToDetail(item)" style="cursor:pointer;">
                        <td style="width:80px" class="pa-2">
                            <v-img 
                                v-if="selected_card_nation=='국내판'"
                                width="80"
                                class="mx-auto"
                                :src="'/upload/card_kr_image/'+encodeURI(item.image)"
                            ></v-img>
                            <v-img 
                                v-if="selected_card_nation=='북미판'"
                                width="80"
                                class="mx-auto"
                                :src="'/upload/card_en_image/'+encodeURI(item.image)"
                            ></v-img>
                            <v-img 
                                v-if="selected_card_nation=='일본판'"
                                width="80"
                                class="mx-auto"
                                :src="'/upload/card_jp_image/'+encodeURI(item.image)"
                            ></v-img>
                        </td>
                        <td style="width:200px"><font class="d-block text-truncate" style="min-width:200px;">{{item.id? item.id.replaceAll('"','') : ''}}</font></td>
                        <td style="width:200px"><font class="d-block text-truncate" style="min-width:200px;">{{item.name? item.name.replaceAll('"','') : ''}}</font></td>
                        <td style="width:200px"><font class="d-block text-truncate" style="min-width:200px;">{{item.rarity? item.rarity.replaceAll('"','') : ''}}</font></td>
                        <td style="width:120px"><font class="d-block text-truncate" style="min-width:120px;">{{item.number && selected_card_nation=='북미판'? item.number.replaceAll('"','') : item.number}}</font></td>
                        <td style="width:120px"><font class="d-block text-truncate" style="min-width:120px;">{{item.set_printedTotal? item.set_printedTotal.replaceAll('"','') : ''}}</font></td>
                        <td style="width:100%"><font class="d-block text-truncate" style="width:400px;">{{item.set_name? item.set_name.replaceAll('"','') : ''}}</font></td>
                    </tr>
                </template>
            </v-data-table>

            <!-- 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    large dark color="orange"
                    class="px-6"
                    @click="dialog.match = true"
                >
                    <v-icon small left style="margin-top:2px;" >mdi-database-edit-outline</v-icon>
                    이미지 매칭하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- 이미지 매칭하기 Dialog -->
        <v-dialog v-model="dialog.match" width="auto">
            <v-sheet width="720">
                <p class="pt-3 mb-3 font-weight-bold text-center text-subtitle-1 grey--text text--darken-2">
                    이미지 매칭하기
                </p>
                <v-divider></v-divider>
                <v-sheet class="pa-3">
                    <table class="disposeTable my-8">
                        <tr>
                            <td>제품명</td>
                            <td>
                                <v-autocomplete
                                    dense outlined hide-details
                                    placeholder="제품명 이름"
                                    :items="set_list"
                                    v-model="set_name"
                                    @change="introduce_set_status()"
                                    @keyup.enter="submit()"
                                ></v-autocomplete>
                            </td>
                        </tr>
                        <tr>
                            <td>블록넘버 없음</td>
                            <td class="text-center">
                                <p 
                                    v-if="!nonNumberSettingCard.length"
                                    class="ma-0"
                                >
                                    블록넘버가 설정되지 않은 카드가 없습니다
                                </p>
                                <table width="100%">
                                    <tr v-for="item in nonNumberSettingCard" :key="item.id">
                                        <td width="50%">{{item.number}}</td>
                                        <td width="50%">{{item.name}}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>이미지 이미 설정됨</td>
                            <td class="text-center">
                                <p 
                                    v-if="!imageAlreadySetCard.length"
                                    class="ma-0"
                                >
                                    이미지가 이미 설정된 카드가 없습니다
                                </p>
                                <table width="100%">
                                    <tr v-for="item in imageAlreadySetCard" :key="item.id">
                                        <td width="50%">{{item.number}}</td>
                                        <td width="50%">{{item.name}}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <v-sheet class="mt-2 pa-2 d-flex justify-center">
                        <v-btn large dark color="blue" class="px-6 mr-4" @click="match_submit()">
                            <v-icon small left>mdi-database-remove-outline</v-icon>이미지 매칭하기
                        </v-btn>
                        <v-btn large dark color="grey" class="px-6" @click="dialog.match=false">
                            <v-icon small left>mdi-cancel</v-icon>취소하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '이미지', value:'image'},
            {text: 'ID', value:'id'},
            {text: '이름', value:'name'},
            {text: '희귀도', value:'rarity'},
            {text: '블록넘버', value:'number'},
            {text: '토탈넘버', value:'set_printedTotal'},
            {text: '제품명', value:'set_name'}
        ],
        
        list: [],

        select_list: {
            search_type: [
                "ID", 
                "이름", 
                "희귀도",
                "블록넘버",
                "토탈넘버",
                "제품명"
            ],
            card_nation: [
                "국내판", 
                "북미판",
                "일본판"
            ]    
        },

        sets_selectList_en: [],
        sets_selectList_kr: [],
        sets_selectList_jp: [],

        selected_card_nation: "국내판",
        search_type: "ID",
        keyword: "",

        dialog: {
            match: false
        },

        set_name: "",

        explain: "",

        nonNumberSettingCard: [],
        imageAlreadySetCard: []
    }),

    mounted(){
        // 기본 목록
        this.load()

        // 제품명 list 가져오기
        this.loadSetList()
    },

    computed:{
        // 제품명
        set_list(){
            switch(this.selected_card_nation){
                case '국내판' : return this.sets_selectList_kr
                case '북미판' : return this.sets_selectList_en
                case '일본판' : return this.sets_selectList_jp
            }
        }
    },

    methods: {
        // 기본 목록
        load(){
            if(this.selected_card_nation=='국내판')
            {
                this.$http.post('/api/admin/card/image_match/list/kr')
                .then((res) => {
                    this.list = res.data
                })
            }
            else if(this.selected_card_nation=='북미판')
            {
                this.$http.post('/api/admin/card/image_match/list/en')
                .then((res) => {
                    this.list = res.data
                })
            }
            else if(this.selected_card_nation=='일본판')
            {
                this.$http.post('/api/admin/card/image_match/list/jp')
                .then((res) => {
                    this.list = res.data
                })
            }
        },

        // 제품명 list 가져오기
        loadSetList(){
            // 한글
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.sets_selectList_kr = res.data.map(a => a.name)
            })

            // 일어
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "jp"
                }
            }).then(res => { 
                this.sets_selectList_jp = res.data.map(a => a.name)
            })

            // 영어
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "en"
                }
            }).then(res => { 
                this.sets_selectList_en = res.data.map(a => a.name)
            })
        },

        // 국가 선택 변경
        card_nation_changed(){
            this.load()
        },

        // 링크
        linkToDetail(item){
            window.open('/card/detail?id='+item.id, '_blank')
        },

        // 검색
        search(){
            if(this.selected_card_nation=='국내판'){
                this.$http.post('/api/admin/card/image_match/search/kr', {
                    params: {
                        keyword: this.keyword,
                        type: this.search_type
                    }
                }).then((res) => {
                    this.list = res.data
                })
            }else if(this.selected_card_nation=='북미판'){
                this.$http.post('/api/admin/card/image_match/search/en', {
                    params: {
                        keyword: this.keyword,
                        type: this.search_type
                    }
                }).then((res) => {
                    this.list = res.data
                })
            }else if(this.selected_card_nation=='일본판'){
                this.$http.post('/api/admin/card/image_match/search/jp', {
                    params: {
                        keyword: this.keyword,
                        type: this.search_type
                    }
                }).then((res) => {
                    this.list = res.data
                })
            }
        },

        // 제품명 선택시 표시문구
        introduce_set_status(){
            if(this.selected_card_nation=='국내판')
            {
                this.$http.post('/api/admin/card/image_match/empty/check/kr', {
                    params: {
                        set_name: this.set_name
                    }
                }).then((res) => {
                    if(res.data.length){
                        this.nonNumberSettingCard = res.data
                    }
                })
                this.$http.post('/api/admin/card/image_match/image/setted/kr', {
                    params: {
                        set_name: this.set_name
                    }
                }).then((res) => {
                    if(res.data.length){
                        this.imageAlreadySetCard = res.data
                    }
                })
            }
            else if(this.selected_card_nation=='북미판')
            {
                this.nonNumberSettingCard = []
                this.imageAlreadySetCard = []
            }
            else if(this.selected_card_nation=='일본판')
            {
                this.$http.post('/api/admin/card/image_match/empty/check/jp', {
                    params: {
                        set_name: this.set_name
                    }
                }).then((res) => {
                    if(res.data.length){
                        this.nonNumberSettingCard = res.data
                    }
                })
                this.$http.post('/api/admin/card/image_match/image/setted/jp', {
                    params: {
                        set_name: this.set_name
                    }
                }).then((res) => {
                    if(res.data.length){
                        this.imageAlreadySetCard = res.data
                    }
                })
            }
        },

        match_submit(){
            if(this.set_name==""){
                alert("이미지 매칭하실 제품명을 선택해주세요")
            }else{
                if(this.selected_card_nation=='국내판'){
                    this.$http.post('/api/admin/card/image_match/match/kr', {
                        params: {
                            set_name: this.set_name
                        }
                    }).then((res) => {
                        console.log(res)
                        if(res.data.affectedRows){
                            alert("이미지가 정상적으로 매칭되었습니다.")
                            this.dialog.match = false
                            this.load()
                        }
                    })
                }else if(this.selected_card_nation=='북미판'){
                    this.$http.post('/api/admin/card/image_match/match/en', {
                        params: {
                            set_name: this.set_name
                        }
                    }).then((res) => {
                        console.log(res)
                        if(res.data.affectedRows){
                            alert("이미지가 정상적으로 매칭되었습니다.")
                            this.dialog.match = false
                            this.load()
                        }
                    })
                }else if(this.selected_card_nation=='일본판'){
                    this.$http.post('/api/admin/card/image_match/match/jp', {
                        params: {
                            set_name: this.set_name
                        }
                    }).then((res) => {
                        console.log(res)
                        if(res.data.affectedRows){
                            alert("이미지가 정상적으로 매칭되었습니다.")
                            this.dialog.match = false
                            this.load()
                        }
                    })
                }
            }
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>
<style scoped>
.disposeTable{
    width: 100%;
    border: 1px solid #e0e0e0;
    border-collapse: collapse;
}

.disposeTable tr td{
    padding: 12px;
    border: 1px solid #e0e0e0;
}

.disposeTable tr td:first-child{
    width:180px;
    text-align:center;
    background:#f8f8f8;
    font-weight:bold;
}
</style>