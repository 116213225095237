<template>
    <div>
        <!-- 제목 -->
        <v-sheet
            class="font-weight-bold text-h6 grey--text text--darken-2 ma-2 pa-4"
            outlined
        >
            덱 자동분류 관리
        </v-sheet>

        <!-- 본문 -->
        <v-sheet
            class="pa-2 ma-2"
            outlined
        >
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex" width="660">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="blue"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-radio-group
                v-model="selected"
                dense
            >
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="list"
                    :footer-props="{
                        'items-per-page-options': [10, 20, 100, -1],
                        'items-per-page-text':'페이지당 덱 자동분류 수'
                    }"
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:45px;">
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <td><font class="d-block text-truncate" style="min-width:200px;">{{item.identifier}}</font></td>
                            <td><font class="d-block text-truncate" style="min-width:200px;">{{item.name}}</font></td>
                            <td><font class="d-block text-truncate" style="min-width:200px;">{{item.icons}}</font></td>
                            <td><font class="d-block text-truncate" style="min-width:100px;">{{item.generation}}</font></td>
                            <td><font class="d-block text-truncate" style="min-width:100px;">{{item.priority}}</font></td>
                            <td style="width:140px">
                                <v-sheet width="140">
                                    <v-select
                                        outlined
                                        dense
                                        hide-details
                                        v-model="item.status"
                                        :items="select_list.status"
                                        @change="status_update(item)"
                                    ></v-select>
                                </v-sheet>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 선택항목 & 버튼 -->
            <v-sheet
                class="pa-2 d-flex justify-end"
                outlined
            >
                <v-btn
                    disabled
                    large
                    class="px-6 mr-4"
                >
                    선택항목: {{selectedItem.identifier}}
                </v-btn>
                <v-btn
                    large
                    dark
                    depressed
                    color="deep-orange"
                    class="px-6"
                    @click="updateByAPI()"
                >
                    <v-icon small left>mdi-update</v-icon>
                    전체 업데이트
                </v-btn>
                <v-btn
                    large
                    dark
                    depressed
                    color="deep-orange"
                    class="px-6 ml-3"
                    @click="translate()"
                >
                    <v-icon small left>mdi-alphabet-greek</v-icon>
                    전체 번역하기
                </v-btn>
                <v-btn
                    large
                    dark
                    depressed
                    color="orange"
                    class="px-6 ml-3"
                    @click="updateOpen()"
                >
                    <v-icon small left>mdi-database-edit-outline</v-icon>
                    수정하기
                </v-btn>
                <v-btn
                    large
                    dark
                    depressed
                    color="blue"
                    class="px-6 ml-3"
                    @click="dialog.create = true"
                >
                    <v-icon small left>mdi-database-plus-outline</v-icon>
                    추가하기
                </v-btn>
                <v-btn
                    large
                    dark
                    depressed
                    color="blue"
                    class="px-6 ml-3"
                    @click="deleteItem()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    삭제하기
                </v-btn>
            </v-sheet>
        </v-sheet>

        <!-- 덱 자동분류 수정하기 (dialog) -->
        <v-dialog
            v-model="dialog.update"
            width="880"
            content-class="rounded-xl dialogCustom"
        >
            <v-sheet class="px-16 py-4 rounded-15" color="#F8F7FB" >
                <!-- 제목 -->
                <p class="mb-5 font-weight-bold text-center" style="font-size:24px;">
                    덱 자동분류 수정하기
                </p>

                <div class="d-flex">
                    <p class="texts-subtitle-1 font-weight-bold mb-2">기본 정보</p>
                </div>

                <!-- identifier -->
                <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                    <p class="title">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span>identifier</span>
                    </p>
                    <v-text-field
                        placeholder="identifier"
                        dense
                        hide-details
                        solo
                        flat
                        v-model="update.identifier"
                    ></v-text-field>
                </v-sheet>

                <!-- name -->
                <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                    <p class="title">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span>name</span>
                    </p>
                    <v-text-field
                        placeholder="name"
                        dense
                        hide-details
                        solo
                        flat
                        v-model="update.name"
                    ></v-text-field>
                </v-sheet>

                <!-- icons -->
                <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                    <p class="title">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span>icons</span>
                    </p>
                    <v-text-field
                        placeholder="icons"
                        dense
                        hide-details
                        solo
                        flat
                        v-model="update.icons"
                    ></v-text-field>
                </v-sheet>

                <!-- generation -->
                <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                    <p class="title">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span>generation</span>
                    </p>
                    <v-text-field
                        placeholder="generation"
                        dense
                        hide-details
                        solo
                        flat
                        v-model="update.generation"
                    ></v-text-field>
                </v-sheet>

                <!-- priority -->
                <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                    <p class="title">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span>priority</span>
                    </p>
                    <v-text-field
                        placeholder="priority"
                        dense
                        hide-details
                        solo
                        flat
                        v-model="update.priority"
                    ></v-text-field>
                </v-sheet>

                <p class="text-subtitle-1 font-weight-bold mt-6 mb-2">메인 포켓몬</p>
                <div
                    v-for="(item, index) in update.cards"
                    :key="'cards_update_'+index"
                    class="mt-2 d-flex mb-2"
                >
                    <!-- 카드 ID -->
                    <v-sheet class="pa-1 mr-2 rounded-10" style="width:64%;">
                        <v-text-field
                            hide-details
                            dense
                            placeholder="count"
                            solo
                            flat
                            v-model="item.count"
                        ></v-text-field>
                    </v-sheet>

                    <!-- 카드 ID -->
                    <v-sheet class="pa-1 mr-2 rounded-10" style="width:64%;">
                        <v-text-field
                            hide-details
                            dense
                            placeholder="name"
                            solo
                            flat
                            v-model="item.name"
                        ></v-text-field>
                    </v-sheet>

                    <!-- 카드 ID -->
                    <v-sheet class="pa-1 mr-2 rounded-10" style="width:64%;">
                        <v-text-field
                            hide-details
                            dense
                            placeholder="set_code"
                            solo
                            flat
                            v-model="item.set_code"
                        ></v-text-field>
                    </v-sheet>

                    <!-- 카드 ID -->
                    <v-sheet class="pa-1 mr-2 rounded-10" style="width:64%;">
                        <v-text-field
                            hide-details
                            dense
                            placeholder="number"
                            solo
                            flat
                            v-model="item.number"
                        ></v-text-field>
                    </v-sheet>

                    <!-- 카드 추가 버튼 -->
                    <v-btn
                        v-if="!index"
                        height="48"
                        class="rounded-10"
                        block
                        color="primary"
                        dark
                        depressed
                        @click="add_card_update()"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>

                    <!-- 카드 삭제 버튼 -->
                    <v-btn
                        v-if="index"
                        height="48"
                        class="rounded-10"
                        block
                        color="primary"
                        dark
                        depressed
                        @click="subtract_card_update(index)"
                    >
                        <v-icon>mdi-minus</v-icon>
                    </v-btn>
                </div>

                <div class="d-flex mt-6 mb-2">
                    <p class="text-subtitle-1 font-weight-bold mb-2">Variants</p>

                    <v-spacer></v-spacer>

                    <v-btn
                        height="32"
                        class="rounded-10 mr-1"
                        width="32"
                        color="primary"
                        dark
                        depressed
                        @click="add_variant()"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                        height="32"
                        class="rounded-10"
                        width="32"
                        color="primary"
                        dark
                        depressed
                        @click="subtract_variant()"
                    >
                        <v-icon>mdi-minus</v-icon>
                    </v-btn>
                </div>
                <v-sheet
                    v-for="(variant, variant_index) in update.variants" :key="'variant_card_update_'+variant_index"
                    class="pa-4 rounded-15 mb-4"
                    color="white"
                >
                    <!-- identifier -->
                    <v-sheet class="d-flex align-center py-2 rounded-10">
                        <p class="title pl-2 mr-4 mb-1">
                            <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                            <span>identifier</span>
                        </p>
                        <v-text-field
                            height="42"
                            dense outlined hide-details
                            class="v15 rounded-10"
                            style="background:white;"
                            placeholder="identifier"
                            v-model="variant.identifier"
                        ></v-text-field>
                    </v-sheet>

                    <!-- name -->
                    <v-sheet class="d-flex align-center py-2 rounded-10">
                        <p class="title pl-2 mr-4 mb-1">
                            <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                            <span>name</span>
                        </p>
                        <v-text-field
                            height="42"
                            dense outlined hide-details
                            class="v15 rounded-10"
                            style="background:white;"
                            placeholder="name"
                            v-model="variant.name"
                        ></v-text-field>
                    </v-sheet>

                    <!-- icons -->
                    <v-sheet class="d-flex align-center py-2 rounded-10">
                        <p class="title pl-2 mr-4 mb-1">
                            <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                            <span>icons</span>
                        </p>
                        <v-text-field
                            height="42"
                            dense outlined hide-details
                            class="v15 rounded-10"
                            style="background:white;"
                            placeholder="icons"
                            v-model="variant.icons"
                        ></v-text-field>
                    </v-sheet>

                    <!-- generation -->
                    <v-sheet class="d-flex align-center py-2 rounded-10">
                        <p class="title pl-2 mr-4 mb-1">
                            <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                            <span>generation</span>
                        </p>
                        <v-text-field
                            height="42"
                            dense outlined hide-details
                            class="v15 rounded-10"
                            style="background:white;"
                            placeholder="generation"
                            v-model="variant.generation"
                        ></v-text-field>
                    </v-sheet>

                    <!-- priority -->
                    <v-sheet class="d-flex align-center py-2 rounded-10 mb-2">
                        <p class="title pl-2 mr-4 mb-1">
                            <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                            <span>priority</span>
                        </p>
                        <v-text-field
                            height="42"
                            dense outlined hide-details
                            class="v15 rounded-10"
                            style="background:white;"
                            placeholder="priority"
                            v-model="variant.priority"
                        ></v-text-field>
                    </v-sheet>

                    <v-sheet
                        v-for="(item, index) in variant.cards"
                        :key="'variant_card_'+index+variant_index"
                        class="d-flex mb-2"
                        color="transparent"
                    >
                        <!-- 카드 ID -->
                        <v-sheet outlined class="pa-1 mr-2 rounded-10" style="width:64%;">
                            <v-text-field
                                hide-details
                                dense
                                placeholder="count"
                                solo
                                flat
                                v-model="item.count"
                            ></v-text-field>
                        </v-sheet>

                        <!-- 카드 ID -->
                        <v-sheet outlined class="pa-1 mr-2 rounded-10" style="width:64%;">
                            <v-text-field
                                hide-details
                                dense
                                placeholder="name"
                                solo
                                flat
                                v-model="item.name"
                            ></v-text-field>
                        </v-sheet>

                        <!-- 카드 ID -->
                        <v-sheet outlined class="pa-1 mr-2 rounded-10" style="width:64%;">
                            <v-text-field
                                hide-details
                                dense
                                placeholder="set_code"
                                solo
                                flat
                                v-model="item.set_code"
                            ></v-text-field>
                        </v-sheet>

                        <!-- 카드 ID -->
                        <v-sheet outlined class="pa-1 mr-2 rounded-10" style="width:64%;">
                            <v-text-field
                                hide-details
                                dense
                                placeholder="number"
                                solo
                                flat
                                v-model="item.number"
                            ></v-text-field>
                        </v-sheet>


                        <!-- 카드 추가/삭제 버튼 -->
                        <div>
                            <v-btn
                                v-if="!index"
                                height="48"
                                class="rounded-10"
                                block
                                color="primary"
                                dark
                                depressed
                                @click="add_variant_card(variant.cards)"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-btn
                                v-if="index"
                                height="48"
                                class="rounded-10"
                                block
                                color="primary"
                                dark
                                depressed
                                @click="subtract_variant_card(variant.cards, index)"
                            >
                                <v-icon>mdi-minus</v-icon>
                            </v-btn>
                        </div>
                    </v-sheet>
                </v-sheet>
                
                <div class="mt-2 pa-2 d-flex justify-center">
                    <v-btn
                        width="120"
                        height="44"
                        depressed
                        large
                        dark
                        color="blue"
                        class="rounded-10 px-6 mr-2"
                        @click="updateSubmit()"
                    >
                        수정하기
                    </v-btn>
                    <v-btn
                        width="120"
                        height="44"
                        depressed
                        large
                        dark
                        color="grey"
                        class="rounded-10 px-6"
                        @click="dialog.update = false"
                    >
                        취소하기
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- 덱 자동분류 추가하기 (dialog) -->
        <v-dialog
            v-model="dialog.create"
            width="880"
            content-class="rounded-xl dialogCustom"
        >
            <v-sheet class="px-16 py-4 rounded-15" color="#F8F7FB" >
                <!-- 제목 -->
                <p class="mb-5 font-weight-bold text-center" style="font-size:24px;">
                    덱 자동분류 추가하기
                </p>

                <div class="d-flex">
                    <p class="texts-subtitle-1 font-weight-bold mb-2">기본 정보</p>
                </div>

                <!-- identifier -->
                <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                    <p class="title">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span>identifier</span>
                    </p>
                    <v-text-field
                        placeholder="identifier"
                        dense
                        hide-details
                        solo
                        flat
                        v-model="create.identifier"
                    ></v-text-field>
                </v-sheet>

                <!-- name -->
                <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                    <p class="title">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span>name</span>
                    </p>
                    <v-text-field
                        placeholder="name"
                        dense
                        hide-details
                        solo
                        flat
                        v-model="create.name"
                    ></v-text-field>
                </v-sheet>

                <!-- icons -->
                <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                    <p class="title">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span>icons</span>
                    </p>
                    <v-text-field
                        placeholder="icons"
                        dense
                        hide-details
                        solo
                        flat
                        v-model="create.icons"
                    ></v-text-field>
                </v-sheet>

                <!-- generation -->
                <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                    <p class="title">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span>generation</span>
                    </p>
                    <v-text-field
                        placeholder="generation"
                        dense
                        hide-details
                        solo
                        flat
                        v-model="create.generation"
                    ></v-text-field>
                </v-sheet>

                <!-- priority -->
                <v-sheet class="d-flex align-center py-2 px-6 mb-3 rounded-10">
                    <p class="title">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span>priority</span>
                    </p>
                    <v-text-field
                        placeholder="priority"
                        dense
                        hide-details
                        solo
                        flat
                        v-model="create.priority"
                    ></v-text-field>
                </v-sheet>

                <p class="text-subtitle-1 font-weight-bold mt-6 mb-2">메인 포켓몬</p>
                <div
                    v-for="(item, index) in create.cards"
                    :key="'cards'+index"
                    class="mt-2 d-flex mb-2"
                >
                    <!-- 카드 ID -->
                    <v-sheet class="pa-1 mr-2 rounded-10" style="width:64%;">
                        <v-text-field
                            hide-details
                            dense
                            placeholder="count"
                            solo
                            flat
                            v-model="item.count"
                        ></v-text-field>
                    </v-sheet>

                    <!-- 카드 ID -->
                    <v-sheet class="pa-1 mr-2 rounded-10" style="width:64%;">
                        <v-text-field
                            hide-details
                            dense
                            placeholder="name"
                            solo
                            flat
                            v-model="item.name"
                        ></v-text-field>
                    </v-sheet>

                    <!-- 카드 ID -->
                    <v-sheet class="pa-1 mr-2 rounded-10" style="width:64%;">
                        <v-text-field
                            hide-details
                            dense
                            placeholder="set_code"
                            solo
                            flat
                            v-model="item.set_code"
                        ></v-text-field>
                    </v-sheet>

                    <!-- 카드 ID -->
                    <v-sheet class="pa-1 mr-2 rounded-10" style="width:64%;">
                        <v-text-field
                            hide-details
                            dense
                            placeholder="number"
                            solo
                            flat
                            v-model="item.number"
                        ></v-text-field>
                    </v-sheet>

                    <!-- 카드 추가 버튼 -->
                    <v-btn
                        v-if="!index"
                        height="48"
                        class="rounded-10"
                        block
                        color="primary"
                        dark
                        depressed
                        @click="add_card()"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>

                    <!-- 카드 삭제 버튼 -->
                    <v-btn
                        v-if="index"
                        height="48"
                        class="rounded-10"
                        block
                        color="primary"
                        dark
                        depressed
                        @click="subtract_card(index)"
                    >
                        <v-icon>mdi-minus</v-icon>
                    </v-btn>
                </div>

                <div class="d-flex mt-6 mb-2">
                    <p class="text-subtitle-1 font-weight-bold mb-2">Variants</p>

                    <v-spacer></v-spacer>

                    <v-btn
                        height="32"
                        class="rounded-10 mr-1"
                        width="32"
                        color="primary"
                        dark
                        depressed
                        @click="add_variant()"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                        height="32"
                        class="rounded-10"
                        width="32"
                        color="primary"
                        dark
                        depressed
                        @click="subtract_variant()"
                    >
                        <v-icon>mdi-minus</v-icon>
                    </v-btn>
                </div>
                <v-sheet
                    v-for="(variant, variant_index) in create.variants" :key="variant_index"
                    class="pa-4 rounded-15 mb-4"
                    color="white"
                >
                    <!-- identifier -->
                    <v-sheet class="d-flex align-center py-2 rounded-10">
                        <p class="title pl-2 mr-4 mb-1">
                            <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                            <span>identifier</span>
                        </p>
                        <v-text-field
                            height="42"
                            dense outlined hide-details
                            class="v15 rounded-10"
                            style="background:white;"
                            placeholder="identifier"
                            v-model="variant.identifier"
                        ></v-text-field>
                    </v-sheet>

                    <!-- name -->
                    <v-sheet class="d-flex align-center py-2 rounded-10">
                        <p class="title pl-2 mr-4 mb-1">
                            <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                            <span>name</span>
                        </p>
                        <v-text-field
                            height="42"
                            dense outlined hide-details
                            class="v15 rounded-10"
                            style="background:white;"
                            placeholder="name"
                            v-model="variant.name"
                        ></v-text-field>
                    </v-sheet>

                    <!-- icons -->
                    <v-sheet class="d-flex align-center py-2 rounded-10">
                        <p class="title pl-2 mr-4 mb-1">
                            <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                            <span>icons</span>
                        </p>
                        <v-text-field
                            height="42"
                            dense outlined hide-details
                            class="v15 rounded-10"
                            style="background:white;"
                            placeholder="icons"
                            v-model="variant.icons"
                        ></v-text-field>
                    </v-sheet>

                    <!-- generation -->
                    <v-sheet class="d-flex align-center py-2 rounded-10">
                        <p class="title pl-2 mr-4 mb-1">
                            <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                            <span>generation</span>
                        </p>
                        <v-text-field
                            height="42"
                            dense outlined hide-details
                            class="v15 rounded-10"
                            style="background:white;"
                            placeholder="generation"
                            v-model="variant.generation"
                        ></v-text-field>
                    </v-sheet>

                    <!-- priority -->
                    <v-sheet class="d-flex align-center py-2 rounded-10 mb-2">
                        <p class="title pl-2 mr-4 mb-1">
                            <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                            <span>priority</span>
                        </p>
                        <v-text-field
                            height="42"
                            dense outlined hide-details
                            class="v15 rounded-10"
                            style="background:white;"
                            placeholder="priority"
                            v-model="variant.priority"
                        ></v-text-field>
                    </v-sheet>

                    <v-sheet
                        v-for="(item, index) in variant.cards"
                        :key="'variant_card_'+index+variant_index"
                        class="d-flex mb-2"
                        color="transparent"
                    >
                        <!-- 카드 ID -->
                        <v-sheet outlined class="pa-1 mr-2 rounded-10" style="width:64%;">
                            <v-text-field
                                hide-details
                                dense
                                placeholder="count"
                                solo
                                flat
                                v-model="item.count"
                            ></v-text-field>
                        </v-sheet>

                        <!-- 카드 ID -->
                        <v-sheet outlined class="pa-1 mr-2 rounded-10" style="width:64%;">
                            <v-text-field
                                hide-details
                                dense
                                placeholder="name"
                                solo
                                flat
                                v-model="item.name"
                            ></v-text-field>
                        </v-sheet>

                        <!-- 카드 ID -->
                        <v-sheet outlined class="pa-1 mr-2 rounded-10" style="width:64%;">
                            <v-text-field
                                hide-details
                                dense
                                placeholder="set_code"
                                solo
                                flat
                                v-model="item.set_code"
                            ></v-text-field>
                        </v-sheet>

                        <!-- 카드 ID -->
                        <v-sheet outlined class="pa-1 mr-2 rounded-10" style="width:64%;">
                            <v-text-field
                                hide-details
                                dense
                                placeholder="number"
                                solo
                                flat
                                v-model="item.number"
                            ></v-text-field>
                        </v-sheet>


                        <!-- 카드 추가/삭제 버튼 -->
                        <div>
                            <v-btn
                                v-if="!index"
                                height="48"
                                class="rounded-10"
                                block
                                color="primary"
                                dark
                                depressed
                                @click="add_variant_card(variant.cards)"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-btn
                                v-if="index"
                                height="48"
                                class="rounded-10"
                                block
                                color="primary"
                                dark
                                depressed
                                @click="subtract_variant_card(variant.cards, index)"
                            >
                                <v-icon>mdi-minus</v-icon>
                            </v-btn>
                        </div>
                    </v-sheet>
                </v-sheet>
                
                <div class="mt-2 pa-2 d-flex justify-center">
                    <v-btn
                        width="120"
                        height="44"
                        depressed
                        large
                        dark
                        color="blue"
                        class="rounded-10 px-6 mr-2"
                        @click="createSubmit()"
                    >
                        추가하기
                    </v-btn>
                    <v-btn
                        width="120"
                        height="44"
                        depressed
                        large
                        dark
                        color="grey"
                        class="rounded-10 px-6"
                        @click="dialog.create = false"
                    >
                        취소하기
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- 로딩 -->
        <v-progress-circular
            v-if="loading"
            style="position:absolute; top: 340px; left: 50%; transform: translate(-50%, -50%); z-index:999;"
            indeterminate
            color="primary"
            size="140"
        ></v-progress-circular>
    </div>
</template>
<script>
export default {
    data: () => ({
        loading: false, 
        
        headers: [
            {text: '', value: ''},
            {text: 'identifier', value: 'identifier'},
            {text: 'name', value: 'name'},
            {text: 'icons', value: 'icons'},
            {text: 'generation', value: 'generation'},
            {text: 'priority', value: 'priority'},
            {text: 'status', value: 'status'}
        ],

        select_list: {
            status: [
                "초기값",
                "수정됨"
            ],

            search_type: [
                "identifier", 
            ]
        },

        search_type: "identifier",
        keyword: "",
        
        list: [],

        selected: false,
        selectedItem: {},

        dialog: {
            update:false,
            create:false
        },

        // 수정하기
        update: {
            identifier: null,
            name: null,
            icons: null,
            generation: null,
            priority: null,

            cards: [
                {
                    count: null,
                    name: null,
                    set_code: null,
                    number: null
                }
            ],

            variants: [
                {
                    identifier: null,
                    name: null,
                    icons: null,
                    generation: null,
                    priority: null,
                    cards: [
                        {
                            count: null,
                            name: null,
                            set_code: null,
                            number: null
                        }
                    ]
                }
            ]
        },

        // 추가하기
        create: {
            identifier: null,
            name: null,
            icons: null,
            generation: null,
            priority: null,

            cards: [
                {
                    count: null,
                    name: null,
                    set_code: null,
                    number: null
                }
            ],

            variants: [
                {
                    identifier: null,
                    name: null,
                    icons: null,
                    generation: null,
                    priority: null,
                    cards: [
                        {
                            count: null,
                            name: null,
                            set_code: null,
                            number: null
                        }
                    ]
                }
            ]
        }
    }),

    mounted(){
        // 검색
        this.search()
    },

    methods: {
        // 검색
        search(){
            this.$http.post('/api/admin/league/deck_auto/search', {
                params: {
                    keyword: this.keyword,
                    type: this.search_type
                }
            }).then((res) => {
                this.list = res.data
            })
        },
        
        // 전체 번역하기
        translate(){
            this.$http.post("/api/admin/league/deck_auto/translate")
            .then((res) => {
                console.log(res)
                alert("덱 자동분류의 이름이 전체 번역되었습니다.")
                this.search()
            })
        },

        add_card(){
            this.create.cards.push(
                {
                    count: null,
                    name: null,
                    set_code: null,
                    number: null
                }
            )
        },

        subtract_card(index){
            this.create.cards.splice(index,1)
        },

        add_variant(){
            this.create.variants.push(
                {
                    identifier: null,
                    name: null,
                    icons: null,
                    generation: null,
                    priority: null,
                    cards: [
                        {
                            count: null,
                            name: null,
                            set_code: null,
                            number: null
                        }
                    ]
                }
            )
        },

        subtract_variant(){
            this.create.variants.shift()
        },

        add_variant_card(variant_cards){
            variant_cards.push(
                {
                    count: null,
                    name: null,
                    set_code: null,
                    number: null
                }
            )
        },

        subtract_variant_card(variant_cards, index){
            variant_cards.splice(index,1)
        },


        add_card_update(){
            this.update.cards.push(
                {
                    count: null,
                    name: null,
                    set_code: null,
                    number: null
                }
            )
        },

        subtract_card_update(index){
            console.log(this.update.cards)
            this.update.cards.splice(index,1)
            console.log(this.update.cards)

            this.update.cards = []
        },

        add_variant_update(){
            this.update.variants.push(
                {
                    identifier: null,
                    name: null,
                    icons: null,
                    generation: null,
                    priority: null,
                    cards: [
                        {
                            count: null,
                            name: null,
                            set_code: null,
                            number: null
                        }
                    ]
                }
            )
        },

        subtract_variant_update(){
            this.update.variants.shift()
        },

        // 선택시 값 업데이트
        selectUpdate(item){
            this.selectedItem.id = item.id
            this.selectedItem.identifier = item.identifier
            this.selectedItem.name = item.name
            this.selectedItem.icons = item.icons
            this.selectedItem.generation = item.generation
            this.selectedItem.priority = item.priority
        },

        // 덱 자동분류 수정 open
        updateOpen(){
            if(Object.keys(this.selectedItem).length){
                this.update.id = this.selectedItem.id
                this.update.identifier = this.selectedItem.identifier
                this.update.name = this.selectedItem.name
                this.update.icons = this.selectedItem.icons
                this.update.generation = this.selectedItem.generation
                this.update.priority = this.selectedItem.priority

                this.$http.post('/api/admin/league/deck_auto/select/card', {
                    params: {
                        deck_auto_id: this.selectedItem.id
                    }
                }).then((res) => {
                    console.log(res)
                    this.update.cards = res.data
                    
                    this.$http.post('/api/admin/league/deck_auto/select/variant', {
                        params: {
                            deck_auto_id: this.selectedItem.id
                        }
                    }).then((res) => {
                        console.log(res)
                        this.update.variants = res.data

                        
                        this.$http.post('/api/admin/league/deck_auto/select/variant', {
                            params: {
                                deck_auto_id: this.selectedItem.id
                            }
                        }).then(async (res) => {
                            console.log(res)
                            this.update.variants = res.data
                            

                            // deck_auto_card_list
                            for(let variant of this.update.variants){
                                
                                await this.$http.post('/api/admin/league/deck_auto/select/variant/card', {
                                    params: {
                                        deck_auto_variant_id: variant.id
                                    }
                                }).then((res) => {
                                    if(res.data.length){
                                        variant.cards = res.data
                                    }else{
                                        variant.cards = []
                                    }
                                })
                            }

                            this.dialog.update = true
                        })
                    })
                })
            }else{
                alert("선택한 덱 자동분류가 없습니다.")
            }
        },

        // 상태값 업데이트
        status_update(item){
            this.$http.post('/api/admin/league/deck_auto/update/status', {
                params: {
                    status: item.status,
                    id: item.id
                }
            }).then(() => {
                alert("상태값이 변경되었습니다.")
                this.search()
            })
        },

        // 추가하기
        async createSubmit(){
            // deck_auto_list
            await this.$http.post('/api/admin/league/deck_auto/insert/deck_auto_list', {
                params: {
                    deck_auto: this.create
                }
            }).then(async(res) => {
                console.log(res)

                // deck_auto_card_list
                for(let deck_auto_card of this.create.cards){
                    await this.$http.post('/api/admin/league/deck_auto/insert/deck_auto_card_list', {
                        params: {
                            deck_auto_id: res.data.insertId,
                            deck_auto_card: deck_auto_card
                        }
                    }).then((res) => {
                        // console.log(res)
                    })
                }

                console.log(this.create.variants)

                // deck_auto_variant_list
                for(let deck_auto_variant of this.create.variants){
                    await this.$http.post('/api/admin/league/deck_auto/insert/deck_auto_variant_list', {
                        params: {
                            deck_auto_id: res.data.insertId,
                            deck_auto_variant: deck_auto_variant
                        }
                    }).then(async (deck_auto_variant_list_res) => {
                        console.log(deck_auto_variant_list_res)

                        // deck_auto_variant_card_list
                        for(let deck_auto_variant_card of deck_auto_variant.cards){
                            await this.$http.post('/api/admin/league/deck_auto/insert/deck_auto_variant_card_list', {
                                params: {
                                    deck_auto_id: res.data.insertId,
                                    deck_auto_variant_id: deck_auto_variant_list_res.data.insertId,
                                    deck_auto_variant_card: deck_auto_variant_card
                                }
                            }).then((res) => {
                                console.log(res)
                            })
                        }
                    })
                }
            })

            alert("추가되었습니다")
            this.dialog.create = false
            this.search()
        },

        // 덱 자동분류 삭제하기
        deleteItem(){
            if(!Object.keys(this.selectedItem).length){
                alert("선택한 덱 자동분류가 없습니다.")
            }else{
                if(confirm('정말 해당 덱 자동분류를 삭제하시겠습니까?')){
                    this.$http.post('/api/admin/league/deck_auto/delete', {
                        params: {
                            id: this.selectedItem.id,
                        }
                    }).then((res) => {
                        alert("해당 덱 자동분류가 삭제되었습니다.")
                        this.search()
                    })
                }
            }
        },

        // 수정하기
        async updateSubmit(){
            // deck_auto_list
            await this.$http.post('/api/admin/league/deck_auto/update/deck_auto_list', {
                params: {
                    deck_auto: this.update
                }
            }).then(async(res) => {
                console.log(res)

                // deck_auto_card_list
                await this.$http.post('/api/admin/league/deck_auto/delete/deck_auto_card_list', {
                    params: {
                        deck_auto_id: this.update.id,
                    }
                }).then((res) => {
                    // console.log(res)
                })

                // deck_auto_card_list
                for(let deck_auto_card of this.update.cards){
                    await this.$http.post('/api/admin/league/deck_auto/insert/deck_auto_card_list', {
                        params: {
                            deck_auto_id: this.update.id,
                            deck_auto_card: deck_auto_card
                        }
                    }).then((res) => {
                        // console.log(res)
                    })
                }

                console.log(this.update.variants)

                // deck_auto_card_list
                await this.$http.post('/api/admin/league/deck_auto/delete/deck_auto_variant_list', {
                    params: {
                        deck_auto_id: this.update.id,
                    }
                }).then((res) => {
                    // console.log(res)
                })

                // deck_auto_variant_list
                for(let deck_auto_variant of this.update.variants){
                    await this.$http.post('/api/admin/league/deck_auto/insert/deck_auto_variant_list', {
                        params: {
                            deck_auto_id: this.update.id,
                            deck_auto_variant: deck_auto_variant
                        }
                    }).then(async (deck_auto_variant_list_res) => {
                        console.log(deck_auto_variant_list_res)

                        // deck_auto_variant_card_list
                        for(let deck_auto_variant_card of deck_auto_variant.cards){
                            await this.$http.post('/api/admin/league/deck_auto/insert/deck_auto_variant_card_list', {
                                params: {
                                    deck_auto_id: this.update.id,
                                    deck_auto_variant_id: deck_auto_variant_list_res.data.insertId,
                                    deck_auto_variant_card: deck_auto_variant_card
                                }
                            }).then((res) => {
                                console.log(res)
                            })
                        }
                    })
                }

                alert("수정되었습니다")
                this.dialog.update = false
                this.search()
                this.selected = false
                this.selectedItem = {}
            })
        },

        // # 업데이트
        async updateByAPI(){
            // await this.$http.get('/api/admin/league/limitless/insert/fetch-tournaments6')
            // .then(async (endpoint6) => { 
            //     console.log(endpoint6.data)
            //     console.log(endpoint6.data.filter(e => e.name == 'Armarouge Box'))
            //     console.log(endpoint6.data.filter(e => e.name == 'Froslass Munkidori'))
            // }) 

            // return

            this.loading = true

            // # 전체 삭제
            await this.$http.post("/api/admin/league/limitless/delete/deck_auto/all")
            .then(async(res) => {
                // endpoint6
                await this.$http.get('/api/admin/league/limitless/insert/fetch-tournaments6')
                .then(async (endpoint6) => {
                    let deck_auto_list = endpoint6.data

                    // identifier가 중복인 경우 generation 값이 더 큰 쪽을 남기고 작은 쪽은 삭제
                    deck_auto_list = await deck_auto_list.reduce((acc, current) => {
                        const existing = acc.find(item => item.identifier === current.identifier)
                        if (existing) {
                            if (existing.generation < current.generation) {
                                acc = acc.filter(item => item.identifier !== current.identifier)
                                acc.push(current)
                            }
                        } else {
                            acc.push(current)
                        }
                        return acc
                    }, [])
                    console.log(deck_auto_list)

                    let deck_name_list = deck_auto_list.map(e => e.identifier)

                    await this.$http.get("/api/admin/league/deck_auto_translate_list/list")
                    .then(async (res) => {
                        // console.log("API", deck_name_list)
                        // console.log("DB", res.data.map(e => e.en))

                        let notInResData = deck_name_list.filter(name => !res.data.map(e => e.en).includes(name))
                        console.log("없어서 추가해야할 덱 이름", notInResData)

                        // 덱 이름번역 추가
                        for(let name of notInResData){
                            await this.$http.post("/api/admin/league/deck_auto_translate_list/create", {
                                params: {
                                    en: name,
                                    kr: "",
                                    jp: "",
                                }
                            }).then((res) => {
                                // console.log(res)
                            })
                        }
                    })

                    for(let deck_auto of deck_auto_list){
                        // # deck_auto_list 입력
                        await this.$http.post('/api/admin/league/limitless/insert/insert/deck_auto_list', {
                            params: {
                                deck_auto: deck_auto
                            }
                        }).then(async(res) => {
                            // console.log(res)

                            for(let deck_auto_card of deck_auto.cards){
                                // # deck_auto_list 입력
                                await this.$http.post('/api/admin/league/limitless/insert/insert/deck_auto_card_list', {
                                    params: {
                                        deck_auto_id: res.data.insertId,
                                        deck_auto_card: deck_auto_card
                                    }
                                }).then((res) => {
                                    // console.log(res)
                                })
                            }

                            for(let deck_auto_variant of deck_auto.variants){
                                // # deck_auto_variant_list 입력
                                await this.$http.post('/api/admin/league/limitless/insert/insert/deck_auto_variant_list', {
                                    params: {
                                        deck_auto_id: res.data.insertId,
                                        deck_auto_icon: deck_auto.icons[0],
                                        deck_auto_variant: deck_auto_variant
                                    }
                                }).then(async (deck_auto_variant_list_res) => {
                                    // console.log(deck_auto_variant_list_res)

                                    for(let deck_auto_variant_card of deck_auto_variant.cards){
                                        // # deck_auto_variant_card_list 입력
                                        await this.$http.post('/api/admin/league/limitless/insert/insert/deck_auto_variant_card_list', {
                                            params: {
                                                deck_auto_id: res.data.insertId,
                                                deck_auto_variant_id: deck_auto_variant_list_res.data.insertId,
                                                deck_auto_variant_card: deck_auto_variant_card
                                            }
                                        }).then((res) => {
                                            // console.log(res)
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
            }).then(() => {
                alert('모든 데이터가 성공적으로 업데이트되었습니다.')
                this.loading = false
            })
        },
    }
}
</script>
<style>
/* 목록 */
#list{
    border:1px solid #efefef;
}

#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}

/* 덱 자동분류 수정/추가 Dialog */
p.title{
    font-weight:bold;
    width:120px;
    margin: 0;
}

p.title span{
    font-size:15px;
}

.dialogCustom .v-input{
    font-weight:bold;
    font-size:15px;
    padding-bottom:2px;
}
</style>