<template>
    <table class="table_style">       
        <!-- 이미지 편집/미리보기 -->   
        <tr>
            <td class="pa-3">
                <clipper-basic
                    :src="url"
                    ref="clipper"
                    class="my-clipper"
                    :ratio="245/342"
                    :init-width="100"
                    :init-height="100"
                >
                    <div class="placeholder" slot="placeholder"></div>
                </clipper-basic>
            </td>
        </tr>

        <!-- 파일 입력 -->
        <tr>
            <td class="py-2 px-3">
                <v-sheet
                    @drop.prevent="addDropFile"
                    @dragover.prevent
                >
                    <v-file-input
                        placeholder="카드 이미지를 선택해주세요"
                        outlined
                        dense
                        show-size
                        hide-details
                        prepend-icon
                        color="blue"
                        v-model="uploadImage"
                        @change="onFileChange"
                    >
                        <template v-slot:prepend-inner>
                            <v-icon class="mr-1" color="blue">mdi-image-filter-hdr</v-icon>
                        </template>
                    </v-file-input>
                </v-sheet>
            </td>
        </tr>

        <!-- 이미지 초기화 -->
        <tr>
            <td class="py-2 px-3">
                <v-btn
                    @click="EmitCropedImage()"
                    depressed
                    width="100%"
                    dark
                    color="blue"
                >
                    이미지 초기화
                </v-btn>
            </td>
        </tr>
    </table>
</template>
<script>
export default {
    props: [
        "card_id",
        "card_nation"
    ],

    data: () => ({
        uploadImage: null,
        url: null,
    }),

    mounted(){
        this.load()
    },

    watch: {
        card_nation(){
            this.load()
        }
    },

    methods: {
        // ReadCard시 이미지 불러와서 표시
        load(){
            // 한글 카드
            if(this.card_nation == "kr")
            {
                this.$http.post('/api/card/detail/card_kr', {
                    params: {
                        id: this.card_id
                    }
                }).then(res => {
                    if(res.data.length){
                        this.url = "/upload/card_"+this.card_nation+"_image/"+ res.data[0].image
                        this.$emit("imageUpdated", res.data[0].image)
                    }
                })
            }
            // 일어 카드
            else if(this.card_nation == "jp")
            {
                this.$http.post('/api/card/detail/card_jp', {
                    params: {
                        id: this.card_id
                    }
                }).then(res => {
                    if(res.data.length){
                        this.url = "/upload/card_"+this.card_nation+"_image/"+ res.data[0].image
                        this.$emit("imageUpdated", res.data[0].image)
                    }
                })
            }
            // 영어 카드
            else if(this.card_nation == "en")
            {
                this.$http.post('/api/card/detail/card_en', {
                    params: {
                        id: this.card_id
                    }
                }).then(res => {
                    if(res.data.length){
                        this.url = "/upload/card_"+this.card_nation+"_image/"+ res.data[0].image
                        this.$emit("imageUpdated", res.data[0].image)
                    }
                })
            }
        },

        // 파일 드래그앤드롭
        addDropFile(e) { 
            // 드롭한 파일 미리보기 표시
            const file = e.dataTransfer.files[0]
            this.uploadImage = e.dataTransfer.files[0]
            this.url = URL.createObjectURL(file)

            setTimeout(() => {
                this.EmitCropedImage()
            }, 2000)
        },

        // 파일 선택시 이미지 표시
        onFileChange() {
            if(this.uploadImage == null){
                this.$emit("imageUpdated", null)
                this.url = null
            }else{
                const file = this.uploadImage
                this.url = URL.createObjectURL(file)

                setTimeout(() => {
                    this.EmitCropedImage()
                }, 2000)
            }
        },

        // 이미지 초기화
        initImage(){
            this.$http.post('/api/card/update/init/image/'+this.card_nation, {
                params: {
                    id: this.$route.query.id
                }
            }).then(() => {
                alert("카드 이미지가 초기화되었습니다.")
                this.$emit("imageUpdated", null)
                this.url = null
                this.$emit("close")
            })
        },

        // Emit전 크롭 이미지로 변경
        EmitCropedImage(){
            // Crop 이미지 파일로 변환
            const canvas = this.$refs.clipper.clip() //call component's clip method
            var imgDataUrl = canvas.toDataURL("image/webp", 0.5) //canvas->image
            var binaryData = atob(imgDataUrl.split(',')[1])
            var array = []
            for (var i = 0; i < binaryData.length; i++) {
                array.push(binaryData.charCodeAt(i));
            }
            var file = new File([new Uint8Array(array)], "filename.png", {type: 'image/png'})

            // Export
            this.$emit("imageUpdated", file)
        }
    }
}
</script>
<style scoped>
/* 테이블 */
.table_style{
    width:320px;
    border:1px solid #ccc;
    border-collapse: collapse;
}

/* 테이블 TD */
.table_style tr td{
    background: white;
    border:1px solid #ccc;
    padding:10px;
}

.my-clipper {
    width:100%;
}

.placeholder {
    width:245px;
    height:363px;
}
</style>