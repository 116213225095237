<template>
    <div>
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">자동번역데이터 관리</font>
            <v-spacer></v-spacer>

            <!-- 카드이름, 기술명 선택 -->
            <!-- <v-sheet width="200">
                <v-select
                    class="mr-4"
                    outlined
                    dense
                    hide-details
                    v-model="selected_type"
                    :items="select_list.type"
                ></v-select>
            </v-sheet> -->
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex" width="660">
                    <!-- 키워드 -->
                    <v-text-field
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="blue"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-radio-group v-model="selected" hide-details>
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="list"
                    :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:45px;">
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <td style="width:33%"><font class="d-block text-truncate" style="min-width:200px;">{{item.en}}</font></td>
                            <td style="width:33%"><font class="d-block text-truncate" style="min-width:200px;">{{item.kr}}</font></td>
                            <td style="width:33%"><font class="d-block text-truncate" style="min-width:200px;">{{item.jp}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 선택항목 & 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    class="px-6 mr-4"
                    large
                    disabled
                >
                    선택항목: {{selectedItem.en}}({{selectedItem.kr}})
                </v-btn>
                <v-btn
                    class="px-6"
                    color="orange"
                    large
                    dark
                    depressed
                    @click="updateOpen()"
                >
                    <v-icon small left>mdi-database-edit-outline</v-icon>
                    수정하기
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="blue"
                    large
                    dark
                    depressed
                    @click="deleteItem()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    삭제하기
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="blue"
                    large
                    dark
                    depressed
                    @click="dialog.create = true"
                >
                    <v-icon small left>mdi-database-plus-outline</v-icon>
                    추가하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- 수정하기 (dialog) -->
        <v-dialog v-model="dialog.update" width="auto" content-class="rounded-10">
            <v-sheet class="pa-4">
                <table class="updatetable">
                    <tr>
                        <th style="width:54px;"></th>
                        <th style="width:300px;">영어</th>
                        <th style="width:300px;">한글</th>
                        <th style="width:300px;">일어</th>
                    </tr>
                    <tr>
                        <td>기존</td>
                        <td>{{selectedItem.en}}</td>
                        <td>{{selectedItem.kr}}</td>
                        <td>{{selectedItem.jp}}</td>
                    </tr>
                    <tr v-if="selectedAfter">
                        <td>변경</td>
                        <td>
                            <v-text-field
                                outlined
                                dense
                                hide-details
                                v-model="selectedAfter.en"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                outlined
                                dense
                                hide-details
                                v-model="selectedAfter.kr"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                outlined
                                dense
                                hide-details
                                v-model="selectedAfter.jp"
                            ></v-text-field>
                        </td>
                    </tr>
                </table>
                <v-sheet class="mt-4 d-flex justify-center">
                    <v-btn
                        class="px-6 mr-4"
                        color="blue"
                        large
                        dark
                        depressed
                        @click="updateSubmit()"
                    >
                        <v-icon small left>mdi-database-edit-outline</v-icon>
                        수정하기
                    </v-btn>
                    <v-btn
                        class="px-6"
                        color="grey"
                        large
                        dark
                        depressed
                        @click="dialog.update = false"
                    >
                        <v-icon small left>mdi-cancel</v-icon>
                        취소하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>

        <!-- 추가하기 (dialog) -->
        <v-dialog v-model="dialog.create" width="auto" content-class="rounded-10">
            <v-sheet class="pa-4">
                <table class="updatetable">
                    <tr>
                        <th style="width:54px;"></th>
                        <th style="width:300px;">영어</th>
                        <th style="width:300px;">한글</th>
                        <th style="width:300px;">일어</th>
                    </tr>
                    <tr v-if="create">
                        <td>추가</td>
                        <td>
                            <v-text-field
                                outlined
                                dense
                                hide-details
                                v-model="create.en"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                outlined
                                dense
                                hide-details
                                v-model="create.kr"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                class="mr-2"
                                outlined
                                dense
                                hide-details
                                v-model="create.jp"
                            ></v-text-field>
                        </td>
                    </tr>
                </table>
                <v-sheet class="mt-4 d-flex justify-center">
                    <v-btn
                        class="px-6 mr-4"
                        color="blue"
                        large
                        dark
                        depressed
                        @click="createSubmit()"
                    >
                        <v-icon small left>mdi-database-edit-outline</v-icon>
                        추가하기
                    </v-btn>
                    <v-btn
                        class="px-6"
                        color="grey"
                        large
                        dark
                        depressed
                        @click="dialog.create = false"
                    >
                        <v-icon small left>mdi-cancel</v-icon>
                        취소하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
export default {
    data: () => ({
        selected_type: "카드이름",

        select_list: {
            type: [
                "카드이름",
                "기술명"
            ]
        },

        headers: [
            {text: '', value: ''},
            {text: '영어', value: 'en'},
            {text: '한글', value: 'kr'},
            {text: '일어', value: 'jp'}
        ],
        list: [],

        selected: false,
        selectedItem: {},
        selectedAfter: {},

        dialog: {
            update:false,
            create:false
        },

        create: {
            en: "",
            kr: "",
            jp: ""
        },

        keyword: ""
    }),

    mounted(){
        // 목록 불러오기
        this.load()
    },

    watch: {
        selected_type(){
            // 목록 불러오기
            this.load()
        }
    },

    methods: {
        // 목록 불러오기
        load(){
            if(this.selected_type == "카드이름")
            {
                this.$http.post('/api/admin/card/translate/list')
                .then((res) => {
                    this.list = res.data
                })
            }
            else if(this.selected_type == "기술명")
            {
                this.$http.post('/api/admin/card/translate/attack/list')
                .then((res) => {
                    this.list = res.data
                })
            }

            // 값 초기화
            this.dialog.update = false
            this.dialog.create = false
            this.selected = false
            this.selectedItem = {}
            this.selectedAfter = {}
            this.create = {}
        },

        // 검색
        search(){
            if(this.selected_type == "카드이름")
            {
                this.$http.post('/api/admin/card/translate/search', {
                    params: {
                        keyword: this.keyword
                    }
                }).then((res) => {
                    this.list = res.data
                })
            }
            else if(this.selected_type == "기술명")
            {
                this.$http.post('/api/admin/card/translate/attack/search', {
                    params: {
                        keyword: this.keyword
                    }
                }).then((res) => {
                    this.list = res.data
                })
            }

            // 값 초기화
            this.dialog.update = false
            this.dialog.create = false
            this.selected = false
            this.selectedItem = {}
            this.selectedAfter = {}
            this.create = {}
        },

        // 선택시 값 업데이트
        selectUpdate(item){
            this.selectedItem.id = item.id
            this.selectedItem.en = item.en
            this.selectedItem.kr = item.kr
            this.selectedItem.jp = item.jp

            this.selectedAfter.id = item.id
            this.selectedAfter.en = item.en
            this.selectedAfter.kr = item.kr
            this.selectedAfter.jp = item.jp
        },

        // 번역 수정 open
        updateOpen(){
            if(Object.keys(this.selectedItem).length){
                this.dialog.update = true
            }else{
                alert("선택한 번역 정보가 없습니다.")
            }
        },

        // 번역 수정 Submit
        updateSubmit(){
            if(this.list.map(e => e.en).includes(this.selectedAfter.en) && this.selectedItem.en != this.selectedAfter.en){
                alert(this.selectedAfter.en + "은 이미 있는 단어이므로 " + this.selectedAfter.en + "으로 수정하실 수 없습니다.")
            }else{
                this.$http.post('/api/admin/card/translate/update', {
                    params: {
                        before: this.selectedItem,
                        after: this.selectedAfter
                    }
                }).then((res) => {
                    if(!res.data.affectedRows){
                        alert("번역 정보 수정중 오류가 발생하였습니다.")
                    }else{
                        alert("해당 번역 정보가 수정되었습니다.")
                        this.load()
                    }
                })
            }
        },

        // 번역 추가 Submit
        createSubmit(){
            if(this.list.map(e => e.en).includes(this.create.en)){
                alert(this.create.en + "은 이미 있는 단어이므로 " + this.create.en + "으로 추가하실 수 없습니다.")
            }else{
                this.$http.post('/api/admin/card/translate/create', {
                    params: {
                        en: this.create.en,
                        kr: this.create.kr,
                        jp: this.create.jp
                    }
                }).then((res) => {
                    if(!res.data.affectedRows){
                        alert("번역 정보 추가중 오류가 발생하였습니다.")
                    }else{
                        alert("번역 정보가 추가되었습니다.")
                        this.load()
                    }
                })
            }
        },

        // 번역 삭제하기
        deleteItem(){
            if(!Object.keys(this.selectedItem).length){
                alert("선택한 번역 정보가 없습니다.")
            }else{
                if(confirm('정말 해당 번역 정보를 삭제하시겠습니까?')){
                    this.$http.post('/api/admin/card/translate/delete', {
                        params: {
                            id: this.selectedItem.id,
                        }
                    }).then((res) => {
                        if(!res.data.affectedRows){
                            alert("번역 정보 삭제중 오류가 발생하였습니다.")
                        }else{
                            alert("해당 번역 정보가 삭제되었습니다.")
                            this.load()
                        }
                    })
                }
            }
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}

.updatetable{
    border:1px solid #efefef;
    border-collapse: collapse;
}

.updatetable tr td{
    height:50px;
    padding:10px;
    border:1px solid #dedede;
}

.updatetable tr th{
    background:#f2f2f2;
    text-align: left;
    padding:10px;
    border:1px solid #dedede;
}
</style>