<template>
    <v-app>
        <v-sheet
            class="d-flex overflow-auto"
            height="100%"
        >
            <!-- 사이드 -->
            <Side
                v-if="$store.state.type== '관리자' || $store.state.type== '운영진'"
            />

            <!-- 본문 -->
            <v-main style="background:#F5F6F7;">
                <router-view :key="$route.fullPath"></router-view>
            </v-main>
        </v-sheet>
    </v-app>
</template>

<script>
import Side from './Side'

export default {
    components: {
        Side
    },

    mounted(){
        if(!(this.$store.state.is_logined && (this.$store.state.type== '관리자' || this.$store.state.type == '운영진'))){
            alert("해당 페이지에대한 접근 권한이 없습니다.")
            this.$router.push('/')
        }
    }
}
</script>