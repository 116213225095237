<template>
    <div
        v-if="$store.state.type == '관리자'"
    >
        <!-- 헤더 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">대시보드</font>
        </v-sheet>
        
        <div class="d-flex justify-space-between">
            <!-- 메인 관리 > 문의 관리 -->
            <v-sheet outlined class="pa-2 ma-2" width="49%">
                <p
                    class="font-weight-medium pt-2 pl-1 mb-2 text-subtitle-1 grey--text text--darken-2"
                    style="cursor:pointer"
                    @click="$router.push('/admin/main/contact')"
                >
                    메인 관리 <v-icon class="mb-1">mdi-chevron-right</v-icon> 문의 관리
                </p>
                <v-data-table
                    id="list"
                    :headers="headers.main_contact"
                    :items="list.main_contact"
                    hide-default-footer
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:120px;"><font class="d-block text-truncate" style="width:120px;">{{item.nickname}}</font></td>
                            <td style="width:240px"><font class="d-block text-truncate" style="width:240px;">{{item.title}}</font></td>
                            <td style="width:160px;"><font class="d-block text-truncate" style="width:160px;">{{new Date(item.created).toLocaleDateString()}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-sheet>

            <!-- 카드 관리 > 카드 리뷰 관리 -->
            <v-sheet outlined class="pa-2 ma-2" width="49%">
                <p
                    class="font-weight-medium pt-2 pl-1 mb-2 text-subtitle-1 grey--text text--darken-2"
                    style="cursor:pointer"
                    @click="$router.push('/admin/card/review')"
                >
                    카드 관리 <v-icon class="mb-1">mdi-chevron-right</v-icon> 카드 리뷰 관리
                </p>
                <v-data-table
                    id="list"
                    :headers="headers.card_review"
                    :items="list.card_review"
                    hide-default-footer
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:120px"><font class="d-block text-truncate" style="width:120px;">{{item.nickname}}</font></td>
                            <td style="width:300px">
                                <font class="d-block text-truncate" style="width:300px;">{{item.content}}</font>
                            </td>
                            <td style="width:200px">
                                <font class="d-block text-truncate" style="width:200px;">
                                    {{new Date(item.created).toLocaleString()}}
                                </font>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-sheet>
        </div>

        <div class="d-flex justify-space-between">
            <!-- 카드 거래 관리 > 카드 가격 관리 -->
            <v-sheet outlined class="pa-2 ma-2" width="49%">
                <p
                    class="font-weight-medium pt-2 pl-1 mb-2 text-subtitle-1 grey--text text--darken-2"
                    style="cursor:pointer"
                    @click="$router.push('/admin/trade/price')"
                >
                    카드 거래 관리 <v-icon class="mb-1">mdi-chevron-right</v-icon> 카드 가격 관리
                </p>
                <v-data-table
                    id="list"
                    :headers="headers.trade_price"
                    :items="list.trade_price"
                    hide-default-footer
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:220px"><font class="d-block text-truncate" :class="item.post_title=='관리자 입력'? 'orange--text':''" style="width:220px;">{{item.post_title}}</font></td>
                            <td style="width:160px;"><font class="d-block text-truncate" style="width:160px;">{{item.card_name}}</font></td>
                            <td style="width:140px;"><font class="d-block text-truncate" style="width:140px;">{{item.card_created==null? null : new Date(item.card_created).toLocaleDateString()}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-sheet>

            <!-- 제보 관리 > 이미지 등록 관리 -->
            <v-sheet outlined class="pa-2 ma-2" width="49%">
                <p
                    class="font-weight-medium pt-2 pl-1 mb-2 text-subtitle-1 grey--text text--darken-2"
                    style="cursor:pointer"
                    @click="$router.push('/admin/provide/image')"
                >
                    제보 관리 <v-icon class="mb-1">mdi-chevron-right</v-icon> 이미지 등록 관리
                </p>
                <v-data-table
                    id="list"
                    :headers="headers.provide_image"
                    :items="list.provide_image"
                    hide-default-footer
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:140px"><font class="d-block text-truncate" style="width:140px;">{{item.nickname}}</font></td>
                            <td style="width:180px;"><font class="d-block text-truncate" style="width:180px;">{{item.card_id}}</font></td>
                            <td style="width:100px;"><font class="d-block text-truncate" style="width:100px;">{{item.card_nation}}</font></td>
                            <td style="width:200px;"><font class="d-block text-truncate" style="width:200px;">{{new Date(item.created).toLocaleDateString()}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-sheet>
        </div>

        <div class="d-flex justify-space-between">
            <!-- 제보 관리 > 정보 입력/수정 관리 -->
            <v-sheet outlined class="pa-2 ma-2" width="49%">
                <p
                    class="font-weight-medium pt-2 pl-1 mb-2 text-subtitle-1 grey--text text--darken-2"
                    style="cursor:pointer"
                    @click="$router.push('/admin/provide/info')"
                >
                    제보 관리 <v-icon class="mb-1">mdi-chevron-right</v-icon> 정보 입력/수정 관리
                </p>
                <v-data-table
                    id="list"
                    :headers="headers.provide_info"
                    :items="list.provide_info"
                    hide-default-footer
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:140px"><font class="d-block text-truncate" style="width:140px;">{{item.nickname}}</font></td>
                            <td style="width:180px;"><font class="d-block text-truncate" style="width:180px;">{{item.card_id}}</font></td>
                            <td style="width:100px;"><font class="d-block text-truncate" style="width:100px;">{{item.card_nation}}</font></td>
                            <td style="width:200px;"><font class="d-block text-truncate" style="width:200px;">{{new Date(item.created).toLocaleDateString()}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-sheet>

            <!-- 제보 관리 > 오류제보 관리 -->
            <v-sheet outlined class="pa-2 ma-2" width="49%">
                <p
                    class="font-weight-medium pt-2 pl-1 mb-2 text-subtitle-1 grey--text text--darken-2"
                    style="cursor:pointer"
                    @click="$router.push('/admin/provide/bug')"
                >
                    제보 관리 <v-icon class="mb-1">mdi-chevron-right</v-icon> 오류제보 관리
                </p>
                <v-data-table
                    id="list"
                    :headers="headers.provide_bug"
                    :items="list.provide_bug"
                    hide-default-footer
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:140px"><font class="d-block text-truncate" style="width:140px;">{{item.nickname}}</font></td>
                            <td style="width:180px;"><font class="d-block text-truncate" style="width:180px;">{{item.card_id}}</font></td>
                            <td style="width:100px;"><font class="d-block text-truncate" style="width:100px;">{{item.card_nation}}</font></td>
                            <td style="width:200px;"><font class="d-block text-truncate" style="width:200px;">{{new Date(item.created).toLocaleDateString()}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-sheet>
        </div>

        <div class="d-flex justify-space-between">
            <!-- 포인트 관리 > 포인트 내역 관리 -->
            <v-sheet outlined class="pa-2 ma-2" width="49%">
                <p
                    class="font-weight-medium pt-2 pl-1 mb-2 text-subtitle-1 grey--text text--darken-2"
                    style="cursor:pointer"
                    @click="$router.push('/admin/point/list')"
                >
                    포인트 관리 <v-icon class="mb-1">mdi-chevron-right</v-icon> 포인트 내역 관리
                </p>
                <v-data-table
                    id="list"
                    :headers="headers.point_list"
                    :items="list.point_list"
                    hide-default-footer
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:140px"><font class="d-block text-truncate" style="width:140px;">{{item.nickname}}</font></td>
                            <td style="width:180px"><font class="d-block text-truncate" style="width:180px;">{{item.activity}}</font></td>
                            <td style="width:140px"><font class="d-block text-truncate" style="width:140px;">{{item.amount}}</font></td>
                            <td style="width:200px"><font class="d-block text-truncate" style="width:200px;">{{new Date(item.created).toLocaleString()}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-sheet>

            <!-- 포인트몰 관리 > 주문 관리 -->
            <v-sheet outlined class="pa-2 ma-2" width="49%">
                <p
                    class="font-weight-medium pt-2 pl-1 mb-2 text-subtitle-1 grey--text text--darken-2"
                    style="cursor:pointer"
                    @click="$router.push('/admin/pointmall/order')"
                >
                    포인트몰 관리 <v-icon class="mb-1">mdi-chevron-right</v-icon> 주문 관리
                </p>
                <v-data-table
                    id="list"
                    :headers="headers.pointmall_order"
                    :items="list.pointmall_order"
                    hide-default-footer
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:200px"><font class="d-block text-truncate" style="width:200px;">{{item.title}}</font></td>
                            <td style="width:120px"><font class="d-block text-truncate" style="width:120px;">{{item.receiver}}</font></td>
                            <td style="width:140px"><font class="d-block text-truncate" style="width:140px;">{{item.phone}}</font></td>
                            <td style="width:190px"><font class="d-block text-truncate" style="width:190px;">{{new Date(item.created).toLocaleString()}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-sheet>
        </div>

        <div class="d-flex justify-space-between">
            <!-- 카드거래 관리 > 수정요청 관리 -->
            <v-sheet outlined class="pa-2 ma-2" width="49%">
                <p
                    class="font-weight-medium pt-2 pl-1 mb-2 text-subtitle-1 grey--text text--darken-2"
                    style="cursor:pointer"
                    @click="$router.push('/admin/trade/request')"
                >
                    카드거래 관리 <v-icon class="mb-1">mdi-chevron-right</v-icon> 수정요청 관리
                </p>
                <v-data-table
                    id="list"
                    :headers="headers.trade_request"
                    :items="list.trade_request"
                    hide-default-footer
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td @click="$router.push(item.link)" style="width:160px; cursor:pointer;"><font class="d-block text-truncate" style="width:160px;">{{item.user_id}}</font></td>
                            <td @click="$router.push(item.link)" style="width:160px; cursor:pointer;"><font class="d-block text-truncate" style="width:140px;">{{item.category}}</font></td>
                            <td @click="$router.push(item.link)" style="width:100%; cursor:pointer;"><font class="d-block text-truncate" style="width:300px;">{{item.content}}</font></td>
                            <td @click="$router.push(item.link)" style="width:220px; cursor:pointer;"><font class="d-block text-truncate" style="width:150px;">{{new Date(item.created).toLocaleDateString()}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-sheet>

            <!-- // 회원 관리 > 신고 목록 -->
            <v-sheet outlined class="pa-2 ma-2" width="49%">
                <p
                    class="font-weight-medium pt-2 pl-1 mb-2 text-subtitle-1 grey--text text--darken-2"
                    style="cursor:pointer"
                    @click="$router.push('/admin/main/contact')"
                >
                    회원 관리 <v-icon class="mb-1">mdi-chevron-right</v-icon> 신고 목록
                </p>
                <v-data-table
                    id="list"
                    :headers="headers.user_reported"
                    :items="list.user_reported"
                    hide-default-footer
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td @click="$router.push(item.link)" style="width:160px; cursor:pointer;"><font class="d-block text-truncate" style="width:160px;">{{item.reporter_user_id}}</font></td>
                            <td @click="$router.push(item.link)" style="width:160px; cursor:pointer;"><font class="d-block text-truncate" style="width:160px;">{{item.reported_user_id}}</font></td>
                            <td @click="$router.push(item.link)" style="width:120px">
                                <font class="d-block text-truncate" style="width:120px; cursor:pointer;">
                                    <span v-if="item.type=='board_post'">게시글</span>
                                    <span v-if="item.type=='board_comment'">댓글</span>
                                    <span v-if="item.type=='board_subcomment'">대댓글</span>
                                    <span v-if="item.type=='trade_post'">거래글</span>
                                    <span v-if="item.type=='trade_comment'">거래글 댓글</span>
                                    <span v-if="item.type=='message'">메세지</span>
                                </font>
                            </td>
                            <td @click="$router.push(item.link)" style="width:160px; cursor:pointer;"><font class="d-block text-truncate" style="width:160px;">{{item.category}}</font></td>
                            <td @click="$router.push(item.link)" style="width:160px; cursor:pointer;"><font class="d-block text-truncate" style="width:160px;">{{new Date(item.created).toLocaleDateString()}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-sheet>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        headers: {
            main_contact: [
                {text: '문의자 닉네임', value: 'nickname'},
                {text: '문의 제목', value: 'title'},
                {text: '문의 일자', value: 'created'}
            ],

            card_review: [
                {text: '작성자 닉네임', value: 'nickname'},
                {text: '리뷰 내용', value: 'content'},
                {text: '작성 일자', value: 'created'}
            ],

            trade_price: [
                {text: '거래글 제목', value: 'post_title'},
                {text: '카드 이름', value: 'card_name'},
                {text: '등록 날짜', value: 'card_created'},
            ],

            provide_image: [
                {text: '제보자 닉네임', value: 'nickname'},
                {text: '카드 ID', value: 'card_id'},
                {text: '국가', value: 'card_nation'},
                {text: '제보 일자', value: 'created'}
            ],

            provide_info: [
                {text: '제보자 닉네임', value: 'nickname'},
                {text: '카드 ID', value: 'card_id'},
                {text: '국가', value: 'card_nation'},
                {text: '제보 일자', value: 'created'}
            ],

            provide_bug: [
                {text: '제보자 닉네임', value: 'nickname'},
                {text: '카드 ID', value: 'card_id'},
                {text: '국가', value: 'card_nation'},
                {text: '제보 일자', value: 'created'}
            ],

            point_list: [
                {text: '회원 닉네임', value: 'nickname'},
                {text: '활동', value: 'activity'},
                {text: '포인트', value: 'amount'},
                {text: '지급일자', value: 'created'}
            ],

            pointmall_order: [
                {text: '상품명', value: 'title'},
                {text: '수령인', value: 'receiver'},
                {text: '전화번호', value: 'phone'},
                {text: '신청일자', value: 'created'}
            ],

            trade_request: [
                {text: '요청자 아이디', value: 'user_id'},
                {text: '항목', value: 'category'},
                {text: '내용', value: 'content'},
                {text: '요청일자', value: 'created'}
            ],

            user_reported: [
                {text: '신고자 아이디', value: 'reporter_user_id'},
                {text: '신고받은 아이디', value: 'reported_user_id'},
                {text: '유형', value: 'type'},
                {text: '항목', value: 'category'},
                {text: '내용', value: 'content'},
                {text: '신고일자', value: 'created'}
            ]
        },
        
        list: {
            main_contact: [],
            card_review: [],
            trade_price: [],
            provide_image: [],
            provide_info: [],
            provide_bug: [],
            point_list: [],
            pointmall_order: [],
            trade_request: [],
            user_reported: []
        },
    }),

    mounted(){
        // 메인 관리 > 문의 관리
        this.load_main_contact()

        // 카드 관리 > 카드 리뷰 관리
        this.load_card_review()

        // 카드 거래 관리 > 카드 가격 관리
        this.load_trade_price()

        // 제보 관리 > 이미지 등록 관리
        this.load_provide_image()

        // 제보 관리 > 정보 입력/수정 관리
        this.load_provide_info()

        // 제보 관리 > 오류제보 관리
        this.load_provide_bug()

        // 포인트 관리 > 포인트 내역 관리
        this.load_point_list()

        // 포인트몰 관리 > 주문 관리
        this.load_pointmall_order()

        // 카드거래 관리 > 수정요청 관리
        this.load_trade_request()
        
        // 회원 관리 > 신고 목록
        this.load_user_reported()
    },

    methods: {
        // 메인 관리 > 문의 관리
        load_main_contact(){
            this.$http.post('/api/admin/main/contact/list')
            .then((res) => {
                this.list.main_contact = res.data.slice(0,5)
            })
        },

        // 카드 관리 > 카드 리뷰 관리
        load_card_review(){
            this.$http.post('/api/admin/card/review/list')
            .then(res => {
                this.list.card_review = res.data.slice(0,5)
            })
        },

        // 카드 거래 관리 > 카드 가격 관리
        load_trade_price(){
            this.$http.post('/api/admin/trade/price/select/all')
            .then(res => {
                this.list.trade_price = res.data.slice(0,5)
            })
        },

        // 제보 관리 > 이미지 등록 관리
        load_provide_image(){
            this.$http.post('/api/admin/provide/image/list/registered')
            .then(res => {
                this.list.provide_image = res.data.slice(0,5)
            })
        },

        // 제보 관리 > 정보 입력/수정 관리
        load_provide_info(){
            this.$http.post('/api/admin/provide/info/list/registered')
            .then(res => {
                this.list.provide_info = res.data.slice(0,5)
            })
        },

        // 제보 관리 > 오류제보 관리
        load_provide_bug(){
            this.$http.post('/api/admin/provide/bug/list/registered')
            .then(res => {
                this.list.provide_bug = res.data.slice(0,5)
            })
        },

        // 포인트 관리 > 포인트 내역 관리
        load_point_list(){
            this.$http.get('/api/admin/point/list')
            .then(res => {
                this.list.point_list = res.data.slice(0,5)
            })
        },

        // 포인트몰 관리 > 주문 관리
        load_pointmall_order(){
            this.$http.post('/api/admin/pointmall/order/list')
            .then(res => {
                this.list.pointmall_order = res.data.slice(0,5)
            })
        },

        // 카드거래 관리 > 수정요청 관리
        load_trade_request(){
            this.$http.post('/api/admin/trade/request/list', {
                params: {
                    status: "접수"
                }
            }).then((res) => {
                this.list.trade_request = res.data.slice(0,5)
            })
        },

        // 회원 관리 > 신고 목록
        load_user_reported(){
            this.$http.post('/api/admin/user/reported/list')
            .then((res) => {
                this.list.user_reported = res.data.slice(0,5)
            })
        },

    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>