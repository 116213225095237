<template>
    <div>
        <!-- 제목 -->
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">공식대회 리그유형 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 목록 -->
            <v-radio-group v-model="selected">
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="list"
                    :footer-props="{'items-per-page-options': [12, 20, 100, -1]}"
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:45px;">
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <td style="width:100%"><font class="d-block text-truncate">{{item.league_type}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 선택항목 & 버튼 -->
            <v-card outlined class="mt-2 pa-2 d-flex justify-end">
                <v-btn
                    class="px-6 mr-4"
                    disabled
                    large
                >
                    선택항목: {{selectedItem.league_type}}
                </v-btn>
                <v-btn
                    class="px-6"
                    color="orange"
                    large
                    dark
                    depressed
                    @click="updateOpen()"
                >
                    <v-icon small left>mdi-database-edit-outline</v-icon>
                    수정하기
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="blue"
                    large
                    dark
                    depressed
                    @click="deleteItem()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    삭제하기
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="blue"
                    large
                    dark
                    depressed
                    @click="dialog.create = true"
                >
                    <v-icon small left>mdi-database-plus-outline</v-icon>
                    추가하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- 수정하기 (dialog) -->
        <v-dialog
            v-model="dialog.update"
            width="auto"
            content-class="rounded-15"
        >
            <v-sheet class="py-4 px-6">
                <!-- 제목 -->
                <p class="mb-4 text-h6 font-weight-bold text-center">
                    리그유형 수정하기
                </p>

                <!-- 테이블 -->
                <v-sheet>
                    <table class="updatetable">
                        <tr>
                            <th style="width:54px;"></th>
                            <th style="width:300px;">리그유형</th>
                        </tr>
                        <tr>
                            <td style="width:54px;">기존</td>
                            <td style="width:300px;">{{selectedItem.league_type}}</td>
                        </tr>
                        <tr v-if="selectedAfter">
                            <td style="width:54px;">변경</td>
                            <td style="width:300px;">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    style="min-width:200px;"
                                    v-model="selectedAfter.league_type"
                                ></v-text-field>
                            </td>
                        </tr>
                    </table>
                </v-sheet>

                <!-- 버튼 -->
                <v-sheet class="mt-4 d-flex justify-center">
                    <v-btn
                        class="rounded-10 px-6 mr-3"
                        color="blue"
                        large
                        dark
                        depressed
                        @click="updateSubmit()"
                    >
                        <v-icon small left>mdi-database-edit-outline</v-icon>
                        수정하기
                    </v-btn>
                    <v-btn
                        class="rounded-10 px-6"
                        color="grey"
                        large
                        dark
                        depressed
                        @click="dialog.update=false"
                    >
                        <v-icon small left>mdi-cancel</v-icon>
                        취소하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>

        <!-- 추가하기 (dialog) -->
        <v-dialog
            v-model="dialog.create"
            width="auto"
            content-class="rounded-15"
        >
            <v-sheet class="py-4 px-6">
                <!-- 제목 -->
                <p class="mb-4 text-h6 font-weight-bold text-center">
                    리그유형 추가하기
                </p>

                <!-- 테이블 -->
                <table class="updatetable">
                    <tr>
                        <th style="width:54px;"></th>
                        <th style="width:300px;">리그유형</th>
                    </tr>
                    <tr v-if="create">
                        <td style="width:54px;">추가</td>
                        <td style="width:300px;">
                            <v-text-field
                                outlined
                                dense
                                hide-details
                                style="min-width:200px;"
                                v-model="create.league_type"
                            ></v-text-field>
                        </td>
                    </tr>
                </table>

                <!-- 버튼 -->
                <v-sheet class="mt-4 d-flex justify-center">
                    <v-btn
                        class="rounded-10 px-6 mr-3"
                        color="blue"
                        large
                        dark
                        depressed
                        @click="createSubmit()"
                    >
                        <v-icon small left>mdi-database-edit-outline</v-icon>
                        추가하기
                    </v-btn>
                    <v-btn
                        class="rounded-10 px-6"
                        color="grey"
                        large
                        dark
                        depressed
                        @click="dialog.create=false"
                    >
                        <v-icon small left>mdi-cancel</v-icon>
                        취소하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '', value: ''},
            {text: '리그유형', value: 'league_type'},
        ],

        list: [],

        selected: false,
        selectedItem: {},
        selectedAfter: {},

        dialog: {
            update:false,
            create:false
        },

        create: {
            league_type: "",
        }
    }),

    mounted(){
        // 목록 불러오기
        this.load()
    },

    methods: {
        // 목록 불러오기
        load(){
            this.$http.get('/api/admin/league/official_league_type_list/list')
            .then((res) => {
                console.log(res)
                this.list = res.data
            })
        },

        // 선택시 값 업데이트
        selectUpdate(item){
            this.selectedItem.id = item.id
            this.selectedItem.league_type = item.league_type

            this.selectedAfter.id = item.id
            this.selectedAfter.league_type = item.league_type
        },

        // 리그유형 수정 open
        updateOpen(){
            if(Object.keys(this.selectedItem).length){
                this.dialog.update = true
            }else{
                alert("선택한 리그유형이 없습니다.")
            }
        },

        // 리그유형 수정 Submit
        updateSubmit(){
            this.$http.post('/api/admin/league/official_league_type_list/update', {
                params: {
                    before: this.selectedItem,
                    after: this.selectedAfter
                }
            }).then((res) => {
                alert("해당 리그유형 정보가 수정되었습니다.")
                this.dialog.update = false
                this.selected = false
                this.selectedItem = {}
                this.selectedAfter = {}
                this.load()
            })
        },

        // 리그유형 추가 Submit
        createSubmit(){
            this.$http.post('/api/admin/league/official_league_type_list/create', {
                params: {
                    league_type: this.create.league_type,
                }
            }).then((res) => {
                alert("리그유형이 추가되었습니다.")
                this.dialog.create = false
                this.create = {}
                this.load()
            })
        },

        // 리그유형 삭제하기
        deleteItem(){
            if(!Object.keys(this.selectedItem).length){
                alert("선택한 리그유형이 없습니다.")
            }else{
                if(confirm('정말 해당 리그유형을 삭제하시겠습니까?')){
                    this.$http.post('/api/admin/league/official_league_type_list/delete', {
                        params: {
                            id: this.selectedItem.id,
                        }
                    }).then((res) => {
                        alert("해당 리그유형이 삭제되었습니다.")
                        this.load()
                    })
                }
            }
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}

.updatetable{
    border:1px solid #efefef;
    border-collapse: collapse;
}

.updatetable tr td{
    height:50px;
    padding:10px;
    border:1px solid #dedede;
}

.updatetable tr th{
    background:#f2f2f2;
    text-align: left;
    padding:10px;
    border:1px solid #dedede;
}
</style>