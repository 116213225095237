<template>
    <v-sheet color="transparent">
        <!-- 헤더 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">대량입력</font>
        </v-sheet>
        
        <!-- 본문 -->
        <v-sheet outlined class="pa-10 ma-2">
            <!-- 한글카드 -->
            <v-sheet
                class="d-flex"
            >
                <!-- card_kr -->
                <div
                    class="mr-10"
                >
                    <!-- API 입력 -->
                    <v-btn
                        style="width:200px;"
                        large
                        color="primary darken-1"
                        depressed
                        :disabled="!card_kr.length"
                        class="rounded-10 mb-4"
                        @click="update_card_kr()"
                    >
                        card_kr
                    </v-btn>

                    <!-- 파일 입력 -->
                    <v-file-input
                        style="width:200px;"
                        class="rounded-10 shrink"
                        prepend-icon=""
                        append-icon="mdi-file-outline"
                        outlined
                        label="card_kr 첨부"
                        v-model="card_kr_csv"
                        @change="handleFileUpload(card_kr_csv, 'card_kr')"
                    />
                </div>

                <!-- attacks_kr -->
                <div
                    class="mr-10"
                >
                    <!-- API 입력 -->
                    <v-btn
                        style="width:200px;"
                        large
                        color="primary darken-1"
                        depressed
                        :disabled="!attacks_kr.length"
                        class="rounded-10 mb-4"
                        @click="insert_attacks_kr()"
                    >
                        attacks_kr
                    </v-btn>

                    <!-- 파일 입력 -->
                    <v-file-input
                        style="width:200px;"
                        class="rounded-10 shrink"
                        prepend-icon=""
                        append-icon="mdi-file-outline"
                        outlined
                        label="attacks_kr 첨부"
                        v-model="attacks_kr_csv"
                        @change="handleFileUpload(attacks_kr_csv, 'attacks_kr')"
                    />
                </div>

                <!-- abilities_kr -->
                <div
                    class="mr-10"
                >
                    <!-- API 입력 -->
                    <v-btn
                        style="width:200px;"
                        large
                        color="primary darken-1"
                        depressed
                        :disabled="!abilities_kr.length"
                        class="rounded-10 mb-4"
                        @click="insert_abilities_kr()"
                    >
                        abilities_kr
                    </v-btn>

                    <!-- 파일 입력 -->
                    <v-file-input
                        style="width:200px;"
                        class="rounded-10 shrink"
                        prepend-icon=""
                        append-icon="mdi-file-outline"
                        outlined
                        label="abilities_kr 첨부"
                        v-model="abilities_kr_csv"
                        @change="handleFileUpload(abilities_kr_csv, 'abilities_kr')"
                    />
                </div>
            </v-sheet>

            <!-- 일어카드 -->
            <v-sheet
                class="d-flex"
            >
                <!-- card_jp -->
                <div
                    class="mr-10"
                >
                    <!-- API 입력 -->
                    <v-btn
                        style="width:200px;"
                        large
                        color="primary darken-1"
                        depressed
                        :disabled="!card_jp.length"
                        class="rounded-10 mb-4"
                        @click="update_card_jp()"
                    >
                        card_jp
                    </v-btn>

                    <!-- 파일 입력 -->
                    <v-file-input
                        style="width:200px;"
                        class="rounded-10 shrink"
                        prepend-icon=""
                        append-icon="mdi-file-outline"
                        outlined
                        label="card_jp 첨부"
                        v-model="card_jp_csv"
                        @change="handleFileUpload(card_jp_csv, 'card_jp')"
                    />
                </div>

                <!-- attacks_jp -->
                <div
                    class="mr-10"
                >
                    <!-- API 입력 -->
                    <v-btn
                        style="width:200px;"
                        large
                        color="primary darken-1"
                        depressed
                        :disabled="!attacks_jp.length"
                        class="rounded-10 mb-4"
                        @click="insert_attacks_jp()"
                    >
                        attacks_jp
                    </v-btn>

                    <!-- 파일 입력 -->
                    <v-file-input
                        style="width:200px;"
                        class="rounded-10 shrink"
                        prepend-icon=""
                        append-icon="mdi-file-outline"
                        outlined
                        label="attacks_jp 첨부"
                        v-model="attacks_jp_csv"
                        @change="handleFileUpload(attacks_jp_csv, 'attacks_jp')"
                    />
                </div>

                <!-- abilities_jp -->
                <div
                    class="mr-10"
                >
                    <!-- API 입력 -->
                    <v-btn
                        style="width:200px;"
                        large
                        color="primary darken-1"
                        depressed
                        :disabled="!abilities_jp.length"
                        class="rounded-10 mb-4"
                        @click="insert_abilities_jp()"
                    >
                        abilities_jp
                    </v-btn>

                    <!-- 파일 입력 -->
                    <v-file-input
                        style="width:200px;"
                        class="rounded-10 shrink"
                        prepend-icon=""
                        append-icon="mdi-file-outline"
                        outlined
                        label="abilities_jp 첨부"
                        v-model="abilities_jp_csv"
                        @change="handleFileUpload(abilities_jp_csv, 'abilities_jp')"
                    />
                </div>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>
import Papa from 'papaparse'

export default {
    data: () => ({
        card_kr_csv: null,
        attacks_kr_csv: null,
        abilities_kr_csv: null,

        card_kr: [],
        attacks_kr: [],
        abilities_kr: [],

        card_jp_csv: null,
        attacks_jp_csv: null,
        abilities_jp_csv: null,

        card_jp: [],
        attacks_jp: [],
        abilities_jp: [],
    }),

    methods: {
        handleFileUpload(file, type) {
            if (file) {
                Papa.parse(file, {
                    complete: (result) => {
                        console.log(result.data)
                        if(type == "card_kr")
                        {
                            this.card_kr = result.data
                        }
                        else if(type == "attacks_kr")
                        {
                            this.attacks_kr = result.data
                        }
                        else if(type == "abilities_kr")
                        {
                            this.abilities_kr = result.data
                        }

                        else if(type == "card_jp")
                        {
                            if (result.data && result.data.length > 0) {
                                result.data.pop()
                            }
                            this.card_jp = result.data
                        }
                        else if(type == "attacks_jp")
                        {
                            if (result.data && result.data.length > 0) {
                                result.data.pop()
                            }
                            this.attacks_jp = result.data
                        }
                        else if(type == "abilities_jp")
                        {
                            if (result.data && result.data.length > 0) {
                                result.data.pop()
                            }
                            this.abilities_jp = result.data
                        }
                    },
                    header: true
                })
            }
        },

        // update_card_kr
        update_card_kr(){
            let promises = []
            
            this.card_kr.forEach(item => {
                // 수정
                let updatePromise = this.$http.post("/api/admin/card/excel/update/card_kr", {
                    params: {
                        id: item.id,
                        name: item.name,
                        effects: item.effects,
                        rules: item.rules,
                        number: item.number,
                        rarity: item.rarity,
                        set_printedTotal: item.set_printedTotal,
                        set_name: item.set_name,
                        writer: "root"
                    }
                }).then((res) => {
                    console.log(res)
                })

                promises.push(updatePromise)
            })

            Promise.all(promises).then(() => {
                alert("모든 요청이 완료되었습니다.")
            })
        },

        // insert_attacks_kr
        insert_attacks_kr(){
            let deletePromises = []
            let insertPromises = []
            
            // 삭제 작업을 위한 Promise들 생성
            this.attacks_kr.forEach(item => {
                let deletePromise = this.$http.post("/api/admin/card/excel/delete/attacks_kr", {
                    params: {
                        card_id: item.card_id
                    }
                }).then((res) => {
                    console.log(res)
                });
                deletePromises.push(deletePromise)
            });

            // 모든 삭제 작업이 완료되면 삽입 작업 시작
            Promise.all(deletePromises).then(() => {
                this.attacks_kr.forEach(item => {
                    let insertPromise = this.$http.post("/api/admin/card/excel/insert/attacks_kr", {
                        params: {
                            card_id: item.card_id,
                            name: item.name,
                            cost: this.convertTypes(item.cost),
                            convertedEnergyCost: item.convertedEnergyCost,
                            damage: item.damage,
                            text: item.text,
                        }
                    }).then((res) => {
                        console.log(res)
                    })
                    insertPromises.push(insertPromise)
                })

                // 모든 삽입 작업이 완료되면 알림 표시
                Promise.all(insertPromises).then(() => {
                    alert("모든 요청이 완료되었습니다.")
                })
            })
        },

        // insert_abilities_kr
        insert_abilities_kr(){
            let deletePromises = []
            let insertPromises = []

            // 삭제 작업을 위한 Promise들 생성
            this.abilities_kr.forEach(item => {
                let deletePromise = this.$http.post("/api/admin/card/excel/delete/abilities_kr", {
                    params: {
                        card_id: item.card_id,
                    }
                }).then((res) => {
                    console.log(res)
                })
                deletePromises.push(deletePromise)
            })

            // 모든 삭제 작업이 완료되면 삽입 작업 시작
            Promise.all(deletePromises).then(() => {
                this.abilities_kr.forEach(item => {
                    let insertPromise = this.$http.post("/api/admin/card/excel/insert/abilities_kr", {
                        params: {
                            card_id: item.card_id,
                            type: item.type,
                            name: item.name,
                            text: item.text,
                        }
                    }).then((res) => {
                        console.log(res)
                    })
                    insertPromises.push(insertPromise)
                })

                // 모든 삽입 작업이 완료되면 알림 표시
                Promise.all(insertPromises).then(() => {
                    alert("모든 요청이 완료되었습니다.")
                })
            })
        },

        // update_card_jp
        update_card_jp(){
            let promises = []

            this.card_jp.forEach(item => {
                // 수정
                let updatePromise = this.$http.post("/api/admin/card/excel/update/card_jp", {
                    params: {
                        id: item.id,
                        name: item.name,
                        effects: item.effects,
                        rules: item.rules,
                        number: item.number,
                        rarity: item.rarity,
                        set_printedTotal: item.set_printedTotal,
                        set_name: item.set_name,
                        writer: "root"
                    }
                }).then((res) => {
                    console.log("update", res)
                })

                promises.push(updatePromise)
            })

            Promise.all(promises).then(() => {
                alert("모든 요청이 완료되었습니다.")
            })
        },

        // insert_attacks_jp
        insert_attacks_jp(){
            let deletePromises = []
            let insertPromises = []

            // 삭제 작업을 위한 Promise들 생성
            this.attacks_jp.forEach(item => {
                let deletePromise = this.$http.post("/api/admin/card/excel/delete/attacks_jp", {
                    params: {
                        card_id: item.card_id
                    }
                }).then((res) => {
                    console.log(res)
                });
                deletePromises.push(deletePromise)
            })

            // 모든 삭제 작업이 완료되면 삽입 작업 시작
            Promise.all(deletePromises).then(() => {
                this.attacks_jp.forEach(item => {
                    let insertPromise = this.$http.post("/api/admin/card/excel/insert/attacks_jp", {
                        params: {
                            card_id: item.card_id,
                            name: item.name,
                            cost: this.convertTypesJP(item.cost),
                            convertedEnergyCost: item.convertedEnergyCost,
                            damage: item.damage,
                            text: item.text,
                        }
                    }).then((res) => {
                        console.log(res)
                    })
                    insertPromises.push(insertPromise)
                })

                // 모든 삽입 작업이 완료되면 알림 표시
                Promise.all(insertPromises).then(() => {
                    alert("모든 요청이 완료되었습니다.")
                })
            })
        },

        // insert_abilities_jp
        insert_abilities_jp(){
            let deletePromises = []
            let insertPromises = []

            // 삭제 작업을 위한 Promise들 생성
            this.abilities_jp.forEach(item => {
                let deletePromise = this.$http.post("/api/admin/card/excel/delete/abilities_jp", {
                    params: {
                        card_id: item.card_id,
                    }
                }).then((res) => {
                    console.log(res)
                })
                deletePromises.push(deletePromise)
            })

            // 모든 삭제 작업이 완료되면 삽입 작업 시작
            Promise.all(deletePromises).then(() => {
                this.abilities_jp.forEach(item => {
                    let insertPromise = this.$http.post("/api/admin/card/excel/insert/abilities_jp", {
                        params: {
                            card_id: item.card_id,
                            type: item.type,
                            name: item.name,
                            text: item.text,
                        }
                    }).then((res) => {
                        console.log(res)
                    })
                    insertPromises.push(insertPromise)
                })

                // 모든 삽입 작업이 완료되면 알림 표시
                Promise.all(insertPromises).then(() => {
                    alert("모든 요청이 완료되었습니다.")
                })
            })
        },

        // type 변경하기
        convertTypes(type) {
            const typeMapping = {
                'type1': 'Grass',
                'type2': 'Fire',
                'type3': 'Water',
                'type4': 'Lightning',
                'type5': 'Psychic',
                'type6': 'Fighting',
                'type7': 'Darkness',
                'type8': 'Metal',
                'type9': 'Colorless'
            };

            for (let key in typeMapping) {
                type = type.replaceAll(key, typeMapping[key])
            }
            
            return type
        },

        // type 변경하기
        convertTypesJP(type) {
            const typeMapping = {
                'grass': 'Grass',
                'fire': 'Fire',
                'water': 'Water',
                'electric': 'Lightning',
                'psychic': 'Psychic',
                'fighting': 'Fighting',
                'dark': 'Darkness',
                'steel': 'Metal',
                'none': 'Colorless',
                'void': '0Cost'
            };

            for (let key in typeMapping) {
                type = type.replaceAll(key, typeMapping[key])
            }
            
            return type
        },
    }
}
</script> 