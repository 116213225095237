<template>
    <v-sheet color="transparent">
        <!-- 헤더 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">카드리뷰 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2">
                <v-sheet class="d-flex" width="660">
                    <v-sheet width="160" class="mr-3">
                        <v-select
                            outlined
                            hide-details
                            dense
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>
                    <v-text-field
                        v-model="keyword"
                        outlined
                        hide-details
                        dense
                        placeholder="검색어를 입력해주세요"
                        @keyup.enter="search()"
                    ></v-text-field>
                    <v-btn
                        height="40"
                        dark color="blue"
                        class="px-6 ml-3"
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-data-table
                id="list"
                :headers="headers"
                :items="list"
                :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
            >
                <template v-slot:item="{ item }">
                    <tr style="cursor:pointer" @click="$router.push('/card/detail?id='+item.card_id)">
                        <td style="width:160px"><font class="d-block text-truncate" style="width:160px;">{{item.card_id}}</font></td>
                        <td style="width:80px"><font class="d-block text-truncate" style="width:80px;">{{item.text_nation}}</font></td>
                        <td style="width:120px"><font class="d-block text-truncate" style="width:120px;">{{item.nickname}}</font></td>
                        <td style="width:120px">
                            <v-rating
                                v-if="item.rate_perform_check"
                                style="width:120px;"
                                v-model="item.rate_perform"
                                half-increments
                                background-color="grey"
                                color="blue"
                                small
                                readonly
                            ></v-rating>
                        </td>
                        <td style="width:120px">
                            <v-rating
                                v-if="item.rate_collect_check"
                                style="width:120px;"
                                v-model="item.rate_collect"
                                half-increments
                                background-color="grey"
                                color="blue"
                                small
                                readonly
                            ></v-rating>
                        </td>
                        <td style="width:100%">
                            <font class="d-block text-truncate" style="width:640px;">{{item.content}}</font>
                        </td>
                        <td style="width:200px">
                            <font class="d-block text-truncate" style="width:200px;">
                                {{new Date(item.created).toLocaleString()}}
                            </font>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '카드 ID', value: 'card_id'},
            {text: '국가', value: 'text_nation'},
            {text: '작성자 닉네임', value: 'nickname'},
            {text: '성능', value: 'rate_perform'},
            {text: '수집', value: 'rate_collect'},
            {text: '리뷰 내용', value: 'content'},
            {text: '작성 일자', value: 'created'}
        ],
        list: [],

        select_list: {
            search_type: [
                "카드 ID",
                "국가",
                "작성자 닉네임",
                "리뷰 내용",
                "작성 일자",
            ]   
        },

        search_type: "카드 ID",
        keyword: ""
    }),

    mounted(){
        this.$http.post('/api/admin/card/review/list')
        .then(res => {
            this.list = res.data
        })
    },

    methods: {
        // 검색
        search(){
            this.$http.post('/api/admin/card/review/search', {
                params: {
                    keyword: this.keyword,
                    type: this.search_type
                }
            }).then((res) => {
                this.list = res.data
            })
        },
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>