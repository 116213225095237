<template>
    <v-sheet>
        <table class="attacks_table mt-2">
            <thead>
                <tr>
                    <th colspan="7" class="blue lighten-5">기술 정보</th>
                </tr>
            </thead>
            <tbody v-for="(item, index) in attacks" :key="index">
                <tr>
                    <!-- 기술명 -->
                    <td class="name">기술명</td>
                    <td>
                        <v-text-field
                            hide-details outlined dense
                            v-model="item.name"
                            @change="attacksUpdated()"
                        ></v-text-field>
                    </td>

                    <!-- 비용 -->
                    <td class="name">비용</td>
                    <td>
                        <v-sheet class="d-flex justify-space-between">
                            <v-sheet
                                width="340"
                                min-height="40"
                                class="rounded pt-1"
                                style="border:1px solid #9e9e9e;"
                            >
                                <v-chip
                                    small
                                    class="my-1 ml-1 mr-0"
                                    dark
                                    color="blue"
                                    v-for='(type, index) in item.cost' :key="index"
                                >
                                    <span v-if="type=='Colorless'" style="color:white">무색</span>
                                    <span v-if="type=='Darkness'" style="color:white">악</span>
                                    <span v-if="type=='Dragon'" style="color:white">드래곤</span>
                                    <span v-if="type=='Fairy'" style="color:white">페어리</span>
                                    <span v-if="type=='Fighting'" style="color:white">격투</span>
                                    <span v-if="type=='Fire'" style="color:white">불꽃</span>
                                    <span v-if="type=='Grass'" style="color:white">풀</span>
                                    <span v-if="type=='Lightning'" style="color:white">번개</span>
                                    <span v-if="type=='Metal'" style="color:white">강철</span>
                                    <span v-if="type=='Psychic'" style="color:white">초</span>
                                    <span v-if="type=='Water'" style="color:white">물</span>
                                    <span v-if="type=='0Cost'" style="color:white">0코스트</span>
                                    <v-icon
                                        class='ml-2'
                                        color="white"
                                        small
                                        @click='item.cost.splice(index, 1), attacksUpdated()'
                                    >
                                        mdi-close
                                    </v-icon>
                                </v-chip>
                            </v-sheet>
                            <!-- 추가 버튼 -->
                            <v-sheet width="48" class="ml-1">
                                <v-menu :close-on-content-click="false">
                                    <template v-slot:activator='{ on }'>
                                        <v-btn x-small width="40" height="40" depressed class="blue white--text" v-on='on'>
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for='(type, index) in select_list.cost' :key="index"
                                            @click='item.cost.push(type.value), attacksUpdated()'
                                        >
                                            <v-list-item-title>{{ type.name }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-sheet>
                        </v-sheet>
                    </td>

                    <!-- 데미지 -->
                    <td class="name">데미지</td>
                    <td>
                        <v-text-field
                            hide-details outlined dense
                            v-model="item.damage"
                            @change="attacksUpdated()"
                        ></v-text-field>
                    </td>
                    <td rowspan="2" class="pa-0" style="width:100px;">
                        <v-btn
                            v-if="!index"
                            height="40"
                            small
                            color="blue"
                            dark
                            depressed
                            @click="addField()"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="index"
                            height="40"
                            small
                            color="blue"
                            dark
                            depressed
                            @click="subtractField(index)"
                        >
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>
                    </td>
                </tr>
                <tr>
                    <!-- 설명 -->
                    <td class="name">설명</td>
                    <td colspan="5">
                        <v-text-field
                            hide-details outlined dense
                            v-model="item.text"
                            @change="attacksUpdated()"
                        ></v-text-field>
                    </td>
                </tr>
            </tbody>
        </table>
    </v-sheet>
</template>
<script>
export default {
    props: [
        "card_id",
        "card_nation",
        "type"
    ],

    data: () => ({
        attacks: [
            {
                name: "",
                cost: [],
                damage: "",
                text: ""
            }
        ],

        select_list: {
            cost: [
                { name:"무색", value:"Colorless" },
                { name:"악", value:"Darkness" },
                { name:"드래곤", value:"Dragon" },
                { name:"페어리", value:"Fairy" },
                { name:"격투", value:"Fighting" },
                { name:"불꽃", value:"Fire" },
                { name:"풀", value:"Grass" },
                { name:"번개", value:"Lightning" },
                { name:"강철", value:"Metal" },
                { name:"초", value:"Psychic" },
                { name:"물", value:"Water" },
                { name:"0코스트", value:"0Cost" }
            ]
        }
    }),

    mounted(){
        if(this.type == "update" || this.type == "provide" || this.type == "read"){
            this.load()
        }
    },

    watch: {
        card_nation(){
            if(this.type == "update" || this.type == "provide" || this.type == "read"){
                this.load()
            }
        }
    },


    methods: {
        async load(){
            // 제보카드 읽기가 아닐 경우
            if(this.type!="read")
            {
                if(this.card_nation=='kr'){
                    this.$http.post('/api/admin/card/pre_card/import/card_kr/attacks', {
                        params: {
                            card_id : this.card_id
                        }
                    }).then((res) => {
                        // 한글카드 기술정보가 있을떄
                        if(res.data.length){
                            this.attacks = res.data
                            this.attacks.forEach(e => {
                                if(e.cost.length){
                                    e.cost = e.cost.split(',')
                                }else{
                                    e.cost = []
                                }
                            })
                            this.$emit("attacksUpdated", this.attacks)
                        // 한글카드 기술정보가 없을떄 (영어카드 기술정보 가져오기)
                        }else{
                            this.$http.post('/api/admin/card/pre_card/import/card_dump/attacks', {
                                params: {
                                    card_id : this.card_id
                                }
                            }).then((res) => {
                                if(JSON.parse(res.data[0].dump).attacks){
                                    this.attacks = JSON.parse(res.data[0].dump).attacks
                                    this.$emit("attacksUpdated", this.attacks)
                                }
                            })
                        }
                    })
                    
                }else if(this.card_nation=='jp'){
                    this.$http.post('/api/admin/card/pre_card/import/card_jp/attacks', {
                        params: {
                            card_id : this.card_id
                        }
                    }).then((res) => {
                        // 일본카드 기술정보가 있을떄
                        if(res.data.length){
                            this.attacks = res.data
                            this.attacks.forEach(e => {
                                if(e.cost.length){
                                    e.cost = e.cost.split(',')
                                }else{
                                    e.cost = []
                                }
                            })
                            this.$emit("attacksUpdated", this.attacks)
                        // 일본카드 기술정보가 없을떄 (영어카드 기술정보 가져오기)
                        }else{
                            this.$http.post('/api/admin/card/pre_card/import/card_dump/attacks', {
                                params: {
                                    card_id : this.card_id
                                }
                            }).then((res) => {
                                if(JSON.parse(res.data[0].dump).attacks){
                                    this.attacks = JSON.parse(res.data[0].dump).attacks
                                    this.$emit("attacksUpdated", this.attacks)
                                }
                            })
                        }
                    })
                }else if(this.card_nation=='en'){
                    this.$http.post('/api/admin/card/pre_card/import/card_dump/attacks', {
                        params: {
                            card_id : this.card_id
                        }
                    }).then((res) => {
                        if(JSON.parse(res.data[0].dump).attacks){
                            this.attacks = JSON.parse(res.data[0].dump).attacks
                            this.$emit("attacksUpdated", this.attacks)
                        }
                    })
                }
            }

            // 제보카드 읽기의 경우
            else if(this.type=="read")
            {
                this.$http.post('/api/admin/provide/info/import/card/attacks', {
                    params: {
                        card_provide_id : this.card_id
                    }
                }).then((res) => {
                    if(res.data.length){
                        this.attacks = res.data
                        this.attacks.forEach(e => {
                            if(e.cost.length){
                                e.cost = e.cost.split(',')
                            }
                        })
                        this.$emit("attacksUpdated", this.attacks)
                    }
                })
            }
        },

        // 기술 추가
        addField(){
            this.attacks.push(
                {
                    name: "",
                    cost: [],
                    damage: "",
                    text: ""
                }
            )
        },

        // 기술 빼기
        subtractField(index){
            this.attacks.splice(index,1)
            this.attacksUpdated()
        },

        // 능력 정보 업데이트
        attacksUpdated(){
            this.$emit("attacksUpdated", this.attacks)
        }
    }
}
</script>
<style scoped>
/* 기술 정보 테이블 */
.attacks_table{
    width:100%;
    border:1px solid #ccc;
    border-collapse: collapse;
    text-align:center;
}

/* 헤더 (기술 정보) */
.attacks_table tr th{
    height:44px;
    font-size:13pt;
    font-weight:bold;
}

/* TD */
.attacks_table tr td{
    font-size:11pt;
    height:40px;
    border:1px solid #ccc;
    padding: 6px;
}

/* 이름 TD */
.name{
    width:100px;
    background:#f2f3f4;
}
</style>