<template>
    <v-sheet color="transparent">
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">카드 자동번역</font>
        </v-sheet>
        
        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-5">
                <v-sheet class="d-flex" width="660">
                    <!-- 검색 TYPE -->
                    <v-sheet width="160" class="mr-3">
                        <v-select
                            outlined
                            hide-details
                            dense
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        v-model="keyword"
                        outlined
                        hide-details
                        dense
                        placeholder="검색어를 입력해주세요"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 검색 버튼 -->
                    <v-btn
                        height="40"
                        dark color="blue"
                        class="px-6 ml-3"
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-data-table
                id="list"
                :headers="headers"
                :items="list"
                :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                show-select
                v-model="selectedItem"
                @update:page="init_selectedItem()"
                @update:sort-desc="init_selectedItem()"
            >
                <template v-slot:item="{ item }">
                    <tr>
                        <td>
                            <v-checkbox
                                :value="item"
                                v-model="selectedItem"
                                style="margin:0px;padding:0px"
                                hide-details
                            />
                        </td>
                        <td @click="linkToCard(item)" style="width:160px;cursor:pointer;"><font class="d-block text-truncate" style="width:160px;">{{item.id!=null? item.id.replaceAll('"',''):''}}</font></td>
                        <td @click="linkToCard(item)" style="width:200px;cursor:pointer;"><font class="d-block text-truncate" style="width:200px;">{{item.name!=null? item.name.replaceAll('"',''):''}}</font></td>
                        <td @click="linkToCard(item)" style="width:180px;cursor:pointer;"><font class="d-block text-truncate" style="width:180px;">{{item.rarity!=null? item.rarity.replaceAll('"',''):''}}</font></td>
                        <td @click="linkToCard(item)" style="width:100px;cursor:pointer;"><font class="d-block text-truncate" style="width:100px;">{{item.number!=null? item.number.replaceAll('"',''):''}}</font></td>
                        <td @click="linkToCard(item)" style="width:100px;cursor:pointer;"><font class="d-block text-truncate" style="width:100px;">{{item.set_printedTotal!=null? item.set_printedTotal.replaceAll('"',''):''}}</font></td>
                        <td @click="linkToCard(item)" style="width:100%;cursor:pointer;"><font class="d-block text-truncate" style="width:460px;">{{item.set_name!=null? item.set_name.replaceAll('"',''):''}}</font></td>
                        <td @click="linkToCard(item)" style="width:160px;cursor:pointer;"><font class="d-block text-truncate" style="width:160px;">{{item.set_releaseDate!=null? item.set_releaseDate.replaceAll('"',''):''}}</font></td>
                    </tr>
                </template>
            </v-data-table>

            <!-- 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    v-if="selectedItem.length"
                    disabled
                    large
                    class="px-6 mr-4 text-lowercase"
                >
                    <p style="max-width:1000px; margin:0;" class="text-truncate">
                        선택항목: {{selectedItem.map(e => e.id.replaceAll('"', ''))}}
                    </p>
                </v-btn>
                <v-btn
                    large dark color="blue"
                    class="px-6 ml-3"
                    @click="translateButton()"
                >
                    <v-icon small left>mdi-database-plus-outline</v-icon>
                    자동번역하기
                </v-btn>
            </v-card>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        // 검색
        select_list: {
            search_type: [
                "ID",
                "영어이름",
                "희귀도",
                "블록넘버",
                "토탈넘버",
                "제품명",
                "제품명 출시일"
            ]
        },
        search_type: "ID",
        keyword: "",

        // 목록
        headers: [
            {text: 'ID', value: 'id'},
            {text: '영어이름', value: 'name'},
            {text: '희귀도', value: 'rarity'},
            {text: '블록넘버', value: 'number'},
            {text: '토탈넘버', value: 'set_printedTotal'},
            {text: '제품명', value: 'set_name'},
            {text: '제품명 출시일', value: 'set_releaseDate'}
        ],
        list: [],

        // 선택항목
        selectedItem: [],

        nameList: [],
        attackList: []
    }),

    mounted(){
        // 검색
        this.search()

        // 자동번역 (Name) 목록 가져오기
        this.loadNameList()
    },

    methods: {
        // 선택한 값 초기화
        init_selectedItem(){
            this.selectedItem = []
        },

        // 카드상세 페이지로 링크
        linkToCard(item){
            window.open('/card/detail?id='+item.id.replaceAll('"',''), '_blank')
        },

        // 검색
        search(){
            this.$http.post('/api/admin/card/translated/search', {
                params: {
                    keyword: this.keyword,
                    type: this.search_type
                }
            }).then((res) => {
                this.list = res.data
                this.init_selectedItem()
            })
        },

        // 자동번역 (Name) 목록 가져오기
        loadNameList(){
            this.$http.post('/api/admin/card/translate/list')
            .then((res) => {
                console.log(res)
                this.nameList = res.data
            })
        },

        // 선택한 값 검색
        translateButton(){
            if(!this.selectedItem.length){
                alert("선택한 카드가 없습니다.")
            }else{
                this.translateSubmit()
            }
        },

        // 이름 입력 (당시의 기술 입력 포함)
        async translateSubmit(){
            for (const item of this.selectedItem){
                // JSON parse
                let dump = JSON.parse(item.dump)

                // 이름 소문자 처리
                dump.name = dump.name.toLowerCase()

                await this.translateCard(dump, "kr")
                await this.translateCard(dump, "jp")

                // 희귀도 자동번역
                await this.translateRarity(dump)
            }
            await this.search()
            alert("번역이 완료되었습니다")
        },

        // 번역하기
        async translateCard(dump, card_nation){
            this.$http.post("/api/admin/card/translated/select", {
                params: {
                    id: dump.id,
                    card_nation: card_nation
                }
            }).then((res) => {
                // 카드 있음
                if(res.data.length)
                {
                    const koreanRegex = /[가-힣]/
                    const japaneseRegex = /[\u3040-\u30FF\u3400-\u4DBF\u4E00-\u9FFF\uF900-\uFAFF\uFF66-\uFF9F]/

                    // 한글 있으면 건너뛰기
                    if(card_nation == "kr")
                    {
                        if(koreanRegex.test(res.data[0].name)){
                            return
                        }
                    }

                    // 일어 있으면 건너뛰기
                    if(card_nation == "jp")
                    {
                        if(japaneseRegex.test(res.data[0].name)){
                            return
                        }
                    }

                    // 카드 입력
                    this.$http.post("/api/admin/card/translated/update", {
                        params: {
                            id: dump.id.toString(),
                            name: card_nation=='kr'? this.translateToKr(dump.name) : this.translateToJp(dump.name),
                            card_nation: card_nation
                        }
                    }).then((res) => {
                        console.log(res)
                    })
                }

                // 카드 없음
                else
                {
                    // 카드 입력
                    this.$http.post("/api/admin/card/translated/insert", {
                        params: {
                            id: dump.id.toString(),
                            name: card_nation=='kr'? this.translateToKr(dump.name) : this.translateToJp(dump.name),
                            card_nation: card_nation
                        }
                    }).then((res) => {
                        console.log(res)
                    })
                }
            })
        },

        // 희귀도 자동번역
        async translateRarity(dump){
            await this.$http.post('/api/admin/card/translated/rarity/search', {
                params: {
                    rarity_en: dump.rarity 
                }
            }).then((res) => {
                if(res.data.length){
                    this.$http.post('/api/admin/card/translated/rarity/update', {
                        params: {
                            id: dump.id.toString(),
                            rarity_kr: res.data[0].kr,
                            rarity_jp: res.data[0].jp
                        }
                    })
                }
            })
        },

        // 카드명 한글로 번역
        translateToKr(name) {
            for(let obj of this.nameList)
            {
                // 포함하면서 온전한 단어인지 확인
                // const wordRegex = new RegExp(`\\b${obj.en}\\b`, 'i')

                // 이름이 번역 키워드랑 완전히 일치하는 경우
                if(obj.en.toLowerCase() == name)
                {
                    return obj.kr
                }

                // 이름이 번역 키워드를 포함하면서 &기호는 없는 경우
                // else if(wordRegex.test(obj.en) && !name.includes('&'))
                // {
                //     return name.replace(obj.en, obj.kr)
                // }

                // 이름이 번역 키워드를 포함하면서 &기호가 있는 경우
                // else if(wordRegex.test(obj.en) && name.includes('&'))
                // {
                //     // 첫번째 이름 변경
                //     let translate = name.replace(obj.en, obj.kr)

                //     // & 기호 횟수만큼 이름 변경
                //     for(let i=0; i<name.split('&').length-1; i++)
                //     {
                //         for(let obj of this.nameList)
                //         {
                //             translate = translate.replace(obj.en, obj.kr)
                //         }
                //     }
                //     return translate
                // }
            }
            return name
        },

        // 카드명 일어로 번역
        translateToJp(name) {
            for(let obj of this.nameList)
            {
                // 포함하면서 온전한 단어인지 확인
                // const wordRegex = new RegExp(`\\b${obj.en}\\b`, 'i')

                // 이름이 번역 키워드랑 완전히 일치하는 경우
                if(obj.en.toLowerCase() == name)
                {
                    return obj.jp
                }

                // 이름이 번역 키워드를 포함하면서 &기호는 없는 경우
                // else if(wordRegex.test(obj.en) && !name.includes('&'))
                // {
                //     return name.replace(obj.en, obj.jp)
                // }

                // 이름이 번역 키워드를 포함하면서 &기호가 있는 경우
                // else if(wordRegex.test(obj.en) && name.includes('&'))
                // {
                //     // 첫번째 이름 변경
                //     let translate = name.replace(obj.en, obj.jp)

                //     // & 기호 횟수만큼 이름 변경
                //     for(let i=0; i<name.split('&').length-1; i++)
                //     {
                //         for(let obj of this.nameList)
                //         {
                //             translate = translate.replace(obj.en, obj.jp)
                //         }
                //     }
                //     return translate
                // }
            }
            return name
        },
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>