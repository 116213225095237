<template>
    <v-sheet class="transparent">
        <!--제목 -->
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">밴 회원 목록</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex" width="660">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="blue"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-radio-group v-model="selected">
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="list"
                    :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                    no-data-text="현재 데이터가 없습니다."
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:45px;">
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <td style="width:100%; cursor:pointer;"><font class="d-block text-truncate" style="width:160px;">{{item.user_id}}</font></td>
                            <td style="width:180px; cursor:pointer;"><font class="d-block text-truncate" style="width:180px;">{{item.nickname}}</font></td>
                            <td style="width:120px; cursor:pointer;"><font class="d-block text-truncate" style="width:120px;">{{item.point}}</font></td>
                            <td style="width:180px; cursor:pointer;"><font class="d-block text-truncate" style="width:180px;">{{item.email}}</font></td>
                            <td style="width:120px; cursor:pointer;"><font class="d-block text-truncate" style="width:120px;">{{item.status}}</font></td>
                            <td style="width:200px; cursor:pointer;"><font class="d-block text-truncate" style="width:200px;">{{new Date(item.created).toLocaleString()}}</font></td>
                            <td style="width:200px; cursor:pointer;"><font class="d-block text-truncate" style="width:200px;">{{new Date(item.ban_end_date).toLocaleString()}}</font></td>
                            <td style="width:200px; cursor:pointer;"><font class="d-block text-truncate" style="width:200px;">{{Math.floor((new Date(item.ban_end_date) - new Date(Date.now()))/(24*3600*1000))}}일</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 선택항목 & 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    class="px-6 mr-4 text-lowercase"
                    disabled
                    large
                    depressed
                >
                    선택항목: {{selectedItem.user_id}} ({{selectedItem.nickname}})
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="blue"
                    large
                    dark
                    depressed
                    @click="dialog.dispose = true"
                >
                    <v-icon small left>mdi-database-plus-outline</v-icon>
                    회원 벤하기
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="blue"
                    large
                    dark
                    depressed
                    @click="pardon_open()"
                >
                    <v-icon small left>mdi-database-plus-outline</v-icon>
                    사면하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- 회원 벤하기 Dialog -->
        <v-dialog v-model="dialog.dispose" width="auto" content-class="rounded-10">
            <v-sheet width="680" class="pa-4">
                <p class="font-weight-bold text-center text-h6 grey--text text--darken-2">
                    회원 벤하기
                </p>
                <v-sheet class="px-3">
                    <table class="disposeTable">
                        <tr>
                            <td>신고자</td>
                            <td>{{$store.state.user_id}}</td>
                        </tr>
                        <tr>
                            <td>신고 대상자</td>
                            <td>
                                <v-autocomplete
                                    dense
                                    outlined
                                    hide-details
                                    :items="select_list.user_id"
                                    placeholder="신고대상자 아이디를 선택해주세요"
                                    v-model="dispose_user_id"
                                ></v-autocomplete>
                            </td>
                        </tr>
                        <tr>
                            <td>처분</td>
                            <td>
                                <v-select
                                    dense
                                    outlined
                                    hide-details
                                    :items="select_list.dispose"
                                    placeholder="처분 일수를 선택해주세요"
                                    v-model="dispose_date"
                                    item-text="text"
                                    item-value="value"
                                ></v-select>
                            </td>
                        </tr>
                        <tr>
                            <td>벤 사유</td>
                            <td>
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    v-model="memo"
                                    placeholder="벤 사유"
                                ></v-text-field>
                            </td>
                        </tr>
                    </table>
                    <v-sheet class="mt-4 d-flex justify-center">
                        <v-btn
                            large
                            dark
                            depressed
                            color="blue"
                            class="px-6 mr-4"
                            @click="report_dispose_submit()"
                        >
                            <v-icon small left>mdi-database-edit-outline</v-icon>
                            신고 처리하기
                        </v-btn>
                        <v-btn
                            large
                            dark
                            depressed
                            color="grey"
                            class="px-6"
                            @click="dialog.dispose = false"
                        >
                            <v-icon small left>mdi-cancel</v-icon>
                            취소하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '', value: ''},
            {text: '회원 아이디', value: 'user_id'},
            {text: '닉네임', value: 'nickname'},
            {text: '포인트', value: 'point'},
            {text: '이메일 주소', value: 'email'},
            {text: '이메일 인증', value: 'status'},
            {text: '가입일자', value: 'created'},
            {text: '밴 종료일자', value: 'ban_end_date'},
            {text: '밴 종료 D-DAY', value: 'ban_end_date'}
        ],

        list: [],

        selected: false,
        selectedItem: {},

        select_list: {
            search_type: [
                "회원 아이디", 
                "닉네임", 
                "포인트",
                "이메일 주소",
                "이메일 인증",
                "가입일자",
                "밴 종료일자"
            ],

            dispose: [
                {text: "1일", value: 1},
                {text: "7일", value: 7},
                {text: "30일", value: 30},
                {text: "100일", value: 100},
                {text: "영구", value: 5000}
            ],

            user_id: []
        },

        search_type: "회원 아이디",
        keyword: "",

        dispose_user_id: "",
        dispose_date: null,
        memo: "",

        dialog: {
            dispose: false
        }
    }),

    mounted(){
        this.load()

        this.loadUser()

        this.checkbanned()
    },

    methods: {
        // 목록
        load(){
            this.$http.post('/api/admin/user/banned/list')
            .then((res) => {
                this.list = res.data
            })
        },

        // 유저 목록 가져오기
        loadUser(){
            this.$http.get('/api/admin/user/normal/list')
            .then((res) => {
                this.select_list.user_id = res.data.map(e => e.user_id)
            })
        },

        // 밴 체크
        checkbanned(){
            this.$http.post('/api/admin/user/banned/check/date')
            .then((res) => {
                console.log(res)
                this.load()
            })
        },

        // 선택하기
        selectUpdate(item){
            this.selectedItem = item
        },

        // 회원 벤하기
        report_dispose_submit(){
            this.$http.post('/api/admin/user/reported/insert', {
                params: {
                    reporter_user_id: this.$store.state.user_id,
                    reported_user_id: this.dispose_user_id,
                    type: "관리자",
                    reported_id: 0,
                    category: "관리자",
                    content: "관리자",
                    link: "/"
                }
            }).then((res) => {
                this.$http.post('/api/admin/user/banned/dispose', {
                    params: {
                        user_id: this.dispose_user_id,
                        report_id: res.data.insertId,
                        dispose_date: this.dispose_date,
                        memo: this.memo,
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        alert("해당 아이디가 정상적으로 밴되었습니다.")
                        this.load()
                        this.dialog.dispose = false
                    }
                })
            })
        },

        // 사면하기
        pardon_open(){
            if(!Object.keys(this.selectedItem).length){
                alert("선택하신 밴 회원가 없습니다.")
            }else{
                if(confirm("정말 해당 밴 회원를 사면하시겠습니까?")){
                    this.$http.post('/api/admin/user/banned/pardon', {
                        params: {
                            user_id: this.selectedItem.user_id,
                        }
                    }).then((res) => {
                        if(res.data.affectedRows){
                            alert("해당 밴 회원가 정상적으로 사면되었습니다.")
                            this.load()
                        }
                    })
                }
            }
        },

        // 검색
        search(){
            this.$http.post('/api/admin/user/banned/search', {
                params: {
                    keyword: this.keyword,
                    type: this.search_type
                }
            }).then((res) => {
                this.list = res.data
            })
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    width:85px;
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>
<style scoped>
.disposeTable{
    width: 100%;
    border: 1px solid #e0e0e0;
    border-collapse: collapse;
}

.disposeTable tr td{
    padding: 12px;
    border: 1px solid #e0e0e0;
}

.disposeTable tr td:first-child{
    width:160px;
    text-align:center;
    background:#f8f8f8;
    font-weight:bold;
}
</style>