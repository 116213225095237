<template>
    <v-sheet color="transparent">
        <!-- 헤더 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">신규카드 관리 (영어)</font>
        </v-sheet>
        
        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 목록 -->
            <v-sheet max-width="1587" class="d-flex pa-2 overflow-auto" v-if="set_list.length">
                <v-sheet
                    v-for="(item, index) in set_list" :key="index"
                    width="320"
                    class="py-4 px-6 mx-2 rounded-lg grey lighten-4"
                    style="cursor:pointer"
                    :style="selectedId == index ? 'border:1px solid #1976D2 !important;' : 'border:1px solid #ddd !important;'"
                    @click="select_set(item, index)"
                >
                    <v-sheet height="100" class="mb-4" color="transparent">
                        <v-img 
                            height="100"
                            contain
                            v-if="list.find(e => e.set.name == item)"
                            :src="list.find(e => e.set.name == item).set.images.logo"
                        ></v-img>
                    </v-sheet>
                    <p class="text-subtitle-1 font-weight-bold mb-0">{{item}}</p>
                    <p class="blue--text text-body-2 mb-0">카드 : {{list.filter(e => e.set.name == item).length}}개</p>
                    <p v-if="list.find(e => e.set.name == item)" class="text-caption mb-0">출시일 : {{list.find(e => e.set.name == item).set.releaseDate}}</p>
                </v-sheet>
            </v-sheet>
            <v-sheet class="pa-10" v-if="!set_list.length">
                <v-col class="py-12 text-center">
                    <v-icon x-large color="blue" class="mb-4">mdi-close-octagon-outline</v-icon>
                    <p class="blue--text">현재 API에 신규카드가 없습니다</p>
                </v-col>
            </v-sheet>
            <!-- 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn  
                    disabled
                    large
                    class="px-6 mr-4"
                >
                    선택항목: {{selectedItem}}
                </v-btn>
                <v-btn
                    large
                    dark
                    color="orange"
                    class="px-6 ml-3"
                    @click="dialog.delete = true"
                >
                    <v-icon small left>
                        mdi-database-remove-outline
                    </v-icon>
                    제품명 삭제하기
                </v-btn>
                <v-btn
                    :disabled="!loading"
                    :dark="loading"
                    large
                    color="blue"
                    class="px-6 ml-3"
                    @click="submitCopyToDB()"
                >
                    <v-icon small left>
                        mdi-database-plus-outline
                    </v-icon>
                    자체 DB에 추가하기
                </v-btn>
            </v-card>
        </v-sheet>

        <v-dialog v-model="dialog.delete" width="auto">
            <v-sheet width="720">
                <p class="pt-3 mb-3 font-weight-bold text-center text-subtitle-1 grey--text text--darken-2">
                    제품명 삭제하기
                </p>
                <v-divider></v-divider>
                <v-sheet class="pa-3">
                    <table class="disposeTable my-8">
                        <tr>
                            <td>제품명</td>
                            <td>
                                <v-autocomplete
                                    dense outlined hide-details
                                    placeholder="제품명 이름"
                                    :items="sets_selectList_en"
                                    v-model="set_name"
                                    @keyup.enter="submit()"
                                ></v-autocomplete>
                            </td>
                        </tr>
                    </table>
                    <v-sheet class="mt-2 pa-2 d-flex justify-center">
                        <v-btn large dark color="blue" class="px-6 mr-4" @click="delete_submit()">
                            <v-icon small left>mdi-database-remove-outline</v-icon>삭제하기
                        </v-btn>
                        <v-btn large dark color="grey" class="px-6" @click="dialog.delete=false">
                            <v-icon small left>mdi-cancel</v-icon>취소하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
import pokemon from 'pokemontcgsdk'
pokemon.configure({apiKey: 'bbcd32a1-e0f7-41ca-8712-77bace202b14'})

export default {
    data: () => ({
        // 신규 제품명 목록
        set_list: [],
        // 신규 카드 목록
        list: [],

        // 선택한 아이템
        selectedItem: "",
        selectedId: -1,
        selectedItemCard: [],

        loading:false,

        dialog: {
            delete: false
        },

        sets_selectList_en: [],
        set_name: ""
    }),

    mounted(){
        // 기본 목록
        this.loadList()

        // 영어 제품명 list 불러오기
        this.enList()
    },

    methods: {
        // 기본 목록
        loadList(){
            pokemon.set.all().then(res => {
                this.$http.post('/api/admin/card/new/set/list')
                .then((res2) => {
                    // API - DB = NEW
                    let API_set_list = res.map(e => e.name)
                    let DB_set_list = res2.data.map(e => e.name.replaceAll('"', ''))
                    let NEW_set_list = API_set_list.filter(e => DB_set_list.indexOf(e) == -1)

                    // Data에 추가
                    this.set_list = NEW_set_list;

                    // 카드갯수, 출시일 가져오기 (동기화 처리)
                    let count = 0
                    for (let i=0; i<this.set_list.length; i++){
                        pokemon.card.all({ q: '!set.name:"'+this.set_list[i]+'"' })
                        .then(res3 => {
                            this.list.push(...res3)

                            // 모든 신규 제품명 가져오기 전까지 disable
                            count += 1
                            if(count == this.set_list.length){
                                this.loading = true
                            }
                        })
                    }
                })
            })
        },

        // 영어 제품명 list 불러오기
        enList(){
            this.$http.post('/api/admin/card/new/set/en/list')
            .then(res => { 
                this.sets_selectList_en = res.data.map(a => a.name)
            })
        },

        // 선택한 제품명 표시
        select_set(item, index){
            if(this.loading){
                if(this.selectedId == index){
                    this.selectedItem = ''
                    this.selectedId = -1
                }else{
                    this.selectedItem = item
                    this.selectedId = index
                    this.selectedItemCard = this.list.filter(e => e.set.name == item)
                }
            }
        },

        // 자체 DB에 추가하기
        submitCopyToDB(){
            if(!Object.keys(this.selectedItem).length){
                alert("선택한 제품명이 없습니다.")
            }else{
                if(confirm("선택하신 제품명을 자체DB에 입력하시겠습니까?\n카드 갯수에 따라 몇분가량 소요될 수 있습니다.\n추가가 완료되었다는 문구가 표시되기 전 새로고침 또는\n페이지 이동을 하시면 오류가 발생할 수 있습니다.")){
                    // 제품명 입력
                    this.$http.post('/api/admin/card/new/set/add', {
                        params: {
                            name: this.selectedItem,
                            totalNumber: '000',
                            release_datetime: this.list.find(e => e.set.name == this.selectedItem).set.releaseDate
                        }
                    }).then((res) => {
                        console.log(res)
                    })

                    // 카드 입력
                    for(let e=0; e < this.selectedItemCard.length; e++){
                        this.$http.post('/api/admin/card/new/copyToDB', {
                            params: {
                                item: this.selectedItemCard[e],
                                dump: JSON.stringify(this.selectedItemCard[e])
                            }
                        }).then((res) => {
                            this.convertURLtoFile(this.selectedItemCard[e].images.small, this.selectedItemCard[e].id, e)
                        })
                    }
                }
            }
        },

        // 자체 이미지 저장
        async convertURLtoFile(url, id, e){
            url = await url.replaceAll("\"", "")
            const response = await fetch(url);
            const data = await response.blob();
            const ext = url.split(".").pop(); // url 구조에 맞게 수정할 것
            const filename = url.replace("https://images.pokemontcg.io/", "").replace("/", "-")
            const metadata = { type: `image/${ext}` };
            var file = await new File([data], filename, metadata)
            var formData = await new FormData();
            await formData.append("image", file);

            await this.$http.post('/api/admin/card/new/image', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                // 자체 이미지 파일명 입력
                this.$http.post('/api/admin/card/new/image_insert', {
                    params: {
                        image: res.data,
                        id:id,
                    }
                }).then((res) => {
                    if(e == this.selectedItemCard.length -1){
                        alert("선택하신 제품명이 자체 DB에 추가되었습니다")
                        this.loadList()
                    }
                })
            })
        },

        // 제품명 삭제하기 Submit
        delete_submit(){
            if(this.set_name==""){
                alert("업데이트할 제품명을 선택해주세요.")
            }else{
                this.$http.post('/api/admin/card/new/delete', {
                    params: {
                        set_name: this.set_name
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        alert("선택하신 제품명이 정상적으로 삭제되었습니다.")
                        this.dialog.delete = false
                        this.loadList()
                        this.enList()
                    }
                })
            }
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}
</style>
<style scoped>
.disposeTable{
    width: 100%;
    border: 1px solid #e0e0e0;
    border-collapse: collapse;
}

.disposeTable tr td{
    padding: 12px;
    border: 1px solid #e0e0e0;
}

.disposeTable tr td:first-child{
    width:160px;
    text-align:center;
    background:#f8f8f8;
    font-weight:bold;
}
</style>