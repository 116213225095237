<template>
    <v-sheet
        v-if="!$route.path.startsWith('/admin/login') || !$route.path.startsWith('/admin/login')"
        style="background:#242f46;"
        class="pa-0"
        width="280"
        min-width="280"
    >
        <v-sheet
            color="transparent"
            class="pt-5 pb-4 mx-auto"
        >
            <v-img
                :src="require('@/assets/logos/admin.svg')"
                contain width="100" class="mx-auto"
                style="cursor:pointer"
                @click="$router.push('/admin/dashboard')"
            />
            <p class="mt-1 text-center text-caption grey--text text--lighten-2">관리자 페이지</p>
            <v-btn 
                to="/"
                width="140"
                class="d-flex rounded-lg mx-auto mb-2" 
                small outlined color="grey lighten-2"
            >
                메인 페이지
            </v-btn>
            <v-btn 
                to="/auth/logout"
                width="140"
                class="d-flex rounded-lg mx-auto" 
                small outlined color="grey lighten-1"
            >
                관리자 로그아웃
            </v-btn>
        </v-sheet>
        <v-divider color="grey" class="mx-2"></v-divider>
        <v-sheet class="mr-2">
            <v-list color="#242f46" tile expand>
                <v-list-group
                    v-for="item in items"
                    :key="item.title"
                    v-model="item.active"
                    dense color="orange"
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title class="d-flex align-center">
                                <v-icon small class="mr-2 orange--text">{{item.icon}}</v-icon>
                                <font class="text-subtitle-1 orange--text">{{item.title}}</font>
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <template v-slot:appendIcon>
                        <v-icon v-if="!item" class="orange--text">mdi-chevron-up</v-icon>
                        <v-icon v-if="item" class="orange--text">mdi-chevron-down</v-icon>
                    </template>
                    <v-list-item
                        v-for="child in item.items"
                        :key="child.title"
                        :to="child.link"
                        dense
                        class="white--text pl-10"
                    >
                        <v-list-item-content>
                            <v-list-item-title v-text="child.title"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        items: []
    }),

    mounted(){
        if(this.$store.state.type == "관리자")
        {
            this.items = [
                {
                    title: '메인 관리',
                    icon: 'mdi-home',
                    active: true,
                    items: [
                        { title: '문의 관리', link: '/admin/main/contact' },
                        { title: 'DB알림 관리', link: '/admin/main/alarm' },
                        { title: '접속로그 관리', link: '/admin/main/visited_log' },
                    ]
                },
                {
                    title: '배너 관리',
                    icon: 'mdi-image-area',
                    active: true,
                    items: [
                        { title: '광고배너 관리', link: '/admin/banner/ads' },
                        { title: '메인배너 관리', link: '/admin/banner/main' },
                    ]
                },
                {
                    title: '카드 관리',
                    icon: 'mdi-cards',
                    active: true,
                    items: [
                        { title: '카드 삭제', link: '/admin/card/delete' },
                        { title: 'DUMP 데이터 추출', link: '/admin/card/dump' },
                        { title: '신규카드 관리 (영어)', link: '/admin/card/new' },
                        { title: '신규카드 관리 (일어)', link: '/admin/card/new_api' },
                        { title: '대량입력', link: '/admin/card/excel' },
                        { title: '대량복사', link: '/admin/card/copy' },
                        { title: '자동번역데이터 관리', link: '/admin/card/translate' },
                        { title: '카드 자동번역', link: '/admin/card/translated' },
                        { title: '선입력카드 관리', link: '/admin/card/pre_card' },
                        { title: '이미지매칭 관리', link: '/admin/card/image_match' },
                        { title: '제품명 관리', link: '/admin/card/set' },
                        { title: '카드리뷰 관리', link: '/admin/card/review' },
                    ]
                },
                {
                    title: '카드검색 관리',
                    icon: 'mdi-magnify',
                    active: true,
                    items: [
                        { title: '카드검색 기본값', link: '/admin/search/basic' },
                        { title: '희귀도 관리', link: '/admin/search/rarity' },
                        { title: '카드종류 관리', link: '/admin/search/supertype' },
                    ]
                },
                {
                    title: '카드거래 관리',
                    icon: 'mdi-cards-outline',
                    active: true,
                    items: [
                        { title: '기타상품 관리', link: '/admin/trade/etc' },
                        { title: '카드 가격그룹 관리', link: '/admin/trade/group' },
                        { title: '카드가격 관리자 입력', link: '/admin/trade/price' },
                        { title: '기본양식 설정', link: '/admin/trade/notice' },
                        { title: '수정요청 관리', link: '/admin/trade/request' },
                    ]
                },
                {
                    title: '리그 관리',
                    icon: 'mdi-cards-playing-outline',
                    active: true,
                    items: [
                        { title: '덱 자동분류 관리', link: '/admin/league/auto' },
                        { title: '덱 이름번역 관리', link: '/admin/league/deck_name' },
                        { title: '공식대회 리그유형 관리', link: '/admin/league/official_league_type' },
                        { title: 'UID 연결신청 관리', link: '/admin/league/uid_apply' },
                    ]
                },
                {
                    title: '덱레시피 관리',
                    icon: 'mdi-cards-playing-outline',
                    active: true,
                    items: [
                        { title: '공지 관리', link: '/admin/deck/notice' },
                        { title: '태그 관리', link: '/admin/deck/tag' },
                        { title: '시즌 관리', link: '/admin/deck/season' },
                        { title: '레귤레이션 관리', link: '/admin/deck/regulation_mark' },
                        { title: '재수록카드 관리', link: '/admin/deck/renewal' },
                        { title: 'PTCGL 덱 코드 관리', link: '/admin/deck/ptcgl' },
                    ]
                },
                {
                    title: '제보 관리',
                    icon: 'mdi-cards',
                    active: true,
                    items: [
                        { title: '공식대회 오류제보 관리', link: '/admin/provide/official_league' },
                        { title: '이미지 등록 관리', link: '/admin/provide/image' },
                        { title: '정보 입력/수정 관리', link: '/admin/provide/info' },
                        { title: '오류제보 관리', link: '/admin/provide/bug' },
                    ]
                },
                {
                    title: '포인트 관리',
                    icon: 'mdi-currency-usd',
                    action: 'mdi-cards-outline',
                    active: true,
                    items: [
                        { title: '포인트 지급 기준 관리', link: '/admin/point/setting' },
                        { title: '포인트 내역', link: '/admin/point/list' },
                    ]
                },
                {
                    title: '포인트몰 관리',
                    icon: 'mdi-storefront-outline',
                    active: true,
                    items: [
                        { title: '이벤트 배너 관리', link: '/admin/pointmall/banner' },
                        { title: '상품 관리', link: '/admin/pointmall/product' },
                        { title: '주문 관리', link: '/admin/pointmall/order' },
                        { title: '안내문구 관리', link: '/admin/pointmall/guide' },
                    ]
                },
                {
                    title: '회원 관리',
                    icon: 'mdi-account',
                    active: true,
                    items: [
                        { title: '회원 목록', link: '/admin/user/normal' },
                        { title: '신고 목록', link: '/admin/user/reported' },
                        { title: '처분 로그', link: '/admin/user/ban_log' },
                        { title: '밴 회원 목록', link: '/admin/user/banned' },
                        { title: '밴 IP 목록', link: '/admin/user/ban_ip' },
                    ]
                },
                {
                    title: '게시판 관리',
                    icon: 'mdi-clipboard-list-outline',
                    active: true,
                    items: [
                        { title: '카테고리 관리', link: '/admin/board/category' },
                        { title: '게시글 관리', link: '/admin/board/post' },
                        { title: '블라인드 게시글 관리', link: '/admin/board/blinded_post' },
                        { title: '댓글 관리', link: '/admin/board/comment' },
                        { title: '댓글 기본문구 설정', link: '/admin/board/ads' },
                        { title: 'DB요청 게시판 공지 관리', link: '/admin/board/db_request' },
                    ]
                },
                {
                    title: '직원 관리',
                    icon: 'mdi-account-cowboy-hat-outline',
                    active: true,
                    items: [
                        { title: '테스터 목록', link: '/admin/manager/tester' },
                        { title: '직원 목록', link: '/admin/manager/list' },
                        { title: '직원 활동로그', link: '/admin/manager/log' },
                    ]
                }
            ]
        }
        else if(this.$store.state.type == "운영진")
        {
            this.items = [
                {
                    title: '카드 관리',
                    icon: 'mdi-cards',
                    active: true,
                    items: [
                        { title: '선입력카드 관리', link: '/admin/card/pre_card' },
                        { title: '제품명 관리', link: '/admin/card/set' },
                    ]
                }
            ]
        }
    }
}
</script>