<template>
    <v-sheet class="transparent">
        <v-sheet outlined class="ma-2 pa-4">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">레귤레이션 관리</font>
        </v-sheet>
        <v-sheet outlined class="pa-2 ma-2">
            <v-radio-group v-model="selected">
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="list"
                    :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                    no-data-text="현재 데이터가 없습니다."
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:45px;">
                                <v-radio @change="selectUpdate(item)" :value="item" dense hide-details></v-radio>
                            </td>
                            <td style="width:70%"><font class="d-block text-truncate" style="width:70%">{{item.regulation_mark}}</font></td>
                            <td style="width:30%">
                                <v-select
                                    style="width:140px;"
                                    hide-details
                                    outlined
                                    dense
                                    :items="select_list.type"
                                    v-model="item.type"
                                    @change="update_type(item)"
                                ></v-select>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    disabled
                    large
                    class="px-6 mr-4 text-lowercase"
                >
                    선택항목: {{selectedItem.regulation_mark}}
                </v-btn>
                <v-btn
                    large dark color="blue"
                    class="px-8 ml-3"
                    @click="dialog.create = true"
                >
                    <v-icon small left>mdi-database-plus-outline</v-icon>
                    추가하기
                </v-btn>
                <v-btn
                    large dark color="orange"
                    class="px-8 ml-3"
                    @click="delete_submit()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    삭제하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- 레귤레이션 추가하기 Dialog -->
        <v-dialog v-model="dialog.create" width="auto">
            <v-sheet width="420">
                <p class="pt-3 mb-3 font-weight-bold text-center text-subtitle-1 grey--text text--darken-2">
                    레귤레이션 추가하기
                </p>
                <v-divider></v-divider>
                <v-sheet class="pa-3">
                    <table class="disposeTable">
                        <tr>
                            <td>레귤레이션</td>
                            <td>
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    v-model="create.regulation_mark"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>타입</td>
                            <td>
                                <v-select
                                    outlined
                                    dense
                                    hide-details
                                    :items="select_list.type"
                                    v-model="create.type"
                                ></v-select>
                            </td>
                        </tr>
                    </table>
                    <v-sheet class="mt-2 pa-2 d-flex justify-center">
                        <v-btn large dark color="blue" class="px-6 mr-4" @click="create_submit()">
                            <v-icon small left>mdi-database-edit-outline</v-icon>추가하기
                        </v-btn>
                        <v-btn large dark color="grey" class="px-6" @click="dialog.create = false">
                            <v-icon small left>mdi-cancel</v-icon>취소하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '', value: ''},
            {text: '레귤레이션', value: 'regulation_mark'},
            {text: '타입', value: 'type'},
        ],
        list: [],

        select_list: {
            type: [
                "익스펜디드",
                "스탠다드"
            ]
        },

        selected: false,
        selectedItem: {},

        dialog: {
            create: false
        },

        create: {
            regulation_mark: "",
            type: ""
        }
    }),

    mounted(){
        // 목록
        this.load()
    },

    methods: {
        // 목록
        load(){
            this.$http.post('/api/admin/card/regulation_mark/list').then((res) => {
                this.list = res.data
            })
        },

        // 선택한 값 저장
        selectUpdate(item){
            this.selectedItem = item
        },

        // 추가하기 제출
        create_submit(){
            if(this.create.regulation_mark==""){
                alert("추가하실 레귤레이션를 입력해주세요.")
            }else{
                this.$http.post('/api/admin/card/regulation_mark/insert', {
                    params: {
                        regulation_mark: this.create.regulation_mark,
                        type: this.create.type
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        alert("레귤레이션이 추가되었습니다.")
                        this.dialog.create = false
                        this.load()
                    }
                })
            }
        },

        // 타입 변경하기
        update_type(item){
            this.$http.post('/api/admin/card/regulation_mark/update/type', {
                params: {
                    type: item.type,
                    id: item.id
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    alert("타입이 변경되었습니다.")
                }
            })
        },

        delete_submit(){
            if(!Object.keys(this.selectedItem).length){
                alert("아직 선택하신 레귤레이션이 없습니다.")
            }else{
                this.$http.post('/api/admin/card/regulation_mark/delete', {
                    params: {
                        id: this.selectedItem.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        alert("레귤레이션이 삭제되었습니다.")
                        this.load()
                    }
                })
            }
        }
    }
}
</script>
<style>
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    width:85px;
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}

#selected_user{
    width:100%;
    border:1px solid #ccc;
    border-collapse: collapse;
}

#selected_user tr th{
    height:20px;
    padding:12px 20px;
    border:1px solid #ccc;
    background: #f7f7f7;
}

#selected_user tr td{
    height:20px;
    padding:12px 20px;
    border:1px solid #ccc;
}
</style>
<style scoped>
.disposeTable{
    width: 100%;
    border: 1px solid #e0e0e0;
    border-collapse: collapse;
}

.disposeTable tr td{
    padding: 12px;
    border: 1px solid #e0e0e0;
}

.disposeTable tr td:first-child{
    width:160px;
    text-align:center;
    background:#f8f8f8;
    font-weight:bold;
}
</style>