<template>
    <div>
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">카드 가격그룹 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-2">
                <v-sheet class="d-flex" width="500">
                    <!-- 유형 -->
                    <v-select
                        style="max-width:120px;"
                        class="mr-2"
                        outlined
                        hide-details
                        dense
                        item-text="name"
                        item-value="value"
                        :items="select_list.card_nation"
                        v-model="keyword.card_nation"
                    ></v-select>

                    <!-- 카드 ID -->
                    <v-text-field
                        v-model="keyword.card_id"
                        outlined
                        hide-details
                        dense
                        placeholder="카드 ID"
                        @keyup.enter="searchSameGroupCard()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="blue"
                        dark
                        depressed
                        @click="searchSameGroupCard()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-data-table
                id="list"
                :headers="headers"
                :items="list"
                :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                :no-data-text="!isSearched? '카드를 검색해주세요' : '해당 고유ID 또는 국가에 해당하는 카드가 없습니다'"
                :loading="loading"
            >
                <template v-slot:item="{ item }">
                    <tr>
                        <td style="width:80px" class="pa-2">
                            <v-img 
                                v-if="item.card_nation=='kr'"
                                width="80"
                                class="mx-auto"
                                :src="'/upload/card_kr_image/'+encodeURI(item.image)"
                            ></v-img>
                            <v-img 
                                v-if="item.card_nation=='en'"
                                width="80"
                                class="mx-auto"
                                :src="'/upload/card_en_image/'+encodeURI(item.image)"
                            ></v-img>
                            <v-img 
                                v-if="item.card_nation=='jp'"
                                width="80"
                                class="mx-auto"
                                :src="'/upload/card_jp_image/'+encodeURI(item.image)"
                            ></v-img>
                        </td>
                        <td @click="linkToDetail(item)" style="width:160px;cursor:pointer"><font class="d-block text-truncate" style="width:160px;">{{item.id}}</font></td>
                        <td @click="linkToDetail(item)" style="width:200px;cursor:pointer"><font class="d-block text-truncate" style="width:200px;">{{item.name}}</font></td>
                        <td @click="linkToDetail(item)" style="width:180px;cursor:pointer"><font class="d-block text-truncate" style="width:180px;">{{item.rarity}}</font></td>
                        <td @click="linkToDetail(item)" style="width:100px;cursor:pointer"><font class="d-block text-truncate" style="width:100px;">{{item.number}}</font></td>
                        <td @click="linkToDetail(item)" style="width:100px;cursor:pointer"><font class="d-block text-truncate" style="width:100px;">{{item.set_printedTotal}}</font></td>
                        <td @click="linkToDetail(item)" style="width:100%;cursor:pointer"><font class="d-block text-truncate" style="width:440px;">{{item.set_name}}</font></td>
                    </tr>
                </template>
            </v-data-table>
        </v-sheet>
    </div>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '이미지', value:'image'},
            {text: 'ID', value:'id'},
            {text: '이름', value:'name'},
            {text: '희귀도', value:'rarity'},
            {text: '블록넘버', value:'number'},
            {text: '토탈넘버', value:'set_printedTotal'},
            {text: '제품명', value:'set_name'}
        ],

        // 목록
        list: [],

        // 선택목록
        select_list: {
            card_nation: [
                {name: "한글", value: "kr"},
                {name: "영어", value: "en"},
                {name: "일어", value: "jp"}
            ],
        },

        keyword: {
            card_id: "",
            card_nation: "kr"
        },

        dialog: {
            update: false
        },

        isSearched: false,
        loading: false
    }),

    methods: {
        // 검색
        async searchSameGroupCard(){
            this.loading = true

            // 슈퍼타입 확인
            let supertype = ""
            await this.$http.post('/api/card/detail/trade/group/isPokemon', {
                params: {
                    card_id: this.keyword.card_id
                }
            }).then((res => {
                if(res.data.length){
                    supertype = res.data[0].supertype
                }
            }))

            // 시리즈, 이름, 희귀도 확인
            let card = ""
            await this.$http.post('/api/card/detail/trade/group/import/card/'+this.keyword.card_nation, {
                params: {
                    card_id: this.keyword.card_id
                }
            }).then((res => {
                console.log(res)
                card = res.data[0]
            }))

            if(card == undefined){
                this.list = []
                this.isSearched = true
                this.loading = false
                return
            }

            // 기술 확인
            let attacks = []
            await this.$http.post('/api/card/detail/trade/group/import/attacks/'+this.keyword.card_nation, {
                params: {
                    card_id: this.keyword.card_id
                }
            }).then((res => {
                if(this.keyword.card_nation == "en"){
                    attacks = JSON.parse(res.data[0].attacks).map(e => e.name)
                }else{
                    attacks = res.data.map(e => e.name)
                }
            }))

            // 시리즈, 이름, 희귀도 같은지 확인
            let idSameList = []
            await this.$http.post('/api/card/detail/trade/group/card/'+this.keyword.card_nation, {
                params: {
                    series : card.series,
                    name : card.name,
                    rarity : card.rarity
                }
            }).then( async(res) => {
                if(res.data.length){
                    idSameList = await res.data.map(e => e.id)
                }
                console.log(idSameList)
            })

            // 포켓몬일 경우 기술명 같은지 확인
            let attacksSameList = []
            if(supertype == "Pokémon")
            {
                for (let i=0; i<idSameList.length; i++){
                    await this.$http.post('/api/card/detail/trade/group/import/attacks/'+this.keyword.card_nation, {
                        params: {
                            card_id: idSameList[i]
                        }
                    }).then( async (res) => {
                        if(attacks.toString() == res.data.map(e => e.name).toString()){
                            attacksSameList.push(idSameList[i])
                        }
                    })
                }

                if(attacksSameList.length){
                    idSameList = attacksSameList
                    console.log(idSameList)
                }
            }

            // 최종 검색
            await this.$http.post('/api/admin/trade/group/idSameList/'+this.keyword.card_nation, {
                params: {
                    ids: idSameList,
                }
            }).then((res) => {
                this.list = res.data
                this.isSearched = true
                this.loading = false
            })
        },

        // 카드상세 페이지로 이동
        linkToDetail(item){
            window.open('/card/detail?id='+item.id, '_blank')
        },
    }
}
</script>