<template>
    <v-sheet>
        <v-sheet outlined class="d-flex align-center ma-2">
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">이벤트 배너 관리</font>
        </v-sheet>
        <v-sheet outlined class="pa-4 ma-2 pt-6">
            <!-- 이벤트 배너(1) 설정 -->
            <p class="text-h5 font-weight-bold mb-2">이벤트 배너 설정</p>
            <BannerSetting title="이벤트 배너" type="pointmall_event1" width="390" height="140" />

            <!-- 이벤트 배너(2) 설정 -->
            <p class="text-h5 font-weight-bold mt-6 mb-2">이벤트 배너 설정</p>
            <BannerSetting title="이벤트 배너" type="pointmall_event2" width="390" height="140" />
        </v-sheet>
    </v-sheet>
</template>
<script>
import BannerSetting from '@/components/BannerSetting'

export default {
    components: {
        BannerSetting
    }
}
</script>