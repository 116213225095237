<template>
    <v-sheet color="transparent">
        <!-- 헤더 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">신규카드 관리 (일어)</font>
        </v-sheet>
        
        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2" style="position:relative;">

            <!-- 목록 -->
            <v-radio-group v-model="selectedItem">
                <v-data-table
                    id="list"
                    :headers="headers"
                    :items="set_list"
                    :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                    no-data-text="현재 데이터가 없습니다."
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td style="width:45px;">
                                <v-radio :value="item" dense hide-details></v-radio>
                            </td>
                            <td style="width:80px">
                                <v-img
                                    height="32"
                                    width="60"
                                    contain
                                    :src="item.image_url"
                                ></v-img>
                            </td>
                            <td style="width:340px"><font class="d-block text-truncate" style="width:280px;">{{item.name}}</font></td>
                            <td style="width:120px"><font class="d-block text-truncate" style="width:120px;">{{item.card_count}}</font></td>
                            <td style="width:120px"><font class="d-block text-truncate" style="width:120px;">{{item.printed_count}}</font></td>
                            <td style="width:200px"><font class="d-block text-truncate" style="width:200px;">{{new Date(item.date).toLocaleDateString()}}</font></td>
                            <td style="width:140px">
                                <v-select
                                    style="width:140px;"
                                    hide-details
                                    outlined
                                    dense
                                    :items="select_list.status"
                                    v-model="item.status"
                                    @change="update_status(item)"
                                ></v-select>
                            </td>
                            <td style="width:280px">
                                <v-text-field
                                    style="width:260px;"
                                    outlined
                                    dense
                                    hide-details
                                    v-model="item.memo"
                                    @change="update_memo(item)"
                                ></v-text-field>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 로딩 -->
            <v-progress-circular
                v-if="loading"
                style="position:absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                indeterminate
                color="primary"
                size="100"
            ></v-progress-circular>

            <!-- 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn  
                    disabled
                    large
                    class="px-6 mr-4"
                >
                    선택항목: {{selectedItem.name}}
                </v-btn>

                <v-btn
                    large
                    dark
                    color="blue"
                    depressed
                    class="px-6 ml-3"
                    @click="openSelect()"
                >
                    <v-icon small left>
                        mdi-database-remove-outline
                    </v-icon>
                    선택 가져오기
                </v-btn>

                <v-btn
                    large
                    dark
                    color="orange"
                    depressed
                    class="px-6 ml-3"
                    @click="dialog.delete = true"
                >
                    <v-icon small left>
                        mdi-database-remove-outline
                    </v-icon>
                    제품명 삭제하기
                </v-btn>
                <v-btn
                    large
                    depressed
                    color="orange"
                    class="px-6 ml-3"
                    dark
                    @click="update_set_list()"
                >
                    <v-icon small left>
                        mdi-checkbox-marked-circle-outline
                    </v-icon>
                    새 제품명 확인하기
                </v-btn>
                <v-btn
                    large
                    depressed
                    color="blue"
                    class="px-6 ml-3 white--text"
                    :disabled="loading || selectedItem.status == '추가완료' || !selectedItem.id"
                    @click="insertDB()"
                >
                    <v-icon small left>
                        mdi-database-plus-outline
                    </v-icon>
                    자체 DB에 추가하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- 제품명 삭제하기 -->
        <v-dialog
            v-model="dialog.delete"
            width="auto"
        >
            <v-sheet width="720">
                <p class="pt-3 mb-3 font-weight-bold text-center text-subtitle-1 grey--text text--darken-2">
                    제품명 삭제하기
                </p>
                <v-divider></v-divider>
                <v-sheet class="pa-3">
                    <table class="disposeTable my-8">
                        <tr>
                            <td>제품명</td>
                            <td>
                                <v-autocomplete
                                    dense outlined hide-details
                                    placeholder="제품명 이름"
                                    :items="set_list"
                                    item-text="name"
                                    item-value="name"
                                    v-model="set_name"
                                ></v-autocomplete>
                            </td>
                        </tr>
                    </table>
                    <v-sheet class="mt-2 pa-2 d-flex justify-center">
                        <v-btn large dark color="blue" class="px-6 mr-4" @click="delete_submit()">
                            <v-icon small left>mdi-database-remove-outline</v-icon>삭제하기
                        </v-btn>
                        <v-btn large dark color="grey" class="px-6" @click="dialog.delete=false">
                            <v-icon small left>mdi-cancel</v-icon>취소하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-dialog>

        <!-- 선택 가져오기 -->
        <v-dialog
            v-model="dialog.select"
            width="auto"
            content-class="rounded-15"
        >
            <v-sheet
                width="720"
                class="rounded-15 pa-4"
            >
                <p class="pt-3 mb-2 font-weight-bold text-center text-h6 grey--text text--darken-2">
                    선택 가져오기
                </p>
                <v-sheet class="pa-3">
                    <table class="selectTable">
                        <tr
                            v-for="item in selectedCardList" :key="item.id"
                        >
                            <td
                                style="width:40px;"
                            >
                                <v-checkbox
                                    class="d-flex pl-2"
                                    :value="item"
                                    v-model="selectedCard"
                                    style="margin:0px;padding:0px"
                                    hide-details
                                />
                            </td>
                            <td>{{item.id}}</td>
                            <td>
                                <v-img
                                    class="mx-auto"
                                    width="60"
                                    :src="item.imageUrl"
                                ></v-img>
                            </td>
                            <td>
                                <p
                                    class="mb-0"
                                >
                                    {{item.name}}
                                </p>
                                <p
                                    class="mb-0 text-caption grey--text"
                                >
                                    {{item.artist}}
                                </p>
                            </td>
                            <td>{{item.sequenceNumber}}</td>
                        </tr>
                    </table>
                    <v-sheet class="mt-2 pa-2 d-flex justify-center">
                        <v-btn large dark color="blue" class="px-6 mr-4" @click="select_card_submit()">
                            <v-icon small left>mdi-database-remove-outline</v-icon>자체 DB 추가하기
                        </v-btn>
                        <v-btn large dark color="grey" class="px-6" @click="dialog.select=false">
                            <v-icon small left>mdi-cancel</v-icon>취소하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {text: '', value: ''},
            {text: '이미지', value: 'image_url'},
            {text: '제품명', value: 'name'},
            {text: '카드 수량', value: 'card_count'},
            {text: '토탈넘버', value: 'printed_count'},
            {text: '출시일자', value: 'date'},
            {text: '상태', value: 'status'},
            {text: '메모', value: 'memo'},
        ],

        select_list: {
            status: [
                "추가완료", 
                "추가안됨",
                "부분추가"
            ],
        },

        // 새 API 제품명 목록
        set_list: [],

        // 새 API 카드 목록
        card_list: [],

        // 선택한 아이템
        selected:false,
        selectedItem: "",
        selectedCardList: [],
        selectedCard: [],

        loading: false,

        set_name: "",

        dialog: {
            delete: false,
            select: false
        }
    }),

    mounted(){
        // 새 API 제품명 목록 불러오기
        this.loadList()

        // 자동번역 (Name) 목록 가져오기
        this.loadNameList()
    },

    methods: {
        // 선택 가져오기 열기
        openSelect(){
            this.dialog.select = true

            this.$http.get("https://www.jpn-cards.com/v2/card/set_id="+this.selectedItem.id+"&pageSize=10000") // (pageSize 없으면 250제한 (공식 문서 설명 없음))
            .then(async (res) => {
                this.selectedCardList = res.data.data
                console.log(this.selectedCardList)
            })
        },

        // 새 API 제품명 목록 불러오기
        loadList(){
            this.$http.post("/api/admin/card/new/api_list/select")
            .then((res) => {
                console.log("new_api_set_list", res.data)
                this.set_list = res.data.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
            })
        },

        // 자동번역 (Name) 목록 가져오기
        loadNameList(){
            this.$http.post('/api/admin/card/translate/list')
            .then((res) => {
                this.nameList = res.data
            })
        },

        // 자체 DB에 추가하기
        insertDB(){
            this.loading = true
            // 선택한 제품명의 카드 목록 가져오기
            this.$http.get("https://www.jpn-cards.com/v2/card/set_id="+this.selectedItem.id+"&pageSize=10000") // (pageSize 없으면 250제한 (공식 문서 설명 없음))
            .then(async (res) => {
                let new_card_list = res.data.data
                console.log("new_card_list", new_card_list)

                // 제품명 이미지 추출
                let upload_set_jp_image = await this.convertURLtoFile(this.selectedItem.image_url, "set_jp_image", this.sanitizeFileName(this.selectedItem.name))

                // 한글 제품명 입력
                this.$http.post('/api/admin/card/new/set/add/kr', {
                    params: {
                        name: this.selectedItem.name,
                        totalNumber: this.selectedItem.printed_count,
                        release_datetime: this.formatDateToMySQLDatetime(this.selectedItem.date)
                    }
                }).then((res) => {
                    console.log("한글 제품명 입력", res)
                })

                // 영어 제품명 입력
                this.$http.post('/api/admin/card/new/set/add', {
                    params: {
                        name: this.selectedItem.name,
                        totalNumber: this.selectedItem.printed_count,
                        release_datetime: this.formatDateToMySQLDatetime(this.selectedItem.date)
                    }
                }).then((res) => {
                    console.log("영어 제품명 입력", res)
                })

                // 일어 제품명 입력
                this.$http.post('/api/admin/card/new/set/add/jp/image', {
                    params: {
                        name: this.selectedItem.name,
                        image: upload_set_jp_image,
                        totalNumber: this.selectedItem.printed_count,
                        release_datetime: this.formatDateToMySQLDatetime(this.selectedItem.date)
                    }
                }).then((res) => {
                    console.log("일어 제품명 입력", res)
                })

                // 카드 정보 입력
                for (let i = 0; i < new_card_list.length; i++)
                {
                    const new_card = new_card_list[i]
                    const convertedData = this.convertToOldApiFormat(new_card)
                    let upload_card_jp_image = await this.convertURLtoFile(new_card.imageUrl, "card_jp_image", this.sanitizeFileName(new_card.name))

                    // card_dump 입력
                    this.$http.post('/api/admin/card/pre_card/insert/card_dump', {
                        params: {
                            id: new_card_list[i].id,
                            image: 'default.png',
                            dump: JSON.stringify(convertedData),
                            isAvailable: 0
                        }
                    }).then(res => {
                        console.log("card_dump 입력", res)
                    })
                    .catch((err) => {
                        console.error(err)
                    })

                    // basic 입력 (KR)
                    await this.$http.post('/api/admin/card/pre_card/insert/card_kr', {
                        params: {
                            id: convertedData.id,
                            name: this.translateToKr(convertedData.name),
                            ancientTrait_name: convertedData.ancientTrait_name,
                            ancientTrait_text: convertedData.ancientTrait_text,
                            effects: null,
                            rules: convertedData.rules.toString(),
                            image: "default.png",
                            number: convertedData.number,
                            rarity: convertedData.rarity,
                            set_printedTotal: convertedData.set.printedTotal.toString(),
                            set_name: convertedData.set.name,
                            writer: this.$store.state.user_id,
                            isAvailable: 1
                        }
                    }).then(res => {
                        console.log('basic 입력 (KR)', res)
                    })
                
                    // basic 입력 (JP)
                    await this.$http.post('/api/admin/card/pre_card/insert/card_jp', {
                        params: {
                            id: convertedData.id,
                            name: this.translateToJp(convertedData.name),
                            ancientTrait_name: convertedData.ancientTrait_name,
                            ancientTrait_text: convertedData.ancientTrait_text,
                            effects: null,
                            rules: convertedData.rules.toString(),
                            image: upload_card_jp_image,
                            number: convertedData.number,
                            rarity: convertedData.rarity,
                            set_printedTotal: convertedData.set.printedTotal.toString(),
                            set_name: convertedData.set.name,
                            writer: this.$store.state.user_id,
                            isAvailable: 1
                        }
                    }).then(res => {
                        console.log('basic 입력 (JP)', res)
                    })

                    // abilities 입력 (KR)
                    if(convertedData.abilities && convertedData.abilities.length){
                        for(let ability of convertedData.abilities){
                            await this.$http.post('/api/admin/card/pre_card/insert/card_kr/abilities', {
                                params: {
                                    card_id: convertedData.id,
                                    type: ability.type,
                                    name: ability.name,
                                    text: ability.text
                                }
                            }).then(res => {
                                console.log('abilities 입력 (KR)', res)
                            })
                        }
                    }

                    // attacks 입력 (KR)
                    if(convertedData.attacks && convertedData.attacks.length){
                        for(let attack of convertedData.attacks){
                            await this.$http.post('/api/admin/card/pre_card/insert/card_kr/attacks', {
                                params: {
                                    card_id: convertedData.id,
                                    name: attack.name,
                                    cost: attack.cost.toString(),
                                    convertedEnergyCost: attack.cost.length,
                                    damage: attack.damage,
                                    text: attack.text
                                }
                            }).then(res => {
                                console.log('attacks 입력 (KR)', res)
                            })
                        }
                    }

                    // abilities 입력 (JP)
                    if(convertedData.abilities && convertedData.abilities.length){
                        for(let ability of convertedData.abilities){
                            await this.$http.post('/api/admin/card/pre_card/insert/card_jp/abilities', {
                                params: {
                                    card_id: convertedData.id,
                                    type: ability.type,
                                    name: ability.name,
                                    text: ability.text
                                }
                            }).then(res => {
                                console.log('abilities 입력 (JP)', res)
                            })
                        }
                    }

                    // attacks 입력 (JP)
                    if(convertedData.attacks && convertedData.attacks.length){
                        for(let attack of convertedData.attacks){
                            await this.$http.post('/api/admin/card/pre_card/insert/card_jp/attacks', {
                                params: {
                                    card_id: convertedData.id,
                                    name: attack.name,
                                    cost: attack.cost.toString(),
                                    convertedEnergyCost: attack.cost.length,
                                    damage: attack.damage,
                                    text: attack.text
                                }
                            }).then(res => {
                                console.log('attacks 입력 (JP)', res)
                            })
                        }
                    }

                    if (i === new_card_list.length - 1) {
                        alert("자체 DB에 추가하기가 완료되었습니다")
                        this.loading = false
                    }
                }
            })
        },

        // 신규 API -> 기존 API로 구조 변경
        convertToOldApiFormat(newApiData) {
            const oldApiFormat = {
                id: newApiData.id,
                set: {
                    name: newApiData.setData.name,
                    total: newApiData.setData.card_count,
                    printedTotal: newApiData.setData.printed_count,
                    releaseDate: newApiData.setData.year == 'None'? "1970/01/01" : newApiData.setData.year+"/01/01"
                },
                name : newApiData.name,
                types : newApiData.types,
                hp : newApiData.hp == -1? null : newApiData.hp,
                evolvesFrom : newApiData.evolvesFrom == 'None'? '' : newApiData.evolvesFrom,
                // evolvesTo : newApiData.evolvesTo == 'None'? '' : newApiData.evolvesTo,
                abilities : newApiData.effect,
                attacks : newApiData.attacks,
                rules :  newApiData.rules == 'None'? [] : newApiData.rules,
                number : newApiData.sequenceNumber.toString(),
                weaknesses : newApiData.weaknesses,
                resistances : newApiData.resistances,
                retreatCost : newApiData.retreatCost,
                convertedRetreatCost : newApiData.convertedRetreatCost,
                supertype : newApiData.supertype,
                subtypes : newApiData.subtypes,
                rarity : newApiData.rarity == 'None'? '' : newApiData.rarity,
                artist : newApiData.artist
            }

            return oldApiFormat
        },

        // 자체 이미지 저장
        async convertURLtoFile(url, upload_type, savename){
            url = await url.replaceAll("\"", "")

            // CORS 프록시를 사용하여 URL을 변환
            // const proxiedUrl = `https://cors-anywhere.herokuapp.com/${url}`
            const proxiedUrl = `/cors-proxy/${url}`

            // 프록시된 URL로 fetch 호출
            const response = await fetch(proxiedUrl, {
                headers: {
                    'Origin': url,
                    'x-requested-with': 'XMLHttpRequest'
                }
            })
            const data = await response.blob()
            const ext = url.split(".").pop()
            const filename = savename + "." + ext
            const metadata = { type: `image/${ext}` }
            var file = await new File([data], filename, metadata)
            var formData = await new FormData()
            await formData.append("image", file)

            // 이미지 업로드
            const uploadFile = await this.$http.post('/api/image/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: {
                    type: upload_type
                }
            })

            // 업로드된 파일명
            return uploadFile.data
        },

        // 파일명으로 문제없게 변환
        sanitizeFileName(filename) {
            //윈도우즈와 Unix/Linux/macOS에서 불허용된 문자들을 대체합니다.
            // 이 예에서는 불허용 문자들을 모두 하이픈('-')으로 대체하고 있습니다.
            let sanitized = filename.replace(/[<>:"/\\|?*]+/g, '-')
            
            // 제어 문자들도 제거합니다.
            sanitized = sanitized.replace(/[\x00-\x1F\x80-\x9F]/g, '')
            
            return sanitized
        },

        // 새 제품명 확인하기
        update_set_list(){
            this.$http.get("https://www.jpn-cards.com/v2/set")
            .then(async (res) => {
                this.new_api_set_list = res.data

                // this.set_list에 있는 id들을 추출
                const existingIds = new Set(this.set_list.map(item => item.id))

                // this.new_api_set_list 중에서 existingIds에 포함되지 않는 항목만 필터링
                const difference = this.new_api_set_list.filter(item => !existingIds.has(item.id))

                // console.log(difference) // difference에는 this.new_api_set_list에서 this.set_list를 뺀 결과가 저장됩니다.

                if(difference.length){
                    if(confirm(`새 제품명(${difference.map(e=>e.name).toString()})이 있습니다.\n추가하시겠습니까?`)){
                        for(let set of difference){
                            await this.$http.post('/api/admin/card/new/api_list/first/insert', {
                                params: {
                                    card_count: set.card_count,
                                    date: set.date,
                                    id: set.id,
                                    image_url: set.image_url,
                                    language: set.language,
                                    name: set.name,
                                    printed_count: set.printed_count,
                                    set_code: set.set_code,
                                    source_url: set.source_url,
                                    uuid: set.uuid,
                                    year: set.year,
                                }
                            }).then(res => {
                                console.log('첫 입력', res)
                            })
                        }
                        alert("제품명이 추가되었습니다.")
                        this.loadList()
                    }
                }else{
                    alert("새 제품명이 없습니다.")
                }
            })
        },

        // 상태 변경하기
        update_status(item){
            this.$http.post('/api/admin/card/new/api_list/update/status', {
                params: {
                    status: item.status,
                    id: item.id
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    alert("상태가 변경되었습니다.")
                }
            })
        },

        // 메모 변경하기
        update_memo(item){
            this.$http.post('/api/admin/card/new/api_list/update/memo', {
                params: {
                    memo: item.memo,
                    id: item.id
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    alert("메모가 변경되었습니다.")
                }
            })
        },

        // 카드명 한글로 번역
        translateToKr(name) {
            for(let obj of this.nameList)
            {
                // 포함하면서 온전한 단어인지 확인
                const wordRegex = new RegExp(`\\b${obj.en}\\b`, 'i')

                // 이름이 번역 키워드랑 완전히 일치하는 경우
                if(obj.en === name)
                {
                    return obj.kr
                }

                // 이름이 번역 키워드를 포함하면서 &기호는 없는 경우
                else if(wordRegex.test(obj.en) && !name.includes('&'))
                {
                    return name.replace(obj.en, obj.kr)
                }

                // 이름이 번역 키워드를 포함하면서 &기호가 있는 경우
                else if(wordRegex.test(obj.en) && name.includes('&'))
                {
                    // 첫번째 이름 변경
                    let translate = name.replace(obj.en, obj.kr)

                    // & 기호 횟수만큼 이름 변경
                    for(let i=0; i<name.split('&').length-1; i++)
                    {
                        for(let obj of this.nameList)
                        {
                            translate = translate.replace(obj.en, obj.kr)
                        }
                    }
                    return translate
                }
            }
            return name
        },

        // 카드명 일어로 번역
        translateToJp(name) {
            for(let obj of this.nameList)
            {
                // 포함하면서 온전한 단어인지 확인
                const wordRegex = new RegExp(`\\b${obj.en}\\b`, 'i')
                
                // 이름이 번역 키워드랑 완전히 일치하는 경우
                if(obj.en === name)
                {
                    return obj.jp
                }

                // 이름이 번역 키워드를 포함하면서 &기호는 없는 경우
                else if(wordRegex.test(obj.en) && !name.includes('&'))
                {
                    return name.replace(obj.en, obj.jp)
                }

                // 이름이 번역 키워드를 포함하면서 &기호가 있는 경우
                else if(wordRegex.test(obj.en) && name.includes('&'))
                {
                    // 첫번째 이름 변경
                    let translate = name.replace(obj.en, obj.jp)

                    // & 기호 횟수만큼 이름 변경
                    for(let i=0; i<name.split('&').length-1; i++)
                    {
                        for(let obj of this.nameList)
                        {
                            translate = translate.replace(obj.en, obj.jp)
                        }
                    }
                    return translate
                }
            }
            return name
        },

        // 날짜 형식 변경하기
        formatDateToMySQLDatetime(inputDate) {
            // Date 객체로 변환
            const dateObj = new Date(inputDate);

            // YYYY-MM-DD 형식으로 변환
            return `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, '0')}-${String(dateObj.getDate()).padStart(2, '0')} 00:00:00`;
        },

        // 제품명 삭제하기 Submit
        delete_submit(){
            if(this.set_name==""){
                alert("업데이트할 제품명을 선택해주세요.")
            }else{
                this.$http.post('/api/admin/card/new/delete', {
                    params: {
                        set_name: this.set_name
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        alert("선택하신 제품명이 정상적으로 삭제되었습니다.")
                        this.dialog.delete = false
                        this.loadList()
                    }
                })
            }
        },

        // 선택 추가하기 > 자체 DB 추가하기 
        async select_card_submit(){
            console.log(this.selectedCard)

            this.loading = true

            // 제품명 이미지 추출
            let upload_set_jp_image = await this.convertURLtoFile(this.selectedItem.image_url, "set_jp_image", this.sanitizeFileName(this.selectedItem.name))

            // 한글 제품명 입력
            this.$http.post('/api/admin/card/new/set/add/kr', {
                params: {
                    name: this.selectedItem.name,
                    totalNumber: this.selectedItem.printed_count,
                    release_datetime: this.formatDateToMySQLDatetime(this.selectedItem.date)
                }
            }).then((res) => {
                console.log("한글 제품명 입력", res)
            })

            // 영어 제품명 입력
            this.$http.post('/api/admin/card/new/set/add', {
                params: {
                    name: this.selectedItem.name,
                    totalNumber: this.selectedItem.printed_count,
                    release_datetime: this.formatDateToMySQLDatetime(this.selectedItem.date)
                }
            }).then((res) => {
                console.log("영어 제품명 입력", res)
            })

            // 일어 제품명 입력
            this.$http.post('/api/admin/card/new/set/add/jp/image', {
                params: {
                    name: this.selectedItem.name,
                    image: upload_set_jp_image,
                    totalNumber: this.selectedItem.printed_count,
                    release_datetime: this.formatDateToMySQLDatetime(this.selectedItem.date)
                }
            }).then((res) => {
                console.log("일어 제품명 입력", res)
            })

            // 카드 정보 입력
            for (let i = 0; i < this.selectedCard.length; i++)
            {
                const new_card = this.selectedCard[i]
                const convertedData = this.convertToOldApiFormat(new_card)
                let upload_card_jp_image = await this.convertURLtoFile(new_card.imageUrl, "card_jp_image", this.sanitizeFileName(new_card.name))

                // card_dump 입력
                this.$http.post('/api/admin/card/pre_card/insert/card_dump', {
                    params: {
                        id: this.selectedCard[i].id,
                        image: 'default.png',
                        dump: JSON.stringify(convertedData),
                        isAvailable: 0
                    }
                }).then(res => {
                    console.log("card_dump 입력", res)
                })
                .catch((err) => {
                    console.error(err)
                })

                // basic 입력 (KR)
                await this.$http.post('/api/admin/card/pre_card/insert/card_kr', {
                    params: {
                        id: convertedData.id,
                        name: this.translateToKr(convertedData.name),
                        ancientTrait_name: convertedData.ancientTrait_name,
                        ancientTrait_text: convertedData.ancientTrait_text,
                        effects: null,
                        rules: convertedData.rules.toString(),
                        image: "default.png",
                        number: convertedData.number,
                        rarity: convertedData.rarity,
                        set_printedTotal: convertedData.set.printedTotal.toString(),
                        set_name: convertedData.set.name,
                        writer: this.$store.state.user_id,
                        isAvailable: 1
                    }
                }).then(res => {
                    console.log('basic 입력 (KR)', res)
                })
            
                // basic 입력 (JP)
                await this.$http.post('/api/admin/card/pre_card/insert/card_jp', {
                    params: {
                        id: convertedData.id,
                        name: this.translateToJp(convertedData.name),
                        ancientTrait_name: convertedData.ancientTrait_name,
                        ancientTrait_text: convertedData.ancientTrait_text,
                        effects: null,
                        rules: convertedData.rules.toString(),
                        image: upload_card_jp_image,
                        number: convertedData.number,
                        rarity: convertedData.rarity,
                        set_printedTotal: convertedData.set.printedTotal.toString(),
                        set_name: convertedData.set.name,
                        writer: this.$store.state.user_id,
                        isAvailable: 1
                    }
                }).then(res => {
                    console.log('basic 입력 (JP)', res)
                })

                // abilities 입력 (KR)
                if(convertedData.abilities && convertedData.abilities.length){
                    for(let ability of convertedData.abilities){
                        await this.$http.post('/api/admin/card/pre_card/insert/card_kr/abilities', {
                            params: {
                                card_id: convertedData.id,
                                type: ability.type,
                                name: ability.name,
                                text: ability.text
                            }
                        }).then(res => {
                            console.log('abilities 입력 (KR)', res)
                        })
                    }
                }

                // attacks 입력 (KR)
                if(convertedData.attacks && convertedData.attacks.length){
                    for(let attack of convertedData.attacks){
                        await this.$http.post('/api/admin/card/pre_card/insert/card_kr/attacks', {
                            params: {
                                card_id: convertedData.id,
                                name: attack.name,
                                cost: attack.cost.toString(),
                                convertedEnergyCost: attack.cost.length,
                                damage: attack.damage,
                                text: attack.text
                            }
                        }).then(res => {
                            console.log('attacks 입력 (KR)', res)
                        })
                    }
                }

                // abilities 입력 (JP)
                if(convertedData.abilities && convertedData.abilities.length){
                    for(let ability of convertedData.abilities){
                        await this.$http.post('/api/admin/card/pre_card/insert/card_jp/abilities', {
                            params: {
                                card_id: convertedData.id,
                                type: ability.type,
                                name: ability.name,
                                text: ability.text
                            }
                        }).then(res => {
                            console.log('abilities 입력 (JP)', res)
                        })
                    }
                }

                // attacks 입력 (JP)
                if(convertedData.attacks && convertedData.attacks.length){
                    for(let attack of convertedData.attacks){
                        await this.$http.post('/api/admin/card/pre_card/insert/card_jp/attacks', {
                            params: {
                                card_id: convertedData.id,
                                name: attack.name,
                                cost: attack.cost.toString(),
                                convertedEnergyCost: attack.cost.length,
                                damage: attack.damage,
                                text: attack.text
                            }
                        }).then(res => {
                            console.log('attacks 입력 (JP)', res)
                        })
                    }
                }

                if (i === this.selectedCard.length - 1) {
                    alert("자체 DB에 추가하기가 완료되었습니다")
                    this.loading = false
                    this.dialog.select = false
                }
            }
        }
    }
}
</script>
<style scoped>
.disposeTable{
    width: 100%;
    border: 1px solid #e0e0e0;
    border-collapse: collapse;
}

.disposeTable tr td{
    padding: 12px;
    border: 1px solid #e0e0e0;
}

.disposeTable tr td:first-child{
    width:160px;
    text-align:center;
    background:#f8f8f8;
    font-weight:bold;
}
</style>

<style scoped>
.selectTable{
    width: 100%;
    border: 1px solid #e0e0e0;
    border-collapse: collapse;
}

.selectTable tr td{
    padding: 6px 8px;
    border: 1px solid #e0e0e0;
    text-align:center;
}
</style>