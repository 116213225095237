<template>
    <v-sheet color="transparent">
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">카드검색 기본값</font>
        </v-sheet>
        
        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <v-sheet class="pa-2 pb-16">
                <p class="mb-2">
                    카드검색 첫 페이지 제품명
                </p>
                <v-sheet class="d-flex">
                    <v-autocomplete
                        style="max-width:600px;"
                        dense outlined hide-details
                        :items="set_list"
                        v-model="set_name"
                    ></v-autocomplete>
                    <v-btn
                        height="40"
                        dark color="blue"
                        class="ml-2 px-6"
                        @click="updateFirstSet()"
                        depressed
                    >
                        <v-icon small left>mdi-database-edit-outline</v-icon>
                        설정하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        set_list: [],
        set_name: ""
    }),

    mounted(){
        // 기존 설정된 제품명 가져오기
        this.importFirstSet()

        // 한글 제품명 list 불러오기
        this.importSetList()
    },

    methods: {
        // 기존 설정된 제품명 가져오기
        importFirstSet(){
            this.$http.post('/api/admin/card/search/first/set/import')
            .then((res) => {
                this.set_name = res.data[0].set_name
            })
        },

        // 한글 제품명 list 불러오기
        importSetList(){
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.set_list = res.data.map(a => a.name)
            })
        },

        // 제품명 설정 변경하기
        updateFirstSet(){
            this.$http.post('/api/admin/card/search/first/set/update', {
                params: {
                    set_name: this.set_name
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    alert("제품명 설정이 변경되었습니다.")
                }
            })
        }
    }
}
</script>