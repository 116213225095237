<template>
    <v-sheet color="transparent">
        <!-- 헤더 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">대량복사</font>
        </v-sheet>
        
        <!-- 본문 -->
        <v-sheet outlined class="pa-10 ma-2">
            <table
                class="reportTable" 
                style="width:660px;"
            >
                <tr>
                    <td>복사할 파일</td>
                    <td>
                        <!-- 파일 입력 -->
                        <v-file-input
                            prepend-icon=""
                            append-icon="mdi-file-outline"
                            outlined
                            label="csv 파일 첨부"
                            hide-details
                            v-model="csv_file"
                            @change="handleFileUpload(csv_file)"
                        />
                    </td>
                </tr>
                <tr>
                    <td>한글 제품명</td>
                    <td class="py-2 px-3">
                        <v-sheet width="480">
                            <v-autocomplete
                                placeholder="선입력카드의 제품명을 선택해주세요"
                                dense
                                hide-details
                                outlined
                                :items="sets_selectList_kr"
                                v-model="selected_set_kr"
                            ></v-autocomplete>
                        </v-sheet>
                    </td>
                </tr>
                <tr>
                    <td>일본 제품명</td>
                    <td class="py-2 px-3">
                        <v-sheet width="480">
                            <v-autocomplete
                                placeholder="선입력카드의 제품명을 선택해주세요"
                                dense
                                hide-details
                                outlined
                                :items="sets_selectList_jp"
                                v-model="selected_set_jp"
                            ></v-autocomplete>
                        </v-sheet>
                    </td>
                </tr>
            </table>
            <v-sheet class="mt-2 pa-2 d-flex justify-center" width="660">
                <v-btn :disabled="!csv_file" depressed color="blue" large class="rounded-10 px-12 white--text" @click="submit()">
                    <v-icon small left>mdi-plus-box</v-icon>복사하기
                </v-btn>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>
import Papa from 'papaparse'

export default {
    data: () => ({
        csv_file: null,
        csv_list: [],

        sets_selectList_kr: [],
        sets_selectList_jp: [],

        selected_set_kr: "",
        selected_set_jp: "",
    }),

    mounted(){
        // 한글 제품명 list 불러오기
        this.$http.post('/api/card/select/sets', {
            params: {
                nation: "kr"
            }
        }).then(res => { 
             this.sets_selectList_kr = res.data.map(a => a.name)
        })

        // 일본 제품명 list 불러오기
        this.$http.post('/api/card/select/sets', {
            params: {
                nation: "jp"
            }
        }).then(res => { 
             this.sets_selectList_jp = res.data.map(a => a.name)
        })
    },

    methods: {
        handleFileUpload(file) {
            if (file) {
                Papa.parse(file, {
                    complete: (result) => {
                        console.log(result.data)
                        // 마지막 행 제거
                        if (result.data && result.data.length > 0) {
                            result.data.pop()
                            this.csv_list = result.data
                        }
                    },
                    header: true
                })
            }
        },

        // 복사하기
        submit(){
            if(this.selected_set_kr == ""){
                alert("한글 제품명을 선택해주세요")
            }
            else if(this.selected_set_jp == ""){
                alert("일어 제품명을 선택해주세요")
            }
            else{
                // 모든 요청을 담을 Promise 배열 생성
                const requests = this.csv_list.map(item => {
                    return this.$http.post('/api/admin/card/pre_card/copy2', {
                        params: {
                            id: item.original_id,
                            pre_card_id: item.copy_id,
                            set_name_kr: this.selected_set_kr,
                            set_name_jp: this.selected_set_jp,
                            number: item.number,
                            image_en: item.image_en? item.image_en : 'default.png',
                            image_kr: item.image_kr? item.image_kr : 'default.png',
                            image_jp: item.image_jp? item.image_jp : 'default.png'
                        }
                    })
                })
                
                // 모든 요청을 병렬로 처리하고, 모두 완료되면 알림 표시
                Promise.all(requests)
                .then(() => {
                    alert("모든 카드가 정상적으로 복사되었습니다.")
                })
                .catch(error => {
                    // 에러 처리
                    console.error("에러 발생:", error)
                    alert("오류가 발생했습니다. 다시 시도해 주세요.")
                })
            }
        }
    }
}
</script>
<style scoped>
.reportTable{
    width: 100%;
    border: 1px solid #e0e0e0;
    border-collapse: collapse;
}

.reportTable tr td{
    padding: 12px;
    border: 1px solid #e0e0e0;
}

.reportTable tr td:first-child{
    width:160px;
    text-align:center;
    background:#f8f8f8;
    font-weight:bold;
}
</style>