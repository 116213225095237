<template>
    <v-sheet>
        <table class="abilities_table mt-2">
            <thead>
                <tr>
                    <th colspan="5" class="blue lighten-5">능력 정보</th>
                </tr>
            </thead>
            <tbody v-for="(item, index) in abilities" :key="index">
                <tr>
                    <td class="name">속성</td>
                    <td>
                        <v-text-field
                            hide-details outlined dense
                            v-model="item.type"
                            @change="abilitiesUpdated()"
                        ></v-text-field>
                    </td>
                    <td class="name">능력명</td>
                    <td>
                        <v-text-field
                            hide-details outlined dense
                            v-model="item.name"
                            @change="abilitiesUpdated()"
                        ></v-text-field>
                    </td>
                    <td rowspan="2" class="pa-0" style="width:100px;">
                        <v-btn
                            v-if="!index"
                            height="40"
                            small
                            color="blue"
                            dark
                            depressed
                            @click="addField()"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="index"
                            height="40"
                            small
                            color="blue"
                            dark
                            depressed
                            @click="subtractField(index)"
                        >
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>
                    </td>
                </tr>
                <tr>
                    <td class="name">설명</td>
                    <td colspan="3">
                        <v-text-field
                            hide-details outlined dense
                            v-model="item.text"
                            @change="abilitiesUpdated()"
                        ></v-text-field>
                    </td>
                </tr>
            </tbody>
        </table>
    </v-sheet>
</template>
<script>
export default {
    props: [
        "card_id", 
        "card_nation",
        "type",
        "cardProvideReadId"
    ],

    data: () => ({
        abilities: [
            {
                type: "",
                name: "",
                text: ""
            }
        ]
    }),

    mounted(){
        if(this.type == "update" || this.type == "provide" || this.type == "read"){
            this.load()
        }
    },

    watch: {
        card_nation(){
            if(this.type == "update" || this.type == "provide" || this.type == "read"){
                this.load()
            }
        }
    },

    methods: {
        load(){
            // 제보카드 읽기가 아닐 경우
            if(this.type != "read")
            {
                if(this.card_nation == "kr"){
                    this.$http.post('/api/admin/card/pre_card/import/card_kr/abilities', {
                        params: {
                            card_id : this.card_id
                        }
                    }).then((res) => {
                        // 한글카드 기술정보가 있을떄
                        if(res.data.length){
                            this.abilities = res.data
                            this.$emit("abilitiesUpdated", this.abilities)
                        // 한글카드 기술정보가 없을떄 (영어카드 기술정보 가져오기)
                        }else{
                            this.$http.post('/api/admin/card/pre_card/import/card_dump/abilities', {
                                params: {
                                    card_id : this.card_id
                                }
                            }).then((res) => {
                                if(JSON.parse(res.data[0].dump).abilities){
                                    this.abilities = JSON.parse(res.data[0].dump).abilities
                                    this.$emit("abilitiesUpdated", this.abilities)
                                }
                            })
                        }

                    })
                }else if(this.card_nation == "jp"){
                    this.$http.post('/api/admin/card/pre_card/import/card_jp/abilities', {
                        params: {
                            card_id : this.card_id
                        }
                    }).then((res) => {
                        // 일본카드 기술정보가 있을떄
                        if(res.data.length){
                            this.abilities = res.data
                            this.$emit("abilitiesUpdated", this.abilities)
                        // 일본카드 기술정보가 없을떄 (영어카드 기술정보 가져오기)
                        }else{
                            this.$http.post('/api/admin/card/pre_card/import/card_dump/abilities', {
                                params: {
                                    card_id : this.card_id
                                }
                            }).then((res) => {
                                if(JSON.parse(res.data[0].dump).abilities){
                                    this.abilities = JSON.parse(res.data[0].dump).abilities
                                    this.$emit("abilitiesUpdated", this.abilities)
                                }
                            })
                        }
                    })
                }else if(this.card_nation == "en"){
                    this.$http.post('/api/admin/card/pre_card/import/card_dump/abilities', {
                        params: {
                            card_id : this.card_id
                        }
                    }).then((res) => {
                        if(JSON.parse(res.data[0].dump).abilities){
                            this.abilities = JSON.parse(res.data[0].dump).abilities
                            this.$emit("abilitiesUpdated", this.abilities)
                        }
                    })
                }
            }

            // 제보카드 읽기의 경우
            else if(this.type == "read")
            {
                this.$http.post('/api/admin/provide/info/import/card/abilities', {
                    params: {
                        card_provide_id : this.card_id
                    }
                }).then((res) => {
                    if(res.data.length){
                        this.abilities = res.data
                        this.$emit("abilitiesUpdated", this.abilities)
                    }
                })
            }
        },

        // 능력 추가
        addField(){
            this.abilities.push(
                {
                    type: "",
                    name: "",
                    text: ""
                }
            )
        },

        // 능력 빼기
        subtractField(index){
            this.abilities.splice(index,1)
            this.abilitiesUpdated()
        },

        // 능력 정보 업데이트
        abilitiesUpdated(){
            this.$emit("abilitiesUpdated", this.abilities)
        }
    }
}
</script>
<style scoped>
/* 능력 정보 테이블 */
.abilities_table{
    width:100%;
    border:1px solid #ccc;
    border-collapse: collapse;
    text-align:center;
}

/* 헤더 (능력 정보) */
.abilities_table tr th{
    height:44px;
    font-size:13pt;
    font-weight:bold;
}

/* TD */
.abilities_table tr td{
    font-size:11pt;
    height:40px;
    border:1px solid #ccc;
    padding: 6px;
}

/* 이름 TD */
.name{
    width:100px;
    background:#f2f3f4;
}
</style>