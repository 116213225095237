<template>
    <v-sheet color="transparent">
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2">
            <!-- 페이지 제목 -->
            <font class="font-weight-bold pa-4 text-h6 grey--text text--darken-2">재수록카드 관리</font>
            <v-spacer></v-spacer>

            <!-- 한글, 일본 시즌 선택 -->
            <v-sheet width="200">
                <v-select
                    class="mr-4"
                    outlined dense hide-details
                    v-model="selected_nation"
                    :items="select_list.nation"
                    item-text="text"
                    item-value="value"
                ></v-select>
            </v-sheet>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2">
                <v-sheet class="d-flex" width="660">
                    <v-sheet width="160" class="mr-3">
                        <v-select
                            outlined
                            hide-details
                            dense
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>
                    <v-text-field
                        v-model="keyword"
                        outlined
                        hide-details
                        dense
                        placeholder="검색어를 입력해주세요"
                        @keyup.enter="search()"
                    ></v-text-field>
                    <v-btn
                        height="40"
                        dark color="blue"
                        class="px-6 ml-3"
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-data-table
                id="list"
                :headers="headers"
                :items="list"
                :footer-props="{'items-per-page-options': [10, 20, 100, -1]}"
                no-data-text="아직 등록된 카드가 없습니다."
            >
                <template v-slot:item="{ item }">
                    <tr>
                        <td style="width:80px" class="pa-2">
                            <v-img 
                                width="80"
                                class="mx-auto"
                                :src="'/upload/card_'+selected_nation+'_image/'+encodeURI(item.image)"
                            ></v-img>
                        </td>
                        <td @click="linkToDetail(item)" style="width:160px;cursor:pointer"><font class="d-block text-truncate" style="width:160px;">{{item.id}}</font></td>
                        <td @click="linkToDetail(item)" style="width:180px;cursor:pointer"><font class="d-block text-truncate" style="width:180px;">{{item.name}}</font></td>
                        <td @click="linkToDetail(item)" style="width:180px;cursor:pointer"><font class="d-block text-truncate" style="width:180px;">{{item.rarity}}</font></td>
                        <td @click="linkToDetail(item)" style="width:100px;cursor:pointer"><font class="d-block text-truncate" style="width:100px;">{{item.number}}</font></td>
                        <td @click="linkToDetail(item)" style="width:100px;cursor:pointer"><font class="d-block text-truncate" style="width:100px;">{{item.set_printedTotal}}</font></td>
                        <td @click="linkToDetail(item)" style="width:100%;cursor:pointer"><font class="d-block text-truncate" style="width:360px;">{{item.set_name}}</font></td>
                    </tr>
                </template>
            </v-data-table>

            <!-- 선택항목 & 버튼 -->
            <v-card
                class="mt-2 pa-2 d-flex justify-end"
                outlined
            >
                <v-btn
                    class="px-6 mr-3"
                    color="blue"
                    large
                    dark
                    depressed
                    @click="dialog.create = true"
                >
                    <v-icon small left>mdi-database-plus-outline</v-icon>
                    추가하기
                </v-btn>
                <v-btn
                    class="px-6"
                    color="red"
                    large
                    dark
                    depressed
                    @click="dialog.delete = true"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    삭제하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- 추가하기 -->
        <v-dialog
            v-model="dialog.create"
            width="auto"
            content-class="rounded-15"
        >
            <v-sheet
                class="pa-4"
            >
                <p class="text-h6 text-center font-weight-bold">재수록카드 추가하기</p>

                <v-sheet>
                    <table class="customTable">
                        <tr>
                            <td>카드명</td>
                            <td style="width:100px;">
                                <v-text-field
                                    style="width:300px;"
                                    outlined
                                    dense
                                    hide-details
                                    v-model="renewal_name"
                                ></v-text-field>
                            </td>
                        </tr>
                    </table>
                </v-sheet>

                <v-sheet class="mt-4 d-flex justify-center">
                    <v-btn
                        class="rounded-10 px-6 mr-4"
                        color="blue"
                        dark
                        large
                        depressed
                        @click="createSubmit()"
                    >
                        <v-icon small left>mdi-database-edit-outline</v-icon>
                        추가하기
                    </v-btn>
                    <v-btn
                        class="rounded-10 px-6"
                        color="grey"
                        dark
                        large
                        depressed
                        @click="dialog.create = false; renewal_name = ''"
                    >
                        <v-icon small left>mdi-cancel</v-icon>
                        취소하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>

        <!-- 삭제하기 -->
        <v-dialog
            v-model="dialog.delete"
            width="auto"
            content-class="rounded-15"
        >
            <v-sheet
                class="pa-4"
            >
                <p class="text-h6 text-center font-weight-bold">재수록카드 삭제하기</p>

                <v-sheet>
                    <table class="customTable">
                        <tr>
                            <td>카드명</td>
                            <td style="width:100px;">
                                <v-text-field
                                    style="width:300px;"
                                    outlined
                                    dense
                                    hide-details
                                    v-model="renewal_name"
                                ></v-text-field>
                            </td>
                        </tr>
                    </table>
                </v-sheet>

                <v-sheet class="mt-4 d-flex justify-center">
                    <v-btn
                        class="rounded-10 px-6 mr-4"
                        color="blue"
                        dark
                        large
                        depressed
                        @click="deleteSubmit()"
                    >
                        <v-icon small left>mdi-database-edit-outline</v-icon>
                        삭제하기
                    </v-btn>
                    <v-btn
                        class="rounded-10 px-6"
                        color="grey"
                        dark
                        large
                        depressed
                        @click="dialog.delete = false; renewal_name = ''"
                    >
                        <v-icon small left>mdi-cancel</v-icon>
                        취소하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        select_list: {
            search_type: [
                "ID",
                "카드명",
            ],

            nation: [
                {text: "한글 재수록카드", value: "kr"},
                {text: "영어 재수록카드", value: "en"},
                {text: "일본 재수록카드", value: "jp"}
            ]
        },

        headers: [
            {text: '이미지', value: 'image'},
            {text: 'ID', value: 'id'},
            {text: '카드명', value: 'name'},
            {text: '희귀도', value:'rarity'},
            {text: '블록넘버', value:'number'},
            {text: '토탈넘버', value:'set_printedTotal'},
            {text: '제품명', value:'set_name'}
        ],
        
        list: [],

        selected_nation: "kr",
        search_type: "카드명",
        keyword: "",

        // 삭제, 추가 > 이름
        renewal_name: "",

        dialog: {
            create: false,
            delete: false,
        },
    }),

    mounted(){
        this.search()
    },

    watch: {
        selected_nation(){
            this.list = []
            this.search()
        }
    },

    methods: {
        // 검색
        search(){
            this.$http.post('/api/admin/deck/renewal/search', {
                params: {
                    search_type: this.search_type,
                    keyword: this.keyword,
                    nation: this.selected_nation
                }
            }).then((res) => {
                console.log(res)
                this.list = res.data
            })
        },

        // 카드상세 페이지 링크
        linkToDetail(item){
            window.open('/card/detail?id='+item.id, '_blank')
        },

        // 추가하기
        createSubmit() {
            this.$http.post('/api/admin/deck/renewal/insert', {
                params: {
                    renewal_name: this.renewal_name.replace("'","\\'"),
                    nation: this.selected_nation
                }
            }).then((res) => {
                console.log(res)
                alert("카드가 추가되었습니다.")
                this.search()
                this.renewal_name = ""
                this.dialog.create = false
            })
        },

        // 삭제하기
        deleteSubmit(){
            this.$http.post('/api/admin/deck/renewal/delete', {
                params: {
                    renewal_name: this.renewal_name.replace("'","\\'"),
                    nation: this.selected_nation
                }
            }).then((res) => {
                console.log(res)
                alert("카드가 삭제되었습니다.")
                this.search()
                this.renewal_name = ""
                this.dialog.delete = false
            })
        },
    }
}
</script>
<style scoped>
/* 목록 */
#list{
    border:1px solid #efefef;
}
#list tr th{
    background:#f7f7f7;
}

#list tr th, #list tr td{
    padding:0 5px;
    padding-left:10px;
}

#list tr th:not(:last-child), #list tr td:not(:last-child){
    border-right:1px solid #efefef;
}

/* Table (수정하기, 추가하기) */
.customTable{
    border:1px solid #efefef;
    border-collapse: collapse;
}

.customTable tr td:first-child{
    text-align: center;
    font-weight: bold;
    background:#f2f2f2;
    height:50px;
    width:140px;
    padding:10px;
    border:1px solid #dedede;
}

.customTable tr td{
    height:50px;
    width:400px;
    padding:10px;
    border:1px solid #dedede;
}
</style>
<style scoped>
.my-clipper {
    width:100%;
}

.placeholder {
    width:300px;
    height:100px;
}
</style>